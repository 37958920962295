import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

// Testimonial data
const testimonialsData = [
    {
        id: 1,
        name: 'Miss. Priya S.',
        image: 'https://via.placeholder.com/100?text=Jonathan',
        rating: 5,
        text: '“I had a fantastic experience with Shraddha Travels for our trip to Mahabaleshwar. The driver was punctual and friendly, making the journey comfortable. Highly recommend!”',
    },
    {
        id: 2,
        name: 'Mr. Rahul M. ',
        image: 'https://via.placeholder.com/100?text=Adam',
        rating: 5,
        text: '“Shraddha Travels made our Ujjain trip memorable! Our driver was professional and guided us to all the best spots. A hassle-free experience!”',
    },
    // Add more testimonials as needed
];

const TestimonialSection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <section className="testimonial-section pt_100 pb_90 py-5">
            <div className="pattern-layer" style={{ backgroundImage: 'url(assets/images/shape/shape-3.png)' }}></div>
            <div className="auto-container">
                <div className="sec-title centred mb_50">
                    <span className="sub-title">Testimonials</span>
                    {/* <h2>Love from our Clients</h2> */}
                </div>
                <Slider {...settings}>
                    {testimonialsData.map(testimonial => (
                        <div className="testimonial-block-one" key={testimonial.id}>
                            <div className="inner-box">
                                <div className="author-box">
                                    <h3 className='text-white'>{testimonial.name}</h3>
                                </div>
                                <p>{testimonial.text}</p>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>

            {/* CSS styles */}
            <style jsx>{`
                .testimonial-section {
                    overflow: hidden; /* Prevents horizontal scroll */
                    width: 100%;
                }

                .auto-container {
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 0 15px; /* Prevents horizontal overflow */
                    box-sizing: border-box;
                }

                .testimonial-block-one {
                    background: white;
                    border-radius: 8px;
                    padding: 20px;
                    margin: 0 10px;
                    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
                    transition: transform 0.3s ease;
                }

                .testimonial-block-one:hover {
                    transform: scale(1.03);
                }

              

                .sec-title h2 {
                    font-size: 2rem;
                    color: #333;
                }

                @media (max-width: 768px) {
                    .sec-title h2 {
                        font-size: 1.5rem;
                    }
                }

            `}</style>
        </section>
    );
};

export default TestimonialSection;
