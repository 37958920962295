
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetomumbaidarshan() {



  const cardData =
  {
    keyword: 'Pune to Mumbai Darshan Bus on Rent',
    heading: 'Shraddha Travels: Pune to Mumbai Darshan Bus on Rent',
    headingDescription: 'Traveling from Pune to Mumbai provides an excellent opportunity to experience one of India’s most dynamic cities. At Shraddha Travels, we specialize in offering top-tier bus rental services for your Mumbai Darshan adventure. Our modern, well-maintained buses ensure a comfortable and enjoyable journey, while our experienced drivers guarantee a smooth and safe trip.',
    top: 'Top Places to Visit in Mumbai with Shraddha Travels:',

    topPlaces: [
        {
          title: "1. Gateway of India",
          description: "This monumental structure overlooking the Arabian Sea is a symbol of Mumbai’s grandeur and serves as a popular starting point for exploring the city’s rich history and vibrant culture. Built in 1924 to commemorate the visit of King George V, the Gateway is an architectural masterpiece, blending Hindu and Muslim styles. Visitors can enjoy boat rides from here, taking in stunning views of the city skyline."
        },
        {
          title: "2. Marine Drive",
          description: "Often referred to as the Queen's Necklace, Marine Drive offers a picturesque promenade along the coastline. Stretching over 3.6 kilometers, it’s an ideal spot for evening walks, enjoying the sea breeze, and capturing breathtaking views of the sunset. The promenade is lined with palm trees and provides a lively atmosphere, especially during the evenings when locals gather to unwind."
        },
        {
          title: "3. Chhatrapati Shivaji Maharaj Terminus",
          description: "A UNESCO World Heritage Site, this grand railway station is an architectural gem showcasing Victorian Gothic Revival style. Designed by architect Frederick William Stevens and completed in 1888, it represents Mumbai’s colonial past and is a must-visit for its stunning design. The station is not only a functional railway hub but also a testament to the city's rich architectural heritage."
        },
        {
          title: "4. Elephanta Caves",
          description: "Located on Elephanta Island, these ancient rock-cut temples are dedicated to Lord Shiva. The caves are renowned for their intricate sculptures, including the impressive Trimurti, a 20-foot depiction of a three-headed Shiva. Designated as a UNESCO World Heritage Site, Elephanta Caves provide a glimpse into India’s artistic and spiritual heritage, attracting history enthusiasts and tourists alike."
        },
        {
          title: "5. Juhu Beach",
          description: "A popular destination for locals and tourists alike, Juhu Beach is known for its lively atmosphere, street food, and beautiful coastline. With golden sands and a vibrant atmosphere, it’s perfect for relaxing by the sea or taking a leisurely stroll at sunset. The beach is famous for its local delicacies, such as vada pav and pani puri, making it a culinary hotspot as well."
        },
        {
          title: "6. Haji Ali Dargah",
          description: "Situated on an islet off the coast of Worli, Haji Ali Dargah is a revered pilgrimage site known for its stunning architecture and serene environment. Built in the 19th century, the mosque is a blend of Indo-Islamic architecture and attracts visitors from all faiths. The walk to the Dargah during low tide offers a unique experience, with the sea on both sides, enhancing the spiritual ambiance."
        },
        {
          title: "7. Siddhivinayak Temple",
          description: "This famous Ganesh temple is one of the most visited religious sites in Mumbai. Devotees come here seeking blessings from Lord Ganesha, making it an important cultural and spiritual landmark. The temple, built in 1801, features a beautiful idol of Ganesha adorned with gold and precious stones. The temple's architecture and the spiritual energy create a deeply moving experience for visitors."
        },
        {
          title: "8. Colaba Causeway",
          description: "A bustling shopping street, Colaba Causeway is famous for its vibrant markets, eclectic mix of shops, and diverse eateries. It’s a great place to experience Mumbai’s lively street life and shop for unique items, from clothes to antiques. The area is also home to several historic cafes and eateries, making it a perfect spot to enjoy local cuisine while soaking in the energetic atmosphere."
        },
        {
          title: "9. Chor Bazaar",
          description: "Known as the 'Thieves Market,' Chor Bazaar is a treasure trove of antiques and vintage goods. It offers a unique shopping experience with its wide range of collectibles and rare items, from furniture to vintage Bollywood posters. Bargaining is a part of the experience, making it a fun and engaging visit for those looking to uncover hidden gems in the bustling market."
        },
        {
          title: "10. Bandra-Worli Sea Link",
          description: "This impressive bridge connects Bandra and Worli, offering stunning views of the city’s skyline and the Arabian Sea. An engineering marvel, the bridge spans 5.6 kilometers and is a crucial part of Mumbai’s infrastructure, significantly reducing travel time between the two areas. The panoramic views from the bridge are particularly breathtaking at sunset, making it a popular spot for photographers and sightseers."
        }
      ],      
      services: [
        {
          name: "Pune to Mumbai Darshan Bus on Rent",
          description: "Experience a comfortable and convenient journey with Shraddha Travels' bus rental services for your Pune to Mumbai Darshan. Our fleet of modern buses is ideal for exploring Mumbai's iconic landmarks, including the Gateway of India and Marine Drive. Enjoy a hassle-free trip with our professional drivers and well-maintained vehicles, ensuring you have a memorable experience."
        },
        {
          name: "Pune to Mumbai International Airport Mini Bus on Rent",
          description: "Travel with ease from Pune to Mumbai International Airport with our mini bus rental services. Perfect for groups and families, our mini buses offer comfort and convenience for airport transfers. Ensure a smooth start to your journey with Shraddha Travels' reliable and punctual service, allowing you to relax before your flight."
        },
        {
          name: "Shraddha Travels Pune: Pune to Navi Mumbai Bus on Rent",
          description: "For trips from Pune to Navi Mumbai, choose Shraddha Travels for a dependable and comfortable bus rental service. Our buses are equipped to handle long journeys with ease, making your travel experience stress-free and enjoyable. Whether for corporate events or personal travel, our modern amenities and professional drivers have you covered."
        },
        {
          name: "Pune to Thane Bus on Rent",
          description: "Explore Thane with our specialized bus rental services from Pune. Ideal for group travel and events, our buses provide a comfortable ride with modern amenities. Trust Shraddha Travels to offer a seamless travel experience with punctual service, allowing you to focus on enjoying your visit."
        },
        {
          name: "Pune to Mumbai Airport Urbania Bus on Rent",
          description: "Opt for Shraddha Travels for your Pune to Mumbai Airport Urbania bus rental needs. Our buses are designed to provide a luxurious and comfortable experience for airport transfers. Whether you're heading to an international flight or a domestic departure, we ensure timely and efficient service, making your travel worry-free."
        },
        {
          name: "Pune to Mumbai Airport Drop Bus on Rent",
          description: "Ensure a smooth and timely airport drop-off with Shraddha Travels' bus rental service. Our buses are perfect for groups needing transportation from Pune to Mumbai Airport. Enjoy a stress-free journey with our reliable service and comfortable vehicles, allowing you to arrive at the airport refreshed."
        },
        {
          name: "Pune to Mumbai Domestic Airport Bus on Rent",
          description: "Traveling to Mumbai Domestic Airport from Pune? Choose Shraddha Travels for a comfortable and efficient bus rental service. Our well-maintained buses offer ample space and amenities for a pleasant journey, making your airport transfer smooth and hassle-free."
        },
        {
          name: "Pune to Panvel Bus on Rent",
          description: "For trips from Pune to Panvel, Shraddha Travels offers reliable and comfortable bus rental services. Our buses are ideal for both short and long-distance travel, ensuring a pleasant journey with modern amenities and professional drivers committed to your comfort."
        },
        {
          name: "Pune to Mumbai Round Trip Bus on Rent",
          description: "Plan a round trip from Pune to Mumbai with ease using Shraddha Travels' bus rental services. Whether you're attending events, exploring the city, or need transportation for a group, our buses are equipped to provide comfort and convenience for the entire journey."
        },
        {
          name: "Pune to Mumbai Events Bus on Rent",
          description: "Make your events in Mumbai memorable with Shraddha Travels' bus rental services. Ideal for corporate events, weddings, and other gatherings, our buses offer the perfect solution for transporting guests comfortably and efficiently from Pune to Mumbai."
        },
        {
          name: "Pune to Dadar Mini Bus on Rent",
          description: "Traveling to Dadar from Pune? Shraddha Travels provides mini bus rental services to ensure a comfortable and efficient journey. Our mini buses are perfect for smaller groups and offer the convenience of flexible scheduling and personalized service, making your trip enjoyable."
        },
        {
          name: "Pune to Bandra Terminus Bus on Rent",
          description: "Traveling from Pune to Bandra Terminus? Shraddha Travels provides reliable and comfortable bus rental services for this route. Our buses are well-maintained and equipped with modern amenities, ensuring your journey to Bandra Terminus is smooth and enjoyable."
        },
        {
          name: "Pune Mumbai Tempo Traveller Hire",
          description: "For a comfortable and flexible travel option between Pune and Mumbai, consider hiring a tempo traveller from Shraddha Travels. Our tempo travellers are ideal for medium-sized groups, offering a practical and cozy solution for travel between these two cities. Enjoy a comfortable ride with essential amenities and personalized service."
        },
        {
          name: "Pune to Mumbai Darshan Mini Bus on Rent",
          description: "Explore Mumbai’s top attractions with our mini bus rental service for Mumbai Darshan from Pune. Shraddha Travels offers mini buses designed for group travel, providing a comfortable and efficient way to experience the city’s landmarks. Whether it’s a sightseeing tour or a cultural visit, our mini buses ensure a pleasant and organized travel experience."
        },
        {
          name: "Pimpri to Mumbai Darshan Mini Bus on Rent",
          description: "For those traveling from Pimpri to Mumbai for a Darshan tour, Shraddha Travels offers mini bus rentals that cater to your group’s needs. Our mini buses are well-suited for comfortable travel, ensuring that your journey from Pimpri to Mumbai is both enjoyable and efficient. Experience the city’s sights with ease and comfort."
        },
        {
          name: "Pune Railway Station to Mumbai Darshan Mini Bus on Rent",
          description: "If you’re arriving at Pune Railway Station and planning a Mumbai Darshan, Shraddha Travels provides mini bus rentals for convenient transportation. Our mini buses ensure a smooth transfer from the railway station to Mumbai, offering comfort and reliability for your sightseeing tour. Enjoy a seamless travel experience with our well-equipped mini buses."
        },
        {
          name: "Pune to Mumbai Darshan Package",
          description: "Shraddha Travels offers a comprehensive Pune to Mumbai Darshan package, tailored to make your sightseeing tour enjoyable and hassle-free. Our package includes transportation in comfortable and well-maintained vehicles, ensuring you visit Mumbai’s top attractions with ease. Experience the city’s landmarks and cultural sites with our dedicated Darshan package."
        },
        {
          name: "Pune to Mumbai Darshan Urbania on Rent",
          description: "For a premium travel experience, consider our Urbania package for Pune to Mumbai Darshan. Shraddha Travels offers the Urbania on rent, providing a luxurious and comfortable option for your sightseeing tour. Enjoy a stylish and convenient journey as you explore Mumbai’s key attractions with our top-tier Urbania service."
        },
        {
          name: "Pune to Mumbai Darshan Bus On Rent Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to Mumbai Darshan Bus On Rent. We ensure a smooth and enjoyable ride for all our customers, providing the best travel experience in the region."
        }
      ],      
      tableData: [
        ['- Pune to Mumbai Darshan Bus on Rent', '- Pune to Mumbai International Airport Mini Bus on Rent'],
        ['- Shraddha Travels Pune: Pune to Navi Mumbai Bus on Rent', '- Pune to Thane Bus on Rent'],
        ['- Pune to Mumbai Airport Urbania Bus on Rent', '- Pune to Mumbai Airport Drop Bus on Rent'],
        ['- Pune to Mumbai Domestic Airport Bus on Rent', '- Pune to Panvel Bus on Rent'],
        ['- Pune to Mumbai Round Trip Bus on Rent', '- Pune to Mumbai Events Bus on Rent'],
        ['- Pune to Dadar Mini Bus on Rent', '- Pune to Bandra Terminus Bus on Rent'],
        ['- Pune Mumbai Tempo Traveller Hire', '- Pune to Mumbai Darshan Mini Bus on Rent'],
        ['- Pimpri to Mumbai Darshan Mini Bus on Rent', '- Pune Railway Station to Mumbai Darshan Mini Bus on Rent'],
        ['- Pune to Mumbai Darshan Package', '- Pune to Mumbai Darshan Urbania on Rent']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Pune to Mumbai Darshan Bus On Rent?",
          WhyChoosedescription: "Shraddha Travels stands out as the premier choice for your Pune to Mumbai Darshan bus rental needs. Our commitment to quality service and customer satisfaction ensures that every aspect of your journey is handled with care and professionalism. We aim to make your travel experience not just a journey, but a memorable adventure."
        },
        {
          WhyChooseheading: "Comfort and Convenience:",
          WhyChoosedescription: "Our fleet includes modern, well-maintained buses equipped with comfortable seating, air conditioning, and ample legroom. We take pride in ensuring that your journey from Pune to Mumbai is as pleasant as possible. With our buses, you can sit back, relax, and enjoy the scenic views without any discomfort."
        },
        {
          WhyChooseheading: "Reliable Service:",
          WhyChoosedescription: "Punctuality is our top priority. We understand the importance of timely arrivals and departures, especially when visiting popular destinations. Our professional drivers are trained to provide reliable service, ensuring you reach your destination on time and without stress."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our drivers are not only skilled but also knowledgeable about the Pune to Mumbai route. They ensure a smooth, safe, and enjoyable journey, allowing you to focus on your trip rather than navigating the roads. With years of experience, they can handle any situation with ease and professionalism."
        },
        {
          WhyChooseheading: "Customizable Options:",
          WhyChoosedescription: "We offer flexible rental options to suit your needs, whether you're planning a day trip or an extended visit. Choose from a range of bus sizes and amenities based on your group size and preferences. Whether you need a simple shuttle service or a luxurious travel experience, we can customize your journey accordingly."
        },
        {
          WhyChooseheading: "Competitive Pricing:",
          WhyChoosedescription: "We provide high-quality bus rental services at competitive prices. Our transparent pricing structure ensures you get the best value for your money without hidden costs. We believe in offering affordable rates while maintaining the highest standards of service and comfort."
        },
        {
          WhyChooseheading: "Comprehensive Support:",
          WhyChoosedescription: "Our customer service team is available to assist with booking, itinerary planning, and any special requests you might have. We are committed to making your journey smooth and hassle-free. Whether you need assistance with route planning or have specific needs, we are here to help every step of the way."
        },
        {
          WhyChooseheading: "Safety First:",
          WhyChoosedescription: "We adhere to all safety regulations and conduct regular maintenance checks on our buses. Your safety is our top priority, and we strive to provide a secure travel experience. Our drivers are trained in safety protocols, ensuring that all measures are taken for a safe journey."
        },
        {
          WhyChooseheading: "Comfortable Amenities:",
          WhyChoosedescription: "Enjoy a range of amenities including Wi-Fi, entertainment systems, and refreshments on our luxury buses. We aim to make your trip enjoyable and relaxing, so you can connect with friends, watch movies, or simply unwind during your travel."
        },
        {
          WhyChooseheading: "Clean and Well-Maintained Vehicles:",
          WhyChoosedescription: "Our buses are thoroughly cleaned before each journey, ensuring a fresh and hygienic environment for all passengers. We take cleanliness seriously and conduct regular inspections to maintain the highest standards of hygiene."
        },
        {
          WhyChooseheading: "Positive Customer Feedback:",
          WhyChoosedescription: "We pride ourselves on delivering excellent service, and our positive customer reviews reflect our commitment to quality and customer satisfaction. Our long list of happy clients is a testament to our dedication to providing exceptional travel experiences."
        }
      ]
      
  }



  const faqData = [
    {
      question: "What types of buses are available for rent for the Pune to Mumbai Darshan?",
      answer: "We offer a range of buses including luxury coaches, standard buses, and mini-buses. Each option is designed to provide maximum comfort and convenience based on your needs."
    },
    {
      question: "How do I book a bus for Pune to Mumbai Darshan?",
      answer: "You can book a bus by contacting our customer service team via phone or email. Alternatively, you can visit our website and fill out the booking form for a quick and easy reservation."
    },
    {
      question: "Are there any additional costs apart from the rental fee?",
      answer: "Our pricing is transparent with no hidden costs. Additional expenses may include fuel charges, tolls, and parking fees if applicable. We provide a detailed quote upfront so you are aware of all costs involved."
    },
    {
      question: "Can I customize the itinerary for the trip?",
      answer: "Yes, we offer customizable options for your itinerary. You can discuss your travel plans with our team, and we will work with you to create a schedule that fits your needs."
    },
    {
      question: "What amenities are provided on the bus?",
      answer: "Depending on the bus type, amenities may include air conditioning, comfortable seating, Wi-Fi, entertainment systems, and refreshments. Please let us know your preferences when booking."
    },
    {
      question: "How far in advance should I book the bus?",
      answer: "We recommend booking your bus as early as possible to ensure availability, especially during peak travel seasons. Booking at least a few weeks in advance is ideal."
    },
    {
      question: "What is the cancellation policy?",
      answer: "Our cancellation policy varies depending on the booking terms. Generally, cancellations made within a specified period before the departure date may incur a cancellation fee. Please refer to our booking terms or contact our team for specific details."
    },
    {
      question: "Are your buses equipped with safety features?",
      answer: "Yes, all our buses are equipped with essential safety features including seat belts, first aid kits, and are regularly maintained to ensure a safe journey for all passengers."
    },
    {
      question: "Can I make changes to my booking after confirmation?",
      answer: "Yes, changes can be made to your booking based on availability. Please contact our customer service team as soon as possible to discuss any modifications."
    },
    {
      question: "How can I contact Shraddha Travels for more information?",
      answer: "You can reach us through our customer service phone line, email, or by visiting our website. Our team is available to answer any questions and assist with your booking."
    }
  ];
  

  const testimonials = [
    {
      name: "Mr. Ravi Sharma",
      text: "Our group recently hired a bus from Shraddha Travels for a Pune to Mumbai Darshan, and the experience was outstanding. The bus was pristine and equipped with comfortable seating and modern amenities, making our journey smooth and enjoyable. The driver was punctual and well-informed about the route, ensuring we arrived on time at each of our destinations. The booking process was straightforward, and the customer service was excellent. We highly recommend Shraddha Travels for anyone looking for reliable and comfortable bus rental services."
    },
    {
      name: "Miss Anjali Deshmukh",
      text: "We chose Shraddha Travels for our Pune to Mumbai Darshan trip, and it was an excellent decision. The luxury bus we rented was spacious and well-maintained, with all the amenities we needed for a comfortable ride. The driver was professional and navigated the busy Mumbai traffic efficiently, allowing us to focus on our sightseeing. The staff at Shraddha Travels was very helpful throughout the booking process, making everything hassle-free. We had a fantastic experience and will definitely use their services again for future trips."
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to Mumbai Darshan Bus On Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Mumbai Darshan Bus On Rent</title>
    <meta name="description" content="Affordable bus rental services for Mumbai Darshan from Pune, including airport transfers and round trips." />
    <meta name="keywords" content="Pune to Mumbai Darshan Bus on Rent, Mumbai Airport Bus Hire, Pune to Navi Mumbai Bus, Mini Bus on Rent for Mumbai Darshan" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Mumbai Darshan Bus On Rent" />
    <meta property="og:description" content="Bus hire services for Mumbai Darshan from Pune, covering airport transfers and sightseeing." />
    <meta property="og:url" content="https://www.amuratatravels.com/pune-to-mumbai-darshan" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/mumbai-darshan.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to Mumbai Darshan Bus On Rent",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/pune-to-mumbai-darshan",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Pune to Mumbai Darshan Bus Hire",
                    "description": "Bus rental services for Mumbai Darshan from Pune, including transfers to Mumbai Airport and local sightseeing."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-02.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Pune to Mumbai Darshan Bus On Rent - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetomumbaidarshan;