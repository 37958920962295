import { Link } from 'react-router-dom';
function Foot() {
  return (
    <div>

      <div className="container-fluid bg-dark" >
        <section className="service">
          <div className="row container-fluid he text-white justify-content-center g-0 p-0">
            <div className="col-12 col-md-3">
              <h4 className="py-3 case">Service</h4>
              <Link className="mx-md-2" to="/Bus-On-Rent-In-Pune"><i className="fas fa-bus"></i> Bus On Rent in Pune</Link>
              <Link className="mx-md-2" to="/Pune-Local-Bus-On-Rent"><i className="fas fa-bus"></i> Pune Local Bus on Rent</Link>
              <Link className="mx-md-2" to="/Bus-Hire-Near-me-Pune"><i className="fas fa-bus"></i> Bus Hire Near me Pune</Link>
              <Link className="mx-md-2" to="/32-35-40-45-50-Seater-Bus-on-Rent-in-Pune"><i className="fas fa-bus"></i> 32,35,40,45,50 Seater Bus on Rent in Pune</Link>
              <Link className="mx-md-2" to="/Pune-To-Lonavala-Bus-Hire"><i className="fas fa-bus"></i> Pune to Lonavala Bus Hire</Link>
              <Link className="mx-md-2" to="/Pune-Sade-Teen-Shakti-Peeth-Bus-On-Rent"><i className="fas fa-bus"></i> Pune Sade Teen Shakti Peeth Bus on Rent</Link>
              <Link className="mx-md-2" to="/Pune-To-Konkan-Darshan-Bus-Hire"><i className="fas fa-bus"></i> Pune to Konkan Darshan Bus Hire</Link>
              <Link className="mx-md-2" to="/Pune-To-Goa-Bus-Hire"><i className="fas fa-bus"></i> Pune to Goa Bus Hire</Link>
              <Link className="mx-md-2" to="/Bus-Hire-In-Pimpri-Chinchwad"><i className="fas fa-bus"></i> Bus Hire in Pimpri Chinchwad</Link>
              <Link className="mx-md-2" to="/Bus-on-Rent-in-Hadapsar"><i className="fas fa-bus"></i> Bus on Rent in Hadapsar</Link>
              <Link className="mx-md-2" to="/Bus-On-Rent-in-Kharadi-Pune"><i className="fas fa-bus"></i> Bus On Rent in Kharadi Pune</Link>
              <Link className="mx-md-2" to="/Bus-on-Rent-in-Hinjewadi"><i className="fas fa-bus"></i> Bus on Rent in Hinjewadi</Link>
              <Link className="mx-md-2" to="/bus-hire-for-picnic-in-pune"><i className="fas fa-bus"></i> Bus Hire for Picnic in Pune</Link>
              <Link className="mx-md-2" to="/Corporate-Travels-in-Pune"><i className="fas fa-bus"></i> Corporate Bus in Pune</Link>
              <Link className="mx-md-2" to="/12-Jyotirlinga-Darshan-Tour-Package-from-Pune"><i className="fas fa-bus"></i> 12 Jyotirlinga Darshan Tour Package from Pune</Link>
              <Link className="mx-md-2" to="/Pune-to-rajasthan-darshan-bus-package"><i className="fas fa-bus"></i> Pune to Rajasthan Darshan Bus Package</Link>
              <Link className="mx-md-2" to="/Tourist-Bus-Rental-Services"><i className="fas fa-bus"></i> Tourist Bus Rental Services</Link>
              <Link className="mx-md-2" to="/Monthly-Bus-Hire-Service-in-Pune"><i className="fas fa-bus"></i> Monthly Bus Hire Service in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Hire-For-Kedarnath-Trip"><i className="fas fa-bus"></i> Bus Hire For Kedarnath Trip</Link>
              <Link className="mx-md-2" to="/Bus-hire-for-Pune-to-Ashtavinayak"><i className="fas fa-bus"></i> Bus hire for Pune to Ashtavinayak</Link>
              <Link className="mx-md-2" to="/Bus-hire-for-Pune-to-Maharashtra-Darshan"><i className="fas fa-bus"></i> Bus hire for Pune to Maharashtra Darshan</Link>
              <Link className="mx-md-2" to="/Explore-All-India-Bus-Hire-Services-From-Pune"><i className="fas fa-bus"></i>  Explore All India Bus Hire Services From Pune</Link>
              <Link className="mx-md-2" to="/Pune-to-Shirdi-Tempo-Traveller"><i className="fas fa-bus"></i>  Pune to Shirdi Tempo Traveller On Rent</Link>
              <Link className="mx-md-2" to="/Pune-to-Shani-Shingnapur-Tempo-Traveller-on-Rent"><i className="fas fa-bus"></i> Pune to Shani Shingnapur Tempo Traveller on Rent</Link>
              <Link className="mx-md-2" to="/Pune-to-Ajanta-Ellora-Tempo-Traveller-On-Rent"><i className="fas fa-bus"></i> Pune to Ajanta Ellora Tempo Traveller On Rent</Link>
            </div>

            <div className="col-12 col-md-3">
              <h4 className="py-3 case">Service</h4>
              <Link className="mx-md-2" to="/Pune-to-Mahabaleshwar-Urbania-On-Rent"><i className="fas fa-bus"></i> Pune to Mahabaleshwar Urbania On Rent</Link>
              <Link className="mx-md-2" to="/Pune-to-Bhimashankar-Jyotirlinga-Mini-Bus-Hire-in-Pune"><i className="fas fa-bus"></i> Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Hire-for-School-Trips-in-Pune"><i className="fas fa-bus"></i> Bus Hire for School Trips in Pune</Link>
              <Link className="mx-md-2" to="/Pune-Local-Bus-on-Rent"><i className="fas fa-bus"></i> Pune Local Bus on Rent</Link>

              <Link className="mx-md-2" to="/Bus-On-Rent-in-Kharadi-Pune"><i className="fas fa-bus"></i> Bus On Rent in Kharadi Pune</Link>
              <Link className="mx-md-2" to="/Bus-on-Rent-in-Hinjewadi"><i className="fas fa-bus"></i> Bus on Rent in Hinjewadi</Link>
              <Link className="mx-md-2" to="/Bus-Hire-For-Wedding-in-Pune"><i className="fas fa-bus"></i> Bus Hire For Wedding in Pune</Link>
              <Link className="mx-md-2" to="/Buses-For-Corporate-Events-in-Pune"><i className="fas fa-bus"></i> Buses For Corporate Events in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Rentals-For-School-Trips-in-Pune"><i className="fas fa-bus"></i> Bus Rentals For School Trips in Pune</Link>
              <Link className="mx-md-2" to="/Bus-booking-for-wedding-in-Pune"><i className="fas fa-bus"></i> Bus booking for wedding in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Booking-for-Marriage-function-in-Pune"><i className="fas fa-bus"></i> Bus Booking for Marriage function in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Service-for-Corporate-Pune"><i className="fas fa-bus"></i> Bus Service for Corporate Pune</Link>
              <Link className="mx-md-2" to="/Bus-on-Rent-For-Event-in-Pune"><i className="fas fa-bus"></i> Bus on Rent For Event in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Rental-for-Tour-Packages-in-Pune"><i className="fas fa-bus"></i> Bus Rental for Tour Packages in Pune</Link>
              <Link className="mx-md-2" to="/Bus-Hire-for-Outstation-in-Pune"><i className="fas fa-bus"></i> Bus Hire for Outstation in Pune</Link>
              <Link className="mx-md-2" to="/Pune-Bus-Hire-Rental-Service"><i className="fas fa-bus"></i> Pune Bus Hire Rental Service</Link>
              <Link className="mx-md-2" to="/Bus-Service-for-Picnic-in-Pune"><i className="fas fa-bus"></i> Bus Service for Picnic in Pune</Link>
              <Link className="mx-md-2" to="/Luxury-Bus-Rental-in-Pune"><i className="fas fa-bus"></i> Luxury Bus Rental in Pune</Link>
              <Link className="mx-md-2" to="/Pune-to-5-Jyotirlinga-Darshan-Bus-Hire"><i className="fas fa-bus"></i> Pune to 5 Jyotirlinga Darshan Bus Hire</Link>
              <Link className="mx-md-2" to="/Pune-to-Mumbai-Darshan-Bus-On-Rent"><i className="fas fa-bus"></i> Pune to Mumbai Darshan Bus On Rent</Link>
              <Link className="mx-md-2" to="/Bus-On-Rent-in-Pimpri-Chinchwad"><i className="fas fa-bus"></i> Bus On Rent in Pimpri Chinchwad</Link>
              <Link className="mx-md-2" to="/Bus-Hire-for-School-Trips-in-Pune"><i className="fas fa-bus"></i> Bus Hire for School Trips in Pune</Link>
              <Link className="mx-md-2" to="/Pune-to-Shirdi-Mini-Bus-Package"><i className="fas fa-bus"></i> Pune to Shirdi Mini Bus Package</Link>
              <Link className="mx-md-2" to="/Luxury-Tempo-Traveller-On-Rent-in-Pune"><i className="fas fa-bus"></i> Luxury Tempo Traveller On Rent in Pune</Link>
              <Link className="mx-md-2" to="/Pune-to-Ashtavinayak-Darshan-Tempo-Traveller-on-Rent"><i className="fas fa-bus"></i> Pune to Ashtavinayak Darshan Tempo Traveller on Rent</Link>
           </div>



            <div className="col-12 col-md-3 text-white">
              <h4 className="py-3 case">Service</h4>
              <Link className="mx-md-2" to="/Tempo-Traveller-On-Rent-in-Pune"><i className="fas fa-bus"></i> Tempo Traveller On Rent in Pune</Link>
              <Link className="mx-md-2" to="/Pune-to-Mahabaleshwar-Tempo-Traveller"><i className="fas fa-bus"></i> Pune to Mahabaleshwar Tempo Traveller</Link>
              <Link className="mx-md-2" to="/Pune-to-Outstation-Tempo-Traveller"><i className="fas fa-bus"></i> Pune to Outstation Tempo Traveller</Link>
              <Link className="mx-md-2" to="/Mini-Bus-on-Rent-in-Pune"><i className="fas fa-bus"></i> Mini Bus on Rent in Pune </Link>
              <Link className="mx-md-2" to="/14-Seater-Tempo-Traveller-on-Rent"><i className="fas fa-bus"></i> 14 Seater Tempo Traveller on Rent </Link>
              <Link className="mx-md-2" to="/17-Seater-Bus-on-Rent-Pune"><i className="fas fa-bus"></i> 17 Seater Bus on Rent Pune </Link>
              <Link className="mx-md-2" to="/Pune-to-Shirdi-Tempo-Traveller"><i className="fas fa-bus"></i> Pune to Shirdi Tempo Traveller </Link>
              <Link className="mx-md-2" to="/20-Seater-Bus-on-Rent-in-Pune"><i className="fas fa-bus"></i> 20 Seater Bus on Rent in Pune</Link>
              <Link className="mx-md-2" to="/25-Seater-Tempo-Traveller-on-Rent-in-Pune"><i className="fas fa-bus"></i> 25 Seater Tempo Traveller on Rent in Pune</Link>
              <Link className="mx-md-2" to="/Tempo-Traveller-on-Rent-in-Pimpri-Chinchwad"><i className="fas fa-bus"></i> Tempo Traveller on Rent in Pimpri Chinchwad</Link>
              <Link className="mx-md-2" to="/32-Seater-Bus-Rent-in-Pune"><i className="fas fa-bus"></i> 32 Seater Bus Rent in Pune</Link>
              <Link className="mx-md-2" to="/35-Seater-Bus-on-Rent-in-Pune"><i className="fas fa-bus"></i> 35 Seater Bus Rent in Pune</Link>
              <Link className="mx-md-2" to="/45-Seater-Bus-on-Rent-in-Pune"><i className="fas fa-bus"></i> 45 Seater Bus Rent in Pune</Link>
              <Link className="mx-md-2" to="/50-Seater-Bus-On-Rent-in-Pune"><i className="fas fa-bus"></i> 50 Seater Bus Rent in Pune</Link>
              <Link className="mx-md-2" to="/Non-Ac-Bus-Rental-Service-in-Pune"><i className="fas fa-bus"></i> Non Ac Bus Rental Service in Pune</Link>
              <Link className="mx-md-2" to="/Luxury-Bus-Rental-in-Pune"><i className="fas fa-bus"></i> Luxury Bus Rental in Pune</Link>
              <Link className="mx-md-2" to="/Luxury-Force-Urbania-On-Rent-in-Pune"><i className="fas fa-bus"></i> Luxury Force Urbania On Rent in Pune</Link>
              <Link className="mx-md-2" to="/Force-Urbania-Luxury-Mini-Bus-On-Rent"><i className="fas fa-bus"></i> Force Urbania Luxury Mini Bus On Rent</Link>
              <Link className="mx-md-2" to="/Mini-Bus-On-Rent-in-Pune"><i className="fas fa-bus"></i>  Bus On Rent in Pune</Link>
              <Link className="mx-md-2" to="/14-17-20-25-Seater-Bus-on-in-Pune"><i className="fas fa-bus"></i> 14 17 20 25 Seater Bus on in Pune</Link>
              <Link className="mx-md-2" to="/Travel-Agents-in-Pune-for-Mini-Bus-Hire"><i className="fas fa-bus"></i> Travel Agents in Pune for Mini Bus Hire</Link>
              <Link className="mx-md-2" to="/Tempo-Traveller-Hire-in-Pune"><i className="fas fa-bus"></i> Tempo Traveller Hire in Pune</Link>
              <Link className="mx-md-2" to="/Force-Urbania-On-Rent-for-Ashtavinayak-Darshan"><i className="fas fa-bus"></i> Force Urbania On Rent for Ashtavinayak Darshan</Link>
              <Link className="mx-md-2" to="/Pune-to-Lonavala-Tempo-Traveller-on-Rent"><i className="fas fa-bus"></i> Pune to Lonavala Tempo Traveller on Rent</Link>
              <Link className="mx-md-2" to="/Pune-to-Trimbakeshwar-Tempo-Traveller-On-Rent"><i className="fas fa-bus"></i> Pune to Trimbakeshwar Tempo Traveller On Rent</Link>
            </div>


            <div className="col-12 col-md-3">
              <h4 className="py-3 case">Service</h4>
              <h4 className="case">Shraddha Travels  </h4>
              <p><strong className='text-white'>Phone:</strong><br />
                <i className="fas fa-phone-alt text-white"></i> <a href="tel:+918087847191" className='d-inline'>+91 8087847191</a><br />
                <i className="fas fa-phone-alt text-white"></i> <a href="tel:+919552010777" className='d-inline'>+91 9552010777</a>

              </p>
              <p className='row w-100'><strong className='col-12 text-white'>Email:</strong><br />
                <a href="mailto:booking@shraddhatravel.com" className='col-12 overflow-hidden'>booking@shraddhatravel.com</a>
              </p>
              <p className='text-white'><strong className='text-white'>Address:</strong><br />
                Shraddha Travels :<br />
                Bhairab nivas lane number 8<br />
                uttareshwar Nagar S/R nb.275<br />
                sathe vasti Lohegaon, Pune
                <br /> Maharashtra - 411047
              </p>
              <div className="mt-4">
                <h5 className="case">Our Location:</h5>

                <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3781.414375224137!2d73.92062317519361!3d18.600422782508673!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMTjCsDM2JzAxLjUiTiA3M8KwNTUnMjMuNSJF!5e0!3m2!1sen!2sin!4v1729680119516!5m2!1sen!2sin" width="100%" height="250" allowfullscreen="" loading="lazy"></iframe>

              </div>
            </div>
            <hr />
            <p className="text-white text-center">
              All Rights Reserved @ <span className="Coloo">Shraddha Travels</span> 2024 Developed By
              <a href="https://www.dexterintelligence.com/" className='d-inline' target="_blank" rel="noopener noreferrer">
                <span className="Coloo">&nbsp;Dexter Intelligence</span>
              </a> | Hosted By
              <a href="https://www.urvahosting.com/" className='d-inline' target="_blank" rel="noopener noreferrer">
                <span className="Coloo">&nbsp;Urva Hosting</span>
              </a>
            </p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default Foot;




