
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punetoashtavinayak() {



  const cardData =
  {
    keyword: 'Pune to Ashtavinayak Darshan Tempo Traveller on Rent ',
    heading: 'Pune to Ashtavinayak Darshan Tempo Traveller on Rent: Shraddha Travels',
    headingDescription: 'Shraddha Travels provides reliable, comfortable, and affordable mini-bus rentals for your Ashtavinayak Darshan tour from Pune. Our Pune to Ashtavinayak mini-bus rental service ensures a smooth and enjoyable journey for you and your group as you explore the eight revered Ganesha temples in Maharashtra. Whether you are traveling with family, friends, or a large group, our spacious mini-buses come equipped with comfortable seating, air-conditioning, ample luggage space, and experienced drivers who are well-versed with the Ashtavinayak route.',
    top: 'Top Places to Visit on the Ashtavinayak Darshan Tour with Shraddha Travel',

    topPlaces: [
        {
          title: "1. Moreshwar Temple, Morgaon",
          description: "Dedicated to Lord Ganesha as Mayureshwar, this ancient temple is believed to mark both the start and conclusion of the Ashtavinayak pilgrimage. The legend speaks of Ganesha defeating a powerful demon riding a peacock, which adds to the temple's significance. The intricate architecture and vibrant festivities during Ganesh Chaturthi draw countless devotees each year."
        },
        {
          title: "2. Siddhivinayak Temple, Siddhatek",
          description: "Situated on the serene banks of the Bhima River, Siddhatek Temple is famous for its unique idol of Lord Ganesha, which is said to fulfill the wishes of sincere devotees. The temple's tranquil setting, combined with its rich history, makes it a favorite among pilgrims seeking blessings for new beginnings and spiritual growth."
        },
        {
          title: "3. Ballaleshwar Temple, Pali",
          description: "Renowned for its historical significance, Ballaleshwar Temple features an idol worshipped by a devotee named Ballal. The temple boasts exquisite architecture and intricate carvings that reflect the region's artistic heritage. Throughout the year, it attracts devotees and tourists, especially during major festivals when it is beautifully decorated."
        },
        {
          title: "4. Varadvinayak Temple, Mahad",
          description: "Nestled among picturesque hills, Varadvinayak Temple is a serene spot where devotees seek blessings for success and prosperity. The temple's scenic location provides an enchanting backdrop, perfect for reflection and prayer. Visitors often express their gratitude after receiving Ganesha's blessings, making it a heartwarming experience."
        },
        {
          title: "5. Chintamani Temple, Theur",
          description: "Chintamani Temple represents Ganesha as the remover of worries, attracting those seeking solace and peace of mind. The temple's calm atmosphere, surrounded by lush greenery, is ideal for spiritual reflection and meditation. Its architectural beauty and historical relevance enhance the overall experience for pilgrims."
        },
        {
          title: "6. Girijatmaj Temple, Lenyadri",
          description: "Set within a series of rock-cut caves, Girijatmaj Temple offers a unique and mystical experience. It is one of the few Ganesha temples located in a mountainous region, making the pilgrimage both challenging and rewarding. The cave's cool ambiance and natural beauty create an enchanting atmosphere for worshippers."
        },
        {
          title: "7. Vighnahar Temple, Ozar",
          description: "Known for its power to eliminate obstacles, Vighnahar Temple is dedicated to Ganesha as Vighneshwar. The temple features stunning carvings and a peaceful environment, inviting visitors to meditate and connect with the divine. Many devotees share stories of how their challenges were overcome after praying here."
        },
        {
          title: "8. Mahaganapati Temple, Ranjangaon",
          description: "Housing a remarkable idol of Lord Ganesha with ten trunks and twenty arms, Mahaganapati Temple symbolizes protection and divine strength. It is particularly popular among those seeking blessings for family well-being and professional success. The temple's vibrant festivals and rituals create an uplifting atmosphere for all who visit."
        },
        {
          title: "9. Pune City Attractions",
          description: "On your way to Ashtavinayak, don’t miss exploring key attractions in Pune, such as the historic Shaniwar Wada, the beautiful Aga Khan Palace, and the serene Parvati Hill. Each site offers a glimpse into Pune's rich cultural heritage and adds a wonderful dimension to your pilgrimage journey."
        },
        {
          title: "10. Scenic Landscapes and Local Villages",
          description: "The journey through Maharashtra’s charming villages and scenic countryside adds unforgettable beauty to the Ashtavinayak Darshan. Pilgrims can enjoy the lush landscapes, traditional architecture, and vibrant local culture, making the entire experience a delightful exploration of rural India."
        }
      ],      
      services: [
        {
          name: "Pune to Ashtavinayak Traveller Tour Package",
          description: "Embark on a transformative spiritual journey with our Pune to Ashtavinayak Traveller Tour Package. This immersive experience allows you to explore the rich culture and deep spirituality of Maharashtra by visiting the eight revered Ashtavinayak temples. Each temple has its own unique significance and legends that resonate with devotees. Our package includes comfortable and spacious Travellers, equipped with modern amenities, making it ideal for family and group travels. Enjoy guided tours, insightful commentary, and ample opportunities for prayer and reflection as you soak in the divine atmosphere of these sacred sites."
        },
        {
          name: "Cheapest Ashtavinayak Tour from Pune",
          description: "Discover the Cheapest Ashtavinayak Tour from Pune, crafted to offer a budget-friendly pilgrimage experience without compromising on quality. Our competitive rates ensure that you can visit all eight temples comfortably and conveniently. This tour is designed for those who seek a spiritual journey without the financial burden, allowing you to focus on your devotion and exploration of the spiritual heart of Maharashtra. Experience guided visits, local cuisine, and the warmth of fellow pilgrims, all while staying within your budget."
        },
        {
          name: "13-Seater Traveller on Rent for Ashtavinayak Tour from Pune",
          description: "For smaller groups, we offer a 13-seater Traveller on Rent for Ashtavinayak Tour from Pune. This option allows you to travel together in luxury while visiting the iconic Ashtavinayak temples. Our well-maintained vehicles are designed for comfort and provide ample space for both passengers and luggage, ensuring a relaxed journey. With experienced drivers familiar with the routes, you can enjoy the scenic landscapes and spend quality time with your companions as you share this spiritual experience."
        },
        {
          name: "17-Seater Traveller on Rent for Ashtavinayak Tour from Pune",
          description: "If you have a larger group, our 17-seater Traveller on Rent for Ashtavinayak Tour from Pune is the perfect choice. This option facilitates group travel while visiting these sacred sites, allowing you to bond with fellow travelers. The spacious seating and excellent amenities ensure that everyone is comfortable throughout the journey. Our drivers prioritize your safety and convenience, providing you with an unforgettable pilgrimage experience where you can share moments of devotion and reflection."
        },
        {
          name: "Pune to Siddhivinayak Temple Tempo Traveller on Rent",
          description: "Visiting the Siddhivinayak Temple is a must for every devotee. Our Pune to Siddhivinayak Temple Tempo Traveller on Rent guarantees a comfortable and safe journey to this significant spiritual site. With spacious seating and professional drivers, you can focus on your prayers and the experience ahead. Book your Tempo Traveller today to enjoy a hassle-free pilgrimage experience, complete with opportunities to explore the temple's rich history and vibrant atmosphere."
        },
        {
          name: "Tempo Traveller on Rent for Pune to Moreshwar Temple at Morgaon Ashtavinayak Darshan",
          description: "Make your way to the Moreshwar Temple at Morgaon with our Tempo Traveller on Rent for Pune to Moreshwar Temple. As the first among the Ashtavinayak temples, it holds a special place in the hearts of devotees. Our Tempo Traveller service offers not only convenience and comfort for your spiritual journey but also the chance to appreciate the beautiful landscapes of Maharashtra as you travel. Enjoy seamless transport while engaging in prayers and discussions about the temple's significance."
        },
        {
          name: "Pune to Siddhivinayak Temple Siddhatek Tempo Traveller on Rent",
          description: "Experience a seamless pilgrimage with our Pune to Siddhivinayak Temple Siddhatek Tempo Traveller on Rent. This service provides a convenient and comfortable way to visit Siddhatek, ensuring that your group enjoys the journey together. With ample space and amenities, you can relax and focus on the spiritual essence of your visit, knowing that everything is taken care of. Enjoy the camaraderie of fellow travelers as you embark on this sacred journey."
        },
        {
          name: "Tempo Traveller on Rent for Ashtavinayak Darshan from Pimpri Chinchwad",
          description: "For those starting from Pimpri Chinchwad, we offer a Tempo Traveller on Rent for Ashtavinayak Darshan. Our service is tailored to make it easy for you to embark on your pilgrimage, ensuring that you and your companions can comfortably visit the revered temples. The journey is designed to be enjoyable and enriching, allowing you to engage in spiritual conversations and share your experiences as you travel towards the divine."
        },
        {
          name: "Pune to Ballaleshwar Temple Pali Traveller Bus on Rent",
          description: "Travel to the Ballaleshwar Temple in Pali with our dedicated Pune to Ballaleshwar Temple Pali Traveller Bus on Rent. This significant temple, known for its rich history and spiritual ambiance, offers a unique atmosphere for devotion. Our service ensures a smooth and comfortable journey, allowing you to focus on your spiritual exploration and personal reflections as you connect with the divine."
        },
        {
          name: "Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan",
          description: "Our service also covers the Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan, ensuring you don't miss out on this important pilgrimage site. Experience the divine beauty of Mahad as you travel comfortably with us, enjoying the scenic views along the route. Our knowledgeable drivers provide insights about the temples and the region, enriching your pilgrimage experience."
        },
        {
          name: "Pune to Chintamani Temple Theur Ashtavinayak Darshan Traveller on Rent",
          description: "Make your way to the Chintamani Temple in Theur with our Traveller on Rent service. This temple is celebrated as one of the Ashtavinayak temples, known for its spiritual significance as the remover of worries. Our service guarantees a comfortable and enjoyable experience, providing ample opportunities for prayer and reflection during your visit."
        },
        {
          name: "Pune to Girijatmaj Temple Lenyadri Traveller on Rent",
          description: "Explore the Girijatmaj Temple in Lenyadri with our Traveller on Rent service. Nestled in the hills, this unique pilgrimage site offers breathtaking views and a serene atmosphere. As you travel, you’ll have the chance to bond with fellow travelers, share stories, and deepen your spiritual journey amidst the natural beauty of the surroundings."
        },
        {
          name: "Pune to Vighnahar Temple Ozar Ganapati Ashtavinayak Darshan",
          description: "Complete your Ashtavinayak tour with a visit to the Vighnahar Temple in Ozar. Our dedicated travel services make it easy to reach this revered site, ensuring a fulfilling pilgrimage experience. This temple is known for its blessings of overcoming obstacles, making it a perfect conclusion to your spiritual journey."
        },
        {
          name: "Pune to Ashtavinayak Darshan Tempo Traveller on Rent Contact Number",
          description: "For prompt and efficient Pune to Ashtavinayak Darshan Tempo Traveller on Rent, contact Shraddha Travels at +91 8087847191. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service as you embark on this sacred pilgrimage."
        }
      ],      
      tableData: [
        ['- Pune to Ashtavinayak Traveller Tour Package', '- Cheapest Ashtavinayak Tour from Pune'],
        ['- 13-Seater Traveller on Rent for Ashtavinayak Tour from Pune', '- 17-Seater Traveller on Rent for Ashtavinayak Tour from Pune'],
        ['- Pune to Siddhivinayak Temple Tempo Traveller on Rent', '- Tempo Traveller on Rent for Pune to Moreshwar Temple at Morgaon Ashtavinayak Darshan'],
        ['- Pune to Siddhivinayak Temple Siddhatek Tempo Traveller on Rent', '- Tempo Traveller on Rent for Ashtavinayak Darshan from Pimpri Chinchwad'],
        ['- Pune to Ballaleshwar Temple Pali Traveller Bus on Rent', '- Pune to Varad Vinayak Temple Mahad Ashtavinayak Darshan'],
        ['- Pune to Chintamani Temple Theur Ashtavinayak Darshan Traveller on Rent', '- Pune to Girijatmaj Temple Lenyadri Traveller on Rent'],
        ['- Pune to Vighnahar Temple Ozar Ganapati Ashtavinayak Darshan', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Pune to Ashtavinayak Darshan Tempo Traveller on Rent?",
          WhyChoosedescription: "Choosing Shraddha Travels for your Pune to Ashtavinayak Darshan Tempo Traveller on Rent offers you a unique and hassle-free experience for your spiritual journey. We are dedicated to providing a seamless travel experience that caters to your comfort and needs. Our commitment to excellence ensures that every aspect of your journey is well taken care of, making it easy for you to focus on your pilgrimage."
        },
        {
          WhyChooseheading: "Comfortable Travel:",
          WhyChoosedescription: "Our Tempo Travelleres are meticulously maintained and equipped with plush seating, ensuring a relaxed and enjoyable travel experience for you and your group. We understand that comfort is key during long journeys, and our vehicles are designed to provide ample legroom and a smooth ride, allowing you to arrive at each temple feeling refreshed and ready for your spiritual exploration."
        },
        {
          WhyChooseheading: "Affordable Rates:",
          WhyChoosedescription: "We pride ourselves on offering competitive pricing without sacrificing quality. Our diverse range of packages is tailored to suit different budgets, allowing you to embark on your spiritual journey without financial strain. We believe that a fulfilling pilgrimage should be accessible to everyone, and our transparent pricing ensures you know exactly what to expect."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our drivers are not only highly skilled but also possess extensive knowledge of the routes and local culture. They are dedicated to ensuring your safety and comfort throughout the journey. With a focus on providing excellent customer service, our drivers are approachable and ready to assist you with any queries you may have during your trip."
        },
        {
          WhyChooseheading: "Flexible Itineraries:",
          WhyChoosedescription: "Recognizing that every group has unique preferences, we offer flexible itineraries that allow you to customize your journey. Whether you wish to spend more time at a specific temple or explore additional sites, we are happy to accommodate your requests. This personalized approach ensures that your pilgrimage aligns with your spiritual goals."
        },
        {
          WhyChooseheading: "Safety and Hygiene:",
          WhyChoosedescription: "Your safety is our utmost priority. We strictly adhere to safety and hygiene protocols, ensuring that our vehicles are regularly sanitized and maintained to the highest standards. We understand the importance of a clean environment, especially during travel, and we take every precaution to provide a safe journey for our passengers."
        },
        {
          WhyChooseheading: "Customer Satisfaction:",
          WhyChoosedescription: "Our commitment to customer satisfaction is at the heart of our service. From the moment you reach out to us to book your trip, to the time you complete your darshan, our team is dedicated to making your experience as smooth and enjoyable as possible. We value your feedback and continuously strive to enhance our services to meet and exceed your expectations."
        }
      ]
      
  }


  const faqData = [
    {
      question: "What is included in the Pune to Ashtavinayak Darshan Tempo Traveller rental?",
      answer: "Our rental package typically includes a comfortable Tempo Traveller, an experienced driver, and fuel costs. You also have the option to customize your itinerary according to your preferences, ensuring a tailored experience."
    },
    {
      question: "How many people can fit in a Tempo Traveller?",
      answer: "Our Tempo Travelleres can comfortably accommodate groups of different sizes, typically ranging from 12 to 20 passengers, depending on the specific vehicle selected."
    },
    {
      question: "What is the cost of renting a Tempo Traveller for the Ashtavinayak Darshan?",
      answer: "The cost varies based on the number of passengers, duration of the trip, and specific requirements. Please contact us for a detailed quote tailored to your needs."
    },
    {
      question: "Can I customize my itinerary?",
      answer: "Absolutely! We offer flexible itineraries to suit your preferences, allowing you to choose which temples to visit and how long to stay at each site."
    },
    {
      question: "How do I book a Tempo Traveller for the Ashtavinayak Darshan?",
      answer: "You can easily book a Tempo Traveller by contacting us through our website or phone. Our team will guide you through the booking process and assist with any questions."
    },
    {
      question: "Is there any cancellation policy?",
      answer: "Yes, we have a cancellation policy in place. Please refer to our website or contact our customer service team for detailed information regarding cancellations and any associated fees."
    },
    {
      question: "Are the drivers trained and experienced?",
      answer: "Yes, all our drivers are professionally trained, experienced, and familiar with the routes to the Ashtavinayak temples. They prioritize your safety and comfort throughout the journey."
    },
    {
      question: "What safety measures are in place for the journey?",
      answer: "We follow strict safety protocols, including regular vehicle maintenance, driver training, and sanitation measures to ensure a safe travel experience."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. Rajesh Kumar",
      text: "I recently booked a Tempo Traveller with Shraddha Travels for our Ashtavinayak Darshan from Pune, and I couldn't be happier with the service! The bus was spacious and comfortable, making our journey a pleasant experience. Our driver was punctual, friendly, and knowledgeable about the temples, which added to our trip. Shraddha Travels made our spiritual journey seamless, and I highly recommend them to anyone looking for reliable transport services!"
    },
    {
      name: "Miss Priya Deshmukh",
      text: "We had an amazing experience with Shraddha Travels for our Ashtavinayak Darshan. The Tempo Traveller was clean and well-maintained, providing us with a comfortable ride. The team was very accommodating, and they worked with us to create an itinerary that fit our needs perfectly. Our driver was exceptional, ensuring we had a smooth journey while sharing interesting insights about the temples. Thank you, Shraddha Travels, for making our pilgrimage truly special!"
    }
  ];
  
  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Pune to Ashtavinayak Darshan Tempo Traveller on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Tempo Traveller On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
  ];






  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to Ashtavinayak Darshan Tempo Traveller on Rent | Affordable Tours</title>
    <meta name="description" content="Rent a Tempo Traveller for your Ashtavinayak Darshan tour from Pune. Affordable rates for group trips to all Ashtavinayak temples." />
    <meta name="keywords" content="Pune to Ashtavinayak Darshan Tempo Traveller, Ashtavinayak Tour from Pune, Traveller Rental Pune, Siddhivinayak Temple Bus, Morgaon Ashtavinayak Tour" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to Ashtavinayak Darshan Tempo Traveller on Rent | Affordable Tours" />
    <meta property="og:description" content="Join our affordable Tempo Traveller rentals from Pune for Ashtavinayak Darshan. Comfortably travel to all significant temples." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-ashtavinayak-darshan-mini-bus-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/ashtavinayak-darshan.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Rental for Ashtavinayak Darshan",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-ashtavinayak-darshan-mini-bus-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental for Ashtavinayak Darshan",
                    "description": "Affordable Tempo Traveller rentals from Pune for an enriching Ashtavinayak Darshan tour, covering all major temples."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -05.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Pune to Ashtavinayak Darshan Tempo Traveller on Rent - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punetoashtavinayak;