import React from 'react';
import { Helmet } from 'react-helmet';

const Enquiry = () => {
    return (



        <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

<section id="about" className="jarallax text-light ">
    <div className="center-y relative text-center ">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Enquiry</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
        <section className="news-details-section fix section-padding">
            <div className="container">
                <div className="row">
                    <div className="col-8">
                        <div className="row">
                            <div className="de-item-list mb30">
                                <h3 className='text-white'>Do you have any enquiry?</h3>
                                <form 
                                    name="contactForm" 
                                    id="contact_form" 
                                    className="form-border" 
                                    action="https://formspree.io/f/xdknwona" 
                                    method="post" 
                                    data-redirect="contact-success.html"
                                >
                                    <input type="hidden" name="_token" value="te8H63NgpmFBBl8UVKjZYpTfKc2WSmiWxAajpCXL" />
                                    <div className="row">
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="name" className="text-muted small">Your Full Name <i className="fa fa-user darkcolor"></i></label>
                                                <input 
                                                    name="name" 
                                                    id="name" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Your Full Name" 
                                                    required 
                                                    pattern="[A-Za-z\s]+" 
                                                    title="Name must contain only letters" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="email" className="text-muted small">Your Email <i className="fa fa-envelope darkcolor"></i></label>
                                                <input 
                                                    type="email" 
                                                    name="email" 
                                                    id="email" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Your Email" 
                                                    required 
                                                    pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                                                    title="Please enter a valid email address" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="phone" className="text-muted small">Your Phone <i className="fa fa-phone darkcolor"></i></label>
                                                <input 
                                                    type="tel" 
                                                    name="phone" 
                                                    id="phone" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Your Phone" 
                                                    required 
                                                    pattern="[0-9]{10}" 
                                                    title="Phone number must be of 10 digits" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="cab_location" className="text-muted small">Cabs <i className="fa fa-map-marker darkcolor"></i></label>
                                                <input 
                                                    name="cab_location" 
                                                    id="cab_location" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Cabs" 
                                                    required 
                                                    pattern="[A-Za-z\s]+" 
                                                    title="Cabs can only have letters" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="days" className="text-muted small">Number of Days <i className="fa fa-calendar-check darkcolor"></i></label>
                                                <input 
                                                    name="days" 
                                                    id="days" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Number of Days" 
                                                    required 
                                                    min="1" 
                                                    max="100" 
                                                    step="1" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="pick_up_point" className="text-muted small">Pick Up Point <i className="fa fa-map-marker-alt darkcolor"></i></label>
                                                <input 
                                                    name="pick_up_point" 
                                                    id="pick_up_point" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Pick Up Point" 
                                                    required 
                                                    pattern="[A-Za-z\s]+" 
                                                    title="Pick Up Point must contain only letters" 
                                                />
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="cabs" className="text-muted small">Select Cabs <i className="fa fa-taxi darkcolor"></i></label>
                                                <select 
                                                    name="cabs" 
                                                    id="cabs" 
                                                    className="border py-2 form-control form-control-lg rounded-0" 
                                                    required
                                                >
                                                    <option value="" selected disabled>Select Cabs</option>
                                                    <option value="Swift Dzire (4 + 1 Seater)">Swift Dzire (4 + 1 Seater)</option>
                                                    <option value="Kia Carens(6 + 1 Seater)">Kia Carens(6 + 1 Seater)</option>
                                                    <option value="Ertiga (6 + 1 Seater)">Ertiga (6 + 1 Seater)</option>
                                                    <option value="Tavera (6 + 1 Seater)">Tavera (6 + 1 Seater)</option>
                                                    <option value="Innova (6 + 1 Seater)">Innova (6 + 1 Seater)</option>
                                                    <option value="13 Seater(13 Seater)">13 Seater(13 Seater)</option>
                                                    <option value="17 Seater(17 Seater)">17 Seater(17 Seater)</option>
                                                    <option value="Innova Crysta(6 + 1 Seater)">Innova Crysta(6 + 1 Seater)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <div className="form-group position-relative">
                                                <label htmlFor="date" className="text-muted small">Date of Journey <i className="fa fa-calendar-alt ml-2 darkcolor"></i></label>
                                                <input 
                                                    type="date" 
                                                    name="date" 
                                                    id="date" 
                                                    className="form-control form-control-lg rounded-0" 
                                                    placeholder="Date of Journey" 
                                                    required 
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group position-relative col-12">
                                            <textarea 
                                                name="message" 
                                                id="message" 
                                                className="form-control form-control-lg rounded-0" 
                                                placeholder="Your Message" 
                                                required 
                                            />
                                        </div>
                                        <div className="g-recaptcha" data-sitekey="copy-your-site-key-here"></div>
                                        <div id='submit' className="mt20 text-center py-3 px-5">
                                            <input 
                                                type='submit' 
                                                id='send_message' 
                                                value='Send Message' 
                                                className="btn-main  mt-2 py-2 px-3 border-0 text-balck" 
                                            />
                                        </div>
                                        <div id="success_message" className='success'>
                                            Your message has been sent successfully. Refresh this page if you want to send more messages.
                                        </div>
                                        <div id="error_message" className='error'>
                                            Sorry, something went wrong. Try again later.
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
    );
};

export default Enquiry;
