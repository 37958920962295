import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const categoriesData = [
    { id: 1, title: 'City Tour', image: '/img/city-img.jpg' },
    { id: 2, title: 'Museum Tour', image: '/img/Museumm.jpg' },
    { id: 3, title: 'Cruise Ship', image: '/img/cruis.jpg' },
    // { id: 5, title: 'Explore History', image: 'https://via.placeholder.com/190?text=Explore+History' },
    // { id: 6, title: 'Hiking & Diving', image: 'https://via.placeholder.com/190?text=Hiking+%26+Diving' },
];

const CategorySection = () => {
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        autoplay: true, // Enable autoplay
        autoplaySpeed: 3000, // Time between slides in milliseconds
        slidesToShow: 3,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            }
        ]
    };

    return (
        <section className="category-section pt_100 pb_90 centred">
            <div className="auto-container">
                <div className="sec-title mb_50">
                    <span className="sub-title">Tour Types</span>
                    <h2>Find Adventure in Life</h2>
                </div>
                <Slider {...settings}>
                    {categoriesData.map(category => (
                        <div className="category-block-one" key={category.id}>
                            <div className="inner-box">
                                <figure className="image-box urva">
                                    <img src={category.image} alt={category.title} />
                                </figure>
                                <h4><a href="" className='darkcolor'>{category.title}</a></h4>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        </section>
    );
};

export default CategorySection;
