
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentinpune() {



  const cardData =
  {
    keyword: ' Bus on Rent in Pune ',
    heading: 'Shraddha Travels: Bus on Rent in Pune ',
    headingDescription: 'If you’re looking for reliable and affordable bus rental services in Pune, Shraddha Travels has you covered. We offer a diverse fleet of buses tailored to various needs, from corporate events to school trips and private tours. Here is a guide to our top bus rental options in Pune that ensure comfort, safety, and convenience for every journey.',

    topPlaces: [
        {
          title: "1. Luxury Bus on Rent in Pune",
          description: "Experience the epitome of comfort with our luxury bus rentals. Ideal for corporate events, weddings, or high-profile gatherings, our luxury buses are equipped with plush seating, modern amenities, and top-notch service. Each vehicle is designed to provide a serene travel experience, complete with entertainment systems, refreshments, and more, ensuring your journey is as enjoyable as your destination."
        },
        {
          title: "2. 40-Seater Bus on Rent in Pune",
          description: "Perfect for large groups, our 40-seater buses provide ample space and comfort. Whether it’s for a corporate outing, family reunion, or a group tour, these buses offer a comfortable ride with features designed to keep everyone relaxed throughout the journey. Spacious seating arrangements, air conditioning, and large windows enhance the experience, making it enjoyable for all passengers."
        },
        {
          title: "3. 32-Seater Bus on Rent in Pune",
          description: "Our 32-seater buses are a great choice for medium-sized groups. They offer a balance between space and affordability, making them ideal for school trips, picnics, and other group activities. With comfortable seating, ample legroom, and modern amenities, your journey will be smooth and enjoyable. Our drivers are experienced in navigating through city traffic, ensuring timely arrivals at your destinations."
        },
        {
          title: "4. 17-Seater Mini Bus on Rent in Pune",
          description: "For smaller groups or short trips, our 17-seater mini buses provide a cozy and efficient travel solution. Perfect for family outings or small corporate events, these mini buses are well-maintained and designed for a pleasant travel experience. Equipped with comfortable seats and essential amenities, they ensure that you and your companions enjoy every moment of the journey."
        },
        {
          title: "5. Tempo Traveller on Rent in Pune",
          description: "Our tempo travellers are ideal for flexible travel needs, offering space for both passengers and luggage. Whether you’re planning a day trip or an extended journey, our tempo travellers provide a comfortable and practical option for diverse travel requirements. With features like air conditioning, reclining seats, and adequate storage, they cater to all your travel needs."
        },
        {
          title: "6. Mini Bus on Rent in Pune",
          description: "For those who need a compact yet comfortable travel option, our mini buses are the perfect choice. They are ideal for smaller groups, offering convenience and ease of movement without compromising on comfort. These buses are designed for agility in urban traffic while providing a comfortable and enjoyable travel experience for passengers."
        },
        {
          title: "7. Luxury Mini Bus on Rent in Pune",
          description: "Our luxury mini buses combine the benefits of compact size with luxury features. They are perfect for small groups looking for a premium travel experience. Enjoy plush interiors, advanced entertainment systems, and modern amenities while traveling in style. Whether it's a special event or a corporate meeting, our luxury mini buses ensure a memorable journey."
        },
        {
          title: "8. Bus on Rent in Hadapsar",
          description: "Shraddha Travels offers specialized bus rental services in Hadapsar, ensuring that local and outstation trips are covered with ease. Our buses are well-maintained and ready to provide a smooth and comfortable travel experience. With a focus on customer satisfaction, we prioritize punctuality and reliability for all our trips."
        },
        {
          title: "9. Bus on Rent in Baner",
          description: "Baner residents and businesses can benefit from our reliable bus rental services. Whether it’s for corporate events, school outings, or personal trips, our Baner-based services ensure timely and efficient transportation solutions. Our buses are equipped with the latest amenities, providing a comfortable ride for all types of group travels."
        },
        {
          title: "10. Bus on Rent in Pimpri Chinchwad",
          description: "For those in Pimpri Chinchwad, Shraddha Travels offers a range of bus rental options tailored to local and outstation needs. Our fleet includes various bus sizes and types, providing flexibility and comfort for any journey. With a commitment to safety and customer satisfaction, we ensure that every trip is enjoyable and hassle-free."
        }
      ],      
      services: [
        {
          name: "Pune to Goa Mini Bus on Rent",
          description: "Experience the scenic journey from Pune to Goa with our mini bus rentals. Ideal for small to medium-sized groups, our mini buses are equipped with comfortable seating and modern amenities. Enjoy a spacious interior and large windows for stunning views along the route, ensuring a smooth and enjoyable trip for all passengers."
        },
        {
          name: "Pune to Goa Tempo Traveller on Rent",
          description: "For a more flexible travel experience, opt for our tempo travellers. Perfect for group trips to Goa, these vehicles offer ample space for both passengers and luggage. With comfortable seating, air conditioning, and a professional driver, your journey will be enjoyable and hassle-free, allowing you to focus on the fun ahead."
        },
        {
          name: "Shraddha Travels Pune",
          description: "Shraddha Travels is your trusted partner for reliable bus rental services from Pune to Goa. Our extensive fleet includes a variety of vehicles to suit every group size and budget. With exceptional service and a commitment to customer satisfaction, we ensure a comfortable and enjoyable travel experience from start to finish."
        },
        {
          name: "Pune to Goa Urbania on Rent",
          description: "Travel in style with our Urbania buses, which are designed for comfort and luxury. Equipped with modern amenities such as reclining seats, entertainment systems, and Wi-Fi, these buses provide an upscale travel experience for your journey from Pune to Goa. Enjoy the comfort of a spacious interior as you relax and take in the beautiful scenery."
        },
        {
          name: "Pune to Goa 17 Seater on Rent",
          description: "Our 17-seater buses are perfect for small groups traveling from Pune to Goa. These buses provide cozy and comfortable seating, along with sufficient space for everyone’s belongings. Ideal for family trips or outings with friends, our 17-seater ensures that you travel together comfortably, making memories along the way."
        },
        {
          name: "Pune to Goa 14, 17, 25, 32 Seater on Rent",
          description: "Choose from a range of bus sizes tailored to suit your group’s needs. Whether you require a 14-seater, 17-seater, 25-seater, or 32-seater, we have the perfect vehicle for your trip to Goa. Each bus is well-maintained and designed for passenger comfort, allowing your group to travel together with ease."
        },
        {
          name: "Pune to Goa 35, 45, 50 Seater on Rent",
          description: "For larger groups, our 35-seater, 45-seater, and 50-seater buses provide ample space and comfort. Enjoy a spacious and relaxing journey from Pune to Goa, complete with air conditioning and comfortable seating. Our professional drivers ensure a safe and smooth ride, so you can enjoy your time with friends or colleagues."
        },
        {
          name: "Pune to Goa Bus Package on Rent",
          description: "Opt for our comprehensive bus packages for your trip from Pune to Goa. These packages offer great value, including not just transportation but also additional services like refreshments, sightseeing options, and guided tours. We handle all the logistics so you can focus on enjoying your trip."
        },
        {
          name: "Hire Pune to Goa Mini Bus & Tempo Traveller on Rent",
          description: "Combine the flexibility of tempo travellers with the comfort of mini buses. Our rental options cater to various group sizes and preferences for your journey from Pune to Goa. Whether you’re planning a fun family getaway or a corporate outing, we have the right vehicle to meet your needs."
        },
        {
          name: "Hire Tempo Traveller Minibus",
          description: "For a versatile travel solution, consider hiring a tempo traveller or mini bus. These options provide comfort and space for your group while traveling from Pune to Goa. Equipped with modern amenities and safety features, our vehicles ensure that you enjoy a pleasant journey, no matter the distance."
        },
        {
          name: "Bus on Rent in Pune",
          description: "Shraddha Travels offers a wide range of buses for rent in Pune, catering to various travel needs. Whether you need a bus for a local trip, a wedding, or an outstation journey, we have the right vehicle for you. Our well-maintained fleet and professional drivers ensure that your travel experience is safe and enjoyable."
        },
        {
          name: "Pune to Goa Bus Hire on Rent",
          description: "Our bus hire services for the Pune to Goa route ensure a smooth and comfortable journey. Choose from our fleet of well-maintained buses, equipped with air conditioning and modern seating. Our experienced drivers are familiar with the route, ensuring you arrive at your destination safely and on time."
        },
        {
          name: "Pune to Goa AC Bus on Rent",
          description: "Travel in comfort with our AC buses for your journey from Pune to Goa. Our air-conditioned buses provide a cool and pleasant environment, making your trip more enjoyable. With spacious seating and entertainment options, you can relax and unwind as you travel to your destination."
        },
        {
          name: "Pune to Goa Bus Rental Service",
          description: "Shraddha Travels provides top-notch bus rental services for trips from Pune to Goa. Our professional drivers and well-maintained buses ensure a seamless and pleasant travel experience. Whether it’s a family vacation, a corporate trip, or a group outing, we are committed to making your journey enjoyable."
        },
        {
          name: "Hire a Bus from Pune to Goa",
          description: "Whether you need a mini bus, tempo traveller, or a large coach, Shraddha Travels offers flexible bus hire options for your journey from Pune to Goa. Our booking process is simple and convenient, allowing you to focus on planning your trip while we handle the transportation."
        },
        {
          name: "Bus Rental Service Pune to Goa",
          description: "For reliable and efficient bus rental services from Pune to Goa, choose Shraddha Travels. Our extensive fleet and exceptional service guarantee a pleasant journey for your group. With transparent pricing and no hidden fees, we offer great value for your travel needs."
        },
        {
          name: "Pune to Goa Holiday Bus Rental",
          description: "Make your holiday to Goa memorable with Shraddha Travels’ bus rental services from Pune. Our well-maintained buses are perfect for long-distance travel, offering comfort and reliability. Enjoy beautiful views and create unforgettable memories as you journey to your beach destination."
        },
        {
          name: "Pune to Goa Family Trip Bus Hire",
          description: "Traveling with family to Goa? Choose Shraddha Travels for your bus hire needs. Our family-friendly buses provide ample space and comfort for a relaxed trip. We prioritize safety and convenience, ensuring that your family vacation is enjoyable from start to finish."
        },
        {
          name: "Pune to Goa Weekend Getaway Bus Rental",
          description: "For a quick weekend getaway to Goa, Shraddha Travels offers convenient bus rental services. Our buses are designed for comfort and efficiency, ensuring a stress-free journey. With a focus on customer satisfaction, we provide reliable transportation so you can enjoy your weekend adventure."
        },
        {
          name: "Pune to Goa Corporate Bus Hire",
          description: "Shraddha Travels provides specialized corporate bus hire services for trips from Pune to Goa. Whether it’s for a company retreat, team-building event, or corporate meeting, our buses offer a professional and comfortable travel experience. Enjoy amenities designed for corporate needs to make your trip productive and pleasant."
        },
        {
          name: "Pune to Goa Group Travel Bus Rental",
          description: "For group travel from Pune to Goa, Shraddha Travels offers a range of bus rental options to accommodate different group sizes. Our buses are ideal for tours, group outings, and other collective travel needs, ensuring that everyone travels comfortably and enjoys the journey."
        },
        {
          name: "AC Bus Hire Pune to Goa",
          description: "Travel in comfort with Shraddha Travels’ AC bus hire service for trips from Pune to Goa. Our air-conditioned buses are designed to provide a cool and comfortable environment, making long-distance travel more enjoyable. Perfect for those seeking a premium travel experience, our AC buses ensure a pleasant journey."
        },
        {
          name: "Comfortable Bus from Pune to Goa",
          description: "Shraddha Travels prioritizes comfort with our bus rental services for journeys from Pune to Goa. Our buses are equipped with modern amenities, spacious seating, and safety features to ensure a relaxing and enjoyable ride for all passengers, whether traveling for leisure or business."
        },
        {
          name: "32-Seater Bus Rental Pune to Goa",
          description: "For medium-sized groups traveling from Pune to Goa, consider our 32-seater bus rental option. These well-maintained buses provide ample space and comfort for your journey, making them ideal for family groups, friends, or small corporate teams looking to explore Goa together."
        },
        {
          name: "50-Seater Bus Hire from Pune to Goa",
          description: "If you're planning a large group trip to Goa, Shraddha Travels offers 50-seater bus rentals to accommodate your needs. Our spacious buses ensure plenty of room and comfort for a long journey, allowing your group to enjoy each other's company while traveling to the beach."
        },
        {
          name: "Tempo Traveller on Rent in Pune for Corporate Events",
          description: "For corporate events, Shraddha Travels offers tempo travellers on rent in Pune. Our tempo travellers are ideal for transporting corporate teams and executives, providing a comfortable and efficient travel option. Equipped with modern amenities, our vehicles ensure a successful and enjoyable corporate experience."
        },
        {
          name: "Luxury Tempo Traveller on Rent in Pashan Pune",
          description: "Experience premium travel with our luxury tempo traveller rentals in Pashan, Pune. Ideal for special occasions or corporate events, these vehicles offer high-end features, spacious interiors, and superior comfort. Elevate your travel experience with our luxurious transport solutions."
        },
        {
          name: "Bus On Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus On Rent in Pune. Our dedicated team is ready to assist you with your travel needs, ensuring a smooth and enjoyable ride for all our customers. Whether it’s a local trip or a journey to Goa, we’ve got you covered."
        }
      ],      
      tableData: [
        ['- Pune to Goa Mini Bus on Rent', '- Pune to Goa Tempo Traveller on Rent'],
        ['- Shraddha Travels Pune', '- Pune to Goa Urbania on Rent'],
        ['- Pune to Goa 17 Seater on Rent', '- Pune to Goa 14, 17, 25, 32 Seater on Rent'],
        ['- Pune to Goa 35, 45, 50 Seater on Rent', '- Pune to Goa Bus Package on Rent'],
        ['- Hire Pune to Goa Mini Bus & Tempo Traveller on Rent', '- Hire Tempo Traveller Minibus'],
        ['- Bus on Rent in Pune', '- Pune to Goa Bus Hire on Rent'],
        ['- Pune to Goa AC Bus on Rent', '- Pune to Goa Bus Rental Service'],
        ['- Hire a Bus from Pune to Goa', '- Pune to Goa Holiday Bus Rental'],
        ['- Pune to Goa Family Trip Bus Hire', '- Pune to Goa Weekend Getaway Bus Rental'],
        ['- Pune to Goa Corporate Bus Hire', '- Pune to Goa Group Travel Bus Rental'],
        ['- AC Bus Hire Pune to Goa', '- Comfortable Bus from Pune to Goa'],
        ['- 32-Seater Bus Rental Pune to Goa', '- 50-Seater Bus Hire from Pune to Goa'],
        ['- Tempo Traveller on Rent in Pune for Corporate Events', '- Luxury Tempo Traveller on Rent in Pashan Pune']
      ],      
      whychoose: [
        {
          "WhyChooseheading": "Why Choose Shraddha Travels for Bus on Rent in Pune?",
          "WhyChoosedescription": "When it comes to renting a bus in Pune, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Whether you're planning a family trip, a corporate event, or a large group outing, our service ensures that you travel in style and comfort. We take pride in our commitment to customer satisfaction, making us the best choice for your travel needs."
        },
        {
          "WhyChooseheading": "Extensive Fleet Options:",
          "WhyChoosedcription": "Our fleet includes a diverse range of buses to accommodate different group sizes and travel requirements. From luxury coaches equipped with premium amenities to standard AC and non-AC buses, we ensure that you have the perfect vehicle for every occasion. Whether it’s a small family gathering or a large corporate event, we have a suitable option ready for you."
        },
        {
          "WhyChooseheading": "Comfort & Safety:",
          "WhyChoosedescription": "Each bus in our fleet is meticulously maintained and outfitted with modern amenities to ensure a comfortable journey. Features such as plush seating, air conditioning, and entertainment systems are standard in our vehicles. Moreover, safety is our utmost priority; we adhere to stringent safety protocols, and all our buses undergo regular inspections to meet the highest safety standards."
        },
        {
          "WhyChooseheading": "Experienced & Professional Drivers:",
          "WhyChoosedescription": "Our drivers are not just skilled; they are also trained to provide exceptional customer service. Familiar with local routes and traffic patterns, they ensure a smooth and timely travel experience. With their professionalism and commitment to passenger safety, you can sit back, relax, and enjoy the journey knowing you’re in capable hands."
        },
        {
          "WhyChooseheading": "Affordable & Transparent Pricing:",
          "WhyChoosedescription": "At Shraddha Travels, we believe that quality service should be accessible. Our pricing structures are designed to be competitive and transparent, with no hidden fees or surprise charges. Whether you're planning a short city tour or an extended outstation trip, we offer affordable rates that provide excellent value for your money."
        },
        {
          "WhyChooseheading": "Customizable Packages:",
          "WhyChoosedescription": "We understand that every trip is unique, which is why we offer flexible and customizable packages tailored to your specific needs. Whether you’re organizing a corporate outing, wedding transportation, school trips, or sightseeing tours, our team will work with you to create a personalized itinerary that fits your schedule and preferences."
        },
        {
          "WhyChooseheading": "24/7 Customer Support:",
          "WhyChoosedescription": "Our dedicated customer support team is available around the clock to assist you with every aspect of your booking. Whether you need help with route planning, last-minute changes, or have any inquiries, we are just a phone call away. Our commitment to customer care ensures a hassle-free experience from the moment you contact us to the completion of your journey."
        },
        {
          "WhyChooseheading": "Punctuality and Reliability:",
          "WhyChoosedescription": "Time is crucial, and we understand its importance in travel planning. At Shraddha Travels, we pride ourselves on our punctuality, ensuring that our buses arrive on time for both departures and arrivals. Our reliable service means you can count on us to keep your travel schedule on track, allowing you to focus on enjoying your trip without the stress of delays."
        }
      ]
      
  }


  const faqData = [
    {
      question: "What types of buses are available for rent?",
      answer: "We offer a variety of buses including luxury coaches, semi-luxury buses, AC/non-AC buses, and mini-buses to accommodate different group sizes and preferences."
    },
    {
      question: "How many passengers can your buses accommodate?",
      answer: "Our buses can accommodate anywhere between 18 to 50 passengers, depending on the model you choose."
    },
    {
      question: "Can I book a bus for an outstation trip?",
      answer: "Yes, we provide bus rental services for both local travel within Pune and outstation trips across Maharashtra and beyond."
    },
    {
      question: "How do I make a booking for a bus on rent?",
      answer: "You can book a bus through our website, call our customer service hotline, or visit our office. We recommend booking in advance, especially during peak seasons."
    },
    {
      question: "Do your buses come with a driver?",
      answer: "Yes, all our buses come with professional, experienced drivers who are familiar with local and long-distance routes to ensure a safe and smooth journey."
    },
    {
      question: "What are your payment options?",
      answer: "We accept multiple payment methods, including online transfers, credit/debit cards, and cash payments."
    },
    {
      question: "Are there any additional charges for fuel or tolls?",
      answer: "Our pricing is transparent, and any additional charges such as fuel and tolls will be clearly mentioned upfront during the booking process."
    },
    {
      question: "Can I rent a bus for a few hours?",
      answer: "Yes, we offer flexible rental options, whether you need a bus for a few hours, a full day, or an extended trip."
    },
    {
      question: "Is there a cancellation policy?",
      answer: "Yes, we have a cancellation policy in place. You can cancel your booking; however, cancellation charges may apply depending on the timing of the cancellation."
    }
  ];
  

  const testimonials = [
    {
      name: "Mr. Rahul Mehta",
      text: "We booked a luxury bus from Shraddha Travels for our corporate event, and the experience was fantastic! The bus was extremely comfortable, spacious, and well-maintained. The driver was very professional and navigated through the city smoothly. The entire team at Shraddha Travels ensured that our group was well taken care of from start to finish. I highly recommend their bus rental service in Pune for any occasion!"
    },
    {
      name: "Miss Sneha Kulkarni",
      text: "Shraddha Travels made our family trip to Lonavala truly enjoyable! We rented a bus for 30 people, and the service exceeded our expectations. The booking process was easy, the rates were very reasonable, and the bus arrived right on time. Our driver was friendly, skilled, and knew the best routes. We felt safe and comfortable throughout the journey. I will definitely use their services again for future trips!"
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus On Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus On Rent in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Reliable bus rental services from Pune to Goa, including mini buses and tempo travellers for group travel." />
    <meta name="keywords" content="Bus On Rent in Pune, Pune to Goa Mini Bus Rental, Tempo Traveller Hire, Corporate Bus Hire, Shraddha Travels" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus On Rent in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Affordable and reliable bus rental services from Pune to Goa for holidays, family trips, and corporate events." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/bus-rental-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus On Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services in Pune",
                    "description": "Comfortable and affordable bus rental services from Pune to Goa, ideal for family trips and corporate events."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-08.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for “Bus on Rent in Pune” – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentinpune;