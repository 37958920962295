
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireforoutstation() {



  const cardData =
  {
    keyword: ' Bus Hire for Outstation in Pune',
    heading: 'Shraddha Travels: Bus Hire for Outstation in Pune',
    headingDescription: 'When it comes to reliable and comfortable bus hire for outstation travel from Pune, Shraddha Travels stands out as the preferred choice for discerning travelers. Whether you’re planning a corporate retreat, a family vacation, or a group tour, our extensive fleet and exceptional service ensure a hassle-free journey to your chosen destination. Here’s why Shraddha Travels is the go-to option for outstation bus hire and the top destinations we serve with excellence.',
    top: 'Top Outstation Destinations Served by Shraddha Travels',

    topPlaces: [
        {
            title: "1. Mumbai",
            description: "Experience the vibrant pulse of Mumbai, filled with iconic landmarks like the Gateway of India, Marine Drive, and the bustling markets of Colaba. Our buses provide a comfortable journey to and from this dynamic metropolis, making it perfect for leisure and business travelers alike. Explore the city's rich culture, indulge in street food, and enjoy the lively nightlife."
        },
        {
            title: "2. Lonavala",
            description: "Escape to the serene beauty of Lonavala, a favorite hill station near Pune known for its lush green hills, stunning waterfalls, and cool climate. Our buses offer a relaxing ride, ensuring you arrive refreshed and ready to explore this picturesque destination ideal for weekend getaways. Visit attractions like Bhushi Dam and Tiger’s Leap, and enjoy the local chikki sweets."
        },
        {
            title: "3. Nashik",
            description: "Known for its lush vineyards and sacred temples, Nashik draws both tourists and pilgrims. This city is not just a pilgrimage site; it’s also a wine lover's paradise with numerous vineyards offering tastings and tours. We offer comfortable bus hire services that make it easy to explore the rich cultural and natural heritage of this enchanting region."
        },
        {
            title: "4. Goa",
            description: "Famous for its stunning beaches, vibrant markets, and lively culture, Goa is a top vacation destination for relaxation and adventure. Enjoy sunbathing on beaches like Baga and Anjuna, explore historical sites such as Fort Aguada, and savor the delicious Goan cuisine. Our buses guarantee a smooth and enjoyable journey, allowing you to relax and soak in the sun at this tropical paradise."
        },
        {
            title: "5. Shirdi",
            description: "As a major pilgrimage site dedicated to Sai Baba, Shirdi attracts thousands of devotees every year. The spiritual atmosphere and serene environment provide a perfect setting for reflection and devotion. Our reliable bus service provides a convenient way to reach this sacred destination, ensuring a peaceful journey for all visitors. Experience the divine serenity and visit the Sai Baba Temple."
        },
        {
            title: "6. Mahabaleshwar",
            description: "With its pleasant climate and breathtaking viewpoints, Mahabaleshwar is a beloved hill station known for its strawberry farms and scenic landscapes. Enjoy activities like boating on Venna Lake and trekking to viewpoints such as Arthur’s Seat. Shraddha Travels offers comfortable buses to this tranquil getaway, perfect for relaxation and rejuvenation amid nature."
        },
        {
            title: "7. Pandharpur",
            description: "Renowned for its spiritual significance, especially the Vithoba temple, Pandharpur is an important pilgrimage site that attracts devotees from all over India. The vibrant atmosphere during festivals adds to its charm. Our buses make it simple and convenient to reach this revered location, ensuring a fulfilling visit to the temple and the surrounding sacred sites."
        },
        {
            title: "8. Ajanta and Ellora Caves",
            description: "Discover the ancient artistry of the Ajanta and Ellora Caves, famous for their intricate carvings and historical importance. These UNESCO World Heritage Sites showcase Buddhist, Jain, and Hindu rock-cut architecture. Our buses provide a comfortable journey, making it easy to explore these awe-inspiring caves while learning about their cultural significance."
        },
        {
            title: "9. Lavasa",
            description: "A beautifully planned hill city, Lavasa offers stunning views, serene lakes, and a peaceful atmosphere. Ideal for both business meetings and leisurely outings, you can enjoy outdoor activities like boating, trekking, and cycling. Our buses ensure a smooth ride to Lavasa, making it a perfect destination for relaxation and rejuvenation away from the hustle and bustle."
        },
        {
            title: "10. Tuljapur",
            description: "Home to the revered Tulja Bhavani Temple, Tuljapur is a key pilgrimage destination known for its rich history and spiritual significance. Visitors can immerse themselves in the local culture and witness traditional rituals. We provide dependable bus services to facilitate your visit to this significant site, ensuring a respectful and memorable experience for all pilgrims."
        }
    ],    
    services: [
        {
            name: "Mini Bus Booking for Outstation",
            description: "Shraddha Travels offers mini bus booking for outstation travel, providing a comfortable and convenient option for your trips. Our mini buses are perfect for smaller groups, featuring plush seating and modern amenities to ensure a pleasant journey. Whether you're planning a family outing or a weekend escape, our mini buses make every outing enjoyable."
        },
        {
            name: "Bus Hire for Outstation in Hadapsar",
            description: "If you’re in Hadapsar and need to hire a bus for an outstation trip, Shraddha Travels is your trusted partner. We provide reliable and comfortable bus hire services to various outstation destinations, ensuring a smooth travel experience. Our team is dedicated to meeting your specific needs, allowing you to focus on enjoying your trip."
        },
        {
            name: "Outstation Mini Bus Hire",
            description: "For your outstation travels, our mini bus hire services offer the perfect solution for smaller groups. Our well-maintained mini buses come equipped with modern amenities such as air conditioning, entertainment systems, and comfortable seating to enhance your travel experience. Enjoy a seamless journey while we take care of your travel needs."
        },
        {
            name: "Hire a Bus for Outstation Pune to Shirdi",
            description: "Traveling from Pune to Shirdi has never been easier! Hire a bus from Shraddha Travels and experience a reliable and comfortable journey. Our buses are designed for maximum comfort and safety, featuring experienced drivers who know the best routes. Make your pilgrimage to Shirdi enjoyable and stress-free with our dedicated service."
        },
        {
            name: "Mini Bus for Outstation from Kharadi",
            description: "If you need a mini bus for outstation travel from Kharadi, Shraddha Travels offers excellent services tailored to ensure a comfortable and efficient journey. Our mini buses are perfect for group outings, ensuring that your trip is both enjoyable and hassle-free. With our commitment to punctuality, you can relax and focus on your adventure."
        },
        {
            name: "Book Bus for Outstation",
            description: "Booking a bus for your outstation travel with Shraddha Travels is simple and convenient. Choose from our diverse range of buses tailored to suit your group size and travel needs. With a straightforward booking process and dedicated customer support, we ensure a memorable trip for everyone involved."
        },
        {
            name: "Outstation Bus Hire in Baner",
            description: "If you’re located in Baner and require a bus for an outstation trip, Shraddha Travels provides top-notch services that guarantee a pleasant journey. Our commitment to comfort and reliability makes us a preferred choice for travelers. With flexible booking options, we cater to both large groups and intimate gatherings."
        },
        {
            name: "Mini Bus Booking Near Me",
            description: "Find convenient mini bus booking services near you with Shraddha Travels. We offer a range of options for booking mini buses tailored for your outstation trips, making your travel planning effortless. Enjoy the peace of mind that comes with our reliable service and well-maintained vehicles."
        },
        {
            name: "Kalyani Nagar to Outstation Bus Hire",
            description: "For outstation travel from Kalyani Nagar, Shraddha Travels provides dependable bus hire services designed to ensure a smooth and comfortable journey. Our experienced drivers and well-maintained buses make your trip worry-free. Let us handle the logistics while you enjoy the journey."
        },
        {
            name: "14, 17, 25, 32 Seater Hire for Outstation Trip",
            description: "Choose from our extensive range of 14-seater, 17-seater, 25-seater, and 32-seater buses for your outstation trip. Shraddha Travels offers a variety of options to fit your group size and travel preferences, making it easy to plan your journey. Each bus is equipped with amenities to ensure comfort and convenience."
        },
        {
            name: "Pune to Ajanta Ellora Caves Bus Tour",
            description: "Discover the wonders of the Ajanta and Ellora Caves with our dedicated bus tour service from Pune. Our buses come with comfortable seating and modern amenities, ensuring your journey to these historic marvels is pleasant and enjoyable. Explore the rich history and stunning architecture without worrying about travel logistics."
        },
        {
            name: "AC 17-Seater Bus on Rent in Pune",
            description: "For smaller groups looking for comfort and style, Shraddha Travels offers AC 17-seater buses for rent in Pune. These air-conditioned buses are ideal for family outings, corporate meetings, or group tours, providing a comfortable environment for all passengers. Travel in style and enjoy the luxury of air conditioning on your journeys."
        },
        {
            name: "Private Bus Service in Pune for Office",
            description: "For corporate outings or meetings, Shraddha Travels offers private bus services in Pune. Our buses are designed to provide a comfortable and efficient travel experience for employees, ensuring that your office events run smoothly. With professional drivers and well-maintained vehicles, your team can relax and focus on the task at hand."
        },
        {
            name: "Outstation School Picnic Bus on Rent",
            description: "Make school picnics memorable with Shraddha Travels’ outstation bus rental services. Our buses are ideal for transporting students, offering comfort and safety for school trips. We prioritize the well-being of your students, ensuring an enjoyable outing for everyone involved while adhering to safety standards."
        },
        {
            name: "Tempo Traveller for Outstation",
            description: "If you require a compact yet comfortable vehicle for your outstation trip, consider renting a tempo traveller from Shraddha Travels. Perfect for medium-sized groups, our tempo travellers provide a flexible and enjoyable ride, equipped with essential amenities for a comfortable journey."
        },
        {
            name: "Book Tempo Traveller for Outstation",
            description: "Booking a tempo traveller for your outstation journey is hassle-free with Shraddha Travels. Our straightforward booking process and wide range of vehicles ensure a reliable and comfortable experience for all travelers. Enjoy the convenience of flexible travel arrangements tailored to your needs."
        },
        {
            name: "Tata Winger for Outstation in Pune",
            description: "Shraddha Travels also offers Tata Winger vehicles for outstation trips. The Tata Winger combines comfort and efficiency, making it an excellent choice for group travel. Enjoy the spacious interiors and smooth ride, ensuring a delightful experience for all passengers."
        },
        {
            name: "Bus Hire for Outstation in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus hire for outstation in Pune. We ensure a smooth and enjoyable ride for all our customers, with a focus on customer satisfaction and travel safety. Reach out to us for any inquiries or to book your next journey!"
        }
    ],
    tableData: [
        ['- Mini Bus Booking for Outstation', '- Hire Bus for Outstation in Hadapsar'],
        ['- Outstation Mini Bus Hire', '- Mini Bus on Rent for Outstation in Pune'],
        ['- Hire a Bus for Outstation Pune to Shirdi', '- Mini Bus for Outstation from Kharadi'],
        ['- Book Bus for Outstation', '- Outstation Bus Hire in Baner'],
        ['- Mini Bus Booking Near Me', '- Kalyani Nagar to Outstation Bus Hire'],
        ['- 14, 17, 25, 32 Seater Hire for Outstation Trip', '- 14, 17, 25, 32, 35 Seater Hire for Outstation from Pune'],
        ['- Pune to Ajanta Ellora Caves Bus Tour', '- AC 17 Seater Bus on Rent in Pune'],
        ['- Bus Hire for Outstation in Pune', '- Private Bus Service in Pune for Office'],
        ['- Outstation School Picnic Bus on Rent', '- Tempo Traveller for Outstation'],
        ['- Book Tempo Traveller for Outstation', '- Mini Bus on Rent for Outstation in Pune'],
        ['- Tata Winger for Outstation in Pune', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Hire for Outstation in Pune?",
            WhyChoosedescription: "When it comes to hiring a bus for outstation travel, Shraddha Travels is your ideal partner for a comfortable and reliable journey. We understand the importance of a well-planned trip, whether it's for leisure or business, and strive to make your travel experience smooth and enjoyable. Here's why you should choose us:"
        },
        {
            WhyChooseheading: "Extensive Fleet:",
            WhyChoosedescription: "We offer a diverse range of vehicles, from mini buses to large coaches, catering to various group sizes and preferences. Our fleet includes modern, well-maintained buses equipped with the latest amenities, ensuring that you travel in comfort, regardless of the distance."
        },
        {
            WhyChooseheading: "Comfort and Safety:",
            WhyChoosedescription: "Your comfort and safety are our top priorities. Our buses are equipped with comfortable seating, air conditioning, and entertainment options. Additionally, we adhere to strict safety protocols and maintain our vehicles to the highest standards, so you can relax and enjoy the journey."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "We pride ourselves on offering competitive pricing that fits your budget without compromising quality. Our transparent pricing policy means there are no hidden fees, allowing you to plan your travel expenses confidently and enjoy peace of mind."
        },
        {
            WhyChooseheading: "Easy Booking Process:",
            WhyChoosedescription: "Our user-friendly online booking system makes it easy to reserve your bus in just a few clicks. If you prefer personalized assistance, our customer service team is readily available to help you with your reservations and any special requests you may have."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional drivers are not only skilled but also familiar with various routes, ensuring timely arrivals and safe travels. Their experience enhances your journey, allowing you to relax while they navigate the roads."
        },
        {
            WhyChooseheading: "Customer Satisfaction:",
            WhyChoosedescription: "We are committed to providing exceptional service and ensuring our customers have a memorable travel experience. Our positive reviews and loyal clientele reflect our dedication to customer satisfaction, and we continuously strive to exceed your expectations."
        },
        {
            WhyChooseheading: "Flexible Options:",
            WhyChoosedescription: "We understand that every trip is unique, which is why we offer flexible booking options to accommodate your schedule. Whether you need a bus for a short day trip, a weekend getaway, or an extended journey, we tailor our services to meet your specific needs."
        },
        {
            WhyChooseheading: "24/7 Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with any queries or concerns you may have. From the moment you book to the conclusion of your journey, we ensure you have the support you need for a hassle-free travel experience."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses do you offer for outstation travel?",
        answer: "We offer a variety of buses, including mini buses, standard buses, and luxury coaches. This ensures that we can accommodate different group sizes and travel preferences, whether for a family outing, corporate event, or pilgrimage."
    },
    {
        question: "How do I book a bus for outstation travel?",
        answer: "You can easily book a bus online through our website or by contacting our customer service team. Our staff will guide you through the booking process, helping you select the best vehicle to suit your travel needs."
    },
    {
        question: "Are your buses equipped with amenities?",
        answer: "Yes, all our buses are equipped with essential amenities, including comfortable seating, air conditioning, and entertainment options. We strive to provide a pleasant travel experience for all passengers."
    },
    {
        question: "Do you provide drivers for the hired buses?",
        answer: "Absolutely! All our bus rentals come with experienced and professional drivers who are familiar with various routes. They ensure your safety and comfort throughout the journey."
    },
    {
        question: "What are your payment options?",
        answer: "We accept various payment methods, including online payments, bank transfers, and cash. This variety ensures that the booking process is convenient for you."
    },
    {
        question: "Can I customize my itinerary?",
        answer: "Yes, we offer flexible itineraries tailored to your travel needs. Our team is more than happy to help you plan your route and schedule based on your specific preferences."
    },
    {
        question: "What if I need to cancel or modify my booking?",
        answer: "We understand that plans can change. Please contact our customer service team as soon as possible to discuss your cancellation or modification options, and we will assist you accordingly."
    },
    {
        question: "Is there a minimum rental duration for outstation trips?",
        answer: "Yes, there is a minimum rental duration for outstation trips, typically starting at 8 hours or 80 kilometers. For specific details, please check with our customer service team."
    },
    {
        question: "Are there additional charges for tolls or parking?",
        answer: "Toll fees, parking charges, and other expenses are generally not included in the rental price and may be charged extra. We will provide a clear breakdown of potential additional costs when you book."
    },
    {
        question: "How can I reach customer support if I have further questions?",
        answer: "You can reach our customer support team via phone, email, or through our website's contact form. We’re here to assist you with any queries you may have regarding your travel needs."
    }
];


const testimonials = [
    {
        name: "Miss Riya Sharma",
        text: "I recently hired a bus for an outstation trip to Lonavala with Shraddha Travels, and I couldn't be happier with the experience! From the booking process to the actual trip, everything was seamless. The bus was spacious, clean, and comfortable, making our journey enjoyable. Our driver was punctual and very friendly, ensuring we had a safe ride. I highly recommend Shraddha Travels for anyone looking for reliable and affordable bus hire services in Pune!"
    },
    {
        name: "Mr. Anil Mehta",
        text: "Our corporate team decided to take a day trip to Nashik, and we chose Shraddha Travels for our bus hire. It was a fantastic decision! The bus was equipped with all the necessary amenities, which made our trip pleasant and productive. The driver was professional and knowledgeable about the route, allowing us to relax and enjoy the journey. We will definitely be using Shraddha Travels for future outstation trips. Thank you for an excellent service!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus Hire for Outstation in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Outstation in Pune | Reliable Travel Services</title>
    <meta name="description" content="Affordable bus hire services for outstation trips from Pune to various destinations." />
    <meta name="keywords" content="Bus Hire for Outstation in Pune, Mini Bus Booking for Outstation, Outstation Bus Hire, Pune to Shirdi Bus" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Outstation in Pune | Reliable Travel Services" />
    <meta property="og:description" content="Reliable and affordable bus hire services for outstation trips from Pune to various destinations." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-hire-for-outstation-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/outstation-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire for Outstation",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-hire-for-outstation-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Hire for Outstation in Pune",
                    "description": "Affordable bus hire services for outstation trips from Pune to various destinations."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-13.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs - Bus Hire for Outstation in Pune - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireforoutstation;