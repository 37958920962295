
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Puneto5jyotilinga() {



  const cardData =
  {
    keyword: 'Pune to 5 Jyotirlinga Darshan Bus Hire',
    heading: 'Shraddha Travels: Pune to 5 Jyotirlinga Darshan Bus Hire',
    headingDescription: 'When you choose Shraddha Travels for your Pune to 5 Jyotirlinga Darshan bus hire, you are not just securing a comfortable and reliable mode of transport, but also embarking on a spiritual journey filled with enriching experiences. Our dedicated bus service is designed to ensure your pilgrimage to the Jyotirlingas is seamless and spiritually uplifting. Here’s what you can expect to see and visit along the way:',
    // top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
      {
        title: "1. Somnath Jyotirlinga",
        description: "Begin your sacred journey with the revered Somnath Jyotirlinga, located in Prabhas Patan, Gujarat. This temple, one of the 12 Jyotirlingas, stands as a testament to resilience and devotion, having been rebuilt several times over centuries. With stunning views of the Arabian Sea, the temple offers a serene atmosphere perfect for reflection and prayer, making it a must-visit for spiritual seekers."
      },
      {
        title: "2. Mahakaleshwar Jyotirlinga",
        description: "Next, visit the Mahakaleshwar Jyotirlinga in Ujjain, Madhya Pradesh, renowned for its unique 'Bhasma Aarti' performed with sacred ash. This ritual, held daily, attracts devotees from all over, adding to the temple’s vibrant atmosphere. Ujjain is rich in spiritual significance, with its ancient temples and ghats lining the sacred Shipra River, enhancing your divine experience."
      },
      {
        title: "3. Omkareshwar Jyotirlinga",
        description: "The Omkareshwar Jyotirlinga is located on Mandhata Island in Khandwa, shaped like the Om symbol. This architectural marvel dedicated to Lord Shiva is surrounded by lush greenery and tranquil waters, creating a peaceful setting for meditation. The island’s rich mythology and breathtaking views amplify the spiritual ambiance, making it a significant pilgrimage site."
      },
      {
        title: "4. Kedarnath Jyotirlinga",
        description: "Nestled in the majestic Himalayas, Kedarnath is accessible through a scenic trek that offers breathtaking views of the surrounding mountains. The Kedarnath Jyotirlinga, situated amidst snow-capped peaks, provides a serene environment that complements its spiritual significance. The journey to this sacred site is as much about the trek as it is about the destination, allowing for deep connection with nature and spirituality."
      },
      {
        title: "5. Kashi Vishwanath Jyotirlinga",
        description: "Conclude your tour with a visit to the Kashi Vishwanath Jyotirlinga in Varanasi, one of the holiest cities in Hinduism. The temple's proximity to the sacred Ganges River, along with the city’s rich historical legacy, makes it a profound spiritual destination. Witnessing the evening Aarti on the ghats is an experience that resonates deeply with the soul, offering a glimpse into the vibrant culture and spirituality of India."
      },
      {
        title: "6. Shirdi Sai Baba Temple",
        description: "While on your pilgrimage, don’t miss the Sai Baba Temple in Shirdi. Though not a Jyotirlinga, this temple is significant for its spiritual ambiance and the legacy of Sai Baba, who taught love, forgiveness, and devotion. The temple complex, filled with devotees from around the world, offers a peaceful environment for reflection and prayer, making it an enriching experience."
      },
      {
        title: "7. Pune - The Spiritual Hub",
        description: "Before embarking on your Jyotirlinga journey, explore Pune’s spiritual landmarks such as the Osho International Meditation Resort and the Dagdusheth Halwai Ganpati Temple. These sites offer enriching experiences that promote wellness and spiritual growth, making Pune a perfect starting point for your pilgrimage."
      },
      {
        title: "8. Ellora Caves",
        description: "En route, visit the UNESCO World Heritage Site of Ellora Caves, which feature ancient rock-cut temples and monasteries. The intricate carvings and stunning architecture reflect India's rich artistic and spiritual heritage, making it a remarkable stop that combines history and devotion."
      },
      {
        title: "9. Ajanta Caves",
        description: "Another historical gem is the Ajanta Caves, famous for their exquisite murals and sculptures that depict Buddhist themes. While not directly related to Jyotirlinga, the caves offer a glimpse into India’s spiritual art and culture, allowing visitors to appreciate the rich artistic traditions that have flourished throughout history."
      },
      {
        title: "10. Lonavala - Hill Station Retreat",
        description: "A picturesque stopover in Lonavala offers scenic beauty and tranquility, perfect for a refreshing break. Surrounded by lush greenery and majestic hills, this hill station is ideal for relaxation and rejuvenation, providing a peaceful escape from the hustle and bustle of city life."
      }
    ],    
    services: [
      {
        name: "Pune to Trimbakeshwar Bus Hire",
        description: "Travel comfortably and conveniently with our Pune to Trimbakeshwar bus hire service. Explore the sacred Trimbakeshwar Temple in Nashik, renowned for its significance as one of the 12 Jyotirlingas. Our buses are equipped with spacious seating, air conditioning, and modern amenities, ensuring you have a comfortable journey. Enjoy stunning views of the surrounding landscapes while our professional drivers handle the roads."
      },
      {
        name: "Pune to Aundha Nagnath Bus on Rent",
        description: "Book a bus for your pilgrimage from Pune to Aundha Nagnath, another revered Jyotirlinga site. Our rental service guarantees comfort and convenience, making your travel experience hassle-free. With a focus on safety and reliability, our well-maintained buses come with friendly drivers who will enhance your spiritual journey to this important destination in Hingoli district, Maharashtra."
      },
      {
        name: "Pune to Bhimashankar Bus Hire on Rent",
        description: "Experience a spiritual journey to Bhimashankar, known for its Jyotirlinga temple nestled in the serene Sahyadri hills. Our bus hire service from Pune provides reliable transportation with comfortable seating and essential amenities. As you travel, enjoy the breathtaking views of nature and the peaceful atmosphere that surrounds this sacred site, ensuring an enjoyable and enriching pilgrimage."
      },
      {
        name: "Pune to Grishneshwar Jyotirlinga Bus on Rent",
        description: "Travel to the Grishneshwar Jyotirlinga with ease using our dedicated bus rental service. Located in Ellora near Aurangabad, this sacred site is a key destination for devotees. Our buses are well-equipped with comfortable seating and air conditioning to make your journey smooth and pleasant. Experience the rich cultural heritage of the area as you approach this historic temple."
      },
      {
        name: "5 Jyotirlinga in Maharashtra Tour Package from Pune",
        description: "Embark on a divine tour of the five Jyotirlingas in Maharashtra with our comprehensive tour package from Pune. This package includes visits to Trimbakeshwar, Bhimashankar, Grishneshwar, Aundha Nagnath, and one other Jyotirlinga, allowing you to experience the spiritual significance of each site. Enjoy comfortable bus transport, guided tours, and spiritual insights along the way, ensuring a fulfilling pilgrimage."
      },
      {
        name: "Tempo Traveller on Rent for 5 Jyotirlinga Darshan",
        description: "For a more personalized and intimate pilgrimage experience, rent a tempo traveller for your 5 Jyotirlinga Darshan tour. Our vehicles are perfect for small groups, providing flexibility and comfort as you explore these sacred sites. With spacious interiors and modern amenities, your journey will be both enjoyable and memorable, allowing you to connect more deeply with each destination."
      },
      {
        name: "Pune to Jyotirlinga Darshan Tour Package with Bus",
        description: "Our tour package for Jyotirlinga Darshan from Pune includes bus rental and a meticulously planned itinerary covering all major Jyotirlinga temples. Enjoy a spiritually enriching journey with organized travel, experienced drivers, and ample time for prayers and rituals at each temple. This comprehensive package ensures a meaningful pilgrimage experience, allowing you to focus on your spiritual journey."
      },
      {
        name: "Mini Bus on Rent for Jyotirlinga Darshan",
        description: "Choose a mini bus for a convenient and comfortable pilgrimage to the Jyotirlingas. Perfect for small groups, our mini buses offer a cozy travel experience while visiting the revered temples. With comfortable seating and enough space for your belongings, this option provides a practical solution for groups seeking a smooth journey to these sacred sites."
      },
      {
        name: "Pune to Bhimashankar Mini Bus on Rent",
        description: "Rent a mini bus for your trip from Pune to Bhimashankar. Our mini buses provide a comfortable and economical travel option for small groups, ensuring a pleasant journey to this sacred Jyotirlinga site. With knowledgeable drivers and a focus on customer satisfaction, your travel experience will be both enjoyable and seamless."
      },
      {
        name: "Pune to Trimbakeshwar Tempo Traveller on Rent",
        description: "Rent a tempo traveller for your journey from Pune to Trimbakeshwar. Ideal for smaller groups, our tempo travellers offer flexibility and comfort, making your visit to the Trimbakeshwar Temple a memorable experience. With spacious seating and modern amenities, you'll enjoy a relaxing ride as you prepare for your spiritual visit."
      },
      {
        name: "Pune to Aundha Nagnath Tempo Traveller on Rent",
        description: "Embark on a spiritual journey from Pune to Aundha Nagnath with our tempo traveller rental service. Shraddha Travels offers comfortable and well-maintained vehicles designed for long-distance travel. Enjoy a smooth and relaxing trip to one of the revered Jyotirlinga temples, ensuring a hassle-free pilgrimage experience that allows for reflection and connection with your spirituality."
      },
      {
        name: "Pune to Grishneshwar Tempo Traveller on Rent",
        description: "For your visit to the Grishneshwar Temple, choose Shraddha Travels for your tempo traveller rental needs. Our vehicles are perfect for comfortable and convenient travel from Pune to Grishneshwar, one of the prominent Jyotirlinga sites. With our well-equipped vehicles and professional service, your journey will be both pleasant and spiritually uplifting."
      },
      {
        name: "Pune to 5 Jyotirlinga Darshan Bus on Rent",
        description: "Shraddha Travels provides bus rental services for an extensive 5 Jyotirlinga Darshan tour from Pune. Our buses are designed to accommodate larger groups, offering comfort and convenience for this significant pilgrimage. Experience a spiritual journey to multiple Jyotirlinga temples, with knowledgeable drivers ensuring a smooth and memorable trip."
      },
      {
        name: "Tempo Traveller on Rent for 5 Jyotirlinga Darshan",
        description: "For a more intimate and flexible travel experience, rent a tempo traveller from Shraddha Travels for your 5 Jyotirlinga Darshan tour. Our tempo travellers provide comfortable seating and essential amenities, making them an ideal choice for smaller groups undertaking this sacred journey. Enjoy a personalized experience as you visit each Jyotirlinga."
      },
      {
        name: "Mini Bus on Rent for 5 Jyotirlinga Darshan from Pune",
        description: "If you need a vehicle that balances comfort and practicality for a group pilgrimage, consider our mini bus rental service for the 5 Jyotirlinga Darshan from Pune. Shraddha Travels offers mini buses perfect for accommodating medium-sized groups, ensuring a pleasant and fulfilling spiritual journey while visiting the revered Jyotirlingas."
      },
      {
        name: "5 Jyotirlinga Darshan Package Urbania on Rent",
        description: "Experience a comprehensive 5 Jyotirlinga Darshan with our Urbania package. Shraddha Travels offers a tailored rental package that includes everything you need for a complete pilgrimage journey, including transportation, accommodations, and guided visits. Enjoy comfort and convenience throughout your spiritual journey."
      },
      {
        name: "Pune to 5 Jyotirlinga Darshan Bus Hire Contact Number",
        description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Pune to 5 Jyotirlinga Darshan Bus Hire. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
      }
    ],    
    tableData: [
      ['Pune to Trimbakeshwar Bus Hire Nashik', 'Pune to Aundha Nagnath Bus on Rent'],
      ['Pune to Bhimashankar Bus Hire on Rent', 'Pune to Grishneshwar Jyotirlinga Bus on Rent'],
      ['5 Jyotirlinga in Maharashtra Tour Package from Pune', 'Tempo Traveller on Rent for 5 Jyotirlinga Darshan'],
      ['Pune to Jyotirlinga Darshan Tour Package with Bus', 'Mini Bus on Rent for Jyotirlinga Darshan'],
      ['Pune to Bhimashankar Mini Bus on Rent', 'Pune to Trimbakeshwar Tempo Traveller on Rent'],
      ['Pune to Aundha Nagnath Tempo Traveller on Rent', 'Pune to Grishneshwar Tempo Traveller on Rent'],
      ['Pune to 5 Jyotirlinga Darshan Bus on Rent', 'Tempo Traveller on Rent for 5 Jyotirlinga Darshan'],
      ['Mini Bus on Rent for 5 Jyotirlinga Darshan from Pune', '5 Jyotirlinga in Darshan Package Urbania on Rent']
    ],    
    whychoose: [
      {
        WhyChooseheading: "Why Choose Shraddha Travels for Pune to 5 Jyotirlinga Darshan Bus Hire?",
        WhyChoosedescription: "At Shraddha Travels, we specialize in providing exceptional bus hire services for pilgrimage tours, including the revered 5 Jyotirlinga Darshan. Our extensive experience ensures a smooth and spiritually fulfilling journey to these sacred destinations, allowing you to focus on your spiritual connection without the stress of travel logistics."
      },
      {
        WhyChooseheading: "Comfortable and Well-Maintained Buses:",
        WhyChoosedescription: "Our fleet consists of modern, well-maintained buses designed to offer maximum comfort during long journeys. Equipped with amenities such as air conditioning, comfortable seating, ample legroom, and clean onboard facilities, our buses ensure that you arrive at your destinations relaxed and rejuvenated, ready for your pilgrimage."
      },
      {
        WhyChooseheading: "Experienced Drivers:",
        WhyChoosedescription: "Our professional drivers are experienced in handling pilgrimage routes and are dedicated to providing a safe and pleasant travel experience. They are not only well-versed with the routes and local conditions but also understand the spiritual significance of the journey, ensuring that your experience is both safe and enriching."
      },
      {
        WhyChooseheading: "Flexible Itineraries:",
        WhyChoosedescription: "We offer customizable tour packages to fit your schedule and preferences. Whether you need a single day tour or a multi-day pilgrimage, we can tailor the itinerary to meet your needs, ensuring you visit all the Jyotirlinga temples efficiently and without feeling rushed, allowing for ample time for prayers and rituals."
      },
      {
        WhyChooseheading: "Competitive Pricing:",
        WhyChoosedescription: "We offer competitive pricing for our bus hire services, providing great value without compromising on quality. Our transparent pricing ensures no hidden costs, making your spiritual journey affordable. We believe that a fulfilling pilgrimage should be accessible to everyone, and we strive to offer the best rates in the industry."
      },
      {
        WhyChooseheading: "24/7 Customer Support:",
        WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with any queries or concerns. From booking to the end of your journey, we ensure that your experience is seamless and stress-free. Whether you have questions before booking or need assistance during your trip, we are just a call away."
      },
      {
        WhyChooseheading: "Safety First:",
        WhyChoosedescription: "We prioritize the safety of our passengers. Our buses undergo regular maintenance checks, and we adhere to all safety regulations to ensure a secure travel experience. We also conduct thorough driver screenings and training, giving you peace of mind as you embark on your spiritual journey."
      },
      {
        WhyChooseheading: "Comfortable Accommodation Options:",
        WhyChoosedescription: "For longer tours, we provide options for comfortable accommodations. Our packages can include stays at well-rated hotels or guesthouses close to the Jyotirlinga sites, ensuring that you have a restful place to recover after your daily explorations, complete with breakfast and other amenities."
      },
      {
        WhyChooseheading: "Positive Customer Feedback:",
        WhyChoosedescription: "Our satisfied customers consistently praise our reliable service, professional staff, and comfortable travel arrangements. We take pride in our excellent track record of customer satisfaction, with many clients returning for future journeys and recommending us to family and friends, highlighting our commitment to quality."
      },
      {
        WhyChooseheading: "Hassle-Free Booking Process:",
        WhyChoosedescription: "Our user-friendly booking system makes it easy to arrange your bus hire for the 5 Jyotirlinga Darshan. With straightforward online booking, prompt confirmation, and flexible payment options, planning your pilgrimage is a breeze. Our staff is always ready to assist you in creating the perfect itinerary tailored to your group’s needs."
      }
    ]
    
  }



  const faqData = [
    {
      question: "What is included in the Pune to 5 Jyotirlinga Darshan bus hire package?",
      answer: "Our package includes bus rental for the entire tour, experienced drivers, and essential amenities such as air conditioning and comfortable seating. We can also arrange accommodation and meals based on your requirements."
    },
    {
      question: "How do I book a bus for the 5 Jyotirlinga Darshan?",
      answer: "You can book a bus by contacting us directly via phone, email, or through our website. Our customer service team will assist you with the booking process, including choosing the right package and itinerary."
    },
    {
      question: "Can I customize the tour itinerary?",
      answer: "Yes, we offer customizable itineraries to fit your schedule and preferences. Let us know your desired dates and specific Jyotirlinga temples you want to visit, and we will tailor the tour accordingly."
    },
    {
      question: "What types of buses are available for the Jyotirlinga Darshan tour?",
      answer: "We offer a range of buses including standard, luxury, and mini buses. You can choose based on your group size and comfort preferences."
    },
    {
      question: "Are the buses equipped with facilities for long journeys?",
      answer: "Yes, our buses are equipped with air conditioning, comfortable seating, and clean facilities to ensure a pleasant travel experience during long journeys."
    },
    {
      question: "Is there a minimum or maximum group size for the tour?",
      answer: "There is no strict minimum or maximum group size. We accommodate small and large groups, providing buses that best fit your needs."
    },
    {
      question: "What are the payment options for booking?",
      answer: "We accept various payment methods including online transfers, credit/debit cards, and cash. Our customer service team will provide details based on your preferred payment option."
    },
    {
      question: "How far in advance should I book the bus for the Jyotirlinga Darshan?",
      answer: "We recommend booking as early as possible to secure your preferred dates and bus type. Early booking also helps in better planning and availability of buses."
    },
    {
      question: "What is the cancellation policy for the bus hire?",
      answer: "Our cancellation policy is flexible. Please contact us for details on the terms and conditions related to cancellations and refunds."
    },
    {
      question: "Can I request additional stops or deviations during the tour?",
      answer: "Yes, you can request additional stops or deviations. We will do our best to accommodate your requests within the constraints of the itinerary and travel time."
    }
  ];
  



  const testimonials = [
    {
      name: "Mrs. Aarti Deshmukh",
      text: "We recently booked a bus with Shraddha Travels for our pilgrimage to the 5 Jyotirlingas, and it was a wonderful experience. The bus was comfortable, clean, and well-maintained, making our long journey much more pleasant. The driver was experienced and knowledgeable about the route, ensuring we reached each temple safely and on time. The flexibility in the itinerary allowed us to visit all the Jyotirlingas at a relaxed pace. I highly recommend Shraddha Travels for anyone planning a similar spiritual tour."
    },
    {
      name: "Mr. Ravi Sharma",
      text: "Shraddha Travels provided an exceptional bus service for our 5 Jyotirlinga Darshan tour from Pune. From the booking process to the end of the journey, everything was handled professionally. The bus was comfortable with all the necessary amenities, and the driver was courteous and punctual. They accommodated our requests for additional stops and made sure our experience was as smooth as possible. The tour was spiritually fulfilling and stress-free, thanks to the excellent service from Shraddha Travels. I would definitely choose them again for future trips."
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune to 5 Jyotirlinga Darshan Bus Hire Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune to 5 Jyotirlinga Darshan Bus Hire</title>
    <meta name="description" content="Reliable bus hire services for the 5 Jyotirlinga Darshan from Pune, including Trimbakeshwar, Aundha Nagnath, and Grishneshwar." />
    <meta name="keywords" content="Pune to 5 Jyotirlinga Darshan Bus Hire, Jyotirlinga Darshan Tour Package, Mini Bus on Rent, Tempo Traveller for Jyotirlinga Darshan" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune to 5 Jyotirlinga Darshan Bus Hire" />
    <meta property="og:description" content="Bus hire services for 5 Jyotirlinga Darshan from Pune, including various travel packages." />
    <meta property="og:url" content="https://www.amuratatravels.com/pune-to-5-jyotirlinga-darshan" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/jyotirlinga-darshan.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune to 5 Jyotirlinga Darshan Bus Hire",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/pune-to-5-jyotirlinga-darshan",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "5 Jyotirlinga Darshan Bus Hire",
                    "description": "Bus hire services for the 5 Jyotirlinga Darshan from Pune, including travel packages to Trimbakeshwar, Aundha Nagnath, and Grishneshwar."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-13.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs – Pune to 5 Jyotirlinga Darshan Bus Hire at Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Puneto5jyotilinga;