import React from 'react';
import '../css/topplace.css';
const placesData = [
    { id: 1, name: 'Pune', image: '/img/pune.jpg' },
    { id: 2, name: 'Mumbai', image: '/img/mumbaii.jpg' },
    { id: 3, name: 'Shirdi', image: '/img/shirdi.jfif' },
    { id: 4, name: '5 Jyotilinga ', image: '/img/5jyoti.webp' },
    { id: 5, name: 'Ashtavinayak', image: '/img/ashtavinayak.jpg' },
    { id: 6, name: 'Lonavala', image: '/img/lonavalaa.jfif' },
];

const TopPlacesSection = () => {
    return (
        <section className="place-section centred pt_100 pb_70">
            <div className="auto-container">
                <div className="sec-title mb_50">
                    <span className="sub-title">Top Places</span>
                    <h2>Top Visited Places</h2>
                </div>
                <div className="row clearfix">
                    {placesData.map(place => (
                        <div className="col-lg-4 col-md-6 col-sm-12 place-block" key={place.id}>
                            <div className="place-block-one">
                                <div className="inner-box">
                                    <figure className="image-box">
                                        <img src={place.image} alt={place.name} />
                                    </figure>
                                    <span className="text">{place.name}</span>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default TopPlacesSection;
