
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busesforcorporate() {



  const cardData =
  {
    keyword: ' Buses for Corporate Events in Pune',
    heading: 'Shraddha Travels: Buses for Corporate Events in Pune',
    headingDescription: 'When it comes to organizing corporate events, seamless transportation is crucial for ensuring a smooth and professional experience. At Shraddha Travels, we offer a range of high-quality buses designed to meet the needs of corporate gatherings in Pune. Whether it’s a conference, team-building event, or corporate outing, our fleet is equipped to handle various requirements with efficiency and style. Here’s a look at the top Shraddha Travels buses for corporate events in Pune:',
    top: 'Top Buses in Pune by Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Executive Coach Bus",
            description: "Our Executive Coach Bus is perfect for corporate events requiring a touch of luxury and comfort. Featuring plush seating, ample legroom, and high-end amenities such as onboard Wi-Fi and charging ports, this bus is designed to provide a first-class experience for your executives and clients. It's ideal for long journeys, ensuring that every passenger travels in style."
        },
        {
            title: "2. 32-Seater Luxury Bus",
            description: "Ideal for medium-sized groups, the 32-Seater Luxury Bus offers a blend of comfort and functionality. With comfortable seating, air-conditioning, and modern entertainment options like flat-screen TVs and sound systems, it’s a great choice for corporate events where a higher level of comfort is desired. This bus ensures your team stays relaxed and entertained during the journey."
        },
        {
            title: "3. 35-Seater Executive Bus",
            description: "The 35-Seater Executive Bus combines style and practicality. Equipped with premium seating, spacious interiors, and advanced features such as mood lighting and individual climate control, it’s suitable for corporate groups that require a comfortable and sophisticated travel experience. Perfect for team outings, this bus enhances productivity and comfort."
        },
        {
            title: "4. 45-Seater Coach Bus",
            description: "Our 45-Seater Coach Bus is designed for larger groups. It offers spacious seating, ample luggage space, and modern amenities like restroom facilities and Wi-Fi, making it an excellent option for corporate events that involve transporting a sizable team or multiple departments. This bus ensures everyone travels comfortably, regardless of the distance."
        },
        {
            title: "5. 50-Seater Luxury Bus",
            description: "For very large corporate gatherings, the 50-Seater Luxury Bus provides a spacious and comfortable solution. With high-end features including reclining seats, in-bus refreshments, and advanced audio-visual systems, it ensures that your team travels in style and comfort. This bus is perfect for conferences, events, and corporate retreats."
        },
        {
            title: "6. VIP Shuttle Bus",
            description: "The VIP Shuttle Bus is perfect for high-profile clients or executives. This bus offers top-tier comfort and privacy, featuring plush seating, tinted windows, and personalized service, making it suitable for transporting key personnel to and from corporate events with a touch of exclusivity. Ideal for business meetings and special events."
        },
        {
            title: "7. Mini Bus (14-Seater)",
            description: "Our 14-Seater Mini Bus is ideal for smaller corporate groups or for use as a shuttle between locations. It provides a cozy and efficient transport solution for team outings or smaller executive meetings. With comfortable seating and a compact design, this bus is perfect for navigating city traffic."
        },
        {
            title: "8. Tempo Traveller (10-Seater)",
            description: "The 10-Seater Tempo Traveller offers a compact yet comfortable option for smaller corporate groups. With its easy maneuverability, comfortable seating, and modern amenities, it’s perfect for short trips or small team events. This vehicle is ideal for intimate gatherings, workshops, or team-building exercises."
        },
        {
            title: "9. Corporate Outing Bus",
            description: "Designed specifically for corporate outings, this bus is equipped with amenities that enhance the travel experience. Features include entertainment systems, adjustable seating, and complimentary refreshments, making it ideal for team-building events or off-site meetings. This bus ensures your team has a memorable experience."
        },
        {
            title: "10. Conference Bus",
            description: "Our Conference Bus is tailored for on-the-go meetings. It features a dedicated space for presentations and discussions, along with Wi-Fi and multimedia capabilities, allowing your team to stay productive during transit. This bus is perfect for companies that want to maximize time while traveling to meetings or events."
        }
    ],    
    services: [
        {
            name: "Corporate Bus Hire for Outstation",
            description: "Shraddha Travels offers top-notch corporate bus hire services for outstation trips from Pune. Our fleet includes a range of buses suited for business travel to destinations beyond the city, ensuring a comfortable and efficient journey for your team. Each vehicle is equipped with amenities to enhance the travel experience, allowing your employees to relax or work on the go."
        },
        {
            name: "Corporate Bus Hire Service in Pune",
            description: "Looking for reliable corporate bus hire services in Pune? Shraddha Travels provides an extensive selection of buses tailored to meet the needs of businesses, from executive shuttles to large luxury coaches. Our team is dedicated to delivering personalized service, ensuring that every aspect of your transportation is handled with care and professionalism."
        },
        {
            name: "Hire a Bus for Corporate Sector",
            description: "Shraddha Travels specializes in hiring buses for the corporate sector, offering tailored solutions to fit various business requirements. Whether it's for team-building events, client transport, or company outings, we have the right vehicle for you. Our buses are designed to provide a productive and comfortable atmosphere, making travel time feel more like a retreat."
        },
        {
            name: "Shraddha Travels Pune",
            description: "Based in Pune, Shraddha Travels is your go-to provider for all corporate bus hire needs. We pride ourselves on delivering exceptional service and a diverse fleet to ensure your corporate transportation needs are met with professionalism. Our experienced staff is available to assist with all logistics, making your planning process seamless."
        },
        {
            name: "Corporate Pick-Up and Drop Service",
            description: "Our corporate pick-up and drop service is designed to offer convenience and reliability for your business events. Enjoy hassle-free transportation with our punctual and professional drivers, ensuring smooth transfers for your team. We track all schedules to adapt to any changes in plans, ensuring timely arrivals and departures."
        },
        {
            name: "AC Bus Hire for Corporate",
            description: "Stay cool and comfortable with our AC bus hire options for corporate events. Shraddha Travels provides modern air-conditioned buses to ensure a pleasant travel experience for your employees and clients. With our focus on comfort and climate control, your team can arrive at their destination feeling refreshed and ready to engage."
        },
        {
            name: "Affordable Corporate Bus Hire",
            description: "Get value for your money with our affordable corporate bus hire services. At Shraddha Travels, we offer competitive pricing without compromising on quality, making it easy to arrange transportation for your corporate events within budget. We believe in transparent pricing with no hidden fees, ensuring your financial planning remains straightforward."
        },
        {
            name: "25-Seater Bus Hire for Corporate",
            description: "Our 25-seater bus is perfect for medium-sized corporate groups. Ideal for team outings or client transfers, this bus provides a comfortable and efficient travel solution. Equipped with modern amenities, it allows your team to travel together, fostering camaraderie and collaboration during transit."
        },
        {
            name: "26-Seater Bus Hire for Corporate",
            description: "For slightly larger groups, our 26-seater bus offers ample space and comfort. It’s a great choice for corporate trips requiring additional seating while still maintaining a cozy atmosphere. Our buses feature comfortable seating arrangements and entertainment options, ensuring an enjoyable ride for everyone."
        },
        {
            name: "32-Seater Bus Hire for Corporate",
            description: "The 32-seater bus from Shraddha Travels provides a spacious and comfortable option for larger corporate gatherings. Ideal for seminars, workshops, or group travel, it combines practicality with comfort. Our professional drivers are trained to navigate the best routes, allowing your team to arrive relaxed and ready."
        },
        {
            name: "50-Seater Bus Hire for Corporate in Pune",
            description: "For very large groups, our 50-seater buses offer extensive seating and amenities. Perfect for large corporate events or conventions, these buses ensure everyone travels together comfortably. With features designed for group engagement, your team can discuss strategies or unwind during the journey."
        },
        {
            name: "Cheap Bus Service in Pune",
            description: "Looking for a budget-friendly bus service in Pune? Shraddha Travels offers competitive rates for corporate bus hires, providing economical solutions without sacrificing quality or service. Our commitment to affordability means you can focus on your event without worrying about transportation costs."
        },
        {
            name: "35-Seater Bus Hire for Corporate Events",
            description: "The 35-seater bus is an excellent choice for sizable corporate events. It provides ample seating and comfort, making it ideal for conferences, workshops, or team-building activities. The spacious interior and advanced entertainment systems create an environment conducive to productivity and relaxation."
        },
        {
            name: "Corporate Bus Service from Pune",
            description: "Shraddha Travels offers dedicated corporate bus services from Pune, catering to various business needs. Whether you require transportation for local or outstation trips, we ensure a reliable and efficient service, allowing your team to focus on what they do best while we handle the logistics."
        },
        {
            name: "Bus Hire for Corporate Event Party",
            description: "When organizing a corporate event party, Shraddha Travels provides top-notch bus hire services to ensure your guests travel comfortably and arrive on time. Our fleet includes a variety of options to suit different event sizes and requirements, with attention to detail that enhances your guests' experience."
        },
        {
            name: "Corporate Bus Services in Pune",
            description: "Shraddha Travels offers comprehensive corporate bus services in Pune, catering to various business needs. From daily commutes to special events, our reliable and professional services ensure smooth transportation for corporate teams, making your travel experience as seamless as possible."
        },
        {
            name: "Private Bus Service in Pune for Office",
            description: "For office transportation needs, Shraddha Travels provides private bus services in Pune that offer convenience and flexibility. Our private buses are designed to accommodate your staff comfortably while ensuring timely arrivals, fostering a productive environment from the moment they step on board."
        },
        {
            name: "Private Buses for Corporate Events in Pune",
            description: "Looking for private buses for corporate events in Pune? Shraddha Travels specializes in providing tailored bus solutions for various corporate gatherings, ensuring a seamless and efficient transport experience for your team. Our personalized service guarantees that your specific requirements are met."
        },
        {
            name: "Luxury Buses for Corporate Events in Pune",
            description: "Experience the height of comfort with our luxury buses for corporate events in Pune. Featuring premium amenities and elegant interiors, our luxury buses are perfect for impressing clients or providing a high-end travel experience for executives. Create a lasting impression with a travel experience that reflects your corporate values."
        },
        {
            name: "Buses for Corporate Events in Pimpri Chinchwad",
            description: "Shraddha Travels offers specialized bus services for corporate events in Pimpri Chinchwad. Whether it's a seminar, workshop, or team-building activity, our buses provide reliable and comfortable transportation for your business events. Our focus on quality ensures your gatherings run smoothly."
        },
        {
            name: "Buses for Corporate Events in Hinjewadi",
            description: "Our fleet of buses is well-equipped to handle corporate events in Hinjewadi. Shraddha Travels ensures that your team or guests experience a smooth and enjoyable journey to and from your corporate functions in this tech hub. Our drivers are familiar with the local area, optimizing routes for efficiency."
        },
        {
            name: "Buses for Corporate Events in Kharadi",
            description: "For corporate events in Kharadi, Shraddha Travels provides a range of bus options tailored to meet your needs. From small team meetings to large conferences, our buses ensure comfortable and efficient transportation, allowing your guests to arrive focused and ready to engage."
        },
        {
            name: "Business Events in Hire Bus on Rent",
            description: "For business events that require bus rentals, Shraddha Travels offers a diverse fleet to suit different group sizes and needs. Our bus hire services provide a reliable and professional solution for your business event transportation, ensuring that logistics are one less thing to worry about."
        },
        {
            name: "Buses for Corporate Events in Pune",
            description: "Shraddha Travels delivers exceptional bus services for corporate events throughout Pune. Our well-maintained fleet and professional drivers ensure that your corporate events are managed with the utmost care and efficiency, enabling you to focus on your objectives."
        },
        {
            name: "Buses for Corporate Events in Magarpatta City",
            description: "Shraddha Travels provides specialized bus services for corporate events in Magarpatta City. Our buses offer comfort and convenience, making sure that your corporate gatherings are well-supported with reliable transportation. Trust us to handle the details while you focus on your event."
        },
        {
            name: "Buses for Corporate Events in Talawade MIDC",
            description: "For events held in Talawade MIDC, Shraddha Travels offers dedicated bus services that cater to the specific needs of corporate gatherings in this industrial area. Enjoy smooth and efficient transportation with our fleet, designed to accommodate diverse business requirements."
        },
        {
            name: "Buses for Corporate Events in Ranjangaon MIDC",
            description: "Shraddha Travels provides tailored bus services for corporate events in Ranjangaon MIDC. Whether you need transportation for large groups or specific event requirements, our buses deliver reliable and comfortable travel solutions, ensuring a hassle-free experience."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers. Our dedicated team is ready to assist you with your booking and any specific needs you may have."
        }
    ],

    tableData: [
    ['- Corporate Bus Hire for Outstation', '- Corporate Bus Hire Service in Pune'],
    ['- Hire a Bus for Corporate Sector', '- Shraddha Travels Pune'],
    ['- Corporate Pick-Up and Drop Service', '- AC Bus Hire for Corporate'],
    ['- Affordable Corporate Bus Hire', '- 25-Seater Bus Hire for Corporate'],
    ['- 26-Seater Bus Hire for Corporate', '- 32-Seater Bus Hire for Corporate'],
    ['- 50-Seater Bus Hire for Corporate in Pune', '- Cheap Bus Service in Pune'],
    ['- 35-Seater Bus Hire for Corporate Events', '- Corporate Bus Service from Pune'],
    ['- Corporate Bus Service from Pune', '- Bus Hire for Corporate Event Party'],
    ['- Corporate Bus Services in Pune', '- Private Bus Service in Pune for Office'],
    ['- Private Buses for Corporate Events in Pune', '- Luxury Buses for Corporate Events in Pune'],
    ['- Buses for Corporate Events in Pimpri Chinchwad', '- Buses for Corporate Events in Hinjewadi'],
    ['- Buses for Corporate Events in Kharadi', '- Business Events in Hire Bus on Rent'],
    ['- Buses for Corporate Events in Pune', '- Buses for Corporate Events in Magarpatta City'],
    ['- Buses for Corporate Events in Talawade MIDC', '- Buses for Corporate Events in Ranjangaon MIDC']
],
whychoose: [
    {
        WhyChooseheading: "Why Choose Shraddha Travels Buses for Corporate Events in Pune?",
        WhyChoosedescription: "When it comes to corporate events in Pune, Shraddha Travels stands out as the ideal choice for bus hire services. We understand the unique needs of businesses and strive to deliver a seamless transportation experience. Our commitment to excellence ensures that every aspect of your travel is taken care of, allowing you to focus on what truly matters—your event."
    },
    {
        WhyChooseheading: "Extensive Fleet:",
        WhyChoosedescription: "At Shraddha Travels, we offer a diverse fleet of buses specifically designed to cater to various corporate event needs. Our options range from luxury coaches equipped with plush interiors for executive travel to spacious buses suitable for transporting large teams. Each vehicle is well-maintained and regularly updated, ensuring a reliable choice for your transportation requirements."
    },
    {
        WhyChooseheading: "Professional Service:",
        WhyChoosedescription: "Our team of experienced drivers and staff are committed to providing exceptional service. Each driver is trained not only to navigate Pune’s roads efficiently but also to ensure a pleasant experience for your guests. Punctuality is paramount; we guarantee that your team arrives on time, every time, setting the right tone for your event."
    },
    {
        WhyChooseheading: "Customizable Options:",
        WhyChoosedescription: "We understand that every corporate event is unique, which is why we offer customizable bus hire solutions. You can tailor your booking to include special amenities such as Wi-Fi, refreshments, and specific seating arrangements. Whether it’s a formal event or a casual outing, we can adapt our services to fit your vision."
    },
    {
        WhyChooseheading: "Reliable and Efficient:",
        WhyChoosedescription: "Timely and reliable service is crucial for corporate events. Shraddha Travels guarantees on-time arrivals and departures, minimizing any disruption to your schedule. Our efficient routing and scheduling ensure that your guests are transported smoothly from start to finish, allowing you to concentrate on your agenda without worrying about logistics."
    },
    {
        WhyChooseheading: "Competitive Pricing:",
        WhyChoosedescription: "We provide high-quality bus hire services at competitive rates, offering great value for money. Our transparent pricing model ensures there are no hidden costs, making budgeting for your corporate event straightforward. We believe that exceptional service should be accessible, allowing you to plan your event without financial strain."
    },
    {
        WhyChooseheading: "Modern Amenities:",
        WhyChoosedescription: "Our buses come equipped with modern amenities such as air conditioning, comfortable seating, and entertainment options. We prioritize your guests’ comfort, ensuring that they can relax and enjoy their journey. Features like onboard restrooms and charging ports make longer trips more convenient and enjoyable."
    },
    {
        WhyChooseheading: "Safety First:",
        WhyChoosedescription: "Safety is our top priority. All our buses undergo regular maintenance and are equipped with the latest safety features. Our drivers are trained to adhere to the highest safety standards, ensuring a secure journey for all passengers. We conduct thorough checks before every trip to guarantee that your journey is both safe and worry-free."
    },
    {
        WhyChooseheading: "Excellent Customer Support:",
        WhyChoosedescription: "From the moment you contact us until the end of your event, our customer support team is here to assist you. We offer personalized service to address any queries or concerns you may have. Our staff is available 24/7 to ensure that you have a smooth experience, providing peace of mind as you plan your event."
    },
    {
        WhyChooseheading: "Flexibility:",
        WhyChoosedescription: "We offer flexible booking options to accommodate changes in your event plans. Whether you need to adjust the number of buses, change pickup times, or modify your itinerary, we can adapt to your needs with ease. Our goal is to provide you with a hassle-free experience, allowing you to make adjustments as necessary."
    },
    {
        WhyChooseheading: "Positive Reputation:",
        WhyChoosedescription: "Shraddha Travels has built a solid reputation for excellence in bus hire services for corporate events in Pune. Our satisfied clients and positive reviews reflect our commitment to delivering outstanding service. We take pride in the long-term relationships we’ve built with our clients, ensuring that they return to us for their future transportation needs."
    }
]

  }



  const faqData = [
    {
        question: "What types of buses are available for corporate events?",
        answer: "We offer a range of buses including luxury coaches, executive buses, and standard coaches. Each type is equipped to meet different needs, from high-end travel to practical group transport."
    },
    {
        question: "How can I book a bus for a corporate event?",
        answer: "You can book a bus by contacting us through our website, phone, or email. Provide details about your event, such as the number of passengers, date, and specific requirements, and we’ll help you with the booking process."
    },
    {
        question: "Are your buses equipped with amenities?",
        answer: "Yes, our buses come with various amenities such as air conditioning, comfortable seating, and entertainment options. We can also accommodate special requests depending on your needs."
    },
    {
        question: "How do you ensure the safety of passengers?",
        answer: "Our buses are regularly maintained and inspected for safety. Our drivers are trained in safety protocols, and we adhere to strict safety standards to ensure a secure journey for all passengers."
    },
    {
        question: "Can I make changes to my booking?",
        answer: "Yes, we offer flexible booking options. If you need to make changes to your reservation, such as adjusting the number of buses or changing pickup times, please contact us as soon as possible, and we’ll accommodate your request."
    },
    {
        question: "What are your payment options?",
        answer: "We offer various payment options including credit/debit cards and bank transfers. Payment details will be provided during the booking process."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "It’s recommended to book your bus as early as possible to ensure availability, especially for large corporate events. We suggest booking at least a few weeks in advance."
    },
    {
        question: "What is your cancellation policy?",
        answer: "Our cancellation policy varies depending on the timing of the cancellation. Please contact us directly for details on our cancellation terms and conditions."
    },
    {
        question: "Are there any additional costs?",
        answer: "We strive to provide transparent pricing with no hidden costs. Any additional charges, such as for extra amenities or changes in booking, will be communicated to you upfront."
    },
    {
        question: "How do I contact customer support?",
        answer: "You can reach our customer support team via phone, email, or through our website’s contact form. We are here to assist you with any questions or concerns regarding your corporate bus hire."
    }
];



const testimonials = [
    {
        name: "Mr. Rajesh Kumar",
        text: "We recently hired Shraddha Travels for our company's annual corporate retreat, and the experience was exceptional. Their fleet of buses was top-notch, with all the modern amenities we needed. The drivers were punctual and professional, ensuring that our team traveled in comfort and arrived on time for each session. The level of service provided exceeded our expectations, and we will definitely choose Shraddha Travels for future corporate events. Highly recommended!"
    },
    {
        name: "Ms. Priya Deshmukh",
        text: "Shraddha Travels made our corporate team-building event a breeze with their outstanding bus hire services. The 35-seater bus we rented was spacious, clean, and well-maintained. The driver was courteous and ensured a smooth journey for our team. Their flexible booking process and competitive rates made it easy to organize everything. We appreciate their excellent service and look forward to using Shraddha Travels again for our upcoming events."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Buses For Corporate Events in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Buses For Corporate Events in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Reliable bus rental services for corporate events in Pune, offering various sizes and comfortable transportation." />
    <meta name="keywords" content="Buses For Corporate Events in Pune, Corporate Bus Hire, Affordable Corporate Bus Rental, Luxury Buses for Corporate Events, Private Bus Service in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Buses For Corporate Events in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Hire comfortable buses for corporate events in Pune. Services include pick-up, drop-off, and luxury options." />
    <meta property="og:url" content="https://www.amuratatravels.com/buses-for-corporate-events-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/corporate-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Buses For Corporate Events",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/buses-for-corporate-events-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Corporate Bus Rental in Pune",
                    "description": "Affordable and reliable bus hire services for corporate events in Pune, including pick-up and drop-off."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-06.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Buses for Corporate Events in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busesforcorporate;