
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Tempotravellerhireinpune() {



  const cardData =
  {
    keyword: ' Tempo Traveller Hire in Pune ',
    heading: 'Shraddha Travels: Tempo Traveller Hire in Pune ',
    headingDescription: 'When it comes to group travel in Pune, Shraddha Travels offers top-notch tempo traveller hire services tailored to meet all your transportation needs. Whether you are planning a corporate outing, family vacation, school trip, or any other group event, our tempo travellers are designed to provide comfort, convenience, and reliability. Here’s why choosing Shraddha Travels for tempo traveller hire in Pune is the best decision:',
    top: 'Top Places to Visit in Pune with Shraddha Travels',

    topPlaces: [
        {
            title: "1. Aga Khan Palace",
            description: "A historic monument and a symbol of India's freedom struggle, the Aga Khan Palace boasts stunning Italianate architecture and serene gardens. It served as a prison for Mahatma Gandhi and other leaders during the Quit India Movement, making it a site of significant historical importance. Visitors can explore the beautiful grounds and learn about India's fight for independence."
        },
        {
            title: "2. Shaniwar Wada",
            description: "Once the seat of the Peshwas of the Maratha Empire, Shaniwar Wada is an iconic fortification known for its impressive ruins and lush gardens. The site features a beautiful entrance gate and historical exhibits that tell the story of the Maratha dynasty. The evening light and sound show is a must-see, bringing the history to life."
        },
        {
            title: "3. Sinhagad Fort",
            description: "Located about 30 kilometers from Pune, Sinhagad Fort offers breathtaking views of the surrounding landscape and is steeped in history. Known for its strategic importance during the Maratha Empire, the fort is a popular trekking spot. Visitors can explore ancient ruins, enjoy local snacks, and take in the panoramic views from the top."
        },
        {
            title: "4. Osho Ashram",
            description: "A peaceful retreat in the heart of the city, the Osho Ashram is a sanctuary for those seeking meditation and tranquility. The ashram offers a variety of meditation programs and workshops, allowing visitors to immerse themselves in Osho's teachings. The serene gardens and art installations create a calming atmosphere, making it a perfect escape from urban life."
        },
        {
            title: "5. Parvati Hill",
            description: "This scenic hill is home to several ancient temples, including the revered Parvati Temple dedicated to various Hindu deities. The climb to the top offers a refreshing hike and rewards visitors with panoramic views of Pune. The serene environment and historical significance make it a lovely spot for reflection and relaxation."
        },
        {
            title: "6. Pune Okayama Friendship Garden",
            description: "A beautifully designed Japanese garden, the Pune Okayama Friendship Garden offers a tranquil escape from the city’s hustle and bustle. Visitors can stroll through meticulously landscaped pathways, enjoy serene water features, and relax in the lush greenery. The garden promotes harmony and peace, making it a popular spot for picnics and leisurely walks."
        },
        {
            title: "7. Raja Dinkar Kelkar Museum",
            description: "This museum showcases an extensive collection of Indian artifacts, including traditional crafts, musical instruments, and decorative arts. Each exhibit reflects Pune's rich cultural heritage and history. The museum's architecture is also noteworthy, providing a beautiful backdrop to the diverse collection."
        },
        {
            title: "8. Fergusson College",
            description: "One of the oldest colleges in Pune, Fergusson College is known for its beautiful campus and historical significance. The blend of modern and colonial architectural styles makes it a charming place to visit. The college also hosts various cultural events, providing insight into the vibrant student life of Pune."
        },
        {
            title: "9. Lavasa",
            description: "A planned hill city near Pune, Lavasa is renowned for its picturesque views and lakeside promenades. The city offers various adventure activities, including trekking, boating, and cycling. It’s a perfect getaway for families and nature lovers looking to enjoy outdoor fun and relaxation amidst beautiful surroundings."
        },
        {
            title: "10. Khadakwasla Dam",
            description: "A popular picnic spot, Khadakwasla Dam is known for its scenic views and tranquil environment. The dam is a great place to unwind and enjoy a day by the water, especially during the monsoon season when the landscape comes alive. Visitors can enjoy boating, picnicking, or simply soaking in the picturesque scenery."
        }
    ],    
    services: [
        {
            name: "14 Seater Tempo Traveller Hire in Pune",
            description: "Our 14-seater tempo travellers are ideal for medium-sized groups who require a bit more space than a standard van. Perfect for family trips, small corporate gatherings, or intimate group outings, these vehicles feature comfortable seating, ample legroom, and sufficient luggage space to accommodate all your belongings. Whether you're heading out for a day trip or a weekend getaway, enjoy a smooth ride with amenities designed for your comfort."
        },
        {
            name: "17 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
            description: "For slightly larger groups, our 17-seater tempo travellers available in Pimpri Chinchwad are the perfect solution. These vehicles are designed for comfort and convenience, making them ideal for group travel across the city or to nearby destinations. With spacious interiors, air conditioning, and professional drivers who know the best routes, you can relax and enjoy the journey, whether it’s for a corporate event or a family outing."
        },
        {
            name: "Tempo Traveller on Rent Near Me",
            description: "Searching for a tempo traveller rental near you? Shraddha Travels offers convenient options across Pune, ensuring that you find a rental location close to you. Our fleet includes a variety of vehicles ready for local and outstation travel needs. With a simple booking process and accessible pick-up points, we make it easy for you to enjoy your journey without any hassle."
        },
        {
            name: "20 Seater Tempo Traveller on Rent in Pune",
            description: "Our 20-seater tempo travellers are perfect for larger groups who need extra space. Ideal for group tours, school trips, or corporate events, these vehicles provide spacious seating, air conditioning, and comfortable interiors. With room for everyone, including luggage, you can embark on your adventure with peace of mind, knowing that comfort is a priority throughout your journey."
        },
        {
            name: "25 Seater Tempo Traveller on Rent in Pune",
            description: "For even bigger groups, our 25-seater tempo travellers offer ample room and comfort. These vehicles are ideal for large family gatherings, corporate outings, and group tours, ensuring that everyone travels together comfortably. With modern amenities, reliable performance, and professional drivers, you can expect a pleasant travel experience that caters to your group's needs."
        },
        {
            name: "32 Seater Tempo Traveller on Rent in Pune",
            description: "Our 32-seater tempo travellers are perfect for very large groups, such as wedding parties or extended family reunions. Equipped with luxurious interiors, comfortable seating, and advanced amenities like entertainment systems and charging points, these vehicles are ideal for extended trips where space and comfort are a priority. Enjoy a premium travel experience that enhances your group journey."
        },
        {
            name: "Pune to Shirdi Tempo Traveller on Rent",
            description: "Traveling from Pune to Shirdi? Our tempo travellers are well-suited for this pilgrimage route, offering comfortable and reliable transportation for your spiritual journey. With spacious interiors and experienced drivers who understand the importance of your trip, you can focus on your spiritual experience while we handle the logistics. Enjoy a hassle-free trip with our well-maintained vehicles."
        },
        {
            name: "Pune to Mahabaleshwar Tempo Traveller on Rent",
            description: "Plan a scenic getaway to Mahabaleshwar with our tempo travellers. Equipped for long-distance travel, our vehicles ensure a comfortable ride through beautiful landscapes. With amenities to keep everyone entertained during the journey and drivers familiar with the route, you can relax and enjoy the stunning views en route to this popular hill station."
        },
        {
            name: "Pune to Ajanta Ellora Tempo Traveller on Rent",
            description: "Explore the historic Ajanta and Ellora Caves with ease. Our tempo travellers provide a comfortable and convenient way to visit these UNESCO World Heritage sites. With ample space for both passengers and luggage, our vehicles ensure your trip is enjoyable and stress-free, allowing you to delve into the rich history and culture of these remarkable caves."
        },
        {
            name: "Pune to Solapur Tempo Traveller on Rent",
            description: "Head to Solapur with our reliable tempo traveller services. Ideal for group trips to this vibrant city, our vehicles ensure a smooth and comfortable ride for all passengers. With experienced drivers and spacious seating, you can explore local attractions and enjoy your time in Solapur without the stress of travel logistics."
        },
        {
            name: "Pune to Kolhapur Darshan Tempo Traveller on Rent",
            description: "Experience the cultural richness of Kolhapur with our tempo traveller rentals. Perfect for darshan trips and sightseeing, our vehicles provide ample space and comfort for a memorable visit. Our drivers are knowledgeable about local sites, ensuring you get the most out of your journey while enjoying a comfortable ride."
        },
        {
            name: "Pune to Goa Tempo Traveller on Rent",
            description: "Planning a beach vacation to Goa? Our tempo travellers are perfect for the journey from Pune to Goa, offering comfort and convenience as you head to this popular destination. With spacious interiors, air conditioning, and amenities to keep your group entertained, you can focus on enjoying your beach holiday and making lasting memories with friends and family."
        },
        {
            name: "Pune to Per KM Tempo Traveller on Rent",
            description: "Need a tempo traveller based on distance? We offer flexible rental options with per-kilometer pricing, allowing you to manage your travel expenses based on your specific trip requirements. This transparency in pricing ensures that you have control over your budget while enjoying the comfort and convenience of our vehicles."
        },
        {
            name: "Tempo Traveller Hire in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient tempo traveller rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today, and let us take care of your travel needs!"
        }
    ],
    tableData: [
        ['- 14 Seater Tempo Traveller Hire in Pune', '- 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad'],
        ['- Tempo Traveller on Rent Near Me', '- 20 Seater Tempo Traveller on Rent in Pune'],
        ['- 25 Seater Tempo Traveller on Rent in Pune', '- 32 Seater Tempo Traveller on Rent in Pune'],
        ['- Pune to Shirdi Tempo Traveller on Rent', '- Pune to Mahabaleshwar Tempo Traveller on Rent'],
        ['- Pune to Ajanta Ellora Tempo Traveller on Rent', '- Pune to Solapur Tempo Traveller on Rent'],
        ['- Pune to Kolhapur Darshan Tempo Traveller on Rent', '- Pune to Mahabaleshwar Luxury Tempo Traveller on Rent'],
        ['- Pune to Goa Tempo Traveller on Rent', '- Pune to Per KM Tempo Traveller on Rent']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels' Tempo Traveller on Rent in Pune?",
            WhyChoosedescription: "When it comes to renting a Tempo Traveller in Pune, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Whether you're planning a family trip or a corporate outing, our service ensures that you travel in style and comfort. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
            WhyChooseheading: "Diverse Fleet:",
            WhyChoosedescription: "We offer a wide range of tempo travellers, including 14-seater, 17-seater, 20-seater, 25-seater, and 32-seater vehicles, ensuring the right fit for any group size."
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "Our tempo travellers are equipped with comfortable seating, ample luggage space, and climate control, providing a superior travel experience for both local and long-distance journeys."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our experienced drivers are familiar with local and outstation routes, prioritizing safety and punctuality for a smooth and hassle-free trip."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We offer transparent and competitive pricing for our rentals, ensuring no hidden costs and excellent value for your money."
        },
        {
            WhyChooseheading: "Flexible Rental Options:",
            WhyChoosedescription: "Whether you need a vehicle for a few hours, a day, or a week, we provide flexible rental options tailored to your specific requirements."
        },
        {
            WhyChooseheading: "Clean and Well-Maintained Vehicles:",
            WhyChoosedescription: "Our tempo travellers are regularly serviced and cleaned to ensure they are in top condition, maintaining the highest standards of hygiene."
        },
        {
            WhyChooseheading: "Customizable Solutions:",
            WhyChoosedescription: "We offer tailor-made services to suit your itinerary, budget, and specific travel needs, whether it's for a family vacation, corporate event, or pilgrimage."
        },
        {
            WhyChooseheading: "Easy Booking Process:",
            WhyChoosedescription: "Our user-friendly booking process allows for simple reservations online or through direct contact for personalized assistance."
        },
        {
            WhyChooseheading: "Reliable Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available to assist you with any queries or issues before, during, or after your journey."
        },
        {
            WhyChooseheading: "Positive Customer Feedback:",
            WhyChoosedescription: "Our commitment to excellent service has earned us positive feedback, reflecting our reliability and customer satisfaction."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of tempo travellers do you offer for rent?",
        answer: "We offer a range of tempo travellers, including 14-seater, 17-seater, 20-seater, 25-seater, and 32-seater vehicles. This variety ensures that we can accommodate groups of all sizes."
    },
    {
        question: "Are your tempo travellers air-conditioned?",
        answer: "Yes, all our tempo travellers are equipped with air conditioning to ensure a comfortable journey regardless of the weather."
    },
    {
        question: "Can I hire a tempo traveller for a one-way trip?",
        answer: "Yes, we offer flexible rental options, including one-way trips. You can choose the rental type that best suits your travel needs."
    },
    {
        question: "How can I book a tempo traveller with Shraddha Travels?",
        answer: "You can book a tempo traveller through our user-friendly online booking system or by contacting our customer support team directly for personalized assistance."
    },
    {
        question: "Are your drivers experienced and professional?",
        answer: "Yes, all our drivers are experienced and professional. They are familiar with local routes and outstation travel, ensuring a safe and smooth journey."
    },
    {
        question: "What is included in the rental price?",
        answer: "Our rental prices are competitive and transparent, with no hidden costs. The rental price typically includes the vehicle, driver, and basic amenities. Any additional services or requirements can be discussed and priced accordingly."
    },
    {
        question: "Do you offer tempo travellers for outstation travel?",
        answer: "Yes, we offer tempo traveller rentals for both local and outstation travel. Whether you're heading to a nearby destination or a distant location, we have the right vehicle for you."
    },
    {
        question: "What safety measures are in place in your tempo travellers?",
        answer: "Our vehicles are equipped with modern safety features, including seat belts, emergency exits, fire extinguishers, first-aid kits, and GPS tracking for real-time monitoring."
    },
    {
        question: "Can I customize my tempo traveller rental to fit my itinerary?",
        answer: "Yes, we offer customizable solutions to match your specific travel itinerary and requirements. Our team will work with you to ensure your trip is tailored to your needs."
    },
    {
        question: "What should I do if I have a problem during my rental period?",
        answer: "Our customer support team is available to assist you with any issues that may arise during your rental period. Please contact us immediately, and we will address your concerns promptly."
    }
];



const testimonials = [
    {
        name: "Mr. Rajesh Kumar",
        text: "I recently hired a tempo traveller from Shraddha Travels for a family trip to Mahabaleshwar, and I couldn’t be happier with the service. The vehicle was clean, spacious, and well-maintained, making our journey very comfortable. The driver was professional and courteous, ensuring we arrived on time and safely. The booking process was smooth and the rates were very reasonable. I highly recommend Shraddha Travels for anyone looking for reliable tempo traveller hire in Pune."
    },
    {
        name: "Miss Priya Sharma",
        text: "Shraddha Travels provided an excellent tempo traveller for our corporate outing. The 20-seater bus was perfect for our group, with ample space and modern amenities. The air conditioning worked flawlessly, and the entertainment system kept everyone engaged throughout the trip. The driver was punctual and handled the vehicle expertly. Their customer service was top-notch, making the entire experience stress-free. If you need a tempo traveller in Pune, Shraddha Travels is the way to go!"
    }
];

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Tempo Traveller Hire in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller Hire in Pune</title>
    <meta name="description" content="Hire Tempo Travellers in Pune for various trips including Shirdi, Mahabaleshwar, and more. Best rates and services!" />
    <meta name="keywords" content="Tempo Traveller hire in Pune, 14 seater tempo traveller, 17 seater tempo traveller, Pune to Shirdi, Pune to Mahabaleshwar" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller Hire in Pune" />
    <meta property="og:description" content="Looking for Tempo Traveller hire in Pune? We offer various options for local and outstation trips at competitive prices." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-hire-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Hire in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-hire-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Hire",
                    "description": "Rent Tempo Travellers in Pune for trips to Shirdi, Mahabaleshwar, and more. Affordable and reliable services."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-22.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs About Tempo Traveller Hire in Pune - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Tempotravellerhireinpune;