import React from 'react';

const newsData = [
    {
        id: 1,
        category: 'Konkan Tour ',
        date: '20 Mar, 2023',
        title: 'Konkan: A symphony of waves, winds, and the whispers of the sea.',
        image: '/img/konkan.jpg',
    },
    {
        id: 2,
        category: ' Himachal Pradesh',
        date: '19 Mar, 2023',
        title: 'In the embrace of the Himalayas, discover the serene beauty of Himachal Pradesh.',
        image: '/img/himachal.jpg',
    },
    {
        id: 3,
        category: 'Ujjain',
        date: '18 Mar, 2023',
        title: 'Ujjain: A sacred journey through time, where the stars align and eternal spirituality reigns."',
        image: '/img/ujjain.jpg',
    },
];

const NewsSection = () => {
    return (
        <section className="news-section pt_100 pb_65">
            <div className="auto-container">
                <div className="sec-title centred mb_50">
                    <h2 className='darkcolor' style={{color:'#661247'}}>Our Latest Tours  </h2>
                </div>
                <div className="row clearfix">
                    {newsData.map((news) => (
                        <div className="col-lg-4 col-md-6 col-sm-12 news-block " key={news.id}>
                            <div className="news-block-one wow fadeInUp animated " style={{border:'3px solid #661247'}} data-wow-delay="300ms" data-wow-duration="1500ms">
                                <div className="inner-box ">
                                    <figure className="image-box">
                                        <a href={news.link}>
                                            <img src={news.image} alt={news.title} />
                                        </a>
                                    </figure>
                                    <div className="lower-content">
                                        <ul className="post-info mb_15">
                                            <li>
                                                <a href={news.link}>{news.category}</a>
                                            </li>
                                        </ul>
                                        <h3>
                                            <a href={news.link}>{news.title}</a>
                                        </h3>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default NewsSection;
