
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Forceurbanialuxuryminibusonrent() {



  const cardData =
  {
    keyword: 'Force Urbania Luxury Mini Bus On Rent',
    heading: 'Force Urbania Luxury Mini Bus On Rent: Shraddha Travels',
    headingDescription: 'When it comes to luxury group travel, the Force Urbania mini bus stands out as a top choice for comfort and elegance. At Shraddha Travels, we are dedicated to providing an unparalleled travel experience with our Force Urbania luxury mini buses. Here’s a look at the top features that make our Force Urbania mini bus rental the ultimate option for your travel needs:',
    top: 'Top Reasons to Rent the Force Urbania Luxury Mini Bus from Shraddha Travels',

    topPlaces: [
        {
            title: "1. Spacious Comfort for Groups",
            description: "The Force Urbania luxury mini bus is perfect for accommodating larger groups. With ample seating and generous legroom, it ensures that everyone travels comfortably. This makes it ideal for family outings, corporate events, school trips, or any occasion where you want to keep your group together while enjoying a relaxed and enjoyable ride."
        },
        {
            title: "2. Affordable Luxury",
            description: "Renting the Force Urbania offers a cost-effective way to experience luxury travel without breaking the bank. You can enjoy high-quality amenities, such as plush seating and climate control, at a fraction of the cost of other luxury vehicles. This makes it a smart choice for group transportation, allowing you to travel in style without the high price tag."
        },
        {
            title: "3. Modern Amenities",
            description: "Our Urbania is equipped with essential features like air conditioning, comfortable seating, and advanced audio-visual systems. This ensures that your travel experience is not only comfortable but also entertaining. Whether you want to enjoy music, watch videos, or simply relax, the modern amenities enhance your journey and make it enjoyable for everyone on board."
        },
        {
            title: "4. Safety First",
            description: "We prioritize your safety above all else. Our buses undergo regular maintenance and thorough inspections to ensure they are in top condition. The Urbania is also equipped with modern safety features, including seat belts and emergency exits, to guarantee a secure travel experience for all passengers. You can travel with peace of mind knowing that safety is our top priority."
        },
        {
            title: "5. Experienced Drivers",
            description: "Our professional drivers are not only trained to provide a safe and smooth journey, but they are also familiar with local routes and traffic patterns. This ensures efficient travel and timely arrivals. With their extensive experience, our drivers are committed to prioritizing your comfort and ensuring that your trip is enjoyable from start to finish."
        },
        {
            title: "6. Flexible Itineraries",
            description: "Renting the Force Urbania allows you to customize your travel plans to suit your needs. Whether you have specific destinations in mind or want to make stops along the way for sightseeing or meals, we can accommodate your preferences. This flexibility means you can create an itinerary that perfectly matches your group's interests and schedule."
        },
        {
            title: "7. Convenient Group Travel",
            description: "Instead of coordinating multiple vehicles and dealing with the complexities of group travel logistics, renting a single mini bus simplifies the entire process. Everyone travels together, making it easier to manage your group and enjoy shared experiences. This convenience enhances the overall travel experience, allowing you to focus on enjoying your time together."
        },
        {
            title: "8. 24/7 Customer Support",
            description: "Our dedicated customer service team is always available to assist you with any questions or changes regarding your rental. Whether you need help with booking, adjustments to your itinerary, or have any concerns during your trip, our team is here to ensure a smooth and hassle-free experience. We are committed to providing support whenever you need it."
        },
        {
            title: "9. Positive Reputation",
            description: "Shraddha Travels has earned a solid reputation for reliability and customer satisfaction in the travel industry. Many clients consistently choose us for their travel needs, which reflects our commitment to providing excellent service. Our clients’ positive feedback and repeat business highlight our dedication to delivering a quality travel experience."
        },
        {
            title: "10. Easy Booking Process",
            description: "Our user-friendly online booking system makes it incredibly simple to reserve your Force Urbania luxury mini bus. Just enter your travel details, and you’ll have your booking confirmed in no time. Focus on planning your itinerary and enjoying your trip, while we handle all the logistics of your transportation. Our goal is to make the booking process as effortless as possible."
        }
    ],
    services: [
        {
            name: "Force Urbania Luxury Mini Bus on Rent in Hadapsar",
            description: "Looking for a luxurious and comfortable mini bus for your next journey? Shraddha Travels offers the Force Urbania luxury mini bus for rent in Hadapsar. With spacious seating, premium interiors, and modern amenities, our Urbania buses provide the ideal blend of luxury and functionality. Whether you need it for a corporate event, a family outing, or a special occasion, enjoy a smooth ride that enhances your travel experience."
        },
        {
            name: "13-Seater Urbania Bus Hire in Kharadi",
            description: "For smaller group travel needs, our 13-seater Force Urbania bus hire service in Kharadi is perfect. Experience top-notch comfort and style with our luxury mini buses that are well-suited for corporate trips, group tours, or family gatherings. The bus features plush seating, air conditioning, and entertainment options to ensure a pleasant journey for all passengers."
        },
        {
            name: "17-Seater Urbania Bus Hire in Kharadi",
            description: "If you have a slightly larger group, our 17-seater Urbania bus rental service in Kharadi offers ample space and premium amenities. Enjoy a smooth and comfortable journey with our luxury mini buses that cater to your every need. With enhanced legroom and onboard facilities, your group can relax and enjoy the ride."
        },
        {
            name: "Luxury Bus for Corporate Events",
            description: "Impress your clients and colleagues with our luxury buses for corporate events. Shraddha Travels provides elegant and well-equipped Urbania buses that ensure a professional and comfortable experience for business travel and corporate gatherings. Our buses are designed to provide a productive environment, with amenities that cater to your business needs."
        },
        {
            name: "Online Bus Booking for Pune Airport",
            description: "Booking a luxury mini bus for Pune Airport has never been easier. With our online bus booking system, you can easily reserve a Force Urbania bus for a seamless and stylish transfer to and from the airport. Our user-friendly platform allows you to select your travel dates and preferences, ensuring a hassle-free experience."
        },
        {
            name: "Pune to Mumbai Airport Urbania Bus on Rent",
            description: "Travel in comfort and style with our Pune to Mumbai Airport Urbania bus rental service. Ideal for group travel, our luxury mini buses ensure a pleasant journey between Pune and Mumbai airports. Equipped with modern amenities, your group can relax, enjoy refreshments, and arrive at the airport refreshed and ready for your journey."
        },
        {
            name: "Force Urbania Luxury Mini Bus for Outstation",
            description: "For outstation trips, choose our Force Urbania luxury mini bus rental. Equipped with all the amenities you need, our Urbania buses are perfect for extended journeys, offering both comfort and reliability. Whether it's a weekend getaway or a longer adventure, you can count on us for a smooth travel experience."
        },
        {
            name: "Urbania Bus Hire in Hinjewadi",
            description: "Experience luxury travel with our Urbania bus hire service in Hinjewadi. Our mini buses are designed to provide a premium experience for all types of group travel, ensuring your journey is as comfortable as it is enjoyable. With spacious interiors and personalized service, we cater to both leisure and business trips."
        },
        {
            name: "Urbania Bus Hire in Aundh Baner",
            description: "For those in Aundh and Baner, our Urbania bus hire service offers a luxurious travel experience. Whether it's for a special event or a regular group outing, our Urbania mini buses provide the perfect blend of style and comfort. Enjoy the journey with amenities that keep you entertained and relaxed."
        },
        {
            name: "Urbania Bus on Rent for Pune to Shirdi Darshan",
            description: "When planning a spiritual journey from Pune to Shirdi, consider our Urbania bus rental service for a comfortable and luxurious travel experience. The Urbania offers ample space and high-end features, ensuring a pleasant journey to Shirdi, allowing you to focus on your darshan without any travel hassles. Our drivers are experienced and know the best routes to enhance your pilgrimage experience."
        },
        {
            name: "Force Urbania on Rent in Pune Airport",
            description: "Traveling from Pune Airport? Choose the Force Urbania for a seamless and comfortable transfer. Our rental service ensures that you experience a smooth transition from the airport to your destination with luxury and ease. Enjoy peace of mind knowing that our professional drivers will be waiting to assist you upon arrival."
        },
        {
            name: "Force Urbania on Rent in Pune Airport Near Me",
            description: "Looking for Force Urbania rentals near Pune Airport? We provide convenient pickup and drop-off services close to the airport, making it easy for you to access luxury transportation right when you land. Our commitment to punctuality ensures you won’t have to wait long to start your journey."
        },
        {
            name: "Force Urbania on Rent in Pimpri Chinchwad",
            description: "Residents of Pimpri Chinchwad can now enjoy the luxury and comfort of the Force Urbania. Whether you need it for corporate events, family gatherings, or any special occasion, our Pimpri Chinchwad rental service ensures top-notch quality and convenience. Experience a travel experience that prioritizes your comfort and satisfaction."
        },
        {
            name: "Force Urbania on Rent in Pimpri Chinchwad Near Me",
            description: "If you're searching for Force Urbania rentals near Pimpri Chinchwad, we offer localized services to cater to your needs. Enjoy luxury travel with easy access to our rentals in and around Pimpri Chinchwad. Our flexible booking options and competitive rates make us the preferred choice for group travel."
        },
        {
            name: "Force Urbania on Rent in Magarpatta City",
            description: "For those in Magarpatta City, our Force Urbania rental service provides an ideal solution for all your transportation needs. Whether it's for a corporate event, a wedding, or a personal trip, experience premium travel with our top-tier Urbania fleet. Our commitment to service excellence ensures that you have a memorable journey."
        },
        {
            name: "Force Urbania on Rent in Hinjewadi",
            description: "Travel in style with our Force Urbania rental service in Hinjewadi. Perfect for business trips, events, or personal use, the Urbania offers exceptional comfort and luxury, making your journey from Hinjewadi a truly enjoyable experience. Sit back, relax, and let our experienced drivers take care of the road."
        },
        {
            name: "Force Urbania Luxury Mini Bus On Rent Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Force Urbania Luxury Mini Bus On Rent. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Force Urbania Luxury Mini Bus on Rent in Hadapsar', '- 13 Seater Urbania Bus Hire in Kharadi'],
        ['- 17 Seater Urbania Bus Hire in Kharadi', '- Luxury Bus for Corporate Events Online Bus Booking'],
        ['- Pune Airport Urbania Bus on Rent', '- Pune to Mumbai Airport Urbania Bus on Rent'],
        ['- Force Urbania Luxury Mini Bus on Rent for Outstation', '- Urbania Bus Hire in Hinjewadi'],
        ['- Urbania Bus Hire in Aundh Baner', '- Urbania Bus on Rent for Pune to Shirdi Darshan'],
        ['- Force Urbania on Rent in Pune Airport', '- Force Urbania on Rent in Pune Airport Near Me'],
        ['- Force Urbania on Rent in Pimpri Chinchwad', '- Force Urbania on Rent in Pimpri Chinchwad Near Me'],
        ['- Force Urbania on Rent in Magarpatta City', '- Force Urbania on Rent in Hinjewadi']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Force Urbania Luxury Mini Bus on Rent?",
            WhyChoosedescription: "When it comes to renting a Force Urbania Luxury Mini Bus in Pune, Shraddha Travels is your top choice for a premium travel experience. We prioritize comfort, safety, and reliability to ensure that every journey you undertake with us is memorable and enjoyable."
        },
        {
            WhyChooseheading: "Exceptional Comfort and Luxury:",
            WhyChoosedescription: "The Force Urbania is meticulously designed with your comfort in mind, featuring plush, adjustable seating, ample legroom, and advanced climate control. Whether you're on a long journey or a short trip, our buses provide a first-class experience that allows you to relax and enjoy the ride."
        },
        {
            WhyChooseheading: "Modern Amenities:",
            WhyChoosedescription: "Our luxury mini buses are equipped with high-quality audio systems for your entertainment, spacious luggage compartments for convenience, and on-demand Wi-Fi to keep you connected. These features ensure that you have everything you need for a comfortable journey, whether for business or leisure."
        },
        {
            WhyChooseheading: "Professional and Experienced Drivers:",
            WhyChoosedescription: "Our drivers are not only professionally trained but also possess extensive experience navigating Pune and surrounding areas. They are dedicated to ensuring a smooth, safe, and punctual travel experience, giving you peace of mind as you focus on your journey."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "We take pride in maintaining our fleet to the highest standards. Each Force Urbania undergoes rigorous inspections and regular maintenance to ensure it remains in top condition. This commitment minimizes the risk of disruptions and enhances your overall travel experience."
        },
        {
            WhyChooseheading: "Customized Travel Solutions:",
            WhyChoosedescription: "No matter the occasion—be it corporate events, weddings, airport transfers, or sightseeing tours—we offer tailored solutions that cater to your specific requirements. Our team collaborates closely with you to ensure all your needs are met, making your travel experience seamless."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We provide competitive rental rates for the Force Urbania, ensuring transparent pricing with no hidden fees. This allows you to budget confidently and enjoy a premium travel experience without financial surprises."
        },
        {
            WhyChooseheading: "Convenient Booking Process:",
            WhyChoosedescription: "Booking your Force Urbania with us is simple and efficient. You can easily make reservations via phone, email, or through our user-friendly online booking system. Our team is always available to assist you with any queries or booking needs, ensuring a hassle-free experience."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "Our commitment to customer satisfaction is evident in the personalized service we provide. From your initial inquiry to the conclusion of your journey, our customer service team is dedicated to ensuring a smooth and enjoyable experience, addressing any concerns promptly."
        },
        {
            WhyChooseheading: "Punctuality and Reliability:",
            WhyChoosedescription: "We understand the importance of timing in travel. Our Urbania mini buses adhere to strict schedules for timely pickups and drop-offs, helping you stay on track with your plans, whether it’s for a corporate meeting or a family event."
        },
        {
            WhyChooseheading: "Wide Service Area:",
            WhyChoosedescription: "We offer Force Urbania rentals across Pune and surrounding areas, including Pimpri Chinchwad, Hadapsar, Magarpatta City, and Hinjewadi. No matter where your journey takes you, we have you covered with reliable transportation options."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What amenities are included with the Force Urbania rental?",
        answer: "The Force Urbania comes equipped with plush seating, air-conditioning, a high-quality audio system, Wi-Fi, and ample luggage space. Additional amenities may be available upon request to enhance your travel experience."
    },
    {
        question: "How can I book the Force Urbania Luxury Mini Bus?",
        answer: "You can book the Force Urbania by contacting us via phone, email, or through our online booking form. Just provide details such as your travel dates, pickup and drop-off locations, and any specific requirements to get started."
    },
    {
        question: "Are there any hidden fees in the rental pricing?",
        answer: "No, our pricing is transparent with no hidden fees. We provide a detailed quote based on your requirements, so you know exactly what to expect without any surprises."
    },
    {
        question: "What is the cancellation policy for the Force Urbania rental?",
        answer: "Our cancellation policy varies depending on how far in advance you cancel. Typically, cancellations made in advance may incur a nominal fee, while last-minute cancellations could involve higher charges. Please contact us for specific details."
    },
    {
        question: "Can I request specific features or configurations for the Force Urbania?",
        answer: "Yes, you can request specific features or configurations based on availability. We offer a range of options to suit different needs and preferences, ensuring your journey is tailored to your expectations."
    },
    {
        question: "Are the drivers experienced and licensed?",
        answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are knowledgeable about local routes and committed to providing a safe and pleasant journey for all passengers."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, changes can be made to your booking, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss any modifications you need."
    },
    {
        question: "What should I do if I need assistance during my journey?",
        answer: "If you require assistance during your journey, please contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly to ensure a smooth travel experience."
    },
    {
        question: "How far in advance should I book the Force Urbania?",
        answer: "To ensure availability and secure your preferred vehicle, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable to avoid any last-minute hassles."
    },
    {
        question: "Are there any restrictions on luggage or other items?",
        answer: "Standard luggage allowances apply, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking so we can make the necessary arrangements."
    }
];



const testimonials = [
    {
        name: "Miss. Sophie M",
        text: "We recently hired the Force Urbania Luxury Mini Bus from Shraddha Travels for a corporate retreat, and I must say, the experience exceeded our expectations. The bus was impeccably clean, with plush seating and all the modern amenities we needed for a comfortable journey. The driver was punctual and professional, ensuring we arrived on time and safely at each destination. Our clients were very impressed with the luxury and comfort of the Urbania. I highly recommend Shraddha Travels for anyone seeking a top-notch travel experience."
    },
    {
        name: "Mr. Rajesh Sharma",
        text: "For my daughter’s wedding, we needed a luxury bus that could transport our guests in style and comfort. Shraddha Travels provided us with the Force Urbania Luxury Mini Bus, and it was perfect for the occasion. The vehicle was elegant and spacious, and the onboard amenities made the journey enjoyable for everyone. The service was impeccable, from the initial booking to the end of our trip. Shraddha Travels made our special day even more memorable with their outstanding service. Thank you for making everything so smooth and luxurious!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Force Urbania Luxury Mini Bus On Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Force Urbania Luxury Mini Bus On Rent | Affordable Rentals</title>
    <meta name="description" content="Rent Force Urbania luxury mini bus in Pune for corporate events, airport transfers, and outstation trips. Book now!" />
    <meta name="keywords" content="Force Urbania luxury mini bus on rent Pune, 13 Seater Urbania, 17 Seater Urbania, Urbania bus hire" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Force Urbania Luxury Mini Bus On Rent | Affordable Rentals" />
    <meta property="og:description" content="Explore our Force Urbania luxury mini bus rentals in Pune for family trips, corporate outings, and airport transfers. Book now!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/force-urbania-luxury-mini-bus-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/force-urbania.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Force Urbania Luxury Mini Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/force-urbania-luxury-mini-bus-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Force Urbania Luxury Mini Bus",
                    "description": "Luxury mini bus rentals for corporate events, airport transfers, and outstation trips in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-18.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs About Force Urbania Luxury Mini Bus on Rent</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Forceurbanialuxuryminibusonrent;