// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';
// import '../css/CustomSlider.css';

// const CustomSlider = ({ items }) => {
//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     autoplay: true,
//     autoplaySpeed: 3000,
//   };

//   return (
//     <div id="why-choose-section" className="px-2">
//       <Slider {...settings}>
//         {items.map((item, index) => (
//           <div key={index} className="slider-item text-center">
//             <h3 className="slider-heading">{item.WhyChooseheading}</h3>
//             <p className="slider-description">{item.WhyChoosedescription}</p>
//           </div>
//         ))}
//       </Slider>
//     </div>
//   );
// };

// export default CustomSlider;








import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../css/CustomSlider.css'
const CustomSlider = ({ items, settings }) => {
  return (
    <div className="slider-container ">
      <Slider {...settings}>
        {items.map((item, index) => (
          <div key={index} className="slider-item">
            {item}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;