import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faPeopleCarry, faUserFriends, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet';
import '../css/Career.css';

const CareerItem = ({ icon, title, description }) => {
    return (




        
        
        <div className="col-lg-6 mb-4">
            <div className="career-item p-4 shadow-sm rounded">
                <div className="career-icon mb-3 text-center">
                    <FontAwesomeIcon icon={icon} size="3x" className="text-primary" />
                </div>
                <h4 className="career-title text-center">
                    <a href="#" className="text-dark">{title}</a>
                </h4>
                <p>{description}</p>
            </div>
        </div>
    );
};

const CareersSection = () => {
    return (
        <div>
            
            <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

            <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Career</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>





          
            <section aria-label="Careers" className="bg-light py-5">
                <div className="container px-0">
                    <div className="row g-2">
                        <div className="col-12 text-center mb-5">
                            <h2 className="section-title">Join Our Team</h2>
                            <p className="section-subtitle">
                                Explore exciting opportunities to be a part of Shraddha Travels.
                            </p>
                        </div>
                        <CareerItem 
                            icon={faCar}
                            title="Cab Driver"
                            description="As a cab driver with Shraddha Travels s, you'll be the face of our service, providing safe and reliable transportation. Your role is crucial in ensuring that our passengers have a comfortable and enjoyable journey. Join us to make travel a delightful experience!"
                        />
                        <CareerItem
                            icon={faPeopleCarry}
                            title="Customer Service Executive"
                            description="Join our customer service team to assist clients with bookings and inquiries. Your role will involve ensuring customer satisfaction and resolving issues, helping us maintain our reputation for exceptional service."
                        />
                        <CareerItem
                            icon={faUserFriends}
                            title="Travel Agent"
                            description="Become a travel agent with Shraddha Travels s and help clients plan their journeys. Your expertise will connect travelers with our services, ensuring they have a seamless and enjoyable travel experience."
                        />
                        <CareerItem
                            icon={faHeadset}
                            title="Operations Coordinator"
                            description="As an operations coordinator, you'll manage the logistics of our cab services, ensuring smooth operations and timely pickups. Your organizational skills will be key in delivering the best possible experience to our customers."
                        />
                    </div>
                </div>
            </section>
        </div>
    );
};

export default CareersSection;
