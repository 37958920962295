import React, { useState } from 'react';
import '../css/FAQSection.css'; // Make sure to create this CSS file

const FAQSection = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const faqs = [
        {
            question: "What types of vehicles does Shraddha Travels  offer?",
            answer: "We offer a range of vehicles including sedans, SUVs, and luxury cars to cater to all your travel needs."
        },
        {
            question: "How can I book a cab with Shraddha Travels ?",
            answer: "You can book a cab through our website or mobile app. Simply enter your pickup and drop-off locations and choose your preferred vehicle."
        },
        {
            question: "Are your drivers experienced?",
            answer: "Yes, all our drivers are highly experienced, undergo background checks, and receive training to ensure your safety and comfort."
        },
        {
            question: "What are your payment methods?",
            answer: "We accept various payment options including credit/debit cards, UPI, and cash."
        },
        {
            question: "Can I cancel my booking?",
            answer: "Yes, you can cancel your booking anytime through our app or website. Please review our cancellation policy for details."
        },
        {
            question: "Do you provide airport pickup and drop-off services?",
            answer: "Absolutely! We offer convenient airport pickup and drop-off services to make your travel hassle-free."
        },
        {
            question: "Is there a waiting charge?",
            answer: "Yes, there is a waiting charge if the driver has to wait more than 10 minutes after the scheduled pickup time."
        },
        {
            question: "Can I request a specific driver?",
            answer: "While we cannot guarantee a specific driver, we ensure all our drivers are well-trained and professional."
        },
        {
            question: "What if I leave something in the cab?",
            answer: "If you leave an item in the cab, please contact our customer support immediately, and we will do our best to assist you."
        },
        {
            question: "Do you offer intercity travel?",
            answer: "Yes, Shraddha Travels  provides intercity travel services. You can book a ride for any destination within the region."
        },
    ];

    const toggleFAQ = index => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    return (

<div>

    
<section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Faqs</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
        <section className="faq-section">
            <div className="faq-container">
                <h2 className="faq-title">Frequently Asked Questions</h2>
                <div className="faq-list">
                    {faqs.map((faq, index) => (
                        <div key={index} className={`faq-item ${activeIndex === index ? 'active' : ''}`}>
                            <div className="faq-question" onClick={() => toggleFAQ(index)}>
                                <h4>{faq.question}</h4>
                                <span className="faq-icon">{activeIndex === index ? '-' : '+'}</span>
                            </div>
                            {activeIndex === index && <p className="faq-answer">{faq.answer}</p>}
                        </div>
                    ))}
                </div>
            </div>
        </section>

</div>
    );
};

export default FAQSection;
