
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Luxurytempotraveller() {



  const cardData =
  {
    keyword: 'Luxury Tempo Traveller On Rent in Pune ',
    heading: 'Luxury Tempo Traveller On Rent in Pune: Shraddha Travels',
    headingDescription: 'Discover the vibrant city of Pune in style and comfort with Shraddha Travels luxury tempo traveller on rent. Pune, known for its rich cultural heritage, historical significance, and modern amenities, is the perfect destination for family trips, corporate outings, or group excursions. With our luxury tempo travellers, you can explore Pune"s attractions without the hassle of navigating through traffic or worrying about parking.',
    top: 'Top Places to Visit in Pune with Shraddha Travel',

    topPlaces: [
        {
            title: "1. Shaniwar Wada",
            description: "A historic fortification from the 18th century, Shaniwar Wada showcases stunning Maratha architecture with intricate carvings and majestic gates. Explore its expansive grounds, beautiful gardens, and the fascinating history that unfolds through the evening light and sound show, depicting tales of valor and legacy."
        },
        {
            title: "2. Aga Khan Palace",
            description: "This magnificent palace, built in the 19th century, is not only a symbol of Pune's historical significance but also a memorial to Mahatma Gandhi. Stroll through the serene gardens, visit the museum showcasing Gandhiji’s life and principles, and admire the elegant architecture that reflects the Indo-Saracenic style."
        },
        {
            title: "3. Saras Baug",
            description: "Known for its lush green gardens and serene lake, Saras Baug is home to the famous Ganesh Temple, attracting devotees and visitors alike. It's an ideal spot for family picnics, leisurely strolls, and enjoying the vibrant flora, making it a cherished escape from the city's hustle and bustle."
        },
        {
            title: "4. Osho International Meditation Resort",
            description: "Experience tranquility at this renowned meditation center, which offers a serene environment amidst beautiful gardens and unique architecture. Visitors can participate in various meditation sessions and workshops, making it a perfect retreat for relaxation and self-discovery."
        },
        {
            title: "5. Khadakwasla Dam",
            description: "A perfect spot for nature lovers, Khadakwasla Dam offers picturesque views of the surrounding hills and is ideal for picnics and photography. Enjoy a peaceful day by the water, or indulge in boating activities while soaking in the serene atmosphere and scenic landscapes."
        },
        {
            title: "6. Sinhagad Fort",
            description: "Perched on a hilltop, Sinhagad Fort offers breathtaking panoramic views and a rich historical backdrop. Explore the fort's ancient ruins, hike the trails leading to its summit, and savor traditional local delicacies from food stalls that line the pathways, all while immersing yourself in the fort's storied past."
        },
        {
            title: "7. Raja Dinkar Kelkar Museum",
            description: "This museum houses an impressive collection of artifacts that showcase the rich culture and heritage of India. With exhibits ranging from musical instruments to intricately crafted sculptures, it provides an enlightening experience for art lovers and history enthusiasts alike."
        },
        {
            title: "8. National War Museum",
            description: "A tribute to the bravery of Indian soldiers, the National War Museum features a diverse array of war memorabilia, including aircraft, vehicles, and personal artifacts. It serves as a poignant reminder of the sacrifices made by the armed forces, instilling a sense of pride and respect among visitors."
        },
        {
            title: "9. Pune Okayama Friendship Garden",
            description: "Inspired by Japanese landscaping, this tranquil garden is a perfect retreat from the bustling city life. With beautifully manicured pathways, serene ponds, and vibrant flora, it invites visitors to enjoy leisurely walks, meditation, or simply relax in a peaceful setting."
        },
        {
            title: "10. Fergusson College",
            description: "Renowned for its heritage architecture, Fergusson College is not only an educational institution but also a vibrant cultural hub. The campus hosts various events, exhibitions, and performances throughout the year, making it a lively spot for students and visitors to engage with the arts and culture."
        }
    ],    
    services: [
        {
            name: "Luxury Tempo Traveller on Rent in Hadapsar",
            description: "Explore the beauty of Pune and its surrounding areas with our luxury tempo traveller available for rent in Hadapsar. Ideal for family trips or group outings, these vehicles are equipped with comfortable seating, modern amenities, and air conditioning for a pleasant ride. Our professional drivers ensure a safe and smooth journey, allowing you to relax and enjoy the scenic views as you travel to your chosen destinations."
        },
        {
            name: "13 Seater Tempo Traveller on Rent in Pune",
            description: "Perfect for small groups, our 13-seater tempo travellers are designed to provide a cozy and enjoyable travel experience. Each vehicle features spacious interiors, comfortable seats, and ample storage for luggage. Rent one today for trips to nearby attractions, corporate outings, or family gatherings. With our reliable service, you can focus on making memories while we handle the logistics of your travel."
        },
        {
            name: "17 Seater Tempo Traveller on Rent in Pune",
            description: "If you're traveling with a larger group, our 17-seater tempo traveller is the ideal choice. Offering generous space and comfort, these vehicles allow everyone to travel together without feeling cramped. Equipped with modern conveniences like USB charging ports and entertainment systems, your journey will be enjoyable and convenient. Perfect for group tours, weddings, and family vacations, this option ensures everyone arrives at their destination refreshed and happy."
        },
        {
            name: "Traveller Hire in Pune for Outstation",
            description: "Planning an outstation trip? Our tempo traveller hire services cater to various destinations, ensuring a hassle-free travel experience. Choose from a range of vehicles tailored to your group size and budget, whether it's a weekend getaway to a nearby hill station or a longer journey to popular tourist spots. Our experienced drivers know the best routes, ensuring you arrive safely and on time while enjoying the journey."
        },
        {
            name: "Pune to Lonavala Traveller Bus on Rent",
            description: "Escape to the scenic hill station of Lonavala with our traveller bus rental service. Enjoy breathtaking views of the Western Ghats and the lush greenery that characterizes this beautiful destination. Our comfortable vehicles make the journey enjoyable, with plenty of space to relax and take in the sights. Whether it's a day trip or a weekend getaway, we ensure your ride is as delightful as the destination itself."
        },
        {
            name: "Pune to 5 Jyotirlinga Darshan Traveller on Rent",
            description: "Embark on a spiritual journey with our specially designed package for the 5 Jyotirlinga darshan. Travel comfortably to these sacred sites with family and friends in our luxury tempo traveller, equipped with reclining seats and climate control for a relaxing experience. Our knowledgeable drivers provide insights along the way, enriching your pilgrimage experience as you visit these revered locations."
        },
        {
            name: "Pune Darshan Traveller on Rent",
            description: "Explore the best of Pune with our Pune darshan traveller service. Visit iconic landmarks, cultural sites, and hidden gems while enjoying the comfort of our well-maintained tempo travellers. Our experienced drivers will guide you through the city's rich history and vibrant culture, ensuring you don't miss any highlights while traveling in comfort."
        },
        {
            name: "Pune to Nashik Traveller on Rent",
            description: "Plan your trip to Nashik with our tempo traveller on rent. Known for its vineyards, temples, and rich cultural heritage, Nashik is a fantastic destination for a weekend getaway. Our vehicles provide a comfortable ride, complete with air conditioning and spacious seating, ensuring you arrive ready to explore the best that the city has to offer."
        },
        {
            name: "Pune to Bhimashankar Traveller Bus Hire",
            description: "Visit the renowned Bhimashankar temple with ease by renting our traveller bus. Our experienced drivers will ensure you reach your destination safely and on time, allowing you to focus on your spiritual journey. With comfortable seating and ample luggage space, this service is designed for a stress-free pilgrimage experience."
        },
        {
            name: "Pune to Ajanta Ellora Traveller Bus Hire",
            description: "Discover the historical caves of Ajanta and Ellora with our traveller bus hire. Enjoy the scenic ride while learning about the rich heritage of these UNESCO World Heritage sites from our knowledgeable drivers. The comfortable seating and onboard amenities make for a pleasant journey, ensuring you arrive ready to explore the stunning artistry and history of these ancient caves."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pune Near Me",
            description: "Searching for a luxury tempo traveller in Pune? Look no further! Shraddha Travels provides nearby options for your convenience, ensuring you find the perfect vehicle for your journey. Our luxury vehicles feature plush interiors, entertainment systems, and all the modern amenities you need for a comfortable and stylish ride, making every trip a memorable experience."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Kothrud",
            description: "For those in Kothrud, our luxury tempo traveller service is just a booking away. Travel comfortably to your desired destination without the stress of traffic or parking. Our professional drivers are well-versed in local routes, ensuring you arrive at your destination relaxed and on time, whether it's a family function, corporate event, or a leisurely outing."
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pune Near Me Contact Number",
            description: "For quick inquiries or bookings, contact us for the nearest luxury tempo traveller options available in Pune. Our team is ready to assist you with all your travel needs, providing personalized service to ensure you have a seamless experience from booking to travel. Don’t hesitate to reach out for any questions or special requests!"
        },
        {
            name: "Luxury Tempo Traveller on Rent in Pimpri Chinchwad",
            description: "Residents of Pimpri Chinchwad can enjoy our luxury tempo traveller services. Whether for personal trips, corporate travel, or special occasions, we cater to all your transportation needs. Our luxury vehicles are equipped with the latest amenities, ensuring a comfortable and stylish ride, perfect for making your events even more memorable."
        },
        {
            name: "Luxury Tempo Traveller On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Luxury Tempo Traveller rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today, and let us make your travel experience exceptional!"
        }
    ],    
    tableData: [
        ['- Luxury Tempo Traveller on Rent in Hadapsar', '- 13 Seater Tempo Traveller on Rent in Pune'],
        ['- 17 Seater Tempo Traveller on Rent in Pune', '- Traveller Hire in Pune for Outstation'],
        ['- Pune to Lonavala Traveller Bus on Rent', '- Pune to 5 Jyotirlinga Darshan Traveller on Rent'],
        ['- Pune Darshan Traveller on Rent', '- Pune to Nashik Traveller on Rent'],
        ['- Pune to Bhimashankar Traveller Bus Hire', '- Pune to Ajanta Ellora Traveller Bus Hire'],
        ['- Luxury Tempo Traveller on Rent in Pune Near Me', '- Luxury Tempo Traveller on Rent in Kothrud'],
        ['- Luxury Tempo Traveller on Rent in Pune Near Me Contact Number', '- Luxury Tempo Traveller on Rent in Pimpri Chinchwad']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Luxury Tempo Traveller on Rent in Pune?",
            WhyChoosedescription: "When it comes to renting a luxury tempo traveller in Pune, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Whether you're planning a family trip, a corporate outing, or a religious pilgrimage, our service ensures that you travel in style and comfort. We are dedicated to delivering a travel experience that exceeds your expectations. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "Our luxury tempo travellers are designed with your comfort in mind. Each vehicle features spacious interiors, plush seating, and generous legroom to ensure a relaxing experience, whether you’re traveling short distances within the city or embarking on long road trips. Modern amenities, such as air conditioning and entertainment systems, add to the convenience, making every journey enjoyable for you and your companions."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "We take great pride in our fleet of well-maintained vehicles. Each tempo traveller undergoes regular inspections and servicing to ensure it meets the highest safety and hygiene standards. Our commitment to maintenance means you can travel with peace of mind, knowing that your vehicle is in excellent condition and equipped with all necessary safety features."
        },
        {
            WhyChooseheading: "Professional Drivers:",
            WhyChoosedescription: "Our drivers are not only experienced and professional but also well-versed in local routes and traffic patterns. They are committed to ensuring a safe and smooth ride, prioritizing your comfort and punctuality. With their expertise, you can relax and enjoy the journey while they navigate through the best routes, ensuring timely arrivals at your destinations."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "At Shraddha Travels, we believe that luxury should be accessible to everyone. Our competitive pricing structure ensures that you get the best value for your money without compromising on quality or comfort. We offer transparent pricing with no hidden fees, making it easy for you to budget for your travels while enjoying top-notch service."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "We understand that every trip is unique, which is why we offer customizable travel packages tailored to your specific needs and preferences. Whether you’re planning a family outing, a corporate event, or a spiritual pilgrimage, we can accommodate your requirements with personalized itineraries that enhance your travel experience and ensure you make the most of your journey."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with inquiries, bookings, and any other needs you may have. We are just a call away, ready to help you with any questions or adjustments to your travel plans. Our commitment to exceptional service means you can rely on us for assistance at any time, ensuring a seamless travel experience from start to finish."
        },
        {
            WhyChooseheading: "Positive Reviews:",
            WhyChoosedescription: "Our satisfied customers consistently praise our services for their reliability, comfort, and professionalism. With numerous positive reviews, we have built a reputation as a trusted provider in the industry. Join the growing list of happy travelers who have experienced the convenience and luxury of traveling with Shraddha Travels, and let us be a part of your memorable journeys."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of luxury tempo travellers are available for rent?",
        answer: "We offer a range of luxury tempo travellers, including 13-seater and 17-seater options, equipped with modern amenities such as air conditioning, comfortable seating, and entertainment systems to ensure a pleasant travel experience."
    },
    {
        question: "How do I book a luxury tempo traveller?",
        answer: "Booking is easy! You can call us directly or visit our website to fill out a booking form. Our customer support team will assist you in finalizing your booking and answering any questions you may have."
    },
    {
        question: "Are the drivers provided by Shraddha Travels experienced?",
        answer: "Yes, all our drivers are highly trained and experienced professionals who know the local routes well. They are committed to ensuring a safe and pleasant journey for all passengers."
    },
    {
        question: "Can I customize my travel itinerary?",
        answer: "Absolutely! We offer customizable travel packages tailored to your specific needs and preferences. Just let us know your requirements, and we will make the necessary arrangements to accommodate your itinerary."
    },
    {
        question: "What are the payment options available?",
        answer: "We accept various payment methods, including cash, bank transfers, and digital wallets, making it convenient for you to settle the payment based on your preference."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a flexible cancellation policy. Please contact our customer support team for detailed information regarding cancellations, refunds, and any associated fees."
    },
    {
        question: "How far in advance should I book my tempo traveller?",
        answer: "We recommend booking your luxury tempo traveller at least a few days in advance to ensure availability, especially during peak travel seasons or holidays when demand is higher."
    },
    {
        question: "Are there any additional charges for long-distance travel?",
        answer: "Additional charges may apply for long-distance travel, depending on the distance and duration of the trip. For detailed pricing information, please consult our customer support team."
    }
];


const testimonials = [
    {
        name: "Mr. Rajesh Patil",
        text: "I recently hired a luxury tempo traveller from Shraddha Travels for a family trip to Lonavala, and I couldn’t have been more pleased! The vehicle was immaculate, and the spacious interiors made our journey incredibly comfortable. Our driver was professional and friendly, ensuring we reached our destination safely and on time. I highly recommend Shraddha Travels for anyone looking for a premium travel experience in Pune!"
    },
    {
        name: "Ms. Anjali Deshmukh",
        text: "Shraddha Travels provided us with a luxury tempo traveller for our corporate retreat to Nashik, and it was the best decision we made! The vehicle was equipped with all the amenities we needed, and the ride was smooth and enjoyable. The staff was attentive to our needs, making the booking process easy and stress-free. I will definitely choose Shraddha Travels again for our future trips!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Luxury Tempo Traveller On Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Luxury Tempo Traveller On Rent in Pune | Comfortable Group Travel</title>
    <meta name="description" content="Rent luxury tempo travellers in Pune for comfortable group travel. Options for 13 & 17 seater available for local and outstation trips." />
    <meta name="keywords" content="Luxury Tempo Traveller on Rent, 13 Seater Tempo Traveller Pune, 17 Seater Tempo Traveller Pune, Traveller Hire Pune, Pune to Lonavala" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Luxury Tempo Traveller On Rent in Pune | Comfortable Group Travel" />
    <meta property="og:description" content="Explore our luxury tempo traveller rental services in Pune. Perfect for family trips, corporate outings, and more!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/luxury-tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/luxury-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Luxury Tempo Traveller On Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/luxury-tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Luxury Tempo Traveller Rental Services in Pune",
                    "description": "Rent luxury tempo travellers in Pune for comfortable group travel. Perfect for outings, picnics, and darshan trips."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 3-09.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Luxury Tempo Traveller On Rent in Pune - Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Luxurytempotraveller;