
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seater35busonrent() {



  const cardData =
  {
    keyword: ' 35-Seater Bus Rental in Pune',
    heading: 'Shraddha Travels: 35-Seater Bus Rental in Pune',
    headingDescription: 'When organizing group travel in Pune, whether for a corporate event, family gathering, school trip, or tour, renting a 35-seater bus from Shraddha Travels is the perfect choice. Our fleet of spacious and well-maintained buses offers unparalleled comfort, safety, and convenience for all your large group travel needs. Here’s why our 35-seater bus rental service stands out:',
    top: 'Top Reasons to Rent a 35-Seater Bus from Shraddha Travels in Pune',

    topPlaces: [
        {
          title: "1. Ample Space for Large Groups",
          description: "Our 35-seater bus is ideal for accommodating larger groups, making it perfect for family trips, corporate outings, school excursions, and group tours. Everyone can travel together comfortably, fostering a sense of camaraderie and making the journey more enjoyable."
        },
        {
          title: "2. Cost-Effective Travel Solution",
          description: "Renting a single bus is often more economical than hiring multiple vehicles. By choosing our 35-seater, you can save on expenses related to fuel, tolls, and parking. This ensures your travel budget is used efficiently, allowing you to allocate more funds towards activities and experiences."
        },
        {
          title: "3. Comfort and Convenience",
          description: "Our buses are designed for comfort, featuring spacious seating, ample legroom, and plush interiors. Passengers can relax, socialize, or even take a nap, making long trips much more enjoyable and less stressful."
        },
        {
          title: "4. Experienced and Professional Drivers",
          description: "Our skilled drivers are not only trained in safe driving practices but also know the best routes in and around Pune. They prioritize your safety and comfort, allowing you to focus on enjoying the trip without worrying about navigation or road conditions."
        },
        {
          title: "5. Customizable Itineraries",
          description: "We understand that every group has unique travel needs. Our rental service allows for flexible itineraries, so you can plan your stops, sightseeing, and activities according to your preferences. Whether it's a scenic route or specific attractions, we tailor the journey to suit your group."
        },
        {
          title: "6. High Standards of Safety",
          description: "Safety is our top priority. Our buses are regularly maintained and undergo thorough safety checks to ensure that they are in excellent condition. This provides peace of mind during your travels, knowing that you are in a safe and reliable vehicle."
        },
        {
          title: "7. Modern Amenities Onboard",
          description: "Our 35-seater buses come equipped with essential amenities such as air conditioning, entertainment systems, Wi-Fi, and charging ports. These features make your journey comfortable and enjoyable, allowing you to stay connected and entertained throughout the trip."
        },
        {
          title: "8. 24/7 Customer Support",
          description: "Our dedicated customer service team is available around the clock to assist you with any inquiries, changes, or special requests. This ensures a smooth rental experience from start to finish, giving you the confidence that help is always just a call away."
        },
        {
          title: "9. Positive Customer Feedback",
          description: "Shraddha Travels is known for its reliable service and customer satisfaction. Many clients return to us for their travel needs, reflecting our commitment to excellence in service. We take pride in the positive experiences shared by our customers, which motivates us to maintain high standards."
        },
        {
          title: "10. Hassle-Free Booking Process",
          description: "Our user-friendly online booking system allows you to reserve your 35-seater bus quickly and easily. This enables you to focus on planning your itinerary and ensuring your trip is organized without unnecessary stress or complications."
        }
      ],
      
      services: [
        {
          name: "35-Seater Bus on Rent in Pune",
          description: "When you need a 35-seater bus in Pune, Shraddha Travels is your go-to provider. Our buses are designed for spacious and comfortable group travel, making them ideal for family outings, corporate events, and school trips. With our 35-seater buses, you can expect a smooth and enjoyable journey across Pune, whether you're exploring the city or heading out for a special event."
        },
        {
          name: "35-Seater Bus on Rent for Pune to Outstation",
          description: "For trips from Pune to outstation locations, our 35-seater buses offer a convenient and comfortable travel solution. These buses are equipped with modern amenities such as air-conditioning and entertainment systems, making them perfect for longer journeys to destinations like Mahabaleshwar, Shirdi, or Lonavala. Enjoy a relaxing ride with ample space for your entire group."
        },
        {
          name: "35-Seater Bus on Rent for Corporate Office",
          description: "If you need a 35-seater bus for corporate office travel, Shraddha Travels has you covered. Our buses are perfect for team outings, daily commutes, or business meetings. With comfortable seating and air-conditioning, your employees will travel in comfort. Our professional drivers ensure timely and efficient service, catering to the specific needs of corporate clients."
        },
        {
          name: "35-Seater Bus on Rent for Corporate Event",
          description: "For corporate events, including conferences and team-building activities, renting a 35-seater bus provides a comfortable and efficient transportation solution. Our buses come with features like air-conditioning and entertainment systems to enhance the travel experience. Our experienced drivers ensure punctual and safe transportation, allowing your team to focus on the event."
        },
        {
          name: "35-Seater Bus Rental Services in Pune",
          description: "Shraddha Travels offers comprehensive 35-seater bus rental services in Pune for various group travel needs. Whether it's for a wedding, corporate event, school excursion, or religious tour, our buses ensure a comfortable and hassle-free travel experience. With competitive rates and exceptional customer service, we are the preferred choice for bus rentals in Pune."
        },
        {
          name: "35-Seater Bus on Rent AC Bus",
          description: "If you require a 35-seater bus with air-conditioning, Shraddha Travels provides the perfect solution. Our air-conditioned buses are ideal for maintaining a cool and comfortable environment during your journey, whether it’s for a wedding, corporate event, or outstation trip. Enjoy a refreshing ride with our well-maintained AC buses."
        },
        {
          name: "35-Seater Bus on Rent Non-AC Bus",
          description: "For a more budget-friendly option, we offer 35-seater non-AC buses. These buses provide an economical choice while still ensuring comfort and ample space. They are ideal for cooler weather or shorter trips, offering reliable transportation for various group events and outings."
        },
        {
          name: "35-Seater Bus on Rent for Marriage Function",
          description: "For weddings, our 35-seater buses are an excellent choice for transporting guests. Our buses are spacious and comfortable, with amenities such as air-conditioning to ensure a pleasant ride. We provide reliable transportation for your guests to and from the wedding venue, ensuring a smooth and enjoyable experience."
        },
        {
          name: "35-Seater Bus on Rent for School Picnic",
          description: "Make your school picnic a memorable experience with our 35-seater bus rental. Our buses are designed to accommodate large groups comfortably, with features like ample luggage space and entertainment systems. Ensure a fun and safe journey for students and teachers with our reliable bus rental services."
        },
        {
          name: "35-Seater Bus on Rent Corporate Picnic",
          description: "For corporate picnics and team-building events, our 35-seater buses offer a comfortable and convenient travel solution. Equipped with air-conditioning and spacious seating, our buses provide a relaxed environment for your team. Enjoy a smooth journey to your corporate outing destination."
        },
        {
          name: "35-Seater Bus on Rent Pimpri Chinchwad",
          description: "If you are in Pimpri Chinchwad and need a 35-seater bus, Shraddha Travels offers reliable bus rental services in the area. Our fleet is ideal for various group travel needs, including corporate events, family functions, and more. Expect a comfortable and hassle-free travel experience with our 35-seater buses."
        },
        {
          name: "Bharat Benz 35-Seater Bus on Rent",
          description: "Experience luxury with our Bharat Benz 35-seater buses. Known for their superior comfort and advanced features, these buses are ideal for any group travel needs. Whether for a corporate event, family function, or outstation trip, our Bharat Benz buses provide a premium travel experience with top-notch amenities and exceptional service."
        },
        {
          name: "Pune to Shirdi 35-Seater Bus on Rent",
          description: "When planning a trip from Pune to Shirdi, Shraddha Travels offers reliable 35-seater bus rentals to ensure a comfortable and enjoyable journey. Our buses are equipped with modern amenities such as air-conditioning, reclining seats, and entertainment systems, making your pilgrimage or visit to Shirdi both pleasant and stress-free."
        },
        {
          name: "Pune to Konkan Darshan 35-Seater Bus on Rent",
          description: "For a scenic journey from Pune to the picturesque Konkan region, our 35-seater buses are the perfect choice. Shraddha Travels offers well-maintained buses designed for comfort and convenience, ideal for exploring the beautiful Konkan coast."
        },
        {
          name: "35-Seater Bus on Rent for 5 Jyotirlinga from Pune",
          description: "Embark on a spiritual journey to the 5 Jyotirlinga temples with Shraddha Travels' 35-seater bus rentals. Our buses are designed to accommodate your group comfortably while visiting these sacred sites, ensuring a reliable and enjoyable travel experience."
        },
        {
          name: "Pune to Nashik Bus on Rent for Picnic",
          description: "Planning a fun-filled picnic from Pune to Nashik? Our 35-seater buses are an excellent choice for transporting your group comfortably. Shraddha Travels offers well-equipped buses with air-conditioning and ample space, perfect for a day of relaxation and enjoyment."
        },
        {
          name: "35-Seater Bus Hire for School Picnic",
          description: "Ensure a memorable and enjoyable school picnic with Shraddha Travels' 35-seater bus rentals. Our buses are ideal for transporting students and teachers comfortably, featuring air-conditioning and ample luggage space."
        },
        {
          name: "35 Seater Bus Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 35 Seater Bus Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
      ],
      
      tableData: [
        ['- 35 Seater Bus on Rent in Pune', '- 35 Seater Bus on Rent for Pune to Outstation'],
        ['- 35 Seater Bus on Rent for Corporate Office', '- 35 Seater Bus on Rent for Corporate Event'],
        ['- 35 Seater Bus Rental Services in Pune', '- 35 Seater Bus on Rent AC Bus'],
        ['- 35 Seater Bus on Rent Non-AC Bus', '- 35 Seater Bus on Rent for Marriage Function'],
        ['- 35 Seater Bus on Rent for School Picnic', '- 35 Seater Bus on Rent Corporate Picnic'],
        ['- 35 Seater Bus on Rent Pimpri Chinchwad', '- Bharat Benz 35 Seater Bus on Rent'],
        ['- Pune to Shirdi 35 Seater Bus on Rent', '- Pune to Konkan Darshan 35 Seater Bus on Rent'],
        ['- 35 Seater Bus on Rent for 5 Jyotirlinga from Pune', '- Pune to Nashik Bus on Rent for Picnic'],
        ['- 35 Seater Bus Hire for School Picnic', '']
      ],
      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for 35-Seater Bus Rental in Pune?",
          WhyChoosedescription: "When it comes to renting a 35-seater bus in Pune, Shraddha Travels stands out as a reliable provider dedicated to ensuring your group travels in comfort and style. Our commitment to excellent service, attention to detail, and customer satisfaction makes us the preferred choice for all your travel needs. Whether you're organizing a family outing, corporate event, or school trip, our service is designed to cater to your specific requirements, offering a seamless travel experience from start to finish."
        },
        {
          WhyChooseheading: "Spacious and Comfortable Buses:",
          WhyChoosedescription: "Our 35-seater buses are thoughtfully designed with passenger comfort in mind. Each bus features plush, ergonomic seating that offers ample legroom, allowing everyone to relax during the journey. The air-conditioning system ensures a pleasant climate, making even long trips enjoyable. With spacious interiors and comfortable seating arrangements, your group can travel together without feeling cramped or uncomfortable, enhancing the overall experience of your trip."
        },
        {
          WhyChooseheading: "Professional and Experienced Drivers:",
          WhyChoosedescription: "Safety is our top priority at Shraddha Travels. Our drivers are not only highly skilled and well-trained but also possess extensive experience in the transportation industry. They are familiar with local routes and traffic conditions, allowing them to navigate efficiently and avoid delays. Our drivers prioritize your safety and comfort, ensuring a smooth and enjoyable ride for all passengers. Their professionalism and courtesy further enhance your travel experience."
        },
        {
          WhyChooseheading: "Well-Maintained Fleet:",
          WhyChoosedescription: "At Shraddha Travels, we take pride in the quality and maintenance of our fleet. Our 35-seater buses undergo rigorous inspections and regular servicing to ensure they meet the highest safety and performance standards. We believe that a well-maintained vehicle is essential for a reliable travel experience, minimizing the risk of breakdowns and ensuring that you can focus on enjoying your journey without worries about vehicle issues."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "We believe in offering competitive pricing for our 35-seater bus rentals without compromising on quality or service. Our transparent pricing structure means you can expect no hidden fees or unexpected costs. This approach allows you to plan your travel budget with confidence, knowing you’re getting exceptional value for the service provided. We aim to make group travel not only enjoyable but also economical."
        },
        {
          WhyChooseheading: "Customizable Travel Solutions:",
          WhyChoosedescription: "Every group has unique travel needs, and we understand that no two trips are the same. Whether you require transportation for a corporate function, family gathering, school outing, or any other occasion, we offer customizable solutions tailored specifically to fit your requirements. Our team is dedicated to working closely with you to understand your needs, preferences, and itinerary, ensuring a personalized and seamless travel experience that meets your expectations."
        },
        {
          WhyChooseheading: "Excellent Customer Service:",
          WhyChoosedescription: "From the moment you reach out to us for your booking until the conclusion of your journey, our customer service team is committed to providing you with the highest level of support. We understand that effective communication and responsiveness are crucial, and our staff is always available to assist you with any inquiries, changes, or special requests. Our focus on customer satisfaction ensures a hassle-free experience, allowing you to enjoy your trip without any concerns."
        },
        {
          WhyChooseheading: "Punctual and Reliable:",
          WhyChoosedescription: "We recognize the importance of punctuality in group travel. Our buses adhere to strict schedules, ensuring timely departures and arrivals to keep your plans on track. We value your time and strive to provide a reliable service that allows you to reach your destination as planned. With Shraddha Travels, you can travel with peace of mind, knowing that we are committed to getting you where you need to be, when you need to be there."
        }
      ]
      
  }



  const faqData = [
  {
    question: "What is included in the rental of a 35-seater bus?",
    answer: "Our 35-seater bus rental includes comfortable seating, air-conditioning, and professional driver services. Additional amenities such as audio systems and Wi-Fi may be available upon request, depending on the specific bus model."
  },
  {
    question: "How do I book a 35-seater bus with Shraddha Travels?",
    answer: "You can book a 35-seater bus by contacting us directly via phone or email, or by filling out our online booking form. Provide us with details such as your travel dates, pick-up and drop-off locations, and any special requirements."
  },
  {
    question: "Are there any additional charges I should be aware of?",
    answer: "Our pricing is transparent, with no hidden fees. However, additional charges may apply for extra services, such as extended travel hours or special requests. We will provide a detailed quote before finalizing your booking."
  },
  {
    question: "What is the cancellation policy for a 35-seater bus rental?",
    answer: "Our cancellation policy varies depending on the timing of the cancellation. Generally, cancellations made well in advance may incur a nominal fee, while last-minute cancellations might involve higher charges. Please contact our customer service for specific details regarding your booking."
  },
  {
    question: "Can I request a specific type of 35-seater bus?",
    answer: "Yes, you can request specific features or types of buses based on availability. We offer a range of options to suit different needs, including luxury and standard models."
  },
  {
    question: "Are the drivers experienced and licensed?",
    answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are well-acquainted with local routes and dedicated to providing a safe and comfortable travel experience."
  },
  {
    question: "Can I make changes to my booking after it is confirmed?",
    answer: "Yes, changes to your booking can be made, subject to availability and any applicable fees. Please contact our customer service team as soon as possible to discuss any modifications to your travel plans."
  },
  {
    question: "What should I do if I need assistance during my journey?",
    answer: "If you require any assistance during your journey, please contact the driver or our customer service team immediately. We are committed to addressing any concerns or issues promptly to ensure a smooth trip."
  },
  {
    question: "How far in advance should I book a 35-seater bus?",
    answer: "To secure your preferred bus and ensure availability, we recommend booking as early as possible. For peak seasons or special events, booking well in advance is advisable to avoid last-minute complications."
  },
  {
    question: "Are there any restrictions on luggage or other items?",
    answer: "There are standard luggage allowances, but we can accommodate additional baggage or special items upon request. Please inform us of any specific needs when making your booking so we can make the necessary arrangements."
  }
];


const testimonials = [
    {
      name: "Mr. Rajesh Kumar",
      text: "I recently rented a 35-seater bus from Shraddha Travels for a family reunion trip in Pune, and I couldn’t have been more impressed. The bus was spacious, clean, and equipped with all the modern amenities we needed. The air-conditioning worked perfectly, keeping us cool throughout the journey. The driver was punctual and professional, ensuring we reached our destinations safely and on time. Shraddha Travels made the entire experience smooth and enjoyable. I highly recommend their services for anyone looking for reliable and comfortable group transportation in Pune."
    },
    {
      name: "Ms. Priya Mehta",
      text: "We hired a 35-seater bus from Shraddha Travels for a corporate team outing, and the service exceeded our expectations. The bus was well-maintained and featured comfortable seating with plenty of legroom. The onboard entertainment system was a great addition, keeping everyone entertained during the trip. The driver was courteous and knowledgeable about the best routes, which made our journey pleasant and efficient. The booking process was straightforward, and the pricing was very reasonable for the quality of service provided. Shraddha Travels is definitely our top choice for future group travel needs."
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "35-Seater Bus Rent in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 35 Seater Bus on Rent in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Rent 35-seater buses in Pune for corporate events, school picnics, and outstation trips. Competitive rates and reliable service!" />
    <meta name="keywords" content="35 seater bus on rent in Pune, 35 seater bus for corporate events, Pune to Shirdi bus rental, school picnic bus rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 35 Seater Bus on Rent in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Discover 35-seater bus rental options in Pune for various occasions, including corporate events and school picnics. Book now!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/35-seater-bus-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/35-seater-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "35 Seater Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/35-seater-bus-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "35 Seater Bus",
                    "description": "Rent a comfortable 35-seater bus for corporate events, school picnics, and outstation trips in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-12.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About 35-Seater Bus Rentals in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seater35busonrent;