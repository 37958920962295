
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Busonrentforeventinpune() {



  const cardData =
  {
    keyword: ' Bus on Rent for Events in Pune',
    heading: 'Shraddha Travels: Bus on Rent for Events in Pune',
    headingDescription: 'When organizing an event in Pune, ensuring reliable and comfortable transportation for guests is a priority. Shraddha Travels offers premier bus rental services tailored to meet the transportation needs of any event. Here’s why Shraddha Travels should be your go-to choice for bus rentals in Pune:',
    top: 'Top Reasons to Bus on Rent for event in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Diverse Fleet of Buses",
            description: "Shraddha Travels boasts an extensive range of buses, including luxury coaches, semi-luxury options, and standard buses. Whether your event needs a compact 17-seater for an intimate gathering or a spacious 52-seater for larger groups, we have the perfect vehicle to accommodate your group size and travel requirements. Our fleet is regularly updated to ensure reliability and comfort."
        },
        {
            title: "2. State-of-the-Art Amenities",
            description: "Our buses are equipped with modern features such as air conditioning, plush reclining seats, advanced entertainment systems, and complimentary Wi-Fi. These amenities ensure a smooth and enjoyable journey for your guests, making every trip more pleasant. From USB charging ports to onboard refreshments, we focus on providing comfort and convenience throughout the journey."
        },
        {
            title: "3. Experienced Drivers",
            description: "Shraddha Travels takes pride in its team of professional and experienced drivers. Our drivers are well-versed in Pune's routes, traffic patterns, and safety regulations. They undergo rigorous training to ensure not only timely arrivals but also a smooth and safe transportation experience. With their local knowledge, they can recommend scenic routes and avoid congested areas."
        },
        {
            title: "4. Flexible Rental Plans",
            description: "We understand that every event is unique, which is why we offer flexible rental packages tailored to meet your specific requirements. Whether you need a bus for a one-time corporate function, a wedding, or recurring transportation services for a series of events, we can customize the best plan for you. Our goal is to provide a seamless and stress-free experience that fits your needs."
        },
        {
            title: "5. Affordable Pricing",
            description: "We provide competitive pricing with no hidden fees, ensuring that you get the best value for your transportation needs. Our transparent pricing structure allows you to plan your event's transportation costs with confidence. We strive to deliver exceptional service at a price that works for your budget, making quality transportation accessible for everyone."
        },
        {
            title: "6. Dedicated Customer Support",
            description: "Our friendly and responsive customer service team is always available to assist you throughout the booking process and on the day of your event. From answering queries to providing real-time updates, we are here to ensure a hassle-free experience from start to finish. Our commitment to excellent customer service means you can focus on enjoying your event while we handle the logistics."
        },
        {
            title: "7. Customizable Event Packages",
            description: "At Shraddha Travels, we offer personalized rental packages designed to suit a variety of events, whether corporate functions, weddings, private parties, or school trips. We collaborate closely with you to understand your vision and ensure that your transportation needs are seamlessly integrated into your event plan. Our flexibility allows us to adapt to last-minute changes and special requests."
        },
        {
            title: "8. Hygiene and Cleanliness",
            description: "We maintain high standards of cleanliness for all our buses. Each vehicle undergoes thorough cleaning and sanitization before and after each trip, with regular maintenance checks to ensure safety and hygiene. Our commitment to cleanliness ensures that passengers enjoy a safe and hygienic environment, especially during peak travel times or in light of health considerations."
        },
        {
            title: "9. Punctuality",
            description: "Timeliness is essential in the world of event transportation, and Shraddha Travels guarantees on-time arrivals and departures. We understand that delays can affect the flow of your event, so you can rely on us to ensure your guests are transported promptly to and from their destinations. Our efficient scheduling and real-time tracking systems enhance our punctuality."
        },
        {
            title: "10. Satisfied Customers",
            description: "Our clients consistently provide positive feedback, praising our reliable service, well-maintained buses, and courteous drivers. We take pride in building long-lasting relationships with our customers, who trust us for their event transportation needs. Our focus on customer satisfaction drives us to continuously improve our services, ensuring that we meet and exceed expectations."
        }
    ],    
    services: [
        {
            name: "Bus on Rent for Wedding Evening in Pune",
            description: "Make your wedding evening unforgettable with Shraddha Travels’ bus rental services. We offer a wide range of options, including luxury and standard buses, ensuring your guests travel comfortably and arrive at the venue on time. With spacious seating and modern amenities, your guests can relax and enjoy the journey, making your special day even more memorable."
        },
        {
            name: "Bus Rental for Wedding Party",
            description: "Enhance your wedding party’s experience with our tailored bus rental services. Our stylish, well-maintained buses come equipped with modern amenities such as air conditioning and entertainment systems, ensuring a smooth ride for your guests. From the ceremony to the reception, we handle the transportation logistics so you can focus on celebrating your love."
        },
        {
            name: "Private Bus Service for Events",
            description: "Shraddha Travels offers exclusive private bus rentals for events, providing privacy and personalized service. Whether it's a corporate gathering, a family reunion, or a private celebration, our buses ensure a comfortable and efficient travel experience. Enjoy the flexibility to set your own schedule while we take care of the driving."
        },
        {
            name: "Bus on Hire for Parties in Pune",
            description: "Looking for transportation for a party? Our buses are ideal for birthday parties, anniversaries, and other celebrations. Shraddha Travels offers reliable and affordable options to meet your event’s transportation needs. With our spacious buses, your guests can travel together, creating a fun and festive atmosphere from the start."
        },
        {
            name: "Pune to Bhimashankar Bus Tour Package",
            description: "Explore the scenic beauty of Bhimashankar with our bus tour package. Shraddha Travels ensures a comfortable and stress-free journey to this spiritual and natural getaway. Enjoy breathtaking views along the way, and relax knowing that we handle all the logistics, so you can focus on your experience."
        },
        {
            name: "Pune to Mahabaleshwar Bus Tour Package",
            description: "Enjoy the lush greenery of Mahabaleshwar with our bus tour services. We offer comfortable, reliable transportation to this popular hill station, perfect for group outings or corporate retreats. Experience the stunning landscapes and cool climate while we take care of the journey, ensuring a delightful day trip or weekend getaway."
        },
        {
            name: "Pune to Goa Bus Tour Packages",
            description: "Travel in style from Pune to Goa with Shraddha Travels. Our tour packages offer convenient and comfortable bus services for a smooth journey to this vibrant destination. Enjoy the scenic route and relax with your friends or family as we take you to the beautiful beaches and lively atmosphere of Goa."
        },
        {
            name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pune",
            description: "We provide a variety of bus sizes, including 32, 35, 40, 45, and 50-seaters. No matter your group size, we have the right bus for your event. Each vehicle is equipped with comfortable seating and modern amenities, ensuring your guests travel in comfort."
        },
        {
            name: "Pune to Shirdi Bus Package",
            description: "Make your pilgrimage to Shirdi stress-free with our bus package. Shraddha Travels ensures a comfortable ride for spiritual journeys or group visits. With professional drivers and well-maintained buses, you can focus on your spiritual experience while we take care of the travel details."
        },
        {
            name: "Pune to Ajanta Ellora Bus on Rent",
            description: "Discover the historic wonders of Ajanta and Ellora with our well-maintained buses. Shraddha Travels provides reliable transportation to these renowned World Heritage sites, ensuring a comfortable and enriching experience as you explore the art and history of these magnificent caves."
        },
        {
            name: "Pune to Ashtavinayak Darshan Bus Package",
            description: "For devotees planning a pilgrimage to the Ashtavinayak temples, our comprehensive bus package offers smooth and convenient travel to all eight sacred temples. Enjoy a spiritual journey with comfortable seating and experienced drivers who ensure you arrive at each temple safely and on time."
        },
        {
            name: "Luxury Bus on Rent for Events in Pune",
            description: "For high-profile events, Shraddha Travels offers luxury buses with premium amenities, ensuring a stylish and comfortable travel experience for your guests. Equipped with plush seating, air conditioning, and entertainment systems, our luxury buses provide the perfect atmosphere for your special occasions."
        },
        {
            name: "Tempo Traveller Rent for Events in Pune",
            description: "If you need transportation for a smaller group, our tempo travellers offer a flexible and comfortable solution. Ideal for corporate or private events, they come equipped with modern amenities to ensure a pleasant travel experience. Enjoy the convenience of group travel with our reliable service."
        },
        {
            name: "Mini Bus on Rent for Events in Pune",
            description: "For smaller gatherings, our mini buses are perfect. Shraddha Travels offers comfortable, affordable mini bus options for private events, corporate functions, and more. Our mini buses provide a cozy atmosphere, making them ideal for intimate celebrations."
        },
        {
            name: "Bus on Rent for Events in Pimpri Chinchwad",
            description: "For events in Pimpri Chinchwad, Shraddha Travels offers a range of well-maintained buses to meet your transportation needs. From corporate events to weddings, we provide seamless solutions for all types of events, ensuring your guests travel comfortably and efficiently."
        },
        {
            name: "Bus on Rent For Event in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus rental services for events in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],    
    tableData: [
        ['- Bus on Rent for Wedding Evening in Pune', '- Bus Rental for Wedding Party'],
        ['- Private Bus Service on Rent for Event in Pune', '- Bus on Hire for Party in Pune'],
        ['- Pune to Bhimashankar Bus Tour Package', '- Pune to Mahabaleshwar Bus Tour Package'],
        ['- Pune to Goa Bus Tour Packages', '- 32, 35, 40, 45, 50 Seater Bus on Rent in Pune'],
        ['- Pune to Shirdi Bus Package', '- Pune to Ajanta Ellora Bus on Rent'],
        ['- Pune to Ashtavinayak Darshan Bus Package', '- Luxury Bus on Rent for Event in Pune'],
        ['- Luxury Bus on Rent for Event in Pimpri Chinchwad', '- Luxury Bus on Rent for Event in Pune Hadapsar'],
        ['- Luxury Bus on Rent for Event in Pune Hadapsar Contact Number', '- Tempo Traveller Rent for Events in Pune'],
        ['- Luxury Tempo Traveller on Rent in Pune for Event', '- Mini Bus on Rent for Event in Pune'],
        ['- Bus on Rent for Event in Pimpri Chinchwad', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus on Rent for Event in Pune?",
            WhyChoosedescription: "Choosing Shraddha Travels for your event transportation in Pune ensures a seamless and comfortable experience for your guests. With our dedication to quality and customer satisfaction, you can trust us to handle all your transportation needs with professionalism and care."
        },
        {
            WhyChooseheading: "Extensive Fleet Options:",
            WhyChoosedescription: "We offer a wide range of buses to accommodate all group sizes, from 17-seaters to 52-seaters. Our diverse fleet ensures that no matter the size of your gathering, we have the perfect vehicle to fit your event's specific requirements, making travel convenient and efficient."
        },
        {
            WhyChooseheading: "Modern Amenities:",
            WhyChoosedescription: "Our buses are equipped with premium amenities such as air conditioning, comfortable seating, onboard entertainment systems, and Wi-Fi. These features are designed to provide an enjoyable travel experience, allowing your guests to relax and enjoy the journey to your event."
        },
        {
            WhyChooseheading: "Safety and Comfort First:",
            WhyChoosedescription: "Your safety and comfort are our top priorities. All our buses undergo regular maintenance and comprehensive safety checks. We invest in our fleet to ensure reliable performance and a smooth ride, giving you peace of mind as you transport your guests."
        },
        {
            WhyChooseheading: "Experienced and Professional Drivers:",
            WhyChoosedescription: "Our skilled drivers are highly experienced and trained to provide safe and punctual transportation. They are familiar with Pune’s routes, ensuring timely arrivals and departures. Their professionalism and dedication to customer service guarantee a smooth travel experience for your guests."
        },
        {
            WhyChooseheading: "Flexible and Customizable Packages:",
            WhyChoosedescription: "We understand that every event is unique, which is why we offer flexible and customizable rental packages. Whether you’re planning a wedding, corporate event, or private gathering, we can tailor our services to meet your specific needs and preferences."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We offer competitive pricing without compromising on the quality of service. Our transparent quotes ensure there are no hidden costs, allowing you to effectively plan your event transportation within budget. We strive to provide the best value for your investment."
        },
        {
            WhyChooseheading: "Excellent Customer Support:",
            WhyChoosedescription: "Our customer service team is dedicated to assisting you at every step of the way. From the initial booking process to the execution of your event transportation, we are here to ensure a hassle-free experience. Our responsive support ensures that all your inquiries are addressed promptly."
        },
        {
            WhyChooseheading: "Hygiene and Cleanliness:",
            WhyChoosedescription: "We maintain high standards of cleanliness across our fleet. Our buses are thoroughly cleaned and sanitized before each trip, ensuring a safe and hygienic environment for your guests. We prioritize health and safety, especially in today’s travel climate."
        },
        {
            WhyChooseheading: "Timely and Reliable Service:",
            WhyChoosedescription: "Punctuality is crucial for event transportation, and we pride ourselves on providing timely services. Our commitment to reliability ensures that your guests are transported to and from the venue without delays, allowing your event to proceed smoothly."
        },
        {
            WhyChooseheading: "Positive Customer Feedback:",
            WhyChoosedescription: "Shraddha Travels has earned a strong reputation for delivering excellent service. Our satisfied clients consistently recommend us for our professionalism, quality buses, and reliable drivers. We take pride in the positive feedback we receive, which reflects our commitment to customer satisfaction."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of buses do you offer for event rentals?",
        answer: "We offer a wide variety of buses, including luxury coaches, semi-luxury buses, standard buses, and mini buses. Depending on your group size, you can choose from 17-seaters to 52-seaters, ensuring the perfect fit for your event."
    },
    {
        question: "How far in advance should I book a bus for my event?",
        answer: "We recommend booking your bus as early as possible, preferably 2-4 weeks in advance, to ensure availability for your desired date and specific requirements. Early booking also allows us to customize your transportation according to your needs."
    },
    {
        question: "Can I customize the route for my event transportation?",
        answer: "Yes, we offer flexible routes tailored to your event's needs. Simply inform us of your pickup and drop-off points, and we will plan the best route for your guests, ensuring a smooth and efficient journey."
    },
    {
        question: "Are your buses equipped with air conditioning?",
        answer: "Yes, all our buses are fully air-conditioned to provide a comfortable travel experience for your guests, especially during the summer months. We prioritize your comfort throughout the journey."
    },
    {
        question: "How do you ensure the safety of passengers?",
        answer: "We prioritize safety by conducting regular maintenance checks on all buses. Our drivers are experienced and adhere to strict safety protocols, ensuring a secure journey. Additionally, each bus is equipped with first aid kits for emergencies."
    },
    {
        question: "Do you provide bus rental services for weddings and other special occasions?",
        answer: "Yes, we specialize in providing transportation for weddings, corporate events, parties, and more. Our buses are perfect for ensuring your guests arrive comfortably and on time, allowing you to focus on your event."
    },
    {
        question: "Are there any hidden charges in the rental price?",
        answer: "No, we maintain complete transparency in our pricing. The quote provided includes all charges, and there are no hidden fees, allowing you to budget your event transportation confidently."
    },
    {
        question: "What amenities are available on the buses?",
        answer: "Our buses come equipped with modern amenities such as air conditioning, comfortable seating, onboard entertainment systems, and Wi-Fi (in select buses). We strive to ensure a pleasant journey for all passengers."
    },
    {
        question: "Do you provide one-way and round-trip services?",
        answer: "Yes, we offer both one-way and round-trip transportation services, depending on the needs of your event. Our flexible options cater to your specific travel requirements."
    },
    {
        question: "How do I make a booking with Shraddha Travels?",
        answer: "Booking with Shraddha Travels is easy. You can contact us via phone, email, or visit our website to fill out the inquiry form. Our customer service team will assist you with the booking process and provide all necessary details."
    }
];



const testimonials = [
    {
        name: "Miss. Shreya Patil",
        text: "We hired a bus from Shraddha Travels for our wedding event in Pune, and it was one of the best decisions we made! The bus was spacious, clean, and equipped with all the modern comforts our guests needed. The driver was very professional, arriving on time and ensuring everyone got to the venue safely. The customer service team was extremely helpful in organizing everything smoothly. Thank you, Shraddha Travels, for making transportation worry-free on our special day!"
    },
    {
        name: "Mr. Ajay Porwal",
        text: "We rented a 40-seater bus for our corporate event, and Shraddha Travels delivered exceptional service. The bus was in excellent condition, and the driver was punctual and friendly. All our team members appreciated the comfortable seating and smooth journey. The entire process, from booking to the event day, was hassle-free. We highly recommend Shraddha Travels for any event in Pune!"
    }
];



  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus on Rent For Event in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus on Rent for Events in Pune | Reliable Event Transportation</title>
    <meta name="description" content="Flexible bus rental services for weddings, parties, and various events in Pune." />
    <meta name="keywords" content="Bus on Rent for Event in Pune, Event Bus Rental, Wedding Bus Hire, Luxury Bus Rental, Mini Bus for Events" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus on Rent for Events in Pune | Reliable Event Transportation" />
    <meta property="og:description" content="Affordable bus rental services for weddings, parties, and special events in Pune." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-on-rent-for-event-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/event-bus-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus on Rent for Events",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-on-rent-for-event-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental for Events in Pune",
                    "description": "Flexible bus rental services for weddings, parties, and various events in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-11.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs – Bus on Rent for Event in Pune by Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Busonrentforeventinpune;