import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const SectionCars = () => {
  const cars = [
    {
      id: 1,
      name: 'Tempo Traveller',
      image: '/img/Gallery-03.jpg',
      likes: 74,
      attributes: [5, 2, 4, 'SUV'],
      price: 265,
    },
    {
      id: 2,
      name: 'Urbania',
      image: '/img/Gallery-01.jpg',
      likes: 36,
      attributes: [5, 2, 4, 'Van'],
      price: 244,
    },
    {
      id: 3,
      name: 'Tata Winger',
      image: '/img/Gallery-05.jpg',
      likes: 85,
      attributes: [5, 2, 4, 'Coach'],
      price: 300,
    },
  ];

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    arrows: true,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1440,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 1024,
        settings: { slidesToShow: 3 },
      },
      {
        breakpoint: 992,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 768,
        settings: { slidesToShow: 2 },
      },
      {
        breakpoint: 576,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 425,
        settings: { slidesToShow: 1 },
      },
      {
        breakpoint: 320,
        settings: { slidesToShow: 1 },
      },
    ],
  };

  return (
    <div style={{ overflowX: 'hidden' }}>
      <section id="section-cars" className="section-cars">
        <div className="container">
          <div className="row align-items-center text-center g-0">
            <div className="col-lg-12 mb-4">
              <h2 className='bluecolor'>Our Vehicle Fleet</h2>
            </div>
            <Slider {...settings}>
              {cars.map((car) => (
                <div className="de-item" key={car.id}>
                  <div className="d-img">
                    <img src={car.image} className="img-fluid" alt={car.name} />
                  </div>
                  <div className="d-info">
                    <div className="d-text">
                      <h4>{car.name}</h4>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>

        <style jsx>{`
          .section-cars {
            padding: 40px 0;
            background-color: #f8f9fa;
            
          }

          h2 {
            font-size: 2.5rem;
            margin-bottom: 20px;
            color: #007bff;
          }

          .de-item {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 15px;
            background: white;
            border: 2px solid #661247;
            border-radius: 10px;
            box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s, box-shadow 0.3s;
            max-width: 100%;
            margin: 10px; /* Add margin to separate items */
          }

          .de-item:hover {
            transform: scale(1.05);
            box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
          }

          .d-img img {
            border-radius: 10px 10px 0 0;
            width: 100%;
            height: auto;
            border-bottom: 2px solid #661247; /* Add a border at the bottom of the image */
          }

          .d-info {
            padding: 15px;
            text-align: center;
          }

          .d-text h4 {
            margin: 0;
            font-size: 1.5rem;
            color: #343a40; /* Darker color for text */
          }

          @media (max-width: 1200px) {
            h2 {
              font-size: 2.2rem;
            }
          }

          @media (max-width: 992px) {
            h2 {
              font-size: 2rem;
            }
          }

          @media (max-width: 768px) {
            h2 {
              font-size: 1.8rem;
            }
            .de-item {
              padding: 10px;
            }
          }

          @media (max-width: 576px) {
            h2 {
              font-size: 1.5rem;
            }
          }
        `}</style>
      </section>
    </div>
  );
};

export default SectionCars;














// import React from 'react';
// import Slider from 'react-slick';
// import 'slick-carousel/slick/slick.css';
// import 'slick-carousel/slick/slick-theme.css';

// const SectionCars = () => {
//   const cars = [
//     {
//       id: 1,
//       name: 'Tempo Traveller',
//       image: '/img/Gallery-03.jpg',
//       likes: 74,
//       attributes: [5, 2, 4, 'SUV'],
//       price: 265,
//     },
//     {
//       id: 2,
//       name: 'Urbania',
//       image: '/img/Gallery-01.jpg',
//       likes: 36,
//       attributes: [5, 2, 4, 'Van'],
//       price: 244,
//     },
//     {
//       id: 3,
//       name: 'Tata Winger',
//       image: '/img/Gallery-05.jpg',
//       likes: 85,
//       attributes: [5, 2, 4, 'Coach'],
//       price: 300,
//     },
//   ];

//   const settings = {
//     dots: true,
//     infinite: true,
//     speed: 500,
//     slidesToScroll: 1,
//     arrows: true,
//     autoplay: true,
//     autoplaySpeed: 2000,
//     responsive: [
//       {
//         breakpoint: 1440,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 3,
//         },
//       },
//       {
//         breakpoint: 992,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 768,
//         settings: {
//           slidesToShow: 2,
//         },
//       },
//       {
//         breakpoint: 576,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 425,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//       {
//         breakpoint: 320,
//         settings: {
//           slidesToShow: 1,
//         },
//       },
//     ],
//   };

//   return (
//     <div style={{ overflowX: 'hidden' }}> {/* Prevent horizontal scroll */}
//       <section id="section-cars" className="section-cars">
//         <div className="container">
//           <div className="row align-items-center text-center g-0 ">
//             <div className="col-lg-12 mb-4">
//               <h2 className='bluecolor'>Our Vehicle Fleet</h2>
//             </div>
//             <Slider {...settings}>
//               {cars.map((car) => (
//                 <div className="de-item" key={car.id}>
//                   <div className="d-img">
//                     <img src={car.image} className="img-fluid" alt={car.name} />
//                   </div>
//                   <div className="d-info">
//                     <div className="d-text">
//                       <h4>{car.name}</h4>
//                     </div>
//                   </div>
//                 </div>
//               ))}
//             </Slider>
//           </div>
//         </div>

//         <style jsx>{`
//           .section-cars {
//             padding: 40px 0;
//             background-color: #f8f9fa;
//           }

//           h2 {
//             font-size: 2.5rem;
//             margin-bottom: 10px;
//             color: #007bff;
//           }

//           .de-item {
//             display: flex;
//             flex-direction: column;
//             align-items: center;
//             padding: 15px;
//             background: white;
//             border-radius: 8px;
//             transition: transform 0.3s, box-shadow 0.3s;
//             max-width: 100%; /* Ensure items don't overflow */
//           }

//           .de-item:hover {
//             transform: scale(1.05);
//             box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
//           }

//           .d-img img {
//             border-radius: 8px 8px 0 0;
//             width: 100%;
//             height: auto;
//           }

//           .d-info {
//             padding: 15px;
//             text-align: center;
//           }

//           @media (max-width: 1200px) {
//             h2 {
//               font-size: 2.2rem;
//             }
//           }

//           @media (max-width: 992px) {
//             h2 {
//               font-size: 2rem;
//             }
//           }

//           @media (max-width: 768px) {
//             h2 {
//               font-size: 1.8rem;
//             }
//             .de-item {
//               padding: 10px;
//             }
//           }

//           @media (max-width: 576px) {
//             h2 {
//               font-size: 1.5rem;
//             }
//           }
//         `}</style>
//       </section>
//     </div>
//   );
// };

// export default SectionCars;
