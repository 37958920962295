
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seater25onrent() {



  const cardData =
  {
    keyword: 'Shraddha Travels: 25 Seater Tempo Traveller on Rent in Pune',
    heading: '25 Seater Tempo Traveller on Rent in Pune',
    headingDescription: 'When you need a spacious and comfortable option for group travel in Pune, the 25-seater Tempo Traveller from Shraddha Travels is the ideal choice. Perfect for corporate events, family gatherings, school excursions, or any large group outing, our 25-seater Tempo Travellers offer a blend of luxury and practicality. Here’s why our 25-seater Tempo Travellers stand out and what you can expect from our rental service:',
    top: 'Top Reasons to Rent a 25-Seater Tempo Traveller from Shraddha Travels in Pune',

    topPlaces: [
        {
          title: "1. Tailored Comfort",
          description: "Our 25-seater tempo travellers are designed with comfort in mind, featuring ergonomic seating that supports long journeys. Passengers can stretch out and enjoy the ride, making every trip feel less like a chore and more like an adventure."
        },
        {
          title: "2. Eco-Friendly Travel",
          description: "By choosing a tempo traveller for your group, you’re making an environmentally conscious decision. Consolidating your travel reduces carbon emissions, allowing you to enjoy your trip while being kind to the planet."
        },
        {
          title: "3. Local Expertise",
          description: "Our drivers are not just skilled at navigating routes; they are also knowledgeable about local attractions and dining options. They can provide insider tips and recommendations to enrich your travel experience."
        },
        {
          title: "4. Themed Trips",
          description: "We can help you create a themed journey tailored to your interests—be it a cultural exploration, a culinary adventure, or a scenic tour. Custom itineraries make your trip unique and memorable."
        },
        {
          title: "5. Entertainment On-the-Go",
          description: "Our tempo travellers are equipped with advanced audio-visual systems. You can curate playlists or even host karaoke sessions, turning travel time into fun bonding moments for your group."
        },
        {
          title: "6. Seamless Connectivity",
          description: "Equipped with charging ports and Wi-Fi options, you can stay connected while on the road. Whether it’s for work or sharing travel moments on social media, we’ve got you covered."
        },
        {
          title: "7. Safety Innovations",
          description: "Our vehicles come with the latest safety features, including GPS tracking and emergency response systems, ensuring peace of mind throughout your journey."
        },
        {
          title: "8. Personalized Services",
          description: "We offer tailored services such as onboard refreshments, customized playlists, or even themed decorations for special occasions, making your trip one-of-a-kind."
        },
        {
          title: "9. Community Building",
          description: "Traveling together fosters a sense of community among passengers. Whether it’s team-building for corporate outings or family bonding, shared experiences create lasting memories."
        },
        {
          title: "10. Hassle-Free Logistics",
          description: "With our all-inclusive service, you don’t have to worry about planning every detail. We handle everything from route planning to rest stops, allowing you to focus on enjoying the journey."
        }
      ],
      services: [
        {
          name: "Tempo Traveller 25 Seater Rent Per Km in Pune",
          description: "Looking for a 25-seater Tempo Traveller rental in Pune on a per-kilometer basis? At Shraddha Travels, we offer flexible rental options that allow you to pay only for the distance you travel. This model is ideal for those planning longer journeys or outstation trips, providing you with control over your budget while ensuring comfort and convenience throughout your trip."
        },
        {
          name: "Tempo Traveller 25 Seater AC Bus on Rent Pune",
          description: "Experience the luxury of air-conditioned travel with our 25-seater AC Tempo Travellers in Pune. Perfect for group outings, corporate events, or family trips, our AC Tempo Travellers are equipped with modern amenities to keep you cool and comfortable, regardless of the weather outside."
        },
        {
          name: "Tempo Traveller 26 Seater Non-AC Bus on Rent in Pune",
          description: "For those seeking a more economical travel option, we offer 26-seater non-AC Tempo Travellers in Pune. These vehicles are ideal for budget-conscious groups who still want a spacious and comfortable ride."
        },
        {
          name: "26 Seater Bus on Rent for Wedding in Pune",
          description: "Planning a wedding and need a large bus for your guests? Our 26-seater buses are perfect for wedding transportation in Pune, ensuring everyone arrives in style and comfort."
        },
        {
          name: "25 Bus on Rent Pimpri Chinchwad Pune",
          description: "If you’re in Pimpri Chinchwad and need a 25-seater bus, Shraddha Travels has you covered with convenient and reliable rental services."
        },
        {
          name: "25 Bus on Rent in Pune for Picnics",
          description: "Planning a picnic with a large group in Pune? Our 25-seater buses provide ample space and comfortable seating for a stress-free outing."
        },
        {
          name: "Pune to Goa 25 Seater Bus Fare",
          description: "Heading from Pune to Goa and need a 25-seater bus? Our competitive pricing ensures excellent value for your money."
        },
        {
          name: "26 Seater Tempo Traveller on Rent in Pune",
          description: "If you require a 26-seater Tempo Traveller for your travels in Pune, we offer well-maintained vehicles equipped with modern amenities."
        },
        {
          name: "25 Seater Bus on Rent in Hadapsar Pune",
          description: "For those located in Hadapsar, we offer 25-seater bus rentals tailored to your needs, ensuring quality and reliability."
        },
        {
          name: "Pune to Shirdi 25 Seater Bus on Rent",
          description: "Traveling from Pune to Shirdi with a large group? Our 25-seater buses are the perfect solution for comfortable and efficient group transportation."
        },
        {
          name: "Pune Airport to Shirdi 25 Seater Tempo Traveller on Rent",
          description: "Arriving at Pune Airport and need a 25-seater Tempo Traveller to take you to Shirdi? We provide convenient airport transfer services."
        },
        {
          name: "Pune to Bhimashankar 25 Seater Tempo Traveller",
          description: "Planning a trip from Pune to Bhimashankar? Our 25-seater Tempo Travellers are ideal for this journey."
        },
        {
          name: "Pune to 5 Jyotirlinga Darshan Tempo Traveller on Rent",
          description: "Embarking on a pilgrimage to visit the 5 Jyotirlingas? Our 25-seater Tempo Travellers are perfect for this spiritual journey."
        },
        {
          name: "25 Seater Tempo Traveller on Rent in Pimpri Chinchwad",
          description: "If you are in Pimpri Chinchwad and need a 25-seater Tempo Traveller, we offer reliable rental services."
        },
        {
          name: "Tempo Traveller on Rent in Hinjewadi",
          description: "Located in Hinjewadi and need a Tempo Traveller for your group? We provide excellent rental options."
        },
        {
          name: "Tempo Traveller on Rent in Kharadi",
          description: "For those in Kharadi, we offer top-notch Tempo Traveller rental services, perfect for various travel needs."
        },
        {
          name: "Luxury Tempo Traveller on Rent in Kothrud Pune",
          description: "Looking for a touch of luxury in your travels? Our luxury Tempo Travellers in Kothrud Pune offer premium comfort and style."
        },
        {
          name: "25 Seater Tempo Traveller on Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient 25 Seater Tempo Traveller on Rent in Pune."
        }
      ],      
      tableData: [
        ['- Tempo Traveller 25 Seater Rent Per Km in Pune', '- Tempo Traveller 25 Seater AC Bus on Rent Pune'],
        ['- Tempo Traveller 26 Seater Non AC Bus on Rent in Pune', '- 26 Bus on Rent for Wedding in Pune'],
        ['- 25 Bus on Rent Pimpri Chinchwad Pune', '- 25 Bus on Rent in Pune for Picnics'],
        ['- Pune to Goa 25 Seater Bus Fare', '- 26 Seater Tempo Traveller on Rent in Pune'],
        ['- 25 Seater Bus on Rent in Hadapsar Pune', '- Pune to Shirdi 25 Seater Bus on Rent'],
        ['- Pune Airport to Shirdi 25 Seater Tempo Traveller on Rent', '- Pune to Bhimashankar 25 Seater Tempo Traveller'],
        ['- Pune to 5 Jyotirlinga Darshan Tempo Traveller on Rent', '- 25 Seater Tempo Traveller on Rent in Pimpri Chinchwad'],
        ['- Tempo Traveller on Rent in Hinjewadi', '- Tempo Traveller on Rent in Kharadi'],
        ['- Luxury Tempo Traveller on Rent in Kothrud Pune', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for 25-Seater Tempo Traveller on Rent in Pune?",
          WhyChoosedescription: "When you choose Shraddha Travels for your 25-seater Tempo Traveller rental needs in Pune, you're opting for a blend of comfort, reliability, and exceptional service. Here’s why we are the top choice for your group travel:"
        },
        {
          WhyChooseheading: "Spacious and Comfortable Vehicles:",
          WhyChoosedescription: "Our 25-seater Tempo Travellers are designed to provide maximum comfort with ample seating space and generous legroom. Perfect for long journeys or group outings, our vehicles ensure a pleasant travel experience."
        },
        {
          WhyChooseheading: "Modern Amenities:",
          WhyChoosedescription: "Enjoy modern features such as air conditioning, high-quality audio systems, and plush seating. Our Tempo Travellers are equipped with the latest amenities to enhance your travel comfort."
        },
        {
          WhyChooseheading: "Well-Maintained Fleet:",
          WhyChoosedescription: "We take pride in maintaining our fleet to the highest standards. Each vehicle undergoes regular inspections and servicing to ensure reliability and safety for every journey."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our professional drivers are experienced and skilled in navigating various routes. They are dedicated to providing a smooth and safe travel experience, adhering to the highest standards of professionalism."
        },
        {
          WhyChooseheading: "Flexible Rental Options:",
          WhyChoosedescription: "We offer customizable rental packages tailored to your needs. Whether you need a Tempo Traveller for a day trip, a long journey, or a special event, we provide flexible solutions to fit your schedule and budget."
        },
        {
          WhyChooseheading: "Competitive Pricing:",
          WhyChoosedescription: "Our pricing is transparent and competitive, with no hidden fees. We provide clear quotes and work to offer the best value for your money without compromising on quality."
        },
        {
          WhyChooseheading: "Convenient Booking Process:",
          WhyChoosedescription: "Booking with us is easy and straightforward. You can reserve your 25-seater Tempo Traveller through our user-friendly website or by contacting our customer support team."
        },
        {
          WhyChooseheading: "Reliable Service:",
          WhyChoosedescription: "We are committed to punctuality and reliability. Our vehicles arrive on time, and we ensure that your travel plans proceed smoothly, allowing you to focus on your trip."
        },
        {
          WhyChooseheading: "Excellent Customer Support:",
          WhyChoosedescription: "Our dedicated customer support team is available to assist you with any inquiries or issues. We are here to provide personalized service and ensure your satisfaction from booking to the end of your journey."
        },
        {
          WhyChooseheading: "Comprehensive Coverage:",
          WhyChoosedescription: "Whether you need a Tempo Traveller for local excursions within Pune or for outstation trips, we offer comprehensive coverage to meet all your travel requirements."
        }
      ]
      
  }


  const faqData = [
    {
      question: "How can I book a 25-seater Tempo Traveller?",
      answer: "You can book a 25-seater Tempo Traveller by calling us at +91 8087847191 or through our website. Our booking process is simple, and our team will guide you through the steps."
    },
    {
      question: "What amenities are included in the 25-seater Tempo Traveller?",
      answer: "Our 25-seater Tempo Travellers come equipped with air conditioning, comfortable seating, audio systems, and other modern amenities. Additional features can be requested based on your needs."
    },
    {
      question: "Are there any additional charges for long-distance travel?",
      answer: "Additional charges may apply for long-distance or outstation travel. These charges depend on factors such as distance, duration, and specific requirements. We provide a detailed quote before you confirm your booking."
    },
    {
      question: "Can I choose between AC and non-AC options?",
      answer: "Yes, we offer both AC and non-AC Tempo Travellers. You can specify your preference when booking to ensure your comfort during the trip."
    },
    {
      question: "What if I need to cancel or modify my booking?",
      answer: "If you need to cancel or modify your booking, please contact our customer support team as soon as possible. We will inform you of any cancellation fees and assist you with changes."
    },
    {
      question: "How are your Tempo Travellers maintained?",
      answer: "Our Tempo Travellers undergo regular maintenance and inspections to ensure they are in excellent condition. We prioritize safety and reliability to provide you with a trouble-free travel experience."
    },
    {
      question: "Are your Tempo Travellers suitable for both local and outstation travel?",
      answer: "Yes, our 25-seater Tempo Travellers are suitable for both local and outstation travel. We offer flexible rental options to accommodate various travel needs."
    },
    {
      question: "What should I do if I encounter any issues during the trip?",
      answer: "If you encounter any issues during your trip, please contact our customer support team immediately. We are available to resolve any concerns and ensure your journey remains smooth."
    },
    {
      question: "How do I get a quote for renting a 25-seater Tempo Traveller?",
      answer: "To get a quote, contact us with details about your trip, including the destination, rental duration, and any special requests. We will provide you with a clear and detailed quote based on your requirements."
    },
    {
      question: "Can I request special features or additional amenities?",
      answer: "Yes, you can request special features or additional amenities when booking. We will do our best to accommodate your requests and enhance your travel experience."
    }
  ];
  


  const testimonials = [
    {
      name: "Mr. Anil Patil",
      text: "We recently hired a 25-seater Tempo Traveller from Shraddha Travels for our family reunion trip from Pune to Lonavala. The vehicle was exceptionally clean, spacious, and well-maintained. The comfort and amenities provided made our journey enjoyable and stress-free. The driver was professional and navigated the routes efficiently. I highly recommend Shraddha Travels for their top-notch service and reliability. We will definitely choose them for our future trips!"
    },
    {
      name: "Ms. Meera Shah",
      text: "For our corporate offsite event, we needed a 25-seater Tempo Traveller for a trip from Pune to Mahabaleshwar. Shraddha Travels exceeded our expectations with their service. The bus was equipped with all the necessary amenities, including comfortable seating and air conditioning, which made the long journey quite pleasant. The booking process was smooth, and the driver was punctual and courteous. Thanks to Shraddha Travels, our team had a fantastic travel experience. We will certainly use their services again!"
    }
  ];
  


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 25 Seater Tempo Traveller on Rent in Pune Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 25 Seater Tempo Traveller on Rent in Pune | Affordable Group Travel</title>
    <meta name="description" content="Rent a 25 Seater Tempo Traveller in Pune for picnics, weddings, and outstation trips. Enjoy comfortable travel at competitive rates!" />
    <meta name="keywords" content="25 seater tempo traveller rent per km in Pune, 25 seater AC bus on rent Pune, Tempo Traveller on rent for weddings in Pune, Pune to Goa 25 Seater Bus fare, 25 Seater bus on rent in Hadapsar Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 25 Seater Tempo Traveller on Rent in Pune | Affordable Group Travel" />
    <meta property="og:description" content="Explore comfortable 25 Seater Tempo Traveller rentals in Pune for various occasions including weddings and picnics." />
    <meta property="og:url" content="https://www.shraddhatravels.com/25-seater-tempo-traveller-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/25-seater-tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "25 Seater Tempo Traveller Rental",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/25-seater-tempo-traveller-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "25 Seater Tempo Traveller",
                    "description": "Comfortable 25 Seater Tempo Traveller for weddings, picnics, and outstation trips."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-09.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs About 25-Seater Tempo Traveller in Pune for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seater25onrent;