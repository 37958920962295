import React from 'react';
import '../css/UniqueSection.css';

const UniqueSection = () => {
    return (
        <section className="unique-section" style={{backgroundColor:'#57B5E1'}}>
            <div className="container">
                <div className="row">
                    <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                        <div className="info-item py-5">
                            <strong className="info-title">
                                <i className="fas fa-globe px-2"></i>
                                Tailored Travel Experiences
                            </strong>
                            <p className="info-description">
                                Discover exclusive travel experiences crafted to reflect your personal interests and dreams.
                            </p>
                        </div>
                        <div className="info-item">
                            <strong className="info-title">
                                <i className="fas fa-user-check px-2"></i>
                                Dedicated Support Team
                            </strong>
                            <p className="info-description">
                                Our expert team is available around the clock to assist you at any stage of your journey.
                            </p>
                        </div>
                    </div>

                    <div className="col-12 col-md-4 d-flex justify-content-center">
                        <img 
                            src="img/urbania.webp" 
                            alt="Unique Travel Experience" 
                            className="experience-image img-fluid" 
                        />
                    </div>

                    <div className="col-12 col-md-4 d-flex flex-column align-items-center">
                        <div className="info-item py-5">
                            <strong className="info-title ">
                                <i className="fas fa-thumbs-up px-2"></i>
                                Value for Your Money
                            </strong>
                            <p className="info-description">
                                Enjoy top-tier services without the hefty price tag, maximizing your travel budget.
                            </p>
                        </div>
                        <div className="info-item">
                            <strong className="info-title">
                                <i className="fas fa-shuttle-van px-2"></i>
                                Complimentary Transfers
                            </strong>
                            <p className="info-description">
                                Benefit from free airport transfers for a seamless travel experience from start to finish.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default UniqueSection;
