import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/privacy-policy.css';


const PrivacyPolicy = () => {
  return (
    <div>
      <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

      
      <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Privacy Policy</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>

      <section className="privacy-policy">
        <div className="container px-0">
          <div className="row g-0">
            <div className="col-12 col-md-7">
              <h3 className="title">Privacy Policy</h3>

              <p>
                At Shraddha Travels , we are committed to protecting your privacy and safeguarding your personal information. This Privacy Policy outlines how we collect, use, disclose, and protect your information when you use our services.
              </p>

              <div className="section">
                <p className="highlight darkcolor">Information We Collect:</p>
                <p className="highlight darkcolor">1. Personal Information:</p>
                <p>
                  When you book a service with us, we may collect personal information such as your name, contact details, payment information, and travel preferences.
                </p>

                <p className="highlight darkcolor">2. Usage Information:</p>
                <p>
                  We may collect information about how you interact with our website and mobile app, including IP address, device information, and pages visited.
                </p>

                <p className="highlight darkcolor">3. Location Information:</p>
                <p>
                  With your consent, we may collect your location information to provide location-based services and improve our offerings.
                </p>
              </div>

              <div className="section">
                <p className="highlight darkcolor">How We Use Your Information:</p>
                <p className="highlight darkcolor">1. To Provide Services:</p>
                <p>
                  We use your information to process bookings, manage reservations, and provide transportation services.
                </p>

                <p className="highlight darkcolor">2. To Improve Services:</p>
                <p>
                  We analyze user behavior and feedback to enhance user experience and develop new features.
                </p>

                <p className="highlight darkcolor">3. For Communication:</p>
                <p>
                  We may use your contact information to send booking confirmations, updates, and promotional offers.
                </p>

                <p className="highlight darkcolor">4. For Marketing:</p>
                <p>
                  With your consent, we may send marketing communications about our services and special offers.
                </p>
              </div>

              <div className="section">
                <p className="highlight darkcolor">Information Sharing and Disclosure:</p>
                <p className="highlight darkcolor">1. Third-Party Service Providers:</p>
                <p>
                  We may share your information with trusted third-party service providers who assist us in providing our services.
                </p>

                <p className="highlight darkcolor">2. Legal Compliance:</p>
                <p>
                  We may disclose your information as required by law or to protect our rights or safety, or that of others.
                </p>
              </div>

              <div className="section">
                <p className="highlight darkcolor">Data Security:</p>
                <p>
                  We employ industry-standard security measures to protect your information from unauthorized access or disclosure. However, no method of transmission is 100% secure.
                </p>

                <p className="highlight darkcolor">Your Rights:</p>
                <p>
                  You have the right to access, update, or delete your personal information. You may also opt-out of marketing communications at any time.
                </p>

                <p className="highlight darkcolor">Changes to this Privacy Policy:</p>
                <p>
                  We reserve the right to update this Privacy Policy at any time. Changes will be effective immediately upon posting on our website.
                </p>
              </div>
            </div>

            <div className='col-12 col-md-5'>
              <div className="image-container">
                <img src="/img/privacy.jpg" className="bg-cover" alt="Privacy Policy" />
              </div>
            </div>
          </div>

          <div className="contact-info">
            <h2>Contact Us</h2>
            <p>For any queries regarding our privacy policy, feel free to reach out:</p>
            <ul>
              <li><b>Phone:</b> <a href="tel:+918087847191">+91 8087847191</a></li>
              <li><b>Email:</b> <a href="mailto:booking@shraddhacabs.com">booking@shraddhacabs.com</a></li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;
