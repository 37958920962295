import React from 'react';
// import './ChooseUsSection.css'; 

const ChooseUsSection = () => {
    return (
        
    <div>
        <section className="chooseus-section pt_100 pb_65 centred py-5">
            <div className="auto-container">
                <div className="sec-title mb_50">
                    <span className="sub-title">We are best</span>
                    <h2 className='text-white'>Why Choose Shraddha Travels</h2>
                </div>
                <div className="row clearfix">
                    {[
                        {
                            icon: 'icon-15',
                            title: 'Flexible Payment',
                            description: 'We are dedicated to offering the exceptional value for your travel investment.',
                            delay: '00ms',
                        },
                        {
                            icon: 'icon-16',
                            title: 'No Change Fees',
                            description: 'Your journey with us is about relaxation, enjoyment, and more discovery.',
                            delay: '200ms',
                        },
                        {
                            icon: 'icon-17',
                            title: 'Flex Deposit',
                            description: 'Ready to start your next adventure? Contact us today to begin planning your dream.',
                            delay: '400ms',
                        },
                        {
                            icon: 'icon-18',
                            title: 'Pay Securely',
                            description: 'Feel free to adapt and customize this text to fit your travel agency with secure payment.',
                            delay: '600ms',
                        },
                    ].map((item, index) => (
                        <div className="col-lg-3 col-md-6 col-sm-12 chooseus-block" key={index}>
                            <div
                                className={`chooseus-block-one wow fadeInUp animated`}
                                data-wow-delay={item.delay}
                                data-wow-duration="1500ms"
                            >
                                <div className="inner-box">
                                    <div className="icon-box"><i className={item.icon}></i></div>
                                    <h3><a href="">{item.title}</a></h3>
                                    <p>{item.description}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>

    </div>
    
    );
};

export default ChooseUsSection;
