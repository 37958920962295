
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seater17onrent() {



  const cardData =
  {
    keyword: '17-Seater Bus on Rent in Pune',
    heading: '17-Seater Bus on Rent in Pune: Shraddha Travels',
    headingDescription: 'Are you planning a group outing or a corporate event in Pune? Look no further! At Shraddha Travels, we offer a reliable 17-Seater Bus on Rent in Pune. Our spacious and comfortable buses are ideal for transporting medium-sized groups for various occasions, including family trips, picnics, and corporate functions. With a focus on customer satisfaction, we ensure a smooth and enjoyable travel experience.',

    top: 'Top Reasons to Rent a 17-Seater Bus from Shraddha Travels in Pune',

    topPlaces: [
    {
        title: "Ideal for Larger Groups",
        description: "Our 17-seater bus is perfect for family trips, corporate outings, school excursions, and group travels. It ensures that everyone can travel together comfortably, fostering camaraderie and making group experiences more enjoyable."
    },
    {
        title: "Spacious and Comfortable",
        description: "Designed with passenger comfort in mind, our buses offer ample legroom and wide seats. Whether you're on a short trip or a long journey, the comfort provided makes for a more pleasant travel experience, allowing everyone to relax and enjoy the ride."
    },
    {
        title: "Cost-Effective Group Travel",
        description: "Renting a single bus is often more economical than arranging multiple vehicles. This not only saves on fuel and parking costs but also simplifies logistics, making it easier to manage your group's travel plans without the hassle of coordinating multiple cars."
    },
    {
        title: "Flexible Itineraries",
        description: "We understand that every group has unique needs. Our customizable routes and schedules allow you to tailor your travel plans according to your preferences, giving you the freedom to explore at your own pace and make spontaneous stops along the way."
    },
    {
        title: "Well-Maintained Fleet",
        description: "Safety and reliability are our top priorities. Our buses undergo regular maintenance checks and are inspected for safety features, ensuring that you have a reliable vehicle for your journey. This commitment to quality gives you peace of mind during your travels."
    },
    {
        title: "Experienced Drivers",
        description: "Our professional drivers are not only skilled but also familiar with local routes and traffic conditions. Their expertise prioritizes your safety and comfort, allowing you to sit back and enjoy the scenery without the stress of navigation."
    },
    {
        title: "Entertainment Options",
        description: "Long trips can sometimes feel tedious, but many of our buses are equipped with entertainment systems. Whether it’s music, movies, or games, these features help keep passengers engaged and make the journey more enjoyable for everyone."
    },
    {
        title: "24/7 Customer Support",
        description: "Our dedicated support team is available around the clock to assist with any questions or booking changes. Whether you need assistance before your trip or support during your journey, we are always just a call away to ensure a smooth experience."
    },
    {
        title: "Safety Standards",
        description: "We prioritize passenger safety by adhering to all safety regulations. Our buses are equipped with essential safety features, including seat belts and first aid kits, providing additional reassurance that your safety is our primary concern."
    },
    {
        title: "Positive Reputation",
        description: "With numerous satisfied customers, Shraddha Travels has built a reputation for excellent service and reliability. Our commitment to providing high-quality travel experiences has made us a trusted choice for group travel, reflected in our positive reviews and customer loyalty."
    }
],

services: [
    {
        name: "17-Seater Bus on Rent in Pune",
        description: "Looking for a spacious and comfortable 17-seater bus in Pune? Shraddha Travels offers reliable rentals perfect for family trips, corporate events, and group outings. Our buses are designed to accommodate your group comfortably, ensuring a pleasant travel experience. Enjoy ample legroom and modern amenities as you travel together."
    },
    {
        name: "17-Seater Bus on Rent Price",
        description: "Get competitive rates for our 17-seater bus rentals in Pune. We pride ourselves on transparent pricing with no hidden charges. Contact us for a detailed quote tailored to your specific travel needs, allowing you to budget effectively for your trip."
    },
    {
        name: "17-Seater Bus Rate Per Km in Pune",
        description: "Our 17-seater bus rate per kilometer in Pune typically ranges from ₹30 to ₹45, inclusive of driver charges and fuel costs. This comprehensive pricing ensures you have a complete travel package, making it easy to plan your journey without unexpected expenses."
    },
    {
        name: "17-Seater AC Tempo Traveller on Rent in Pune",
        description: "Experience comfort on long journeys with our 17-seater AC Tempo Traveller on rent in Pune. Perfect for extended trips, our air-conditioned buses provide a refreshing atmosphere, making your travel enjoyable regardless of the distance."
    },
    {
        name: "17-Seater Non-AC Tempo Traveller on Rent in Pune",
        description: "For budget-conscious travelers, our 17-seater Non-AC Tempo Traveller offers ample space and comfort at an economical rate. Ideal for local outings or short trips, these vehicles provide reliable transportation without breaking the bank."
    },
    {
        name: "17-Seater Tempo Traveller for Mahabaleshwar from Pune",
        description: "Planning a scenic trip to Mahabaleshwar? Our 17-seater Tempo Traveller is perfect for your journey, providing comfort and space for a delightful travel experience. Enjoy breathtaking views and make lasting memories with your group."
    },
    {
        name: "17-Seater Tempo Traveller for Pune to Goa",
        description: "Travel in style with our 17-seater Tempo Traveller for Pune to Goa. Ideal for family vacations or friends' trips, these vehicles come equipped with ample luggage space, ensuring you have everything you need for your beach holiday."
    },
    {
        name: "17-Seater Tempo Traveller Pune to Nashik",
        description: "Book our 17-seater Tempo Traveller for a smooth and enjoyable ride from Pune to Nashik. Whether visiting wineries or spiritual sites, our vehicles offer comfort and reliability for your journey."
    },
    {
        name: "17-Seater Tempo Traveller Pune to Mumbai Rent",
        description: "Get dependable transportation with our 17-seater Tempo Traveller for rent from Pune to Mumbai. This service is perfect for both leisure and business travel, ensuring you arrive at your destination comfortably and on time."
    },
    {
        name: "17-Seater Tempo Traveller on Rent in Hadapsar",
        description: "Conveniently located in Hadapsar, Shraddha Travels offers 17-seater Tempo Travellers for easy access and reliable transportation. Perfect for group outings and family events, our vehicles ensure a comfortable journey for all."
    },
    {
        name: "17-Seater Tempo Traveller on Rent in Pimpri Chinchwad",
        description: "Our 17-seater Tempo Traveller on rent in Pimpri Chinchwad provides reliable transportation for group outings. Experience comfort and spacious interiors, making every trip enjoyable for everyone in your party."
    },
    {
        name: "17-Seater Tempo Traveller Hire in Viman Nagar",
        description: "Choose our 17-seater Tempo Traveller hire in Viman Nagar for your group travel needs. With a focus on comfort and affordability, our service is designed to cater to all your travel requirements seamlessly."
    },
    {
        name: "Pune to Shirdi 17-Seater Bus Booking",
        description: "Simplify your pilgrimage with our Pune to Shirdi 17-seater bus booking. Offering a comfortable ride to the holy site, our service ensures a smooth journey, allowing you to focus on your spiritual experience."
    },
    {
        name: "17-Seater Bus on Rent Pune Contact Number",
        description: "For prompt and efficient service, contact Shraddha Travels at +91 8087847191 for your 17-seater bus rental in Pune. Our dedicated team is ready to assist you, ensuring a smooth and enjoyable ride for all customers. Experience the convenience and reliability of our service today!"
    }
],

tableData: [
    ['- 17-Seater Bus on Rent Pune', '- 17-Seater Bus on Rent in Pune Price'],
    ['- 17-Seater Bus Rate Per Km in Pune', '- 17-Seater AC Tempo Traveller on Rent in Pune'],
    ['- 17-Seater Non AC Tempo Traveller on Rent in Pune', '- 17-Seater Tempo Traveller for Mahabaleshwar from Pune'],
    ['- 17-Seater Tempo Traveller for Pune to Goa', '- 17-Seater Tempo Traveller Pune to Nashik'],
    ['- 17-Seater Tempo Traveller Pune to Mumbai Rent', '- 17-Seater Tempo Traveller on Rent in Hadapsar'],
    ['- 17-Seater Tempo Traveller on Rent in Pimpri Chinchwad', '- 17-Seater Tempo Traveller Hire in Viman Nagar'],
    ['- Pune to Shirdi 17-Seater Bus Booking', '']
],

whychoose: [
    {
        WhyChooseheading: "Spacious Comfort:",
        WhyChoosedescription: "Our 17-seater buses are meticulously designed to provide ample space for passengers, ensuring a comfortable ride for everyone. With generous legroom and ergonomically designed seats, your group can relax and stretch out during long journeys, making travel a pleasant experience."
    },
    {
        WhyChooseheading: "Competitive Pricing:",
        WhyChoosedescription: "We offer affordable rates tailored to fit groups of all sizes. Renting a bus can significantly cut down costs compared to arranging multiple cars, saving you money on fuel and parking fees. Our transparent pricing means no hidden charges, ensuring you get great value for your investment."
    },
    {
        WhyChooseheading: "Professional Drivers:",
        WhyChoosedescription: "Our experienced drivers are highly trained and not only skilled at handling the vehicle but also possess extensive knowledge of local and outstation routes. They prioritize your safety and comfort, making stops when needed and ensuring a smooth journey from start to finish."
    },
    {
        WhyChooseheading: "Flexible Booking Options:",
        WhyChoosedescription: "We recognize that every trip has its own unique requirements. That’s why we offer customizable rental options to suit your specific needs, whether it’s a short day trip, a weekend getaway, or an extended journey across multiple destinations. You can modify your itinerary as you go!"
    },
    {
        WhyChooseheading: "Well-Maintained Vehicles:",
        WhyChoosedescription: "Safety is our top priority. Our fleet of 17-seater buses undergoes regular maintenance and thorough safety inspections to ensure they are in top condition. This commitment to upkeep means you can travel with confidence, knowing your vehicle is reliable and safe."
    },
    {
        WhyChooseheading: "Entertainment Features:",
        WhyChoosedescription: "To enhance your journey, many of our buses are equipped with modern entertainment systems, including audio-visual setups. Passengers can enjoy movies, music, or games, making long rides more enjoyable and engaging for everyone on board."
    },
    {
        WhyChooseheading: "24/7 Customer Support:",
        WhyChoosedescription: "Our dedicated support team is available around the clock to assist you with any inquiries, booking changes, or concerns that may arise during your trip. Whether it’s a last-minute adjustment or a question about your itinerary, we’re here to help."
    },
    {
        WhyChooseheading: "Safety First:",
        WhyChoosedescription: "We adhere to stringent safety protocols, including regular vehicle inspections and comprehensive driver training, to ensure a secure travel experience. Your safety and comfort are our primary concerns, and we take every measure to protect our passengers."
    },
    {
        WhyChooseheading: "Positive Customer Feedback:",
        WhyChoosedescription: "With numerous satisfied customers who praise our reliability and quality of service, we have built a strong reputation in the industry. Many of our clients return to us for their travel needs, and we value their trust as a testament to our commitment to excellence."
    },
    {
        WhyChooseheading: "Convenient Booking Process:",
        WhyChoosedescription: "Our user-friendly online booking system simplifies the reservation process. You can book your 17-seater bus in just a few clicks, with the option to customize your journey. Plus, our straightforward payment process makes it easy to secure your booking quickly and efficiently."
    }
]

  }



  const faqData = [
    {
        question: "What is the seating capacity of the bus?",
        answer: "Our 17-seater bus can comfortably accommodate 17 passengers, along with their luggage. It’s perfect for group outings, family trips, or corporate events."
    },
    {
        question: "Are there any hidden charges?",
        answer: "We pride ourselves on transparency. Our pricing includes all standard charges, and any additional fees (such as for extra mileage or overtime) will be clearly communicated before you finalize your booking."
    },
    {
        question: "Can we customize our travel itinerary?",
        answer: "Absolutely! We encourage you to tailor your itinerary to fit your group’s preferences. Whether you want to make additional stops or change your route, we’re here to help."
    },
    {
        question: "Are the drivers experienced?",
        answer: "Yes, all our drivers are professional and well-trained, with extensive knowledge of local routes. They are committed to providing you with a safe and pleasant journey."
    },
    {
        question: "What amenities are included in the bus?",
        answer: "Our 17-seater buses come equipped with comfortable seating, air conditioning, and many feature entertainment options to keep passengers entertained on longer trips."
    },
    {
        question: "How do I book a bus?",
        answer: "You can easily book a bus through our website or by contacting our customer support team. We’re here to assist you with any questions during the booking process."
    },
    {
        question: "What is your cancellation policy?",
        answer: "We understand that plans can change. Our cancellation policy is flexible, allowing you to make changes or cancellations with minimal hassle. Please check our website for specific details."
    },
    {
        question: "Is the bus well-maintained?",
        answer: "Yes, we prioritize the upkeep of our fleet. All our buses undergo regular maintenance and thorough inspections to ensure they are in top condition for your journey."
    },
    {
        question: "Can we take the bus outside Pune?",
        answer: "Yes, you can use the bus for outstation trips. Additional charges may apply depending on the distance and duration of your journey, and we’ll discuss these with you at the time of booking."
    },
    {
        question: "What should we do in case of an emergency during the trip?",
        answer: "Our drivers are trained to handle emergencies effectively. In case of any issues, you can reach out to our support team 24/7 for immediate assistance."
    }
];


const testimonials = [
    {
        name: "Mr. Vinod Adate",
        text: "We hired a 17-Seater Bus from Shraddha Travels for our family trip to Mahabaleshwar. The bus was clean, comfortable, and our driver was punctual and professional. Highly recommend!"
    },
    {
        name: "Ms. Anjali Deshmukh",
        text: "Our company used Shraddha Travels for a corporate outing. The 17-Seater Bus was perfect for our team. The service was excellent, and the pricing was very reasonable!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 17 Seater Bus on Rent Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 17 Seater Bus on Rent in Pune | Affordable Rentals</title>
    <meta name="description" content="Rent a 17 Seater Bus in Pune for various trips including local and outstation travel. Enjoy competitive pricing and excellent service!" />
    <meta name="keywords" content="17 Seater Bus on Rent Pune, 17 Seater Bus on Rent in Pune Price, 17 Seater Bus Rate Per Km in Pune, 17 Seater Bus on Rent in Pune, 17 Seater AC Tempo Traveller on Rent in Pune, 17 Seater Non AC Tempo Traveller on Rent in Pune, 17 Seater Tempo Traveller for Mahabaleshwar from Pune, 17 Seater Tempo Traveller Pune to Goa, 17 Seater Tempo Traveller Pune to Nashik, 17 Seater Tempo Traveller Pune to Mumbai Rent, 17 Seater Tempo Traveller on Rent in Hadapsar, 17 Seater Tempo Traveller on Rent in Pimpri Chinchwad, 17 Seater Tempo Traveller Hire in Viman Nagar, Pune to Shirdi 17 Seater Bus Booking" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 17 Seater Bus on Rent in Pune | Affordable Rentals" />
    <meta property="og:description" content="Discover 17 Seater Bus rental services in Pune for local and outstation trips. Competitive rates and excellent service await you!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/17-seater-bus-on-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/17-seater-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "17 Seater Bus Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/17-seater-bus-on-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "17 Seater Bus",
                    "description": "Comfortable 17 Seater Bus on rent for various occasions in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-06.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs 17-Seater Bus Rental in Pune for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seater17onrent;