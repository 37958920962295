import React from 'react';
import '../css/OurNetwork.css';
import { Helmet } from 'react-helmet';

const OurNetwork = () => {
  const cities = [
    {
      name: 'Pune',
      imgSrc: '/img/network/Our network-01.jpg',
      description: 'Pune is known for its educational institutions and pleasant climate.',
    },
    {
      name: 'Mumbai',
      imgSrc: '/img/network/Our network-03.jpg',
      description: 'Mumbai is renowned for its bustling streets, nightlife, and iconic landmarks.',
    },
    {
      name: 'Pimpri chinchwad',
      imgSrc: '/img/network/Our network-02.jpg',
      description: 'Pimpri-Chinchwad is known for its industry and modern infrastructure.',
    },
    {
      name: 'Goa',
      imgSrc: '/img/network/Our network-04.jpg',
      description: 'Goa is known for its stunning beaches, vibrant nightlife, rich culture, and scenic beauty.',
    },
    {
      name: 'Kohlapur',
      imgSrc: '/img/network/Our network-05.jpg',
      description: 'Kolhapur is known for its rich history, exquisite handicrafts, and traditional cuisine.',
    },
    {
      name: 'Nashik',
      imgSrc: '/img/network/Our network-06.jpg',
      description: 'Nashik is known for its scenic vineyards, rich cultural heritage, and religious significance.',
    },
    {
      name: 'Shirdi',
      imgSrc: '/img/network/Our network-07.jpg',
      description: 'Shirdi is known for its spiritual significance, devotion to Sai Baba, and pilgrimage community.',
    },
    {
      name: 'Mahabaleshwar',
      imgSrc: '/img/network/Our network-08.jpg',
      description: 'Mahabaleshwar is known for its breathtaking views, lush greenery, and pleasant climate.',
    },
    {
      name: 'Ashtavinayak',
      imgSrc: '/img/network/Our network-09.jpg',
      description: 'Ashtavinayak is known for its divine temples, spiritual significance, and rich traditions.',
    },
  ];

  return (
    <div>
      <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Our network</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
      <section className="our-network">
        <div className="container">
          <h2 className="network-title">Cities We Serve</h2>
          <div className="network-cards">
            {cities.map((city, index) => (
              <div className="network-card" key={index}>
                <img src={city.imgSrc} alt={city.name} className="network-image" />
                <div className="network-card-content">
                  <h3 style={{color:'#61BAE4'}}>{city.name}</h3>
                  <p>{city.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default OurNetwork;
