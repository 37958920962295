
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireforpunrtoashta() {



  const cardData =
  {
    keyword: 'Bus Hire for Pune to Ashtavinayak',
    heading: 'Shraddha Travels: Bus Hire for Pune to Ashtavinayak',
    headingDescription: 'If you are planning a spiritual pilgrimage or a serene getaway, Shraddha Travels offers exceptional bus hire services for your journey from Pune to Ashtavinayak. This revered pilgrimage circuit in Maharashtra is dedicated to the eight sacred Ganesh temples, each known for its unique significance and divine presence. Our reliable bus rental service ensures a comfortable and seamless travel experience as you embark on this sacred journey.',
    top: 'Top Places to Visit in Ashtavinayak with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Siddhivinayak Temple (Siddhatek)",
            description: "Located in Siddhatek, this temple is dedicated to Lord Ganesha as Siddhivinayak. Renowned for its unique idol, which has an unusual trunk facing the right, it holds significant historical importance and attracts numerous devotees seeking blessings for success and prosperity."
        },
        {
            title: "2. Ballaleshwar Temple (Ballalwadi)",
            description: "Situated in Ballalwadi, this temple is dedicated to Lord Ganesha in his form as Ballaleshwar. It is a popular site for devotees due to its historical and spiritual significance, believed to have been established by a devoted saint. The serene ambiance and lush surroundings enhance the spiritual experience for visitors."
        },
        {
            title: "3. Varadvinayak Temple (Mahad)",
            description: "Located in Mahad, Varadvinayak Temple is known for its unique Ganesh idol, which is considered to grant wishes and provide blessings to its devotees. The temple's architectural beauty and tranquil setting make it a favored pilgrimage spot, particularly during festivals dedicated to Lord Ganesha."
        },
        {
            title: "4. Chintamani Temple (Theur)",
            description: "The Chintamani Temple in Theur is famous for its deity Chintamani Ganesh, believed to remove obstacles and fulfill desires. Devotees visit this temple seeking clarity of thought and success in their endeavors. The temple's serene environment and rich history add to its spiritual charm."
        },
        {
            title: "5. Girijatmaj Temple (Lonavala)",
            description: "Situated in Lonavala, this temple is dedicated to Ganesh in his form as Girijatmaj. Renowned for its beautiful surroundings and peaceful ambiance, the temple is nestled in the hills, providing a perfect backdrop for meditation and reflection amidst nature."
        },
        {
            title: "6. Vighneshwar Temple (Ozar)",
            description: "The Vighneshwar Temple in Ozar is dedicated to Lord Ganesh as Vighneshwar. Known for its historical significance, this temple attracts devotees from all over who seek protection from obstacles. The intricate carvings and majestic architecture create a captivating atmosphere for worship."
        },
        {
            title: "7. Mahaganapati Temple (Ranjangaon)",
            description: "Located in Ranjangaon, this temple is dedicated to Lord Ganesh as Mahaganapati, revered for its grandeur and the divine aura it exudes. Devotees flock to this temple during the Ganesh Chaturthi festival, making it a vibrant hub of spiritual activity and celebration."
        },
        {
            title: "8. Yeshvantganapati Temple (Khopoli)",
            description: "The Yeshvantganapati Temple in Khopoli is known for its serene environment and is a significant part of the Ashtavinayak pilgrimage. Surrounded by lush greenery, it offers a peaceful retreat for those seeking solace and connection with the divine."
        },
        {
            title: "9. Ranjangaon Ganpati",
            description: "This temple, situated in Ranjangaon, is dedicated to Mahaganapati, the deity known for removing obstacles and granting blessings to devotees. The temple is an architectural marvel, attracting both pilgrims and tourists who come to experience its spiritual ambiance."
        },
        {
            title: "10. Chintamani Ganapati Temple",
            description: "Located in Theur, this temple is highly revered and visited by devotees seeking spiritual solace and blessings from Lord Ganesh. The temple's rich history and the belief that it fulfills sincere prayers make it a must-visit for those on a spiritual journey."
        }
    ],    
    services: [
        {
            name: "Ashtavinayak Tour from Pune by Bus Package",
            description: "Experience the sacred Ashtavinayak tour from Pune with our comprehensive bus package. This all-inclusive package covers all eight Ashtavinayak temples, ensuring you visit each site comfortably. Our well-planned itinerary includes guided tours and ample time for worship, making your pilgrimage both enriching and hassle-free."
        },
        {
            name: "Ashtavinayak Tour From Pune Bus Package",
            description: "Our Ashtavinayak tour from Pune bus package provides a convenient and comfortable way to visit the revered Ashtavinayak temples. Enjoy a hassle-free journey with our well-maintained buses and expert guides who share insights into the historical and spiritual significance of each temple, enriching your pilgrimage experience."
        },
        {
            name: "Ashtavinayak Darshan 32 Seater Bus Hire Pune",
            description: "For small to medium-sized groups, our 32-seater bus hire for Ashtavinayak Darshan in Pune is an excellent choice. These buses offer ample space and comfort, allowing you to relax as you travel between temples. Equipped with modern amenities, they are perfect for an enjoyable pilgrimage experience."
        },
        {
            name: "Pune to Ranjangaon Ganpati Darshan Bus on Rent",
            description: "Traveling from Pune to Ranjangaon Ganpati? Our bus rental service provides comfortable and reliable transportation for your Ganpati darshan. Our experienced drivers ensure a smooth journey while you focus on your spiritual experience, making stops along the way if desired."
        },
        {
            name: "Bus Hire for Pune to Ashtavinayak Tour",
            description: "Our bus hire service for Pune to Ashtavinayak tour ensures a comfortable and well-organized pilgrimage. We provide various bus options tailored to your group size and preferences, complete with professional drivers and guides to assist you throughout the journey."
        },
        {
            name: "Best Ashtavinayak Tour from Pune Bus Service",
            description: "For the best Ashtavinayak tour from Pune, our bus service stands out with top-notch amenities and exceptional customer service. Our buses feature comfortable seating, air conditioning, and entertainment options, ensuring a memorable pilgrimage to the Ashtavinayak temples."
        },
        {
            name: "32, 35, 40 Seater Bus Hire for Ashtavinayak",
            description: "We offer flexible bus hire options for your Ashtavinayak tour, including 32, 35, and 40-seater buses. Each bus is equipped with modern amenities to ensure a comfortable journey, allowing you to focus on your spiritual journey rather than logistics."
        },
        {
            name: "Volvo Bus for Ashtavinayak Darshan",
            description: "Experience luxury travel with our Volvo bus for Ashtavinayak Darshan. Our premium Volvo buses offer superior comfort and amenities, including reclining seats and onboard refreshments, making your pilgrimage both relaxing and enjoyable."
        },
        {
            name: "45 and 50 Seater Bus on Rent for Ashtavinayak Darshan",
            description: "For a comfortable and spacious journey to the Ashtavinayak temples, consider our 45 and 50-seater bus rentals. These buses are ideal for large groups, offering ample space and modern amenities to ensure a pleasant pilgrimage experience for everyone."
        },
        {
            name: "Pune to Morgaon Bus Hire on Rent",
            description: "Travel from Pune to Morgaon Ganpati with ease using our bus hire service. Our buses are well-maintained and equipped with comfortable seating and essential amenities, providing a reliable transportation solution for your visit to this sacred Ganpati temple."
        },
        {
            name: "Pune to Siddhatek Ganpati Bus on Rent",
            description: "Experience a smooth journey from Pune to Siddhatek Ganpati with our dedicated bus rental service. Our friendly drivers ensure a hassle-free trip, allowing you to meditate and reflect on your spiritual journey during travel."
        },
        {
            name: "Pune to Pali Ganpati Bus on Hire",
            description: "For those visiting Pali Ganpati, our bus hire service from Pune offers a convenient and comfortable travel option. Enjoy a stress-free journey with our well-maintained buses designed to enhance your pilgrimage experience, complete with knowledgeable drivers to assist you."
        },
        {
            name: "Pune to Mahad Ganpati Bus on Hire",
            description: "Our bus rental service for the Pune to Mahad Ganpati trip provides a reliable and comfortable way to reach this revered temple. With our experienced drivers and comfortable buses, you can focus on your prayers and spiritual aspirations."
        },
        {
            name: "Pune to Theur Bus Hire",
            description: "Travel from Pune to Theur Ganpati with our efficient bus hire service. Our buses are equipped with all the necessary amenities to ensure a comfortable and pleasant trip to this significant Ganpati temple, making your pilgrimage a memorable experience."
        },
        {
            name: "Pune to Lenyadri Ganpati Bus on Rent",
            description: "Explore Lenyadri Ganpati with our dedicated bus rental service from Pune. Our buses offer comfortable seating and reliable transportation, making your visit to this Ganpati temple a smooth and enjoyable experience, with opportunities to enjoy the scenic beauty along the way."
        },
        {
            name: "Pune to Ozar Ganpati Bus Hire",
            description: "Our bus hire service for the Pune to Ozar Ganpati journey ensures a comfortable and convenient trip. Enjoy a well-maintained bus and professional service, allowing you to engage fully in the spiritual experience without any travel worries."
        },
        {
            name: "Pune to Ranjangaon Ganpati Bus Hire",
            description: "Travel to Ranjangaon Ganpati with ease using our bus hire service from Pune. Our reliable and comfortable buses are designed to provide a smooth and enjoyable pilgrimage experience, making stops at notable points along the way to enhance your journey."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "For all your bus rental needs, including trips to various Ganpati temples and Ashtavinayak darshan, Shraddha Travels Pune offers exceptional service. Our fleet includes a range of vehicles to suit different group sizes and preferences, ensuring a comfortable and memorable journey for every traveler."
        },
        {
            name: "Bus hire for Pune to Ashtavinayak Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus hire for Pune to Ashtavinayak. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers, making your pilgrimage seamless."
        }
    ],
    
    tableData: [
        ['- Ashtavinayak tour from Pune by Bus Package', '- Ashtavinayak Tour From Pune Bus Package'],
        ['- Ashtavinayak Darshan 32 Seater Bus Hire Pune', '- Pune to Ranjangaon Ganpati Darshan Bus on Rent'],
        ['- Bus hire for Pune to Ashtavinayak tour', '- Best Ashtavinayak Tour From Pune Bus Service'],
        ['- 32, 35, 40 Seater Bus Hire for Ashtavinayak', '- Volvo Bus for Ashtavinayak Darshan'],
        ['- 45, 50 Seater Bus on Rent for Ashtavinayak Darshan', '- Pune to Morgaon Bus Hire on Rent'],
        ['- Pune to Siddhatek Ganpati Bus on Rent', '- Pune to Pali Ganpati Bus on Hire'],
        ['- Pune to Mahad Ganpati Bus on Hire', '- Pune to Theur Bus Hire'],
        ['- Pune to Lenyadri Ganpati Bus on Rent', '- Shraddha Travels Pune On Rent'],
        ['- Pune to Ozar Ganpati Bus Hire', '- Pune to Ranjangaon Ganpati Bus Hire']
    ],
    whychoose: [
        {
            WhyChooseheading: "Why Choose Us for Bus Hire from Pune to Ashtavinayak?",
            WhyChoosedescription: "When it comes to hiring a bus for your pilgrimage to the Ashtavinayak temples, we are your trusted provider for a comfortable and reliable journey. Our service ensures that you travel with ease and enjoyment, allowing you to focus on your spiritual experience. Here's why you should choose us:"
        },
        {
            WhyChooseheading: "Comfort and Reliability:",
            WhyChoosedescription: "Our fleet of modern, well-maintained buses ensures a comfortable and smooth ride. Equipped with plush seating, air conditioning, and ample legroom, our buses are designed for long-distance travel. We prioritize your comfort by ensuring that each vehicle is spacious and equipped with amenities like onboard refreshments, making your pilgrimage to Ashtavinayak a pleasant and restful experience."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional drivers are not only skilled but also well-versed in the routes to the Ashtavinayak temples. With years of experience, they ensure a safe and reliable journey, strictly adhering to all traffic regulations. Their familiarity with the area allows them to navigate efficiently, ensuring timely arrivals and providing insights about the sites you’ll visit along the way."
        },
        {
            WhyChooseheading: "Customized Itineraries:",
            WhyChoosedescription: "Whether you are planning a quick visit to specific temples or a comprehensive tour of the Ashtavinayak circuit, we offer fully customizable itineraries tailored to your spiritual objectives. Our team works closely with you to understand your preferences, allowing for adjustments to the schedule as needed, so you can have a personalized experience that suits your needs."
        },
        {
            WhyChooseheading: "Affordable Pricing:",
            WhyChoosedescription: "We offer competitive rates without compromising on quality. Our transparent pricing ensures there are no hidden costs, so you can budget effectively for your pilgrimage. We believe in providing value for money, ensuring that every aspect of your journey is of the highest standard, from vehicle maintenance to customer service."
        },
        {
            WhyChooseheading: "Exceptional Customer Service:",
            WhyChoosedescription: "Our dedicated customer support team is available to assist you with all your queries and concerns. From the moment you book your trip to post-trip feedback, we are here to ensure a seamless experience. We strive to make communication easy and responsive, providing you with all the information you need for a worry-free pilgrimage."
        },
        {
            WhyChooseheading: "Punctual and Dependable:",
            WhyChoosedescription: "We understand the importance of time in your pilgrimage. Our commitment to punctuality means you can rely on us to adhere to your schedule. We meticulously plan every journey to maximize your time at each temple, allowing you to immerse yourself in the spiritual experience without the stress of delays or missed appointments."
        }
    ]
      }


      const faqData = [
        {
            question: "How can I book a bus for the Ashtavinayak trip?",
            answer: "Booking a bus with Shraddha Travels is straightforward. You can contact us via phone or through our website to provide your travel details. Our team will assist you in selecting the right bus and finalizing your booking."
        },
        {
            question: "What is included in the bus rental service?",
            answer: "Our bus rental service includes the vehicle, a professional driver, and essential amenities such as comfortable seating, air conditioning, and entertainment options. Additional services can be customized based on your specific needs."
        },
        {
            question: "Can the itinerary be customized?",
            answer: "Yes, we offer customizable itineraries to fit your specific requirements. Whether you wish to visit all eight Ashtavinayak temples or select a few, we can tailor the trip to match your preferences."
        },
        {
            question: "What are the payment options available?",
            answer: "We accept a variety of payment methods, including online transfers, credit/debit cards, and cash payments. Please contact us to discuss the payment options that best suit your needs."
        },
        {
            question: "Are there any discounts for large groups?",
            answer: "Yes, we provide special rates and discounts for large groups. Contact us for a customized quote based on your group size and specific travel requirements."
        },
        {
            question: "What should I do if I need to change my booking?",
            answer: "If you need to make changes to your booking, please contact our customer service team as soon as possible. We will assist you in adjusting your itinerary and any other details as needed."
        },
        {
            question: "Is there a cancellation policy?",
            answer: "Yes, we have a cancellation policy in place. Please refer to our terms and conditions or contact our support team for detailed information on cancellations and any applicable fees."
        },
        {
            question: "What safety measures are in place for the trip?",
            answer: "We prioritize safety with well-maintained vehicles, experienced drivers, and adherence to all safety regulations. Regular maintenance checks and driver training ensure a secure and comfortable journey."
        }
    ];
    

    const testimonials = [
        {
            name: "Mrs. Priya Sharma",
            text: "Our trip to the Ashtavinayak temples with Shraddha Travels was truly memorable. From the moment we booked the bus to the end of our journey, the experience was flawless. The 45-seater bus was extremely comfortable, clean, and well-maintained. Our driver was not only skilled but also very knowledgeable about the routes, ensuring we reached each temple safely and on time. The customer service team was prompt and helpful, making the booking process smooth. I highly recommend Shraddha Travels for anyone planning a pilgrimage to Ashtavinayak!"
        },
        {
            name: "Mr. Rajesh Deshmukh",
            text: "I recently hired a bus from Shraddha Travels for a group trip to the Ashtavinayak temples, and I couldn’t be more satisfied. The bus was spacious and comfortable, perfect for our group of 30. The driver was professional and considerate, accommodating our stops and keeping us on schedule. The whole experience was well-organized, and the pricing was very reasonable. It was a pleasure to travel with Shraddha Travels, and I will definitely choose them for future trips. Thank you for making our spiritual journey so pleasant and stress-free!"
        }
    ];
    

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "  Bus hire for Pune to Ashtavinayak Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Pune to Ashtavinayak | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Explore our Ashtavinayak bus hire services from Pune. Comfortable and affordable options for family trips, corporate outings, and group travels." />
    <meta name="keywords" content="Pune to Ashtavinayak Bus Hire, Ashtavinayak Tour Package, Ganpati Darshan Bus Rental, Group Travel Bus Hire, Volvo Bus Rental for Ashtavinayak" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Pune to Ashtavinayak | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Book our bus rental service for an unforgettable Ashtavinayak tour from Pune. Options available for all group sizes." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-to-ashtavinayak-bus-hire" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/ashtavinayak-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire for Pune to Ashtavinayak",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-to-ashtavinayak-bus-hire",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Pune to Ashtavinayak Bus Rental",
                    "description": "Comfortable and affordable bus rental services from Pune to Ashtavinayak, ideal for family trips and group travels."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-16.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs Bus Hire from Pune to Ashtavinayak for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireforpunrtoashta;