
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Seaterbusonrentinpune() {



  const cardData =
  {
    keyword: ' 32, 35, 40, 45, and 50 Seater Buses on Rent in Pune',
    heading: 'Shraddha Travels: 32, 35, 40, 45, and 50 Seater Buses on Rent in Pune',
    headingDescription: 'When seeking reliable and comfortable bus rental services in Pune, Shraddha Travels provides a range of options to suit your needs. From corporate events and family outings to special occasions and local tours, our diverse fleet ensures you travel in style and comfort. Here’s a look at the top bus rental options available with Shraddha Travels in Pune.',

    topPlaces: [
        {
          title: "1. 32-Seater Bus",
          description: "Our 32-seater bus is an excellent choice for medium-sized groups. With a spacious and comfortable interior, it features reclining seats, ample legroom, and large windows for scenic views. Ideal for family gatherings, small corporate events, or school outings, this bus ensures a pleasant travel experience while allowing you to enjoy the journey as much as the destination."
        },
        {
          title: "2. 35-Seater Bus",
          description: "The 35-seater bus offers additional room for larger groups, making it perfect for corporate events, extended local trips, or significant family outings. Equipped with air conditioning, onboard entertainment, and comfortable seating, this bus ensures everyone travels in style. It’s designed for a smooth ride, allowing you to relax and enjoy your time together."
        },
        {
          title: "3. 40-Seater Bus",
          description: "Designed for larger groups, the 40-seater bus provides ample seating and comfort for all passengers. It’s suitable for major events and group travel, including weddings, picnics, and team outings. With modern amenities such as a sound system and charging points, this bus ensures a fun and engaging atmosphere during your journey across Pune."
        },
        {
          title: "4. 45-Seater Bus",
          description: "The 45-seater bus balances capacity and comfort, making it ideal for sizable groups. Whether you're planning a corporate retreat or a large family reunion, this bus guarantees a pleasant journey. Featuring comfortable seating, air conditioning, and ample storage space for luggage, it’s designed to accommodate all your travel needs without sacrificing comfort."
        },
        {
          title: "5. 50-Seater Bus",
          description: "Our 50-seater bus is perfect for very large groups, offering maximum seating capacity without compromising on comfort. It’s an excellent choice for major corporate events, large school excursions, and extensive local tours. With high-quality seating, climate control, and plenty of space for luggage, this bus ensures that everyone travels comfortably and arrives at their destination refreshed."
        },
        {
          title: "6. Luxury 32-Seater Bus",
          description: "The luxury 32-seater bus provides a premium travel experience with high-end features such as plush seating, mood lighting, and entertainment systems. Ideal for special occasions, executive travel, or luxurious group outings, this bus elevates your travel experience. Enjoy additional amenities like refreshments and personalized service, making your journey as memorable as the event itself."
        },
        {
          title: "7. AC 35-Seater Bus",
          description: "Our AC 35-seater bus is designed to keep you cool during warmer months. Perfect for local trips and corporate events, it offers a comfortable and refreshing environment. With spacious seating, large windows, and a professional driver familiar with local routes, you can focus on enjoying the journey while we take care of the rest."
        },
        {
          title: "8. Non-AC 40-Seater Bus",
          description: "The non-AC 40-seater bus is a cost-effective option providing reliable transportation with ample space. It’s suitable for budget-conscious groups who need dependable travel within Pune. Despite being a non-AC option, it still offers comfortable seating and sufficient room for luggage, ensuring that your travel needs are met without stretching your budget."
        },
        {
          title: "9. Luxury 45-Seater Bus",
          description: "Combining spaciousness with high-end features, the luxury 45-seater bus is perfect for upscale events and luxurious tours. Enjoy premium comfort with reclining seats, an entertainment system, and onboard refreshments. This bus is designed for those who appreciate quality and style, ensuring that every journey is as enjoyable as the destination."
        },
        {
          title: "10. Executive 50-Seater Bus",
          description: "The executive 50-seater bus offers a top-tier travel experience for very large groups. Equipped with executive seating, state-of-the-art technology, and spacious interiors, it’s ideal for high-end corporate events and large-scale conferences. Experience unparalleled comfort and sophistication, making your travel experience exceptional from start to finish."
        }
      ],      
      services: [
        {
          name: "32 Seater Bus Hire Near Me in Pune",
          description: "Find the best local 32-seater bus rental options with Shraddha Travels. Ideal for medium-sized groups, our buses are equipped with comfortable seating and modern amenities, ensuring a pleasant journey for family trips, events, or day excursions around Pune. Experience reliable transportation that meets all your local travel needs."
        },
        {
          name: "32 Seater Bus Hire for Outstation in Pune",
          description: "Planning an outstation trip? Our 32-seater buses are designed for comfortable and efficient travel to destinations outside Pune. With spacious interiors and experienced drivers, we ensure your group enjoys a smooth journey, whether you’re heading to hill stations or historical sites. Let us handle the logistics while you focus on making memories."
        },
        {
          name: "32 Seater Bus Rate Per Km in Pune",
          description: "Get competitive rates for 32-seater bus rentals based on distance traveled. At Shraddha Travels, we believe in transparent pricing with no hidden fees, allowing you to budget effectively for your trip. Our pricing model ensures you receive excellent value without compromising on comfort or quality."
        },
        {
          name: "35 Seater Bus on Rent in Pune",
          description: "Ideal for slightly larger groups, our 35-seater buses provide ample space and comfort for various occasions, including family gatherings, corporate events, and school trips. With plush seating and air conditioning, our buses guarantee a comfortable ride for all passengers, making every journey enjoyable."
        },
        {
          name: "35 Seater Tourist Bus Near Me in Pune",
          description: "Discover local options for 35-seater tourist buses that are perfect for sightseeing tours and travel within Pune. Our buses are equipped with features designed for comfort, including reclining seats and ample legroom, allowing your group to explore the city’s attractions in style and comfort."
        },
        {
          name: "35 Seater Tourist Bus For Outstation in Pune",
          description: "For outstation trips, our 35-seater buses offer a comfortable and spacious solution, ensuring a pleasant journey for larger groups. Equipped with all necessary amenities, our buses make long-distance travel enjoyable, whether you’re going for a weekend getaway or a longer holiday."
        },
        {
          name: "35 Seater Bus for Corporate Office in Pune",
          description: "Arrange transportation for your corporate office with our 35-seater buses, suitable for business meetings, seminars, and team outings. Our professional drivers prioritize punctuality and safety, ensuring your team arrives on time and ready to engage."
        },
        {
          name: "35 Seater Bus for Corporate Outing in Pune",
          description: "Plan a memorable corporate outing with our 35-seater buses, offering ample space and comfort for your team. Ideal for team-building activities or company picnics, our buses ensure a relaxed atmosphere where everyone can enjoy the journey together."
        },
        {
          name: "40 Seater Tourist Bus Near Me in Pune",
          description: "Find local rental options for 40-seater tourist buses that are perfect for large group travel and tours within Pune. With spacious interiors and amenities designed for group comfort, our buses provide an excellent solution for school outings, corporate events, or family vacations."
        },
        {
          name: "40 Seater Bus Rent Per Km in Pune",
          description: "Get detailed information on the cost of renting a 40-seater bus per kilometer. Our competitive and transparent pricing structure ensures you can plan your travel budget efficiently without any surprises. Contact us for a personalized quote based on your specific travel needs."
        },
        {
          name: "40 Seater Bus on Rent in Pune",
          description: "For larger events and group travel, our 40-seater buses offer plenty of space and comfort for an enjoyable journey. Designed with group travel in mind, these buses come equipped with modern facilities, ensuring a comfortable and enjoyable ride for everyone on board."
        },
        {
          name: "40 Seater Bus on Rent in Pune for Wedding",
          description: "Make your wedding transportation seamless with our 40-seater buses. Ideal for transporting guests comfortably to and from the wedding venue, our buses ensure everyone arrives in style. With attentive service and spacious seating, your guests will appreciate the convenience and comfort."
        },
        {
          name: "45 Seater Bus Near Me for Rent",
          description: "Looking for a 45-seater bus near you for rent? At Shraddha Travels, we offer spacious and comfortable 45-seater buses suitable for various occasions. Whether it’s a family trip, corporate event, or a school outing, our well-maintained buses are perfect for accommodating large groups and ensuring a smooth ride."
        },
        {
          name: "45 Seater Bus for School Trip",
          description: "For school trips, a 45-seater bus from Shraddha Travels ensures that all students travel comfortably and safely. Our buses are equipped with essential amenities, and our experienced drivers prioritize safety and punctuality, making your school outing stress-free and enjoyable."
        },
        {
          name: "45 Seater Bus Price Per Km in Pune",
          description: "Curious about the price per km for renting a 45-seater bus in Pune? Shraddha Travels offers competitive rates based on distance and duration, ensuring you get excellent value for your money without hidden fees. Reach out to us for a customized quote tailored to your travel plans."
        },
        {
          name: "50 Seater Bus Rental Services in Pune",
          description: "Shraddha Travels provides reliable 50-seater bus rental services in Pune, ideal for large groups. Our fleet of 50-seater buses is perfect for corporate events, weddings, and group tours, offering ample space, comfort, and professional service for all your travel needs."
        },
        {
          name: "50 Seater Bus for Corporate Outing Near Me",
          description: "Planning a corporate outing and need a 50-seater bus near you? Shraddha Travels offers well-equipped 50-seater buses that provide a comfortable and professional environment, ensuring your team travels in style. Our dedicated service ensures a seamless outing experience."
        },
        {
          name: "50 Seater Bus Hire in Pune",
          description: "For reliable 50-seater bus hire in Pune, turn to Shraddha Travels. Our 50-seater buses are ideal for large groups and come with a range of amenities to ensure a pleasant journey, including air conditioning and comfortable seating."
        },
        {
          name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pune for Family",
          description: "If you’re looking for a bus to accommodate your family trip, Shraddha Travels offers a range of options from 32 to 50-seater buses. Our buses are perfect for family outings, providing comfort and ample space for everyone to enjoy a memorable trip together."
        },
        {
          name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pimpri Chinchwad",
          description: "Shraddha Travels offers a variety of bus rental options in Pimpri Chinchwad, including 32, 35, 40, 45, and 50-seater buses. Whether you need a bus for a local event or an outstation trip, we have the right vehicle to meet your needs, ensuring a comfortable and enjoyable journey."
        },
        {
          name: "32, 35, 40, 45, 50 Seater Bus for Outstation Trip",
          description: "Planning an outstation trip and need a bus for your group? Shraddha Travels provides a range of buses from 32 to 50-seater for outstation journeys. Our buses are equipped to handle long-distance travel, ensuring comfort and safety throughout your trip."
        },
        {
          name: "32, 35, 40, 45, 50 Seater Bus for Corporate Trip",
          description: "For corporate trips, Shraddha Travels offers a variety of bus sizes from 32 to 50-seater to accommodate your team. Our buses provide a professional and comfortable environment, ideal for business events and team outings, ensuring an efficient travel experience."
        },
        {
          name: "32, 35, 40, 45, 50 Seater Bus on Rent in Pune Contact Number",
          description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus rentals in Pune. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
      ],      
      tableData: [
        ['- 32 Seater Bus Hire Near Me in Pune', '- 32 Seater Bus Hire for Outstation in Pune'],
        ['- Shraddha Travels Pune', '- 32 Seater Bus Rate Per Km in Pune'],
        ['- 35 Seater Bus on Rent in Pune', '- 35 Seater Tourist Bus Near Me in Pune'],
        ['- 35 Seater Tourist Bus For Outstation in Pune', '- 35 Seater Bus for Corporate Office in Pune'],
        ['- 35 Seater Bus for Corporate Outing in Pune', '- 40 Seater Tourist Bus Near Me in Pune'],
        ['- 40 Seater Bus Rent Per Km in Pune', '- 40 Seater Bus on Rent in Pune'],
        ['- 40 Seater Bus on Rent in Pune for Wedding', '- 45 Seater Bus Near Me for Rent'],
        ['- 45 Seater Bus for School Trip', '- 45 Seater Bus Price Per Km in Pune'],
        ['- 50 Seater Bus Rental Services in Pune', '- 50 Seater Bus for Corporate Outing Near Me'],
        ['- 50 Seater Bus Hire in Pune', '- 32, 35, 40, 45, 50 Seater Bus on Rent in Pune for Family'],
        ['- 32, 35, 40, 45, 50 Seater Bus on Rent in Pimpri Chinchwad', '- 32, 35, 40, 45, 50 Seater Bus for Outstation Trip'],
        ['- 32, 35, 40, 45, 50 Seater Bus for Corporate Trip', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for 32, 35, 40, 45, 50 Seater Bus on Rent in Pune?",
          WhyChoosedescription: "When it comes to renting buses of various sizes in Pune, Shraddha Travels is the top choice for numerous reasons. Our commitment to comfort, safety, and customer satisfaction makes us the ideal partner for your travel needs. We strive to provide an unforgettable travel experience tailored to your unique requirements."
        },
        {
          WhyChooseheading: "Diverse Fleet:",
          WhyChoosedescription: "We offer a wide range of bus sizes, from 32 to 50-seaters, ensuring you get the right vehicle for your specific needs. Whether it’s a small family outing, a school trip, or a large corporate event, our diverse fleet guarantees the perfect fit, allowing for flexibility in planning your journey."
        },
        {
          WhyChooseheading: "Comfort and Safety:",
          WhyChoosedescription: "Our buses are meticulously maintained and equipped with modern amenities such as plush seating, air conditioning, and entertainment systems to ensure a comfortable and safe journey. We prioritize your well-being by conducting regular safety checks and keeping our vehicles clean and hygienic."
        },
        {
          WhyChooseheading: "Experienced Drivers:",
          WhyChoosedescription: "Our professional drivers are not only experienced but also courteous and trained to ensure a smooth and safe ride. They are familiar with local and outstation routes, which allows them to navigate efficiently, making your travel experience hassle-free and enjoyable."
        },
        {
          WhyChooseheading: "Affordable Pricing:",
          WhyChoosedescription: "We offer competitive and transparent pricing for our bus rentals. Our pricing structure is designed to provide excellent value for your money, ensuring there are no hidden fees. You can rest assured that you’re getting the best rates without compromising on quality and service."
        },
        {
          WhyChooseheading: "Flexible Booking:",
          WhyChoosedescription: "We provide flexible booking options to accommodate your needs, whether it’s for a few hours, a full day, or multiple days. Our user-friendly booking process is designed to be quick and convenient, allowing you to plan your trip with ease and minimal hassle."
        },
        {
          WhyChooseheading: "Punctual Service:",
          WhyChoosedescription: "At Shraddha Travels, we understand the importance of punctuality. We ensure that our buses arrive on time and adhere to your schedule, providing reliable service every time. Our commitment to timely departures and arrivals helps keep your travel plans on track."
        },
        {
          WhyChooseheading: "Personalized Solutions:",
          WhyChoosedescription: "We offer customized solutions to cater to your specific requirements, whether it’s for a family trip, corporate outing, or school event. Our team works closely with you to understand your needs, ensuring a tailored experience that exceeds your expectations."
        },
        {
          WhyChooseheading: "24/7 Customer Support:",
          WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with any inquiries, bookings, or issues you may have. We are committed to providing responsive and helpful support, ensuring you feel confident and supported throughout your travel experience."
        }
      ]
        }


        const faqData = [
            {
              question: "What types of buses are available for rent?",
              answer: "Shraddha Travels offers a variety of buses, including 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. We have different types of buses to suit various group sizes and travel needs."
            },
            {
              question: "How do I book a bus with Shraddha Travels?",
              answer: "You can book a bus by visiting our website, calling our customer service hotline, or visiting our office. We recommend booking in advance to secure your preferred vehicle."
            },
            {
              question: "What amenities are included in the buses?",
              answer: "Our buses are equipped with comfortable seating, air conditioning, and other amenities to ensure a pleasant journey. The specific amenities may vary depending on the type of bus you choose."
            },
            {
              question: "What is the cost of renting a bus?",
              answer: "The cost of renting a bus depends on factors such as the size of the bus, the duration of the hire, and the distance traveled. We offer competitive and transparent pricing. Contact us for a customized quote based on your requirements."
            },
            {
              question: "Can I hire a bus for an outstation trip?",
              answer: "Yes, we offer bus rentals for both local and outstation trips. Whether you’re planning a day trip or a longer journey, we can accommodate your travel needs."
            },
            {
              question: "Are your buses well-maintained?",
              answer: "Yes, all our buses are regularly maintained and cleaned to ensure they are in excellent condition. We prioritize the safety and comfort of our passengers."
            },
            {
              question: "Can I request a specific type of bus?",
              answer: "We will do our best to accommodate your request for a specific type of bus based on availability. Please provide your requirements when making a booking, and we will work to meet your needs."
            },
            {
              question: "What is your cancellation policy?",
              answer: "We have a cancellation policy in place, and cancellation charges may apply depending on when you cancel. For more details, please contact our customer support team."
            },
            {
              question: "Are your drivers experienced and professional?",
              answer: "Yes, our drivers are experienced, professional, and trained to provide safe and reliable transportation. They are knowledgeable about local routes and committed to delivering excellent service."
            },
            {
              question: "Do you offer 24/7 customer support?",
              answer: "Yes, our customer support team is available 24/7 to assist with bookings, answer queries, and address any issues you may encounter."
            }
          ];
          
          const testimonials = [
            {
              name: "Mr. Rajesh Patel",
              text: "We recently hired a 50-seater bus from Shraddha Travels for our company's annual conference, and the experience was exceptional. The bus was spacious, comfortable, and well-equipped with modern amenities. The driver was professional and punctual, ensuring we arrived at our destination on time. The booking process was smooth, and the customer service was top-notch. I highly recommend Shraddha Travels for anyone in need of reliable and comfortable bus rentals in Pune."
            },
            {
              name: "Miss Aisha Deshmukh",
              text: "I booked a 35-seater bus from Shraddha Travels for my family's reunion trip, and it was a fantastic experience. The bus was clean, comfortable, and perfect for our group size. The driver was friendly and knowledgeable about the best routes, making our journey enjoyable. The whole process, from booking to the actual trip, was seamless. Shraddha Travels is definitely my go-to choice for bus rentals in Pune, and I’ll be using their services again in the future."
            }
          ];
          

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " 32,35,40,45,50 Seater Bus on Rent in Pune Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | 32, 35, 40, 45, 50 Seater Bus on Rent in Pune</title>
    <meta name="description" content="Affordable and reliable bus rental services in Pune for 32, 35, 40, 45, and 50 seater buses for corporate trips, weddings, and outings." />
    <meta name="keywords" content="32 Seater Bus Hire, 35 Seater Bus Rental, 40 Seater Bus on Rent, 45 Seater Bus Hire, 50 Seater Bus Rental Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | 32, 35, 40, 45, 50 Seater Bus on Rent in Pune" />
    <meta property="og:description" content="Reliable and affordable bus rental services in Pune for 32, 35, 40, 45, and 50 seater buses for various occasions." />
    <meta property="og:url" content="https://www.amuratatravels.com/32-35-40-45-50-seater-bus-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/bus-rent-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "32, 35, 40, 45, 50 Seater Bus on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/32-35-40-45-50-seater-bus-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services in Pune",
                    "description": "Affordable and reliable bus rental services for 32, 35, 40, 45, and 50 seater buses for corporate trips, weddings, and family outings."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-10.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for “32, 35, 40, 45, 50 Seater Bus on Rent in Pune” – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Seaterbusonrentinpune;