
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Tempotraveeronrentinpune() {



  const cardData =
  {
    keyword: 'Tempo Traveller On Rent in Pune',
    heading: 'Tempo Traveller on Rent in Pune: Shraddha Travels',
    headingDescription: 'Are you planning a group trip and looking for a comfortable, spacious, and reliable transportation option in Pune? Shraddha Travels offers Tempo Traveller on Rent in Pune to make your journey hassle-free and enjoyable. Whether it’s a family vacation, corporate outing, pilgrimage, or wedding, our well-maintained tempo travellers are the perfect solution for all your travel needs.',
    top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
      {
        title: "1. Khadakwasla Lake",
        description: "A picturesque reservoir surrounded by hills, Khadakwasla Lake is perfect for a peaceful day out. Enjoy boating or simply relax by the water while taking in the beautiful scenery."
      },
      {
        title: "2. Nehru Memorial Museum",
        description: "Located in the historic Aga Khan Palace, this museum is dedicated to Jawaharlal Nehru. It features exhibits on his life, Indian independence, and important artifacts, providing a deep insight into India's history."
      },
      {
        title: "3. Pataleshwar Cave Temple",
        description: "An ancient rock-cut temple dedicated to Lord Shiva, this serene spot offers a glimpse into Pune’s heritage. Carved out of a single rock, it’s a unique architectural marvel that dates back to the 8th century."
      },
      {
        title: "4. Katraj Snake Park",
        description: "A unique attraction for wildlife enthusiasts, this park is home to various snake species and other reptiles. It’s an educational experience for visitors to learn about conservation and biodiversity."
      },
      {
        title: "5. Shreemant Dagdusheth Halwai Ganapati Temple",
        description: "Famous for its stunning architecture and vibrant festivals, this temple dedicated to Lord Ganesha attracts devotees from all over. The intricate decorations and the grand idol make it a must-visit."
      },
      {
        title: "6. Pune Tribal Museum",
        description: "This lesser-known museum showcases the rich cultural heritage of the tribal communities in Maharashtra. Through artifacts, art, and stories, it offers a fascinating insight into tribal life and traditions."
      },
      {
        title: "7. Saras Baug",
        description: "A lush garden featuring a temple dedicated to Lord Ganesha, Saras Baug is a popular spot for locals to unwind. The serene lake and beautiful surroundings make it an ideal picnic location."
      },
      {
        title: "8. Empress Botanical Garden",
        description: "Established in 1856, this botanical garden is home to a diverse collection of plants and trees. It’s a peaceful retreat for nature lovers, offering a perfect setting for leisurely walks and photography."
      },
      {
        title: "9. The Pune Wada",
        description: "A unique heritage hotel that replicates the style of a traditional Peshwa-era mansion. Visitors can explore its beautiful architecture and enjoy a royal dining experience that reflects Pune’s rich culinary heritage."
      },
      {
        title: "10. Dosti Park",
        description: "This hidden gem features themed gardens, walking paths, and a serene ambiance, perfect for a leisurely stroll or a family outing. The park's unique design and tranquility make it a delightful escape from city life."
      }
    ],
    services: [
      {
        name: "Tempo Traveller on Rent in Pune",
        description: "Looking for an easy and convenient solution for group travel? Shraddha Travels offers a wide range of Tempo Travellers on rent in Pune for all kinds of trips, whether it's a short local journey or a long-distance outstation trip. Our vehicles are designed to provide ample space, comfort, and reliability, making them ideal for family vacations, corporate outings, or weekend getaways with friends. Enjoy a hassle-free travel experience with all the amenities you need."
      },
      {
        name: "Traveller Bus on Rent in Pune",
        description: "Shraddha Travels provides Traveller buses on rent in Pune with flexible options suited for both small and large groups. Our buses come with comfortable seating, air conditioning, and professional drivers who prioritize your safety and comfort. Whether you’re planning a school trip, a corporate event, or a family reunion, we have the perfect solution to meet your needs."
      },
      {
        name: "Tempo Traveller on Rent Pune to Outstation",
        description: "For long-distance journeys, Shraddha Travels offers Tempo Travellers on rent from Pune to popular outstation destinations like Shirdi, Mahabaleshwar, and Goa. Our well-maintained vehicles ensure a hassle-free and comfortable journey, complete with all necessary amenities, allowing you to relax and enjoy the scenery on your way."
      },
      {
        name: "Pune to Shirdi Tempo Traveller Rate",
        description: "Planning a pilgrimage to Shirdi? Shraddha Travels offers affordable Pune to Shirdi Tempo Traveller rates starting from ₹18 per kilometer. With our professional drivers and well-maintained vehicles, you can expect a comfortable, safe, and memorable journey as you focus on your spiritual experience."
      },
      {
        name: "Tempo Traveller Hire in Pune",
        description: "Shraddha Travels offers Tempo Traveller hire in Pune for all occasions, including family outings, corporate trips, and group tours. With a variety of seating capacities, we can accommodate groups of any size, ensuring everyone travels comfortably. Our flexible booking options and reliable service make planning your trip easy."
      },
      {
        name: "Tempo Traveller on Rent in Hadapsar",
        description: "If you're in Hadapsar and need a reliable travel option, Shraddha Travels provides Tempo Travellers on rent for group travel, family events, and weekend getaways. Our experienced drivers and well-maintained vehicles guarantee a smooth and enjoyable journey for all your local travel needs."
      },
      {
        name: "Tempo Traveller on Rent in Pune Rate Per Km",
        description: "At Shraddha Travels, our Tempo Traveller rates start from ₹18 to ₹25 per kilometer, depending on the vehicle type and trip duration. We pride ourselves on transparent pricing with no hidden costs, ensuring that you receive the best value for your money while enjoying a comfortable travel experience."
      },
      {
        name: "Tempo Traveller Rental in Pune",
        description: "Shraddha Travels is your go-to choice for Tempo Traveller rental in Pune. Whether you're planning a local tour or a long-distance journey, our fleet of well-equipped vehicles is ready to provide a comfortable travel experience. Our knowledgeable staff is available to assist you in selecting the perfect vehicle for your needs."
      },
      {
        name: "Pune to Mahabaleshwar Cab Tempo Traveller",
        description: "Planning a scenic trip to Mahabaleshwar? Shraddha Travels offers cab Tempo Traveller services for a comfortable journey with ample space for passengers and luggage. Our experienced drivers are familiar with the best routes, ensuring you reach your destination safely and on time while enjoying the beautiful landscapes."
      },
      {
        name: "Pune to Goa Tempo Traveller on Rent",
        description: "Planning a trip to Goa? Shraddha Travels offers Tempo Travellers on rent, equipped with modern amenities to ensure a smooth and comfortable journey. Enjoy your beach holiday with friends and family without worrying about travel logistics. Our reliable service allows you to focus on having fun and making memories."
      },
      {
        name: "Luxury Tempo Traveller on Rent in Pune",
        description: "For those seeking a premium travel experience, Shraddha Travels provides Luxury Tempo Travellers featuring plush interiors, spacious seating, and advanced amenities. Perfect for special occasions or corporate events, our luxury vehicles ensure you travel in style and comfort."
      },
      {
        name: "Tempo Traveller On Rent in Pune Contact Number",
        description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Tempo Traveller rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
      }
    ],
    tableData: [
      ['- Tempo Traveller in Pune', '- 17 Seater Bus on Rent in Pune Price'],
      ['- 17 Seater Tempo Traveller on Rent in Pune', '- Traveller on Rent Pune'],
      ['- Traveller Bus on Rent in Pune', '- Tempo Traveller on Rent Pune to Outstation'],
      ['- Tempo Traveller on Rent in Pune', '- 17 Seater Tempo Traveller on Rent in Pune'],
      ['- Pune to Shirdi Tempo Traveller Rate', '- Tempo Traveller Hire in Pune'],
      ['- Tempo Traveller on Rent in Hadapsar', '- Tempo Traveller on Rent in Pune Rate Per Km'],
      ['- Tempo Traveller Rental Pune', '- Pune to Mahabaleshwar Cab Tempo Traveller'],
      ['- Pune to Goa Tempo Traveller on Rent', '- Luxury Tempo Traveller on Rent in Pune']
    ],
    whychoose: [
      {
        WhyChooseheading: "Why Choose Shraddha Travels' Tempo Traveller on Rent in Pune?",
        WhyChoosedescription: "When it comes to renting a Tempo Traveller in Pune, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Whether you're planning a family trip or a corporate outing, our service ensures that you travel in style and comfort. Here's why Shraddha Travels is the best choice for your trip:"
      },
      {
        WhyChooseheading: "Variety of Options:",
        WhyChoosedescription: "Shraddha Travels offers Tempo Travellers in different seating capacities, ranging from 9-seaters to 26-seaters, accommodating groups of any size."
      },
      {
        WhyChooseheading: "Luxury and Comfort:",
        WhyChoosedescription: "Our vehicles are equipped with push-back seats, ample legroom, air conditioning, and entertainment systems to ensure a pleasant travel experience."
      },
      {
        WhyChooseheading: "Professional Drivers:",
        WhyChoosedescription: "All Tempo Travellers come with highly skilled, professional drivers, ensuring safe and hassle-free travel for all passengers."
      },
      {
        WhyChooseheading: "Affordable Rates:",
        WhyChoosedescription: "We provide competitive pricing, making group travel both affordable and enjoyable for everyone."
      },
      {
        WhyChooseheading: "Well-Maintained Fleet:",
        WhyChoosedescription: "Our Tempo Travellers are regularly serviced and thoroughly cleaned, ensuring they are in excellent condition for reliable and safe travel."
      },
      {
        WhyChooseheading: "24/7 Customer Support:",
        WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist with your booking and address any inquiries or concerns."
      },
      {
        WhyChooseheading: "Clean and Safe Vehicles:",
        WhyChoosedescription: "We prioritize hygiene and safety, with our vehicles regularly cleaned and maintained to provide a safe journey."
      }
    ]
  }



  const faqData = [
    {
      question: "What seating capacities do Shraddha Travels’ Tempo Travellers offer?",
      answer: "Shraddha Travels offers Tempo Travellers with seating capacities ranging from 9-seater, 12-seater, 17-seater, and up to 26-seater, depending on your group size."
    },
    {
      question: "What are the rental charges for a Tempo Traveller in Pune?",
      answer: "The rental charges vary depending on the type of Tempo Traveller, the duration, and distance of your trip. Typically, the rates start from ₹18 to ₹25 per kilometer."
    },
    {
      question: "Are drivers included in the Tempo Traveller rental service?",
      answer: "Yes, all Tempo Traveller bookings come with professional, experienced drivers who ensure a safe and comfortable journey."
    },
    {
      question: "Can I rent a Tempo Traveller for an outstation trip?",
      answer: "Absolutely! Shraddha Travels provides Tempo Travellers for both local and outstation trips. You can travel to popular destinations around Pune or anywhere across Maharashtra."
    },
    {
      question: "How do I book a Tempo Traveller with Shraddha Travels?",
      answer: "You can easily book a Tempo Traveller by contacting Shraddha Travels via their website, phone, or visiting their office. Provide details about your trip, and they will assist you with availability and pricing."
    },
    {
      question: "Is there a minimum rental period for Tempo Traveller bookings?",
      answer: "Yes, the minimum rental period is typically one day, but it may vary depending on the type of booking and destination. Please confirm during booking."
    },
    {
      question: "What amenities are available in Shraddha Travels’ Tempo Travellers?",
      answer: "Our Tempo Travellers come equipped with modern amenities such as air conditioning, comfortable reclining seats, ample legroom, and luggage space. Entertainment options are available in some vehicles."
    },
    {
      question: "Can I modify or cancel my booking?",
      answer: "Yes, you can modify or cancel your booking by contacting our customer service. Please note that cancellation fees may apply depending on the timing of the cancellation."
    },
    {
      question: "Are there any hidden charges in the Tempo Traveller rental?",
      answer: "No, our pricing is transparent. Any additional charges, such as tolls, parking, or night halt fees, will be communicated to you in advance."
    },
    {
      question: "Do you offer Tempo Traveller services for corporate events or weddings?",
      answer: "Yes, Shraddha Travels provides Tempo Travellers for a variety of events, including corporate trips, weddings, family outings, and more."
    },
    {
      question: "How far in advance should I book a Tempo Traveller for my trip?",
      answer: "We recommend booking at least 1-2 weeks in advance to ensure availability, especially during peak travel seasons or for large group bookings."
    },
    {
      question: "What safety measures do you take for Tempo Traveller trips?",
      answer: "Safety is our top priority. All our vehicles are regularly serviced, sanitized, and equipped with first aid kits. Our drivers are experienced and follow all road safety guidelines."
    }
  ];



  const testimonials = [
    {
      name: "Mr. Nikhil Sharma",
      text: "We booked a 17-seater Tempo Traveller from Shraddha Travels for our family trip to Mahabaleshwar, and it was a fantastic experience. The vehicle was clean, comfortable, and had great legroom. Our driver was very professional and ensured we had a smooth journey. I highly recommend Shraddha Travels for group trips!"
    },
    {
      name: "Ms. Sneha Desai",
      text: "Shraddha Travels provided excellent service for our corporate outing. The 12-seater Tempo Traveller we rented was well-maintained, and the booking process was hassle-free. The driver was punctual and drove safely throughout our trip. It was a great experience overall!"
    }
  ];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Tempo Traveller On Rent In Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];






  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller On Rent in Pune | Affordable 17 Seater Bus Rentals</title>
    <meta name="description" content="Rent luxury tempo travellers in Pune, including 17-seater options for local and outstation trips. Affordable rates and top-notch service for your travel needs!" />
    <meta name="keywords" content="Tempo Traveller in Pune, 17 seater bus on rent in Pune price, 17 seater tempo traveller on rent in Pune, Traveller on rent Pune, Traveller bus on rent in Pune, Tempo Traveller on rent Pune to Outstation, Tempo traveller on rent in Pune, 17 seater tempo traveller on rent in Pune, Pune to Shirdi tempo traveller rate, Tempo Traveller hire in Pune, Tempo Traveller on rent in Hadapsar, Tempo Traveller on rent in Pune rate per km, Tempo Traveller Rental Pune, Pune to Mahabaleshwar cab tempo traveller, Pune to Goa tempo traveller on rent, Luxury Tempo Traveller on rent in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller On Rent in Pune | Affordable 17 Seater Bus Rentals" />
    <meta property="og:description" content="Discover the best tempo traveller rental services in Pune. Rent a 17-seater bus for local and outstation trips with competitive pricing!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-on-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller Rental",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-on-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller",
                    "description": "Luxury Tempo Traveller on Rent in Pune for local and outstation trips."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-01.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}


              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Shraddha Travels' Tempo Traveller on Rent in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  // color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Tempotraveeronrentinpune;