
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Besthireinpimpri() {



  const cardData =
  {
    keyword: ' Bus Hire in Pimpri Chinchwad',
    heading: 'Shraddha Travels: Bus Hire in Pimpri Chinchwad',
    headingDescription: 'When it comes to reliable and comfortable bus hire services in Pimpri Chinchwad, Shraddha Travels stands out as a top choice. Whether you need a bus for a corporate event, family outing, school trip, or any other occasion, we offer a range of options tailored to meet your needs. Our extensive fleet and commitment to quality ensure that your journey is smooth and enjoyable. Here’s a look at the top bus hire options we provide in Pimpri Chinchwad:',

    topPlaces: [
        {
            title: "1. 35-Seater Bus Hire",
            description: "Ideal for medium-sized groups, our 35-seater buses are perfect for corporate outings, family reunions, and group tours. These buses offer a comfortable ride with ample seating and storage space. Equipped with air conditioning and modern amenities, they ensure a pleasant journey. Whether you're heading to a destination or just exploring, this option provides the right balance of space and comfort."
        },
        {
            title: "2. 40-Seater Bus Hire",
            description: "For slightly larger groups, our 40-seater buses provide extra comfort and space. They are well-suited for school trips, inter-city travel, and group vacations, ensuring a pleasant journey for everyone. With spacious interiors, these buses feature reclining seats and entertainment options, making long journeys enjoyable and stress-free. Ideal for teams or families traveling together, this option ensures everyone can travel in style."
        },
        {
            title: "3. 45-Seater Bus Hire",
            description: "Our 45-seater buses cater to larger groups needing more room. With spacious seating and modern amenities, these buses are perfect for corporate events, weddings, and other significant gatherings. They come equipped with top-notch audio systems and charging ports, allowing passengers to stay entertained and connected during the journey. This option is ideal for those looking to travel comfortably without compromising on space."
        },
        {
            title: "4. 50-Seater Bus Hire",
            description: "Our 50-seater buses offer the largest capacity for big groups. Whether it's a large corporate event, a team-building exercise, or a major family celebration, these buses provide ample space and comfort. Designed for efficiency and convenience, they include ample luggage space and onboard restrooms, making them suitable for long-distance travel. Enjoy a seamless travel experience with everything you need right on board."
        },
        {
            title: "5. Luxury AC Bus Hire",
            description: "For a touch of luxury, our AC buses come equipped with climate control, comfortable seating, and additional amenities. Perfect for long journeys and special occasions, these buses ensure a premium travel experience. Passengers can enjoy plush interiors, entertainment systems, and refreshments, making every trip feel special. Ideal for weddings or high-profile corporate events, this option elevates group travel."
        },
        {
            title: "6. Mini Bus Hire",
            description: "Our mini buses are an excellent choice for smaller groups or more intimate gatherings. With seating options ranging from 14 to 32 seats, these buses are ideal for short trips and local travel. They provide a cozy atmosphere, making them great for family outings or small corporate meetings. With comfortable seating and a friendly driver, this option is perfect for navigating through busy city streets or exploring hidden gems."
        },
        {
            title: "7. Urbania Bus Hire",
            description: "Experience the comfort and sophistication of our Urbania buses. These premium vehicles offer advanced features and plush interiors, making them perfect for high-end travel needs. With spacious seating, modern entertainment systems, and superior climate control, your journey will be as luxurious as your destination. Ideal for executive travel or VIP events, this option ensures you arrive in style."
        },
        {
            title: "8. Tempo Traveller Hire",
            description: "Our tempo travellers provide a compact yet comfortable option for group travel. Ideal for shorter trips and smaller groups, they offer flexibility and ease of maneuverability. With comfortable seating and enough space for luggage, these vehicles are perfect for excursions and day trips. The economical nature of tempo travellers makes them a popular choice for budget-conscious travelers seeking comfort."
        },
        {
            title: "9. School Bus Hire",
            description: "We offer specially designed buses for school trips, ensuring safety and comfort for students. Our school buses are well-maintained and equipped with all necessary safety features, including seat belts and first-aid kits. They provide a fun and safe environment for educational outings, sports events, and field trips. With experienced drivers familiar with school protocols, you can trust us for a worry-free travel experience."
        },
        {
            title: "10. Wedding Bus Hire",
            description: "Make your special day even more memorable with our wedding bus hire service. Our buses are decorated and customized to match your wedding theme, providing a stylish and comfortable transportation solution for you and your guests. We ensure punctuality and efficiency, allowing you to focus on celebrating your big day. With options for bridal parties and guest shuttles, our service is designed to make wedding transportation seamless and elegant."
        }
    ],    
    services: [
        {
            name: "Bus Hire in Pimpri Chinchwad for Outstation",
            description: "For outstation trips, our bus hire service in Pimpri Chinchwad provides comfortable and reliable transportation. Whether you’re heading to nearby cities like Nashik or distant destinations such as Goa, our well-maintained buses ensure a smooth and pleasant journey. Our experienced drivers are familiar with various routes, ensuring timely arrivals and a hassle-free travel experience."
        },
        {
            name: "Bus Rental in Pimpri Chinchwad",
            description: "Shraddha Travels offers comprehensive bus rental services in Pimpri Chinchwad. Choose from a variety of bus sizes and types to meet your specific needs, from family outings to corporate events. Each bus is equipped with modern amenities such as comfortable seating, air conditioning, and ample storage, ensuring that your journey is as enjoyable as your destination."
        },
        {
            name: "Bus on Rent Near Me Pimpri Chinchwad",
            description: "Looking for a bus rental near you in Pimpri Chinchwad? Our service provides convenient options for renting a bus close to your location, making it easy to book the vehicle you need for your trip. With our user-friendly booking process and prompt service, you can secure the right vehicle without any hassle, whether it's for a day trip or an extended journey."
        },
        {
            name: "Urbania Bus on Rent in Pimpri Chinchwad",
            description: "For a luxurious and comfortable travel experience, consider renting an Urbania bus in Pimpri Chinchwad. Ideal for special events or high-profile trips, the Urbania offers premium amenities such as plush seating, onboard entertainment, and spacious interiors, making it perfect for corporate retreats, weddings, or any event where comfort is a priority."
        },
        {
            name: "14, 17, 25, 32 Seater on Rent in Pimpri Chinchwad",
            description: "Our fleet includes a range of bus sizes to suit different group sizes. Whether you need a 14-seater for a small gathering or a 32-seater for a larger family reunion, we have the right vehicle for your needs. All our buses are maintained to the highest standards, ensuring a safe and comfortable travel experience for your group."
        },
        {
            name: "35, 40, 45, 50 Seater on Rent in Pimpri Chinchwad",
            description: "For larger groups, we offer buses ranging from 35 to 50 seats. These spacious buses are perfect for corporate outings, family reunions, or any event requiring ample seating and comfort. Each bus is equipped with air conditioning and comfortable seating arrangements, ensuring that everyone can relax and enjoy the journey."
        },
        {
            name: "AC Tempo Traveller on Rent in Pimpri Chinchwad",
            description: "Our AC tempo travellers are ideal for those seeking a comfortable and climate-controlled vehicle for travel. Perfect for both short and long trips, these vehicles ensure a pleasant journey with features like reclining seats, ample legroom, and air conditioning, making them suitable for family vacations or corporate travel."
        },
        {
            name: "Mini Bus on Rent in Chinchwad",
            description: "For smaller groups or intimate gatherings, our mini buses provide a convenient and comfortable option. Explore Chinchwad and surrounding areas with ease using our well-maintained mini buses, which are ideal for local events, day trips, or short excursions. They offer flexibility and ease of maneuverability, making them perfect for navigating city streets."
        },
        {
            name: "Bus Hire for School Picnic in Pimpri Chinchwad",
            description: "Planning a school picnic? Our bus hire service in Pimpri Chinchwad is perfect for transporting students and staff to and from picnic locations. Enjoy a safe and comfortable ride with our dedicated school trip buses, which are equipped with necessary safety features to ensure the well-being of all passengers while promoting a fun and enjoyable outing."
        },
        {
            name: "Corporate Bus Hire in Pimpri Chinchwad",
            description: "Our corporate bus hire service is tailored to meet the needs of business groups. Whether for meetings, conferences, or team-building events, we offer comfortable and reliable buses to ensure your corporate transportation is seamless and efficient. With professional drivers and a focus on punctuality, we help you make the best impression at your business events."
        },
        {
            name: "Bus Hire in Pimpri Chinchwad for Wedding Function",
            description: "Make your wedding day special with our bus hire service in Pimpri Chinchwad. We offer stylish and comfortable buses to transport guests to and from wedding functions, ensuring a smooth and elegant experience. Our buses can be decorated to match your wedding theme, adding a touch of personalization to your special day."
        },
        {
            name: "Bus Hire for Events Party in Pimpri Chinchwad",
            description: "For parties and special events, our bus hire service provides a fun and convenient way to transport guests. Choose from our range of buses to accommodate your party needs and ensure everyone arrives in comfort. Whether it’s a birthday party, reunion, or celebration, we ensure a lively and enjoyable atmosphere during the journey."
        },
        {
            name: "Shraddha Travels Pune On Rent",
            description: "Shraddha Travels is your trusted partner for all bus rental needs in Pimpri Chinchwad. With a wide range of vehicles including Urbania buses, mini buses, tempo travellers, and more, we are dedicated to providing exceptional service for any occasion. Our focus is on customer satisfaction, ensuring that your travel experience exceeds expectations."
        },
        {
            name: "Bus Hire in Pimpri Chinchwad Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient bus hire in Pimpri Chinchwad. We ensure a smooth and enjoyable ride for all our customers, prioritizing your comfort and satisfaction. Our friendly team is always ready to assist with your queries and bookings."
        }
    ],    
    tableData: [
        ['- Bus Hire in Pimpri Chinchwad for Outstation', '- Bus Rental in Pimpri Chinchwad'],
        ['- Bus on Rent Near Me Pimpri Chinchwad', '- Urbania Bus on Rent in Pimpri Chinchwad'],
        ['- 14, 17, 25, 32 Seater on Rent in Pimpri Chinchwad', '- 35, 40, 45, 50 Seater on Rent in Pimpri Chinchwad'],
        ['- AC Tempo Traveller on Rent in Pimpri Chinchwad', '- Bus on Rent in Pimpri Chinchwad'],
        ['- Mini Bus on Rent in Chinchwad', '- Bus Hire for School Picnic in Pimpri Chinchwad'],
        ['- Corporate Bus Hire in Pimpri Chinchwad', '- Bus Hire in Pimpri Chinchwad for Wedding Function'],
        ['- Bus Hire for Events Party in Pimpri Chinchwad', '- Shraddha Travels Pune On Rent']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Hire in Pimpri Chinchwad?",
            WhyChoosedescription: "When it comes to bus hire in Pimpri Chinchwad, Shraddha Travels stands out as a trusted provider committed to delivering comfortable and reliable journeys. Whether you're planning a family outing, a corporate event, or a school trip, our services ensure that you travel in style and comfort. Here’s why Shraddha Travels is the best choice for your travel needs:"
        },
        {
            WhyChooseheading: "Diverse Fleet of Vehicles:",
            WhyChoosedcription: "At Shraddha Travels, we pride ourselves on offering a diverse fleet of buses tailored to accommodate various group sizes and travel requirements. From compact mini buses perfect for smaller gatherings to spacious 50-seater options designed for larger events, we have the ideal vehicle for every occasion. Each bus is equipped with modern features to ensure a comfortable journey."
        },
        {
            WhyChooseheading: "Comfort and Convenience:",
            WhyChoosedescription: "We prioritize passenger comfort in every aspect of our bus services. Our vehicles come equipped with state-of-the-art amenities, including air conditioning, plush seating with ample legroom, and entertainment systems to enhance your travel experience. We strive to create an environment where you can relax, socialize, or even work while on the move."
        },
        {
            WhyChooseheading: "Professional and Experienced Drivers:",
            WhyChoosedescription: "Our team of drivers is not only highly trained and experienced but also dedicated to ensuring your safety and comfort. Familiar with the best routes in and around Pimpri Chinchwad, our drivers maintain a high standard of professionalism. They prioritize smooth driving, punctuality, and courteous service, making your journey pleasant from start to finish."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "At Shraddha Travels, we believe in offering transparent and competitive pricing with no hidden fees. Our detailed quotes break down all costs, ensuring you understand what you're paying for. This commitment to fair pricing means you can enjoy high-quality service without worrying about unexpected expenses."
        },
        {
            WhyChooseheading: "Customizable Packages:",
            WhyChoosedescription: "We understand that every event is unique, which is why we offer flexible and customizable bus hire packages tailored to your specific needs. Whether you're organizing a corporate event, a family gathering, a school trip, or a special occasion, our team will work closely with you to create a package that aligns perfectly with your requirements and budget."
        },
        {
            WhyChooseheading: "Reliable Service:",
            WhyChoosedescription: "Reliability is at the core of our service ethos. We ensure that our buses are meticulously maintained and adhere to strict schedules. Our commitment to punctuality means you can count on us to be there on time, allowing you to plan your itinerary without any last-minute surprises."
        },
        {
            WhyChooseheading: "Excellent Customer Support:",
            WhyChoosedescription: "Our customer support team is dedicated to providing assistance at every stage of your journey. From the initial booking process to post-journey follow-ups, we are here to address any questions or concerns you may have. Our goal is to ensure a seamless experience, making your travel plans as stress-free as possible."
        },
        {
            WhyChooseheading: "Safety and Cleanliness:",
            WhyChoosedescription: "Safety is a top priority at Shraddha Travels. Our buses undergo regular maintenance and comprehensive inspections to ensure they are in peak condition. Additionally, we maintain rigorous cleanliness standards, regularly sanitizing our vehicles to provide a hygienic environment for all passengers. Your safety and comfort are our utmost concern."
        }
    ]
    
  }


  const faqData = [
    {
        question: "What types of buses are available for hire in Pimpri Chinchwad?",
        answer: "We offer a range of buses including 14-seater, 17-seater, 32-seater, 35-seater, 40-seater, and 50-seater options. Additionally, we provide mini buses, luxury buses, and tempo travellers to suit various group sizes and preferences."
    },
    {
        question: "How can I book a bus with Shraddha Travels?",
        answer: "Booking is simple! You can contact us via phone or email to discuss your requirements. Our customer service team will assist you with availability, pricing, and booking details to ensure a seamless reservation process."
    },
    {
        question: "Are the buses equipped with air conditioning?",
        answer: "Yes, we offer both AC and non-AC buses. For added comfort, especially during hot weather, we recommend our AC buses which are equipped with climate control systems."
    },
    {
        question: "What amenities are provided on the buses?",
        answer: "Our buses are equipped with a range of amenities including comfortable seating, air conditioning (for AC buses), ample luggage space, and sometimes onboard entertainment. Specific amenities may vary depending on the type of bus you choose."
    },
    {
        question: "Is there a minimum or maximum number of passengers required?",
        answer: "We accommodate various group sizes. Whether you need a small mini bus for a few people or a large 50-seater bus for a big group, we have options to fit your needs. For exact requirements, please contact us for detailed information."
    },
    {
        question: "Are there any additional charges?",
        answer: "Our pricing is transparent with no hidden costs. The total cost depends on factors such as the type of bus, distance, and duration of hire. We provide detailed quotes, so you are aware of the full cost before confirming your booking."
    },
    {
        question: "Can I customize my bus hire package?",
        answer: "Yes, we offer customizable packages to meet your specific needs. Whether you have special requirements for pickup and drop-off locations or need additional services, we can tailor a package to suit your preferences."
    },
    {
        question: "What should I do if there is a delay or issue during the trip?",
        answer: "If you encounter any issues during your trip, please contact our customer support team immediately. We are here to assist you and resolve any problems promptly to ensure a smooth journey."
    },
    {
        question: "How far in advance should I book a bus?",
        answer: "We recommend booking as early as possible to ensure availability, especially during peak seasons. Early booking also allows you to secure the best rates and options for your trip."
    },
    {
        question: "What safety measures are in place for the journey?",
        answer: "We prioritize safety with regular maintenance and inspections of our buses. Our drivers are trained to handle various road conditions, and we adhere to strict safety protocols to ensure a secure journey for all passengers."
    }
];



const testimonials = [
    {
        name: "Mr. Arjun Patel",
        text: "I recently hired a 40-seater bus from Shraddha Travels for a family reunion, and the experience was outstanding. The bus was spacious, clean, and equipped with all the necessary amenities. The driver was courteous and professional, ensuring a smooth and enjoyable journey from Pimpri Chinchwad to our destination. The booking process was hassle-free, and the customer service team was very responsive. I highly recommend Shraddha Travels for anyone looking for reliable and comfortable bus hire services in Pimpri Chinchwad!"
    },
    {
        name: "Miss Sneha Deshmukh",
        text: "Our office chose Shraddha Travels for a corporate outing, and we were extremely pleased with the service. We hired a 50-seater AC bus, and it was perfect for our group. The bus was well-maintained, with comfortable seating and excellent air conditioning. The driver arrived on time and handled the journey professionally. The entire process, from booking to the actual trip, was seamless and efficient. I would definitely use Shraddha Travels again for any future travel needs in Pimpri Chinchwad."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: "Bus Hire in Pimpri Chinchwad Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire in Pimpri Chinchwad | Affordable & Comfortable Journeys</title>
    <meta name="description" content="Reliable bus hire services in Pimpri Chinchwad for outstation trips, school picnics, weddings, and corporate events. Various seating options available." />
    <meta name="keywords" content="Bus Hire in Pimpri Chinchwad, Bus Rental, Outstation Bus Hire, Mini Bus on Rent, Tempo Traveller, Shraddha Travels Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire in Pimpri Chinchwad | Affordable & Comfortable Journeys" />
    <meta property="og:description" content="Explore our bus hire services in Pimpri Chinchwad for school picnics, weddings, and corporate events. Comfortable and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-hire-pimpri-chinchwad" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/pimpri-bus-hire.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire in Pimpri Chinchwad",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pimpri Chinchwad",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-hire-pimpri-chinchwad",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Rental Services in Pimpri Chinchwad",
                    "description": "Affordable bus hire services for outstation trips, school picnics, weddings, and corporate events in Pimpri Chinchwad."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 2 -08.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Bus Hire in Pimpri Chinchwad – Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Besthireinpimpri;