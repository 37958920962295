
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireforweddinginpune() {



  const cardData =
  {
    keyword: ' Bus Hire Services for Weddings in Pune',
    heading: 'Shraddha Travels: Bus Hire Services for Weddings in Pune',
    headingDescription: 'Planning a wedding involves numerous details, and transportation is a crucial aspect that can make or break your special day. Shraddha Travels offers top-notch bus hire services to ensure that your guests arrive at the wedding venue comfortably and on time. Here’s a look at the top bus hire options for weddings in Pune provided by Shraddha Travels:',
    top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. 14-Seater Minibus",
            description: "Ideal for smaller wedding parties or transporting guests from different locations to the venue. Our 14-seater minibuses are equipped with comfortable seating and air conditioning, ensuring a pleasant journey. With ample space for luggage and personal belongings, this minibus is perfect for intimate gatherings, making it a convenient choice for your special day."
        },
        {
            title: "2. 17-Seater Minibus",
            description: "Perfect for medium-sized groups, this minibus offers ample space and comfort. It’s a great choice for shuttling guests to and from wedding-related events or the reception. The additional seating allows for a more relaxed atmosphere, making it easy for everyone to enjoy the ride together, enhancing the overall experience of the wedding festivities."
        },
        {
            title: "3. 25-Seater Bus",
            description: "For larger gatherings, our 25-seater buses provide more room and comfort. These buses are well-suited for transporting guests between hotels, the wedding venue, and other locations. With plush seating and ample legroom, your guests will travel in style, making the journey part of the celebration."
        },
        {
            title: "4. 32-Seater Bus",
            description: "This option is perfect for accommodating extended family and friends. With extra space and modern amenities, our 32-seater buses ensure that everyone travels comfortably. Equipped with air conditioning and entertainment systems, this bus makes for a fun and enjoyable travel experience for all your guests."
        },
        {
            title: "5. 35-Seater Luxury Bus",
            description: "Elevate the wedding experience with our 35-seater luxury buses. Featuring plush seating, enhanced air conditioning, and additional amenities, these buses provide a premium travel experience for your guests. The luxurious interiors and spacious seating arrangements create a sophisticated atmosphere, perfect for special occasions."
        },
        {
            title: "6. 40-Seater Executive Bus",
            description: "Our 40-seater executive buses are ideal for large wedding parties seeking a blend of luxury and functionality. These buses come with high-end features and ample space, ensuring a sophisticated travel experience. Ideal for transporting bridal parties or large families, these buses offer the comfort and style that matches the grandeur of your wedding."
        },
        {
            title: "7. 45-Seater Deluxe Bus",
            description: "For the ultimate in comfort and style, our 45-seater deluxe buses offer spacious seating and premium amenities. This option is perfect for large weddings where comfort and elegance are priorities. With features like reclining seats and modern entertainment systems, your guests will enjoy a relaxing ride to the wedding venue."
        },
        {
            title: "8. 50-Seater Bus",
            description: "Our 50-seater buses are designed to handle very large groups. With generous seating and modern facilities, these buses ensure that every guest travels comfortably to the wedding venue. Perfect for coordinating transportation for guests from various locations, these buses help keep your wedding schedule on track."
        },
        {
            title: "9. Urbania Luxury Mini Bus",
            description: "The Urbania luxury mini bus offers a stylish and comfortable option for wedding transportation. With modern amenities and a sleek design, it’s a great choice for smaller groups looking for a touch of elegance. Its compact size allows for easy maneuverability in urban areas, making it a practical choice for city weddings."
        },
        {
            title: "10. Bharat Benz 35-Seater Bus",
            description: "Our Bharat Benz 35-seater buses combine luxury with reliability. Known for their smooth ride and comfortable seating, these buses provide a high-quality travel experience for wedding guests. With well-appointed interiors and a focus on passenger comfort, they ensure that your guests arrive at the venue relaxed and ready to celebrate."
        }
    ],    
    services: [
        {
            name: "Bus Hire Service for Wedding in Pune",
            description: "We provide specialized bus hire services designed to cater to the unique requirements of wedding events in Pune. Whether you need a small bus for a few guests or a large one for an entire wedding party, we’ve got you covered. Our services are tailored to ensure that every guest enjoys a comfortable and timely journey to the venue."
        },
        {
            name: "Pune Bus Rental for Wedding",
            description: "Our bus rental services in Pune are ideal for weddings, offering a variety of options to suit different group sizes and preferences. With a diverse fleet of well-maintained buses, you can choose the perfect vehicle that aligns with your wedding theme and guest requirements, ensuring a smooth and joyful experience on your special day."
        },
        {
            name: "AC Bus Hire for Wedding",
            description: "Comfort is key for wedding transportation. Our air-conditioned buses ensure a pleasant journey for all your guests, regardless of the weather. Enjoy a stress-free travel experience with a cool, comfortable environment that lets your guests arrive relaxed and ready to celebrate."
        },
        {
            name: "Shraddha Travels Pune",
            description: "As a leading provider of wedding bus hire services in Pune, Shraddha Travels is known for our reliability, professionalism, and excellent customer service. Our dedicated team works closely with you to understand your needs, ensuring that all transportation logistics are flawlessly executed on your big day."
        },
        {
            name: "Affordable Bus Hire for Wedding",
            description: "We offer competitive pricing without compromising on quality. Our affordable bus hire options ensure that you get the best value for your money while providing comfortable transport for your guests. Experience premium service at budget-friendly rates, allowing you to allocate more to other wedding expenses."
        },
        {
            name: "Bus Hire for All Wedding Functions",
            description: "From pre-wedding rituals to the reception, our buses are available for all wedding functions, ensuring seamless transportation throughout the event. We coordinate schedules efficiently, allowing your guests to focus on enjoying the celebrations without worrying about logistics."
        },
        {
            name: "Bride and Groom Bus Hire Pune",
            description: "We provide special bus services for the bride and groom, ensuring they travel in comfort and style to and from the wedding venue. Experience luxury and elegance as you make your grand entrance and exit, with attentive service catering to your every need."
        },
        {
            name: "Budget Bus Rental for Wedding in Pune",
            description: "Our budget-friendly options make it easy to manage your wedding expenses without sacrificing quality. Get the best rates on bus rentals for your wedding in Pune, allowing you to provide excellent transportation for your guests while keeping costs in check."
        },
        {
            name: "Comfortable Bus Hire for Wedding",
            description: "Our fleet of buses is equipped with comfortable seating and modern amenities to make sure your guests travel in comfort throughout the wedding events. Features like extra legroom, climate control, and entertainment options ensure that everyone enjoys the journey as much as the destination."
        },
        {
            name: "25-Seater Bus Service for Wedding",
            description: "Perfect for medium-sized wedding parties, our 25-seater buses offer ample space and comfort for your guests. This option is ideal for ensuring that groups travel together, fostering camaraderie and making the journey part of the celebration."
        },
        {
            name: "26-Seater Bus Service for Wedding",
            description: "Slightly larger than our 25-seater option, the 26-seater bus provides additional space and comfort, ideal for accommodating more guests. This bus is perfect for larger family groups, allowing everyone to share in the excitement and joy of the wedding day."
        },
        {
            name: "32-Seater Bus Service for Wedding",
            description: "For larger groups, our 32-seater buses offer ample seating and features to ensure a comfortable journey for everyone. With room for extended families and friends, this option helps keep everyone together, making for a unified travel experience."
        },
        {
            name: "50-Seater Bus Hire Service for Wedding",
            description: "Our 50-seater buses are designed for very large wedding parties, providing spacious and comfortable seating for all your guests. Ideal for coordinating transport from multiple locations, these buses ensure that no one is left out of the celebration."
        },
        {
            name: "Bus Rental for Wedding Party",
            description: "We offer flexible bus rental options for wedding parties, ensuring you have the right transportation for all your guests, from the ceremony to the reception. Our team will work with you to plan routes and schedules that fit your wedding itinerary perfectly."
        },
        {
            name: "Sleeper Coach Bus on Rent for Wedding Party",
            description: "For those looking for a luxurious and comfortable option, our sleeper coach buses offer reclining seats and additional amenities for a premium travel experience. Perfect for long distances, these coaches ensure your guests arrive refreshed and ready to celebrate."
        },
        {
            name: "Bus Hire For Wedding Pune",
            description: "Shraddha Travels provides comprehensive bus hire services specifically for weddings in Pune, ensuring all your transportation needs are met. With a focus on safety, comfort, and punctuality, we strive to make your special day truly unforgettable."
        },
        {
            name: "Bus Booking for Wedding in Pune",
            description: "Our easy booking process allows you to secure the perfect bus for your wedding with just a few simple steps. We provide assistance throughout the booking process to ensure you select the best option for your needs and budget."
        },
        {
            name: "Wedding Bus Hire Near Me Pune",
            description: "Searching for a wedding bus hire service near you in Pune? Look no further—Shraddha Travels is here to provide excellent service and convenient options for your special day, with competitive pricing and a fleet that meets your needs."
        },
        {
            name: "Shuttle Rental for Wedding in Pune",
            description: "Ensure seamless transportation for your wedding guests with our shuttle rental services. Ideal for moving guests between the venue, hotels, and other locations, our shuttles offer comfort and reliability to keep the festivities flowing smoothly."
        },
        {
            name: "Wedding Party Bus Rental",
            description: "Elevate your wedding experience with our party bus rental services. Perfect for transporting bridal parties or guests, our party buses are equipped with amenities to add a touch of luxury and enjoyment, creating lasting memories."
        },
        {
            name: "Wedding Bus Transportation in Pune",
            description: "Shraddha Travels specializes in wedding bus transportation across Pune. Our services are designed to handle various needs, ensuring everyone arrives at the venue on time and in style, enhancing the overall wedding experience."
        },
        {
            name: "Party Bus for Wedding",
            description: "Celebrate your wedding in style with a party bus from Shraddha Travels. Our party buses feature modern amenities, spacious interiors, and fun extras to provide a lively and enjoyable travel experience for your guests."
        },
        {
            name: "Bus for Wedding Travels",
            description: "Whether you need transportation for a small gathering or a large wedding party, we offer a range of buses suited for all your wedding travel needs. Our dedicated service ensures that your guests arrive at every function comfortably and punctually."
        },
        {
            name: "Bus Booking for Wedding",
            description: "Secure your wedding transportation easily with our bus booking services. We offer a straightforward booking process, ensuring that you get the perfect bus for your wedding day, complete with all the necessary arrangements for a hassle-free experience."
        },
        {
            name: "Bus on Rent for Wedding in Pimpri Chinchwad",
            description: "For weddings in Pimpri Chinchwad, Shraddha Travels provides bus rental services tailored to meet the specific needs of your event. Our local knowledge and expertise ensure a smooth and comfortable experience for all guests."
        },
        {
            name: "Bus Rental for Wedding Near Me",
            description: "Looking for bus rental services near you? Shraddha Travels offers local bus rental options for weddings, making it easy to find the right transportation for your special day, complete with exceptional customer service."
        },
        {
            name: "AC Bus Booking for Marriage",
            description: "Keep your guests comfortable with our AC bus booking services. Our air-conditioned buses ensure a pleasant journey, regardless of the weather, making them ideal for wedding events where comfort is a priority."
        },
        {
            name: "Full Bus Booking for Marriage Price",
            description: "Get transparent pricing for full bus bookings for your marriage event. We offer competitive rates and detailed cost breakdowns to help you plan your wedding transportation budget effectively, ensuring no surprises along the way."
        },
        {
            name: "Pune to Outstation Bus Booking for Marriage",
            description: "If your wedding involves travel to outstation locations, our services cover Pune to various destinations. We provide reliable and comfortable bus transportation for outstation wedding events, ensuring a seamless experience for all involved."
        },
        {
            name: "Bus Booking for Marriage Event",
            description: "Plan your wedding transportation with ease using our bus booking services. Whether it's a local or outstation wedding event, we ensure that your transportation needs are met with efficiency and professionalism, allowing you to focus on your special day."
        },
        {
            name: "Bus Hire For Wedding in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Bus Hire For Wedding in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers."
        }
    ],    
    tableData: [
        ['- Bus Hire Service for Wedding in Pune', '- Pune Bus Rental for Wedding'],
        ['- AC Bus Hire for Wedding', '- Shraddha Travels Pune'],
        ['- Affordable Bus Hire for Wedding', '- Bus Hire for All Wedding Functions'],
        ['- Bride and Groom Bus Hire Pune', '- Budget Bus Rental for Wedding in Pune'],
        ['- Comfortable Bus Hire for Wedding', '- 25-Seater Bus Service for Wedding'],
        ['- 26-Seater Bus Service for Wedding', '- 32-Seater Bus Service for Wedding'],
        ['- 50-Seater Bus Hire Service for Wedding', '- Bus Rental for Wedding Party'],
        ['- Sleeper Coach Bus on Rent for Wedding Party', '- Bus Hire for Wedding Pune'],
        ['- Bus Booking for Wedding in Pune', '- Bus Rental for Wedding'],
        ['- Wedding Bus Hire Near Me Pune', '- Shuttle Rental for Wedding in Pune'],
        ['- Wedding Party Bus Rental', '- Wedding Bus Transportation in Pune'],
        ['- Party Bus for Wedding', '- Bus for Wedding Travels'],
        ['- Bus Booking for Wedding', '- Bus on Rent for Wedding in Pimpri Chinchwad'],
        ['- Bus Rental for Wedding Near Me', '- AC Bus Booking for Marriage'],
        ['- Full Bus Booking for Marriage Price', '- Pune to Outstation Bus Booking for Marriage'],
        ['- Bus Booking for Marriage Event', '']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Hire For Wedding in Pune?",
            WhyChoosedescription: "At Shraddha Travels, we specialize in providing exceptional bus hire services tailored for weddings in Pune. Our commitment to quality and customer satisfaction ensures that your transportation needs are met with the utmost care and precision, allowing you to focus on creating beautiful memories on your special day."
        },
        {
            WhyChooseheading: "Comprehensive Fleet of Buses:",
            WhyChoosedescription: "Our extensive fleet includes a variety of buses, from cozy minibuses for intimate gatherings to luxurious coaches designed for larger wedding parties. Each vehicle is equipped to handle your specific requirements, ensuring that every guest enjoys a comfortable ride, regardless of the group size or destination."
        },
        {
            WhyChooseheading: "Comfort and Luxury:",
            WhyChoosedescription: "We prioritize your guests' comfort with our modern buses. Each vehicle features state-of-the-art amenities, including air-conditioning, plush reclining seats, and ample legroom. Our aim is to create an inviting atmosphere where your guests can relax, socialize, and arrive refreshed and ready to celebrate."
        },
        {
            WhyChooseheading: "Reliable and Professional Service:",
            WhyChoosedescription: "Our team is dedicated to maintaining a high standard of reliability and professionalism. Our experienced drivers are not only skilled but also familiar with the local routes and wedding venues, ensuring that your guests arrive at their destinations safely and punctually. With us, you can trust that your transportation will be seamless and stress-free."
        },
        {
            WhyChooseheading: "Customizable Options:",
            WhyChoosedescription: "Understanding that every wedding is unique, we offer customizable services that allow you to tailor your transportation according to your specific needs. Whether you require a particular bus size, additional features like Wi-Fi or refreshments, or a themed decor, we work closely with you to ensure every detail aligns with your vision."
        },
        {
            WhyChooseheading: "Competitive Pricing:",
            WhyChoosedescription: "We believe in providing transparent and competitive pricing for our wedding bus hire services. Our flexible packages cater to various budgets, ensuring you receive excellent value without compromising on quality. We provide detailed cost breakdowns so you can plan your wedding transportation budget effectively."
        },
        {
            WhyChooseheading: "Easy Booking Process:",
            WhyChoosedescription: "Our user-friendly booking process is designed to make your planning as straightforward as possible. With just a few clicks or a quick call, you can secure the perfect bus for your wedding day. We also provide confirmation and ongoing support to address any questions or changes leading up to your event."
        },
        {
            WhyChooseheading: "Local Expertise:",
            WhyChoosedescription: "As a Pune-based company, we possess an in-depth understanding of the local area and its wedding venues. This local expertise enables us to provide efficient and reliable transportation solutions tailored to your needs, ensuring timely arrivals and departures, no matter the complexity of your wedding itinerary."
        },
        {
            WhyChooseheading: "Exceptional Customer Service:",
            WhyChoosedescription: "Our dedicated customer service team is committed to ensuring that your experience with us is nothing short of exceptional. From your initial inquiry to the day of your wedding, we are here to assist with any questions, special requests, or last-minute adjustments, ensuring that every detail is taken care of for a smooth transportation experience."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of buses do you offer for wedding hire?",
        answer: "We offer a variety of buses for wedding hire, including shuttles, party buses, and luxury coaches. Whether you need a small bus for a few guests or a large bus for an entire wedding party, we have options to suit your needs."
    },
    {
        question: "How can I book a bus for my wedding?",
        answer: "Booking a bus is easy with Shraddha Travels. Simply contact us via phone or email, provide details about your wedding, and we will assist you in selecting the right bus and finalize your booking."
    },
    {
        question: "What features are included in your wedding buses?",
        answer: "Our wedding buses come with modern amenities such as air-conditioning, comfortable seating, and ample space. Some options may also include additional features like entertainment systems and refreshments, depending on your requirements."
    },
    {
        question: "Are your buses equipped for long-distance travel?",
        answer: "Yes, our buses are well-maintained and equipped to handle long-distance travel. Whether your wedding involves local or outstation trips, we ensure that your journey is comfortable and enjoyable."
    },
    {
        question: "What are your pricing options for wedding bus hire?",
        answer: "We offer competitive and transparent pricing for our wedding bus hire services. Pricing varies based on the type of bus, duration of hire, and additional features. Contact us for a detailed quote tailored to your wedding needs."
    },
    {
        question: "Can I make changes to my booking after it is confirmed?",
        answer: "Yes, we offer flexibility with bookings. If you need to make changes to your reservation, please contact us as soon as possible, and we will do our best to accommodate your requests."
    },
    {
        question: "How early should I book a bus for my wedding?",
        answer: "To ensure availability and secure the best options, we recommend booking your wedding bus as early as possible. Early booking also allows for better planning and coordination of your transportation needs."
    },
    {
        question: "What measures do you take to ensure the safety of passengers?",
        answer: "The safety of our passengers is a top priority. Our buses are regularly maintained, and our drivers are experienced and trained to provide safe and reliable transportation. We adhere to all safety regulations and guidelines to ensure a secure journey."
    }
];



const testimonials = [
    {
        name: "Mr. Rahul Patel",
        text: "We recently hired Shraddha Travels for our wedding transportation, and I couldn’t be happier with the service. From the moment we reached out to book, the process was smooth and professional. The bus was immaculate and equipped with everything we needed for our guests to travel comfortably between the ceremony and reception. The driver was punctual and courteous, ensuring everything went off without a hitch. Highly recommend Shraddha Travels for anyone looking for reliable and top-notch bus hire for their wedding in Pune!"
    },
    {
        name: "Ms. Priya Sharma",
        text: "Choosing Shraddha Travels for our wedding bus hire was one of the best decisions we made. We had a large guest list, and their fleet of buses ensured that everyone was accommodated comfortably. The buses were modern and well-maintained, and the service was exceptional. Our guests praised the comfort and convenience of the transportation. Shraddha Travels made our wedding day stress-free by handling all the transportation needs with professionalism and care. Thank you for making our special day even more memorable!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus Hire For Wedding in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Wedding in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Affordable bus rental services for weddings in Pune, including AC and sleeper coaches for all wedding functions." />
    <meta name="keywords" content="Bus Hire for Wedding in Pune, Pune bus rental for wedding, AC bus hire for wedding, wedding party bus rental, shuttle rental for wedding in Pune" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire for Wedding in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Book affordable bus rental services for weddings in Pune. Comfortable transportation for all wedding functions." />
    <meta property="og:url" content="https://www.amuratatravels.com/bus-hire-wedding-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/wedding-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire for Wedding",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/bus-hire-wedding-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Wedding Bus Rental in Pune",
                    "description": "Affordable and comfortable bus hire services for all wedding functions in Pune."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-05.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs – Bus Hire For Wedding in Pune with Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireforweddinginpune;