import React from 'react';
import { Helmet } from 'react-helmet';

const Blog = () => {
    const cardData = {
        keyword: "Blog",
    };
    const Images = [
        {
          place: "/img/tempoo.jpg",
          text: "Tempo Traveller Hire in Pune",
        },
        {
          place: "/img/minu-buss.jpg",
          text: "Mini Bus On Rent in Pune",
        },
        {
          place: "/img/32-seat.jfif",
          text: "32 Seater Bus Rent in Pune",
        },
        {
          place: "/img/45-seatt.png",
          text: "45 Seater Bus on Rent in Pune",
        },
        {
          place: "/img/luxury.jfif",
          text: "Luxury Bus Rental in Pune",
        },
        {
          place: "/img/forcee.webp",
          text: "Force Urbania On Rent in Pune",
        },
        {
          place: "/img/buss.jpg",
          text: "Bus Service for Picnic in Pune",
        },
        {
          place: "/img/minu-buss.jpg",
          text: "17 Seater Tempo Traveller on Rent in Pune",
        },
      ];
    
    
    

    return (
        <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

<section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Blog</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
           
            <section>
                {/* <div className="container px-0"> */}
                    <div className="row g-4 justify-content-center">
                        {/* Featured Blog Post */}
                        <div className="col-xl-4 col-lg-6 col-md-6">
                            <div className="news-card-items mt-0">
                                <div className="news-image">
                                    <img src="/img/blog.webp" alt="featured-blog" />
                                </div>
                                <div className="news-content">
                                    <div className="news-cont">
                                        <h3>
                                            <a href="#" style={{ color: '#6A1349' }}>
                                                Why Choose Shraddha Travels for Your Travel Needs?
                                            </a>
                                        </h3>
                                        <p>At Shraddha Travels s, we prioritize your comfort and safety, ensuring an unforgettable travel experience across Pune and beyond.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

                        {/* Image Cards Section */}
                       
                       
                    </div>
                </div>
            </section>
        </div>
    );
};

export default Blog;
