
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function PuneLocalbusonrent() {



  const cardData =
  {
    keyword: 'Local Buses for Rent in Pune ',
    heading: 'Shraddha Travels: Local Buses for Rent in Pune ',
    headingDescription: 'When it comes to finding reliable local bus rental services in Pune, Shraddha Travels stands out as a premier choice. Whether you are planning a corporate event, family reunion, or any group travel, Shraddha Travels offers an exceptional fleet of buses to suit your needs. Here’s a detailed look at the top local buses for rent in Pune provided by Shraddha Travels.',
    top: 'Top Places to Visit in Pune with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Luxury Mini Bus (15-20 Seats)",
            description: "Ideal for small groups, Shraddha Travels' Luxury Mini Bus offers unparalleled comfort and style. With plush, reclining seating designed for relaxation, advanced air conditioning to keep you cool, and state-of-the-art entertainment systems featuring music and video options, this bus transforms travel into a delightful experience. Perfect for corporate outings, weddings, and family gatherings, this mini bus ensures that every journey is memorable, allowing you to arrive at your destination refreshed and in good spirits."
        },
        {
            title: "2. Standard Mini Bus (20-30 Seats)",
            description: "The Standard Mini Bus from Shraddha Travels strikes a perfect balance between comfort and affordability. Equipped with spacious, comfortable seating and essential amenities like air conditioning and a sound system, it’s an excellent choice for school trips, local sightseeing, and small group tours. This option allows groups to travel together without sacrificing comfort, making it a popular choice for various occasions. With its practical features, it’s designed to make every outing enjoyable and hassle-free."
        },
        {
            title: "3. Executive Bus (30-40 Seats)",
            description: "For larger groups seeking a touch of sophistication, the Executive Bus offers spacious seating and high-end features designed for comfort. With extra legroom, modern air conditioning, and premium interiors that include stylish finishes, it’s perfectly suited for business meetings, conferences, and group travel within Pune. This bus provides a professional atmosphere, making it ideal for corporate events, where comfort and style are paramount. Experience a seamless journey with amenities that cater to your every need."
        },
        {
            title: "4. Deluxe Bus (40-50 Seats)",
            description: "The Deluxe Bus combines spaciousness with ultimate comfort. Featuring enhanced amenities such as reclining seats, Wi-Fi connectivity for your devices, and on-board refreshments to keep you energized, it’s perfect for longer local trips, day tours, and extended events in and around Pune. This bus not only provides ample space but also ensures that every passenger enjoys a relaxing and enjoyable journey, making it a favored choice for family outings and corporate events where convenience and comfort are key."
        },
        {
            title: "5. Premium Coach (50-60 Seats)",
            description: "Shraddha Travels’ Premium Coach is tailored for those who seek luxury on a larger scale. With high-quality interiors that exude elegance, ample legroom for added comfort, and state-of-the-art amenities including entertainment systems and charging ports, it’s designed for large events, corporate outings, and inter-city tours within Pune. This coach not only provides comfort but also enhances the overall travel experience, ensuring that you and your group arrive at your destination feeling refreshed and ready to engage."
        },
        {
            title: "6. Executive Luxury Bus (60-70 Seats)",
            description: "For a truly upscale experience, the Executive Luxury Bus offers an opulent travel environment that caters to all your needs. Features include plush reclining seats, personalized air conditioning controls, advanced multimedia systems for entertainment, and attentive on-board services. It's the go-to option for executive travel and high-profile events, ensuring that passengers arrive at their destination feeling valued and well cared for, with every detail designed to enhance comfort and satisfaction."
        },
        {
            title: "7. Economy Bus (30-40 Seats)",
            description: "If you're looking for a budget-friendly option without compromising on comfort, the Economy Bus is an excellent choice. It provides basic amenities, including comfortable seating and efficient air conditioning, making it suitable for school excursions, local transfers, and casual group outings. This bus allows groups to travel affordably while still enjoying a pleasant journey, ensuring that everyone can participate in group activities without the stress of high transportation costs."
        },
        {
            title: "8. Party Bus (20-30 Seats)",
            description: "For a fun and unique travel experience, the Party Bus is equipped with entertainment systems, mood lighting, and comfortable seating that can be customized for celebrations. It’s perfect for bachelor parties, birthdays, and group adventures around Pune, providing a lively atmosphere that keeps the party going even on the road. With features designed for enjoyment, this bus transforms your journey into a celebration, making every moment memorable."
        },
        {
            title: "9. Tourist Bus (40-50 Seats)",
            description: "Designed for sightseeing and tourist activities, the Tourist Bus features large windows for panoramic views, air conditioning for comfort, and comfortable seating that accommodates longer journeys. It’s ideal for guided city tours, heritage trips, and leisure activities around Pune. This bus makes exploring new destinations enjoyable, allowing passengers to take in the sights comfortably while knowledgeable guides share the history and culture of each location."
        },
        {
            title: "10. Shuttle Bus (15-20 Seats)",
            description: "The Shuttle Bus is perfect for short-distance travel and frequent trips. With a focus on convenience and practicality, it’s ideal for airport transfers, hotel shuttles, and local business commutes. This option provides a reliable solution for everyday travel needs, ensuring timely and comfortable transportation for all passengers. Its compact size allows for easy maneuverability in busy areas, making it a favorite for quick trips around the city."
        }
    ],    
    services: [
        {
            name: "Bus Rental in Pune",
            description: "Shraddha Travels offers the best bus rental services in Pune, catering to a wide range of travel needs. Whether you're planning a family trip, corporate outing, school excursion, or pilgrimage, we provide comfortable buses with professional drivers to ensure a smooth and safe journey. Our fleet includes a variety of sizes, from 32-seater to 50-seater buses, making it easy to find the perfect vehicle for any occasion."
        },
        {
            name: "32, 35, 40, 45, 50 Seater Buses",
            description: "With Shraddha Travels, you can choose from an extensive range of bus sizes, including 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. These buses are perfect for various events such as weddings, school trips, corporate events, and weekend getaways. Our well-maintained vehicles and experienced drivers ensure you travel in comfort and style, regardless of the size of your group."
        },
        {
            name: "Pune Darshan Tempo Traveller on Rent",
            description: "Explore Pune’s rich cultural heritage with our Pune Darshan Tempo Traveller on rent. Ideal for small groups and family trips, our air-conditioned, spacious tempo travellers provide a comfortable experience as you visit famous attractions like Shaniwar Wada, Aga Khan Palace, and Parvati Hill. Enjoy a seamless journey while discovering the best of Pune with ease."
        },
        {
            name: "Pune to Sinhagad Fort Mini Bus on Rent",
            description: "Planning a trip to Sinhagad Fort? Shraddha Travels offers mini buses on rent for a comfortable and reliable journey. Perfect for school outings, trekking groups, or family excursions, our mini-buses come equipped with all necessary amenities, ensuring a smooth ride to this historic fort. Enjoy the scenic views along the way and create lasting memories with your group."
        },
        {
            name: "Pune to Jejuri Bus Hire",
            description: "For those heading to the revered Jejuri temple, Shraddha Travels provides the best bus hire service. Our spacious and well-maintained buses, driven by experienced drivers, ensure safe and comfortable transportation for families and groups. Enjoy a hassle-free pilgrimage experience as you make your way to one of Maharashtra's sacred sites."
        },
        {
            name: "Pune to Ranjangaon Ganpati Bus Hire",
            description: "Our Pune to Ranjangaon Ganpati bus hire service is designed for those seeking convenient and affordable transportation for pilgrimage trips. With ample space, comfortable seating, and modern amenities, our buses offer a smooth journey to one of the most sacred Ganpati temples in the region, making your spiritual experience enjoyable and memorable."
        },
        {
            name: "Pune to Ketkawale Balaji Temple Bus on Rent",
            description: "Experience a comfortable journey with our Pune to Ketkawale Balaji Temple bus on rent. Shraddha Travels provides top-notch bus rental services for religious trips, ensuring a peaceful pilgrimage. Our clean and spacious buses accommodate groups of all sizes, making the journey to the Ketkawale Balaji Temple a memorable experience filled with devotion and comfort."
        },
        {
            name: "Pune to Narayanpur Tempo Traveller on Rent",
            description: "If you're planning a visit to Narayanpur, our tempo traveller on rent is the ideal choice for small groups and families. Offering a comfortable ride, our vehicles allow you to relax and enjoy scenic views during your journey. With experienced drivers and well-maintained vehicles, your trip will be safe, enjoyable, and hassle-free."
        },
        {
            name: "Pune to Morachi Chincholi Bus on Rent",
            description: "Visit the picturesque village of Morachi Chincholi with ease using our bus on rent. Known for its peacock sanctuary, Morachi Chincholi is a great spot for family groups, school trips, or corporate outings. Our spacious and comfortable buses provide an affordable solution for group travel, allowing you to explore this unique destination without any stress."
        },
        {
            name: "Pune Local Bus on Rent",
            description: "For local travel needs, Shraddha Travels offers a variety of buses for rent, including options for one-day trips, events, and city tours. Whether it’s a family function, wedding, or local tour, we provide a range of buses suitable for any group size, ensuring comfortable travel within Pune and nearby areas."
        },
        {
            name: "Pune Local Bus Hire for Pune Darshan",
            description: "Explore Pune with our local bus hire service, specifically designed for Pune Darshan. Ideal for groups looking to visit the city’s rich cultural and historical landmarks, our knowledgeable drivers ensure an informative and smooth tour to places like Shaniwar Wada and Aga Khan Palace, making it an enriching experience for all."
        },
        {
            name: "Pune Darshan Mini Bus on Rent",
            description: "For smaller groups, our Pune Darshan mini bus on rent is the perfect choice. Travel comfortably while exploring Pune’s top attractions, from historical sites to bustling markets. Our air-conditioned and well-maintained mini-buses ensure a smooth and enjoyable tour for you and your group, making it easy to discover the beauty of the city."
        },
        {
            name: "Pune Local Bus on Rent for One Day",
            description: "Shraddha Travels offers local buses for rent for one-day trips, perfect for family outings, corporate events, and more. Whether it’s a wedding, a local tour, or just a day out with friends, our buses provide a reliable and comfortable option for group travel, ensuring that you have a memorable experience."
        },
        {
            name: "Pune Local Bus on Rent for Local Trips",
            description: "Planning a local trip in Pune? Our local bus on rent service is designed to provide hassle-free transportation for various occasions, from family gatherings to corporate outings. With a fleet of modern buses, you can travel around Pune comfortably and affordably, making your event planning easier."
        },
        {
            name: "Pune to Jejuri Tempo Traveller on Rent",
            description: "For smaller groups heading to Jejuri, our tempo traveller on rent service offers a comfortable and smooth ride. Equipped with modern amenities, our vehicles ensure families and small groups have an enjoyable journey while visiting the Jejuri temple, making it a pleasant experience from start to finish."
        },
        {
            name: "Pune to Bhimashankar Mini Bus on Rent",
            description: "Make your pilgrimage to Bhimashankar effortless with our mini bus on rent service. Perfect for group travel, our mini-buses offer comfortable seating and a smooth journey to one of Maharashtra’s renowned pilgrimage sites. Ideal for school trips, family outings, or group pilgrimages, we ensure your journey is both enjoyable and memorable."
        },
        {
            name: "Pune Local Bus on Rent Contact Number",
            description: "For prompt and efficient bus rental services in Pune, contact Shraddha Travels at +91 8087847191. Our dedicated team is ready to assist you with your booking, ensuring a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
        }
    ],
    
    tableData: [
        ['- Bus Rental in Pune', '- Bus Hire in Pune'],
        ['- 32, 35, 40, 45, 50 Seater', '- Bus Rental in Pune'],
        ['- Pune Darshan Tempo Traveller on Rent', '- Pune to Sinhagad Fort Mini Bus on Rent'],
        ['- Pune to Jejuri Bus Hire', '- Pune to Ranjangaon Ganpati Bus Hire'],
        ['- Pune to Ketkawale Balaji Temple Bus on Rent', '- Pune to Narayanpur Tempo Traveller on Rent'],
        ['- Pune to Morachi Chincholi Bus on Rent', '- Pune Local Bus on Rent'],
        ['- Pimple Gurav Local Bus on Rent', '- Pune Local Bus Hire for Pune Darshan'],
        ['- Pune Darshan Mini Bus on Rent', '- Pune Local Bus on Rent for One Day'],
        ['- Pune Local Bus on Rent for Local Trips', '- Pune to Jejuri Tempo Traveller on Rent'],
        ['- Pune to Bhimashankar Mini Bus on Rent', '- Bus Hire in Pune']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Pune Local Bus Rentals?",
            WhyChoosedescription: "When it comes to renting a local bus in Pune, Shraddha Travels stands out as a premier choice for a variety of reasons. Here's why you should choose us for your next local bus rental:"
        },
        {
            WhyChooseheading: "Exceptional Comfort and Convenience:",
            WhyChoosedescription: "At Shraddha Travels, we prioritize your comfort. Our fleet of buses includes a range of options from luxury to standard, ensuring that you travel in style and ease. With spacious seating, air conditioning, and modern amenities, every journey is a pleasant experience."
        },
        {
            WhyChooseheading: "Diverse Fleet of Buses:",
            WhyChoosedescription: "Whether you need a small mini-bus for a family outing or a large deluxe bus for corporate events, we have a diverse fleet to meet your needs. Our buses come in various sizes, including 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options, ensuring that we have the perfect fit for your group size."
        },
        {
            WhyChooseheading: "Affordable and Transparent Pricing:",
            WhyChoosedescription: "We offer competitive pricing with no hidden costs. Our rental rates are transparent and tailored to fit your budget, providing you with great value for your money. Whether it’s a short local trip or a full day rental, we ensure cost-effectiveness without compromising on quality."
        },
        {
            WhyChooseheading: "Experienced and Professional Drivers:",
            WhyChoosedescription: "Our drivers are not only skilled and experienced but also trained to provide a safe and smooth travel experience. With a deep knowledge of local routes and landmarks, they ensure that you reach your destination safely and on time."
        },
        {
            WhyChooseheading: "Reliable and Punctual Service:",
            WhyChoosedescription: "Reliability is at the core of our services. We understand the importance of punctuality, especially for events and schedules. Our buses are well-maintained and adhere to strict schedules to ensure that you arrive at your destination without delays."
        },
        {
            WhyChooseheading: "Flexible Rental Options:",
            WhyChoosedescription: "We offer flexible rental options to accommodate your specific needs. Whether you need a bus for a few hours or an entire day, we can customize our services to fit your itinerary and requirements."
        },
        {
            WhyChooseheading: "Customer-Centric Approach:",
            WhyChoosedescription: "Customer satisfaction is our top priority. From the moment you book with us until the end of your journey, we provide exceptional customer service, addressing any concerns and ensuring a seamless experience."
        },
        {
            WhyChooseheading: "Hassle-Free Booking Process:",
            WhyChoosedescription: "Our booking process is straightforward and user-friendly. You can easily book your local bus rental through our website or by contacting our customer service team. We offer prompt responses and efficient handling of your requests."
        }
    ]
    
  }



  const faqData = [
    {
        question: "What types of buses are available for rent with Shraddha Travels?",
        answer: "Shraddha Travels offers a range of buses for local rentals, including 32-seater, 35-seater, 40-seater, 45-seater, and 50-seater options. We also provide mini-buses and tempo travellers for smaller groups. All our vehicles are well-maintained and equipped with modern amenities."
    },
    {
        question: "How can I book a local bus with Shraddha Travels?",
        answer: "You can book a local bus by contacting us directly through our website or by phone. Provide us with details such as the date of travel, the size of the group, and the destination, and we will assist you in selecting the appropriate bus and finalize the booking."
    },
    {
        question: "Are the buses equipped with amenities?",
        answer: "Yes, our local buses come with a range of amenities including comfortable seating, air conditioning, and audio systems. We strive to ensure that your journey is as comfortable and enjoyable as possible."
    },
    {
        question: "What is the rental cost for a local bus?",
        answer: "The rental cost varies depending on factors such as the type of bus, duration of the rental, and the distance to be traveled. For an accurate quote, please contact us with the details of your trip, and we will provide a customized pricing estimate."
    },
    {
        question: "Can I hire a bus for a single day?",
        answer: "Yes, Shraddha Travels offers bus rentals for single-day trips as well as extended periods. Whether you need a bus for a few hours or an entire day, we can accommodate your needs."
    },
    {
        question: "What areas do you cover for local bus rentals?",
        answer: "We provide local bus rentals throughout Pune and its surrounding areas. If you have a specific destination or route in mind, please let us know, and we will ensure that our services meet your requirements."
    },
    {
        question: "Are the drivers experienced and professional?",
        answer: "Yes, all our drivers are experienced, professional, and well-versed in local routes. They are committed to providing a safe and pleasant journey for all passengers."
    },
    {
        question: "Can I make changes to my booking after it has been confirmed?",
        answer: "If you need to make changes to your booking, please contact us as soon as possible. We will do our best to accommodate any adjustments based on availability and your new requirements."
    },
    {
        question: "What safety measures are in place for passengers?",
        answer: "We prioritize passenger safety by maintaining our buses to the highest standards and ensuring that all safety protocols are followed. Our drivers are trained to handle various situations and ensure a safe journey for everyone on board."
    },
    {
        question: "Is there a minimum rental duration for local bus hires?",
        answer: "While there is no strict minimum rental duration, we generally recommend booking for a minimum of 4-6 hours to ensure availability and meet your travel needs effectively. For specific details, please contact us."
    }
];



const testimonials = [
    {
        name: "Mrs. Anjali Choubey",
        text: "I recently rented a 35-seater mini bus from Shraddha Travels for a family outing in Pune, and I couldn’t be more pleased with the service. The booking process was straightforward, and the staff were incredibly helpful and responsive. The bus itself was spotless, comfortable, and equipped with everything we needed for a day trip. Our driver, Mr. Singh, was professional, punctual, and knowledgeable about the local area, making our journey smooth and enjoyable. I highly recommend Shraddha Travels for anyone in need of a reliable and comfortable bus rental in Pune. They truly made our outing a memorable one!"
    },
    {
        name: "Ms. Sarswati Patel",
        text: "Our company recently hired a 50-seater deluxe bus from Shraddha Travels for an off-site corporate event in Pune. The experience was fantastic from start to finish. The bus was luxurious, with ample space and all the modern amenities, which made the trip extremely comfortable for our team. The booking was hassle-free, and the driver, Mr. Rao, was courteous and efficient. We were impressed by the level of professionalism and the attention to detail in ensuring everything went smoothly. For anyone looking for top-notch bus rental services in Pune, Shraddha Travels is the way to go. We’ll definitely be using their services again!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune Local Bus on Rent Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune Local Bus on Rent | Affordable Bus Rentals</title>
    <meta name="description" content="Explore Pune with our local bus hire services. Reliable and affordable options for Pune Darshan and local trips." />
    <meta name="keywords" content="Pune Local Bus on Rent, Bus Rental in Pune, Bus Hire in Pune, Pune Darshan Mini Bus on Rent, Local Bus for Pune Trips" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune Local Bus on Rent | Affordable Bus Rentals" />
    <meta property="og:description" content="Affordable local bus hire services in Pune for all your sightseeing and travel needs. Safe and comfortable transportation." />
    <meta property="og:url" content="https://www.shraddhatravels.com/pune-local-bus-rent" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/local-bus-rent.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Pune Local Bus on Rent",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/pune-local-bus-rent",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Local Bus Hire in Pune",
                    "description": "Reliable and affordable local bus rental services for Pune sightseeing and trips."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-11.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Pune Local Bus on Rent for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default PuneLocalbusonrent;