import React from 'react';
import { Helmet } from 'react-helmet';
import '../css/TermsConditions.css'; // Ensure this CSS file includes the new styles


const TermsConditions = () => {
  return (
    <div>
    
    <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

        <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Term & Conditions</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>
    

      <section className="terms-section">
        <div className="container ">
          <div className="row g-0">
            {/* Text Content Column */}
            <div className="col-md-7">
              <div className="terms-content">
                <p><b className="highlight">1. Booking Confirmation:</b> All bookings are subject to availability and will be confirmed upon successful payment and issuance of a booking reference number.</p>

                <p><b className="highlight">2. Payment:</b> Full payment is required at the time of booking. Payments can be made online or at our authorized offices.</p>

                <p><b className="highlight">3. Cancellation and Rescheduling:</b> Cancellations and rescheduling are subject to our cancellation policy. Fees may apply based on timing.</p>

                <p><b className="highlight">4. Passenger Responsibility:</b> Passengers must carry valid identification and tickets. We reserve the right to refuse boarding without proper documentation.</p>

                <p><b className="highlight">5. Baggage Policy:</b> Limited baggage is allowed per ticket. Additional baggage may incur extra charges.</p>

                <p><b className="highlight">6. Travel Insurance:</b> We recommend purchasing travel insurance to cover unforeseen events.</p>

                <p><b className="highlight">7. Safety Regulations:</b> Passengers must adhere to all safety regulations and instructions from our staff.</p>

                <p><b className="highlight">8. Changes to Itinerary:</b> We reserve the right to alter itineraries due to unforeseen circumstances.</p>

                <p><b className="highlight">9. Refusal of Service:</b> We may refuse service to passengers exhibiting disruptive behavior or violating terms.</p>

                <p><b className="highlight">10. Jurisdiction:</b> These terms are governed by the laws of [Country/State]. Any disputes shall be resolved in [City].</p>

                <h3>General Policy</h3>
                <p><b className="highlight">Booking Procedure:</b> Book services through our website or customer service.</p>
                <p><b className="highlight">Customer Service:</b> Our team is available to assist with inquiries and requests.</p>

                <h3>Refund Policy</h3>
                <p><b className="highlight">Cancellation Fees:</b> Fees may apply based on the timing of the cancellation.</p>
                <p><b className="highlight">Refund Process:</b> Refunds will be processed according to the original payment method.</p>
                <p><b className="highlight">No-Show Policy:</b> No-shows may not be eligible for refunds.</p>
              </div>
            </div>

            {/* Image Column */}
            <div className="col-md-5">
              <div className="image-container">
                <img src="/img/term.jpg" alt="Terms and Conditions" className="terms-image" />
              </div>
            </div>
          </div>

          <div className="contact-info">
            <h2>Contact Us</h2>
            <p>For any queries regarding our terms, feel free to reach out:</p>
            <ul>
              <li><b>Phone:</b> <a href="tel:+918087847191">+91 8087847191</a></li>
              <li><b>Email:</b> <a href="mailto:booking@shraddhacabs.com">booking@shraddhacabs.com</a></li>
            </ul>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsConditions;
