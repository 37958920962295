import React from 'react';

const BusRatesTable = () => {
  const ratesData = [
    { seater: "17 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "27", specialPermitNumber: "8087847191" , driver: "Extra" , parking: "Excluded"},
    { seater: "20 Seater", mumbai: "Urbania Bus", mahabaleshwar: "30", specialPermitNumber: "8087847191" , driver: "Extra" , parking: "Excluded"},
    { seater: "26 Seater", mumbai: "Ac Bus", mahabaleshwar: "40", specialPermitNumber: "8087847191" , driver: "Extra" , parking: "Excluded"},
    { seater: "35 Seater", mumbai: "Ac Bus", mahabaleshwar: "48", specialPermitNumber: "8087847191" , driver: "Extra" , parking: "Excluded"},
    { seater: "45 Seater", mumbai: "Ac Bus", mahabaleshwar: "55", specialPermitNumber: "8087847191" , driver: "Extra" , parking: "Excluded"},
    { seater: "50 Seater", mumbai: "Ac Bus", mahabaleshwar: "60", specialPermitNumber: "8087847191" , driver: "Extra" , parking: "Excluded"},
  ];

  const ratesData2 = [
    { seater: "17 Seater", mumbai: "Tempo Traveller", mahabaleshwar: "24",specialPermitNumber: "8087847191", driver: "Extra" , parking: "Excluded"},
    { seater: "20 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "28", specialPermitNumber: "8087847191", driver: "Extra" , parking: "Excluded" },
    { seater: "26 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "32", specialPermitNumber: "8087847191", driver: "Extra" , parking: "Excluded"},
    { seater: "35 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "35", specialPermitNumber: "8087847191", driver: "Extra" , parking: "Excluded" },
    { seater: "45 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "45", specialPermitNumber: "8087847191", driver: "Extra" , parking: "Excluded"},
    { seater: "50 Seater", mumbai: "Non Ac Bus", mahabaleshwar: "50", specialPermitNumber: "8087847191", driver: "Extra" , parking: "Excluded" },
  ];

  const tableStyle = {
    width: "100%",
    borderCollapse: "collapse",
    margin: "20px auto"
  };

  const thStyle = {
    backgroundColor: "#6C164C",
    color: "white",
    padding: "8px",
    border: "1px solid #ddd"
  };

  const tdStyle = {
    padding: "8px",
    border: "1px solid #ddd",
    textAlign: "center"
  };

  const trEvenStyle = {
    backgroundColor: "#f2f2f2"
  };

  const handleWhatsAppClick = (number, seater) => {
    const message = `Hi, I would like to inquire about the rates for the ${seater} Bus.`;
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(url, '_blank');
  };

  return (
    <div className='container p-0'>
      <h3>Rates for AC Buses</h3>
      <div className='table-responsive'>
        <table style={tableStyle}>
          <thead>
            <tr>
              <th style={thStyle}>BUSES</th>
              <th style={thStyle}>Bus Name</th>
              <th style={thStyle}>Per KM</th>
              <th style={thStyle}>Driver Food</th>
                <th style={thStyle}>Toll Parking</th>
              <th style={thStyle}>Book Now</th>
            </tr>
          </thead>
          <tbody>
            {ratesData.map((row, index) => (
              <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
                <td style={tdStyle}>{row.seater}</td>
                <td style={tdStyle}>{row.mumbai}</td>
                <td style={tdStyle}>{row.mahabaleshwar}</td>
                <td style={tdStyle}>{row.driver}</td>
                  <td style={tdStyle}>{row.parking}</td>
                <td style={tdStyle}>
                  <button
                    onClick={() => handleWhatsAppClick(row.specialPermitNumber, row.seater)}
                    style={{ cursor: 'pointer',backgroundColor:'#661247',padding:'10px', borderRadius:'8px', color:'white' }}
                  >
                    Book Now
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div>
        <h3>Rates for Non AC Buses</h3>
        <div className='table-responsive'>
          <table style={tableStyle}>
            <thead>
              <tr>
                <th style={thStyle}>BUSES</th>
                <th style={thStyle}>Bus Name</th>
                <th style={thStyle}>Per KM</th>
                <th style={thStyle}>Driver Food</th>
                <th style={thStyle}>Toll Parking</th>
                <th style={thStyle}>Book Now</th>
              </tr>
            </thead>
            <tbody>
              {ratesData2.map((row, index) => (
                <tr key={index} style={index % 2 === 0 ? trEvenStyle : null}>
                  <td style={tdStyle}>{row.seater}</td>
                  <td style={tdStyle}>{row.mumbai}</td>
                  <td style={tdStyle}>{row.mahabaleshwar}</td>
                  <td style={tdStyle}>{row.driver}</td>
                  <td style={tdStyle}>{row.parking}</td>
                  <td style={tdStyle}>
                    <button
                      onClick={() => handleWhatsAppClick(row.specialPermitNumber, row.seater)}
                      style={{ cursor: 'pointer',backgroundColor:'#661247',padding:'10px', borderRadius:'8px', color:'white' }}
                  >
                      Book Now
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default BusRatesTable;




