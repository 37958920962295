import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Header from './components/Header';
import Ourfleet from './pages/Ourfleet';
import ServiceSection from './pages/ServiceSection';
import OurNetwork from './pages/OurNetwork';
import GallerySection from './pages/GallerySection';
import HeroSection from './components/HeroSection';
import AboutSection from './pages/AboutSection';
import FAQSection from './pages/FAQSection';
import TermsConditions from './pages/TermsConditions';
import PrivacyPolicy from './pages/PrivacyPolicy';
import CarList from './pages/CarList';
import '@fortawesome/fontawesome-free/css/all.min.css';
import Booking from './pages/Booking';
import ContactInfo from './pages/ContactInfo';
import Enquiry from './pages/Enquiry';
import CareersSection from './pages/CareersSection';
import Blog from './pages/Blog';
import Foot from './components/Footer';
import BusRatesTable from './pages/BusRatesTable';
import Tempotraveeronrentinpune from './pages/Tempo Traveller On Rent in Pune';
import Packages from './pages/Packages';
import Punetomahabaleshwertempotraveller from './pages/Pune to Mahabaleshwar Tempo Traveller';
import Punetooutstationtempo from './pages/Pune to Outstation Tempo Traveller';
import Minibusonrent from './pages/Mini Bus On Rent in Pune';
import Seaterbus14 from './pages/14 Seater Tempo Traveller on Rent';
import Seater17onrent from './pages/17 Seater Bus on Rent Pune';
import Punetoshirditempo from './pages/Pune to Shirdi Tempo Traveller';
import Seater20onrent from './pages/20 Seater Bus on Rent in Pune';
import ScrollToTop from './components/ScrollToTop';
import Seater25onrent from './pages/25 Seater Tempo Traveller on Rent in Pune';
import TempoTravelleronrentinpimpri from './pages/Tempo Traveller on Rent in Pimpri Chinchwad';
import Seatet32onrent from './pages/32 Seater Bus Rent in Pune';
import Seater35busonrent from './pages/35 Seater Bus on Rent in Pune';
import Seater45onrentinpune from './pages/45 Seater Bus on Rent in Pune';
import Seater50onrent from './pages/50 Seater Bus On Rent in Pune';
import Nonacbusrentalservice from './pages/Non Ac Bus Rental Service in Pune';
import Luxurybusrentalinpune from './pages/Luxury Bus Rental in Pune';
import Luxuryforceurbaniaonrentinpune from './pages/Luxury Force Urbania On Rent in Pune';
import Forceurbanialuxuryminibusonrent from './pages/Force Urbania Luxury Mini Bus On Rent';
import Minibusorrentinpune from './pages/Mini-Bus On Rent in Pune';
import Tempo14seater17seateroninpune from './pages/14 17 20 25 Seater Bus on in Pune';
import Travelagentsinpune from './pages/Travel Agents in Pune for Mini Bus Hire';
import Tempotravellerhireinpune from './pages/Tempo Traveller Hire in Pune';
import Forceurbaniaonrentforshta from './pages/Force Urbania On Rent for Ashtavinayak Darshan';
import Puneromahabaleshwarurbania from './pages/Pune to Mahabaleshwar Urbania On Rent';
import Punetobhimashnakarjyotilinga from './pages/Pune to Bhimashankar Jyotirlinga Mini Bus Hire in Pune';
import Bushireforschool from './pages/Bus Hire for School Trips in Pune';
import PuneLocalbusonrent from './pages/Pune Local Bus on Rent';
import Busonrentinkharadipune from './pages/Bus On Rent in Kharadi Pune';
import Busonrentinhinjewadi from './pages/Bus on Rent in Hinjewadi';
import Bushireforweddinginpune from './pages/Bus Hire For Wedding in Pune';
import Busesforcorporate from './pages/Buses For Corporate Events in Pune';
import Busrentalforschooltripinpune from './pages/Bus Rentals For School Trips in Pune';
import Busbookingforwedding from './pages/Bus booking for wedding in Pune';
import BusBookingformarrieag from './pages/Bus Booking for Marriage function in Pune';
import Busserviceforcorporate from './pages/Bus Service for Corporate Pune';
import Busonrentforeventinpune from './pages/Bus on Rent For Event in Pune';
import Busrentalfortour from './pages/Bus Rental for Tour Packages in Pune';
import Bushireforoutstation from './pages/Bus Hire for Outstation in Pune';
import Punebushirerental from './pages/Pune Bus Hire Rental Service';
import Busserviceforpicnic from './pages/Bus Service for Picnic in Pune';
import Puneto5jyotilinga from './pages/Pune to 5 Jyotirlinga Darshan Bus Hire';
import Punetomumbaidarshan from './pages/Pune to Mumbai Darshan Bus On Rent';
import Busonrentinpimpri from './pages/Bus On Rent in Pimpri Chinchwad';
import Busonrentinpune from './pages/Bus On Rent in Pune';
import Bushirenearme from './pages/Bus Hire Near me Pune';
import Seaterbusonrentinpune from './pages/32,35,40,45,50 Seater Bus on Rent in Pune';
import Punetolonavalabushire from './pages/Pune to Lonavala Bus Hire';
import Punesadeteen from './pages/Pune Sade Teen Shakti Peeth Bus on Rent';
import Punetokonkan from './pages/Pune to Konkan Darshan Bus Hire';
import Punetogoa from './pages/Pune to Goa Bus Hire';
import Besthireinpimpri from './pages/Bus Hire in Pimpri Chinchwad';
import Busonrentinhadapsar from './pages/Bus on Rent in Hadapsar';
import Bushireforpicnic from './pages/Bus Hire for Picnic in Pune';
import Corporattravelsinpune from './pages/Corporate Travels in Pune';
import Jyotilinga from './pages/12 Jyotirlinga Darshan Tour Package from Pune';
import Punetorajashtan from './pages/Pune to Rajasthan Darshan Bus Package';
import Touristbusservice from './pages/Tourist Bus Rental Services';
import Monthlybushireservice from './pages/Monthly Bus Hire Service in Pune';
import Bushireforkedarnath from './pages/Bus Hire For Kedarnath Trip';
import Bushireforpunrtoashta from './pages/Bus hire for Pune to Ashtavinayak';
import Bushireformaharashtra from './pages/Bus hire for Pune to Maharashtra Darshan';
import Exploreallindia from './pages/Explore All India Bus Hire Services From Pune';
import Punetoshanishingnapur from './pages/Pune to Shani Shingnapur Tempo Traveller on Rent';
import Punetoajanta from './pages/Pune to Ajanta Ellora Tempo Traveller On Rent';
import Punetoashtavinayak from './pages/Pune to Ashtavinayak Darshan Tempo Traveller on Rent';
import Punetolonavalatempo from './pages/Pune to Lonavala Tempo Traveller on Rent';
import Punetotrimbakeshwar from './pages/Pune to Trimbakeshwar Tempo Traveller On Rent';
import Punetoshirdiminibus from './pages/Pune to Shirdi Mini Bus Package';
import Luxurytempotraveller from './pages/Luxury Tempo Traveller On Rent in Pune';
import ContactButtons from './components/ContactButtons';
function App() {
  return (
    <Router>
      <ScrollToTop/>
     <Header/>
  <ContactButtons/>
      <Routes>
        
        <Route path="/" element={<HeroSection/>} />
        <Route path="/about-us" element={<AboutSection/>} />
        <Route path="/faqs" element={<FAQSection/>} />
        <Route path="/term-condition" element={<TermsConditions/>} />
        <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
        <Route path="/our-fleet" element={<Ourfleet/>} />
        <Route path="/service" element={<ServiceSection/>} />
        <Route path="/our-network" element={<OurNetwork/>} />
        <Route path="/packages" element={<Packages/>} />
        <Route path="/book-taxi" element={<Booking/>} />
        <Route path="/gallery" element={<GallerySection/>} />
        <Route path="/contact" element={<ContactInfo/>} />
        <Route path="/enquiry" element={<Enquiry/>} />
        <Route path="/carrer" element={<CareersSection/>} />
        <Route path="/blog" element={<Blog/>} />
        <Route path="/Tempo-Traveller-On-Rent-in-Pune" element={<Tempotraveeronrentinpune/>} />
        <Route path="/Pune-to-Mahabaleshwar-Tempo-Traveller" element={<Punetomahabaleshwertempotraveller/>} />
        <Route path="/Pune-to-Outstation-Tempo-Traveller" element={<Punetooutstationtempo/>} />
        <Route path="/Mini-Bus-On-Rent-in-Pune" element={<Minibusonrent/>} />
        <Route path="/14-Seater-Tempo-Traveller-on-Rent" element={<Seaterbus14/>} />
        <Route path="/17-Seater-Bus-on-Rent-Pune" element={<Seater17onrent/>} />
        <Route path="/Pune-to-Shirdi-Tempo-Traveller" element={<Punetoshirditempo/>} />
        <Route path="/20-Seater-Bus-on-Rent-in-Pune" element={<Seater20onrent/>} />
        <Route path="/25-Seater-Tempo-Traveller-on-Rent-in-Pune" element={<Seater25onrent/>} />
        <Route path="/Tempo-Traveller-on-Rent-in-Pimpri-Chinchwad" element={<TempoTravelleronrentinpimpri/>} />
        <Route path="/32-Seater-Bus-Rent-in-Pune" element={<Seatet32onrent/>} />
        <Route path="/35-Seater-Bus-on-Rent-in-Pune" element={<Seater35busonrent/>} />
        <Route path="/45-Seater-Bus-on-Rent-in-Pune" element={<Seater45onrentinpune/>} />
        <Route path="/50-Seater-Bus-On-Rent-in-Pune" element={<Seater50onrent/>} />
        <Route path="/Non-Ac-Bus-Rental-Service-in-Pune" element={<Nonacbusrentalservice/>} />
        <Route path="/Luxury-Bus-Rental-in-Pune" element={<Luxurybusrentalinpune/>} />
        <Route path="/Luxury-Force-Urbania-On-Rent-in-Pune" element={<Luxuryforceurbaniaonrentinpune/>} />
        <Route path="/Force-Urbania-Luxury-Mini-Bus-On-Rent" element={<Forceurbanialuxuryminibusonrent/>} />
        <Route path="/Mini-Bus-On-Rent-in-Pune" element={<Minibusorrentinpune/>} />
        <Route path="/14-17-20-25-Seater-Bus-on-in-Pune" element={<Tempo14seater17seateroninpune/>} />
        <Route path="/Travel-Agents-in-Pune-for-Mini-Bus-Hire" element={<Travelagentsinpune/>} />
        <Route path="/Tempo-Traveller-Hire-in-Pune" element={<Tempotravellerhireinpune/>} />
        <Route path="/Force-Urbania-On-Rent-for-Ashtavinayak-Darshan" element={<Forceurbaniaonrentforshta/>} />
        <Route path="/Pune-to-Mahabaleshwar-Urbania-On-Rent" element={<Puneromahabaleshwarurbania/>} />
        <Route path="/Pune-to-Bhimashankar-Jyotirlinga-Mini-Bus-Hire-in-Pune" element={<Punetobhimashnakarjyotilinga/>} />
        <Route path="/Bus-Hire-for-School-Trips-in-Pune" element={<Bushireforschool/>} />
        <Route path="/Pune-Local-Bus-on-Rent" element={<PuneLocalbusonrent/>} />
        <Route path="/Bus-On-Rent-in-Kharadi-Pune" element={<Busonrentinkharadipune/>} />
        <Route path="/Bus-on-Rent-in-Hinjewadi" element={<Busonrentinhinjewadi/>} />
        <Route path="/Bus-Hire-For-Wedding-in-Pune" element={<Bushireforweddinginpune/>} />
        <Route path="/Buses-For-Corporate-Events-in-Pune" element={<Busesforcorporate/>} />
        <Route path="/Bus-Rentals-For-School-Trips-in-Pune" element={<Busrentalforschooltripinpune/>} />
        <Route path="/Bus-booking-for-wedding-in-Pune" element={<Busbookingforwedding/>} />
        <Route path="/Bus-Booking-for-Marriage-function-in-Pune" element={<BusBookingformarrieag/>} />
        <Route path="/Bus-Service-for-Corporate-Pune" element={<Busserviceforcorporate/>} />
        <Route path="/Bus-on-Rent-For-Event-in-Pune" element={<Busonrentforeventinpune/>} />
        <Route path="/Bus-Rental-for-Tour-Packages-in-Pune" element={<Busrentalfortour/>} />
        <Route path="/Bus-Hire-for-Outstation-in-Pune" element={<Bushireforoutstation/>} />
        <Route path="/Pune-Bus-Hire-Rental-Service" element={<Punebushirerental/>} />
        <Route path="/Bus-Service-for-Picnic-in-Pune" element={<Busserviceforpicnic/>} />
        <Route path="/Luxury-Bus-Rental-in-Pune" element={<Busserviceforpicnic/>} />
        <Route path="/Pune-to-5-Jyotirlinga-Darshan-Bus-Hire" element={<Puneto5jyotilinga/>} />
        <Route path="/Pune-to-Mumbai-Darshan-Bus-On-Rent" element={<Punetomumbaidarshan/>} />
        <Route path="/Bus-On-Rent-in-Pimpri-Chinchwad" element={<Busonrentinpimpri/>} />
        <Route path="/Bus-On-Rent-in-Pune" element={<Busonrentinpune/>} />
        <Route path="/Bus-Hire-Near-me-Pune" element={<Bushirenearme/>} />
        <Route path="/32-35-40-45-50-Seater-Bus-on-Rent-in-Pune" element={<Seaterbusonrentinpune/>} />
        <Route path="/Pune-to-Lonavala-Bus-Hire" element={<Punetolonavalabushire/>} />
        <Route path="/Pune-Sade-Teen-Shakti-Peeth-Bus-on-Rent" element={<Punesadeteen/>} />
        <Route path="/Pune-to-Konkan-Darshan-Bus-Hire" element={<Punetokonkan/>} />
        <Route path="/Pune-to-Goa-Bus-Hire" element={<Punetogoa/>} />
        <Route path="/Bus-Hire-in-Pimpri-Chinchwad" element={<Besthireinpimpri/>} />
        <Route path="/Bus-on-Rent-in-Hadapsar" element={<Busonrentinhadapsar/>} />
        <Route path="/Bus-Hire-for-Picnic-in-Pune" element={<Bushireforpicnic/>} />
        <Route path="/Corporate-Travels-in-Pune" element={<Corporattravelsinpune/>} />
        <Route path="/12-Jyotirlinga-Darshan-Tour-Package-from-Pune" element={<Jyotilinga/>} />
        <Route path="/Pune-to-Rajasthan-Darshan-Bus-Package" element={<Punetorajashtan/>} />
        <Route path="/Tourist-Bus-Rental-Services" element={<Touristbusservice/>} />
        <Route path="/Monthly-Bus-Hire-Service-in-Pune" element={<Monthlybushireservice/>} />
        <Route path="/Bus-Hire-For-Kedarnath-Trip" element={<Bushireforkedarnath/>} />
        <Route path="/Bus-hire-for-Pune-to-Ashtavinayak" element={<Bushireforpunrtoashta/>} />
        <Route path="/Bus-hire-for-Pune-to-Maharashtra-Darshan" element={<Bushireformaharashtra/>} />
        <Route path="/Explore-All-India-Bus-Hire-Services-From-Pune" element={<Exploreallindia/>} />
        <Route path="/Pune-to-Shani-Shingnapur-Tempo-Traveller-on-Rent" element={<Punetoshanishingnapur/>} />
        <Route path="/Pune-to-Ajanta-Ellora-Tempo-Traveller-On-Rent" element={<Punetoajanta/>} />
        <Route path="/Pune-to-Ashtavinayak-Darshan-Tempo-Traveller-on-Rent" element={<Punetoashtavinayak/>} />
        <Route path="/Pune-to-Lonavala-Tempo-Traveller-on-Rent" element={<Punetolonavalatempo/>} />
        <Route path="/Pune-to-Trimbakeshwar-Tempo-Traveller-On-Rent" element={<Punetotrimbakeshwar/>} />
        <Route path="/Pune-to-Shirdi-Mini-Bus-Package" element={<Punetoshirdiminibus/>} />
        <Route path="/Luxury-Tempo-Traveller-On-Rent-in-Pune" element={<Luxurytempotraveller/>} />
        </Routes>
     <Foot/>
    </Router>
 

  );
}

export default App;
