
import { text } from '@fortawesome/fontawesome-svg-core';
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Punebushirerental() {



  const cardData =
  {
    keyword: 'Pune Bus Hire Rental Service',
    heading: 'Shraddha Travels: Pune Bus Hire Rental Service',
    headingDescription: 'When it comes to bus hire rental services in Pune, Shraddha Travels stands out as the premier choice known for its exceptional service and reliability. Whether you’re organizing a corporate event, family outing, or group trip, Shraddha Travels offers a variety of options to ensure a comfortable and enjoyable journey. Here’s a guide to the top places you can explore with our bus hire rental service in Pune:',
    top: 'Top Places to Visit with Shraddha Travels:',

    topPlaces: [
        {
            title: "1. Lonavala",
            description: "Located just a short drive from Pune, Lonavala is a popular hill station celebrated for its scenic beauty and lush landscapes. Visitors can explore attractions like Bhushi Dam, where you can enjoy the monsoon water cascades, and Lonavala Lake, perfect for a peaceful picnic. The Karla Caves, ancient rock-cut Buddhist shrines, offer a glimpse into the region's rich history."
        },
        {
            title: "2. Mahabaleshwar",
            description: "Famous for its stunning viewpoints, such as Arthur’s Seat and Wilson Point, Mahabaleshwar is a must-visit hill station for nature lovers. The area is dotted with fresh strawberry farms, and visitors can indulge in delicious strawberry treats. Enjoy boating in Venna Lake or explore the historic Pratapgad Fort for breathtaking views of the Western Ghats."
        },
        {
            title: "3. Khandala",
            description: "Adjacent to Lonavala, Khandala is known for its picturesque valleys and waterfalls. It's an ideal getaway for adventure enthusiasts, offering trekking routes and scenic viewpoints like Duke’s Nose, which is popular among rock climbers. Khandala Lake provides a serene setting for a day of relaxation surrounded by nature."
        },
        {
            title: "4. Alibaug",
            description: "This coastal town features beautiful beaches and historic forts, making it a great destination for a beach getaway. Alibaug’s beaches, such as Versoli and Alibaug Beach, are perfect for sunbathing and water sports. Don’t miss the historic Kolaba Fort, which can be reached on foot during low tide, offering a glimpse into the region's maritime history."
        },
        {
            title: "5. Pune to Shirdi",
            description: "Known for the Shirdi Sai Baba Temple, Shirdi is a major pilgrimage site drawing devotees from across the country. The temple complex is tranquil and spiritually uplifting, offering a place for reflection and prayer. Our bus rental service makes it easy to travel comfortably, ensuring you have ample time to explore the surrounding attractions and experience the local culture."
        },
        {
            title: "6. Lavasa",
            description: "A planned city with picturesque lakeside views, Lavasa offers a blend of natural beauty and modern amenities. Enjoy lakeside activities like kayaking or simply relax in the serene environment. The vibrant architecture and well-maintained gardens provide a perfect backdrop for a peaceful retreat or a family outing."
        },
        {
            title: "7. Nashik",
            description: "Renowned for its vineyards and wine tours, Nashik is a favorite destination for wine enthusiasts. Visit renowned vineyards like Sula Vineyards and sample exquisite wines while enjoying the picturesque landscape. Nashik is also home to numerous temples and the sacred Godavari River, making it a blend of spirituality and leisure."
        },
        {
            title: "8. Tungabhadra",
            description: "For a historical adventure, head to Tungabhadra, known for its ancient temples and ruins. This area offers rich historical significance, with sites like the famous Hampi nearby. Explore the stunning architecture and intricate carvings, making it an enriching experience for history buffs and spiritual seekers alike."
        },
        {
            title: "9. Kolhapur",
            description: "Famous for its rich cultural heritage and the Mahalaxmi Temple, Kolhapur offers a fascinating glimpse into Maharashtra’s history. The city is known for its unique Kolhapuri cuisine, especially the spicy misal and delicious sweets. Explore local markets for traditional handicrafts and the renowned Kolhapuri chappals."
        },
        {
            title: "10. Ashtavinayak Temples",
            description: "This pilgrimage tour covers eight sacred Ganesh temples around Pune, each with its unique significance and architecture. Experience a spiritual journey as you visit these revered sites, known for their serene ambiance and beautiful surroundings. With Shraddha Travels, you can hire a bus to comfortably visit all the Ashtavinayak temples, making your pilgrimage both convenient and memorable."
        }
    ],    
    services: [
        {
            name: "Bus Hire in Pune",
            description: "At Shraddha Travels, we provide a wide range of affordable bus hire options tailored to meet various group sizes and travel needs. Whether you’re planning a wedding, corporate event, or a group tour, we have the ideal bus rental solutions for you. Our diverse fleet includes luxury, standard, and mini buses, ensuring that you can find the perfect vehicle for your journey. Our dedicated customer support team is always ready to assist with bookings and provide recommendations based on your specific requirements."
        },
        {
            name: "Local Bus Companies for Hire",
            description: "We collaborate with reputable local bus companies to offer a diverse range of vehicles suited for all types of journeys. This partnership not only ensures access to the best options but also competitive rates, providing you with the flexibility and convenience needed for any travel plan. You can rest assured that all our partners adhere to strict safety and maintenance standards."
        },
        {
            name: "Pune Pandharpur Tuljapur Bus on Rent",
            description: "For those embarking on a spiritual journey, our bus rental services to Pandharpur and Tuljapur are designed to ensure a comfortable and serene travel experience. Each bus is equipped with essential amenities such as air conditioning, reclining seats, and entertainment options, allowing you to relax and focus on your spiritual journey. Our experienced drivers are familiar with the best routes to make your trip as smooth as possible."
        },
        {
            name: "Pune to Kolhapur Bus on Rent",
            description: "Explore Kolhapur’s rich cultural heritage and vibrant history with ease using our bus rental services. Our well-maintained buses are designed for comfort, featuring spacious seating and modern amenities, while our professional drivers ensure a hassle-free trip. Enjoy a day of sightseeing, visiting landmarks like the Mahalaxmi Temple and local markets, all while traveling in style and comfort."
        },
        {
            name: "Pune to Konkan Darshan Bus on Rent",
            description: "Discover the stunning scenic beauty of the Konkan region with our reliable bus rentals. We provide comfortable transportation for your Konkan Darshan tour, featuring visits to pristine beaches, historical forts, and lush green landscapes. Our knowledgeable drivers will guide you through the best attractions, ensuring you don’t miss any hidden gems along the way."
        },
        {
            name: "Pune to Shirdi Bus Hire Hadapsar",
            description: "Planning a pilgrimage to Shirdi? Our bus hire services from Hadapsar to Shirdi offer the utmost comfort and convenience, allowing you to focus on your spiritual journey while we handle all transportation logistics. Our buses come with essential facilities, ensuring a pleasant ride to one of India’s most revered pilgrimage sites."
        },
        {
            name: "Rent a Bus in Pune",
            description: "Whether you need a bus for a single day or an extended period, Shraddha Travels offers flexible rental options that cater to your specific needs. Our diverse fleet ensures you can find the perfect vehicle, while our team is committed to providing reliable service, from the booking process to the moment you arrive at your destination."
        },
        {
            name: "Luxury Bus on Rent in Pune",
            description: "Experience premium travel with our luxury bus rentals, ideal for special occasions or high-profile events. Our luxury buses feature plush interiors, spacious seating, and advanced amenities such as Wi-Fi and entertainment systems, ensuring an unforgettable travel experience. Perfect for weddings, corporate events, or any gathering where you want to make a lasting impression."
        },
        {
            name: "40 Seater Bus on Rent in Pune",
            description: "For larger groups, our 40-seater buses offer ample space and comfort, making them perfect for group trips, corporate outings, and events. These buses are equipped with modern facilities to enhance your travel experience, allowing everyone to travel comfortably and arrive together at your destination."
        },
        {
            name: "27 Seater Bus on Rent in Pune",
            description: "Our 27-seater buses are a great choice for medium-sized groups, providing a balance of space and affordability. Ideal for school trips, family gatherings, or corporate events, these buses are designed to ensure that everyone travels comfortably, with enough room for luggage and personal items."
        },
        {
            name: "17 Seater Bus on Rent in Pune",
            description: "Catering to smaller groups, our 17-seater buses offer a cozy and comfortable travel experience. These buses are perfect for intimate gatherings, short trips, or excursions, providing a friendly atmosphere where passengers can enjoy the journey together."
        },
        {
            name: "Traveller Bus on Rent in Pune",
            description: "For versatile travel needs, our Traveller buses provide spacious and comfortable options. Whether you're transporting a medium-sized group or require flexibility in your travel plans, our Traveller buses are equipped with modern amenities to ensure a pleasant journey for everyone."
        },
        {
            name: "Mini Bus on Rent in Pune",
            description: "Ideal for smaller groups or short journeys, our mini buses offer convenience and affordability. Perfect for local trips, corporate outings, or family events, these buses are practical solutions that don’t compromise on comfort."
        },
        {
            name: "Bus Nashik to Aurangabad on Rent",
            description: "Travel comfortably between Nashik and Aurangabad with our dedicated bus rental service. Shraddha Travels offers reliable and well-maintained buses for this route, ensuring a smooth and enjoyable journey. Enjoy the beautiful landscapes along the way while our experienced drivers take care of the road."
        },
        {
            name: "Mini Coach Rental in Pune",
            description: "For a more spacious option that still offers the benefits of a smaller vehicle, consider renting a mini coach from Shraddha Travels in Pune. Our mini coaches are perfect for medium-sized groups and come equipped with comfortable seating and modern amenities to ensure a pleasant journey."
        },
        {
            name: "Bus Hire Rental Service in Pimpri Chinchwad",
            description: "Shraddha Travels offers exceptional bus hire rental services in Pimpri Chinchwad, catering to various transportation needs. Our buses are equipped to handle different group sizes and preferences, ensuring reliable and comfortable travel for any occasion."
        },
        {
            name: "Bus Hire Rental Service in Kharadi Pune",
            description: "In Kharadi, Pune, Shraddha Travels provides premium bus hire rental services tailored to your specific needs. Our fleet includes a range of vehicles suitable for corporate events, family gatherings, and other group travel requirements, ensuring that every journey is enjoyable."
        },
        {
            name: "Bus Hire Rental Service in Kothrud",
            description: "Shraddha Travels extends its reliable bus hire rental services to Kothrud, Pune. Whether you need a bus for a local event, corporate outing, or educational trip, our dedicated fleet and professional drivers ensure a seamless travel experience."
        },
        {
            name: "Best Bus Rental Services in Shivaji Nagar",
            description: "For the best bus rental services in Shivaji Nagar, Pune, Shraddha Travels is your go-to provider. We offer a range of buses suited for different travel needs, from luxury options to economical choices, all backed by our commitment to quality service and customer satisfaction."
        },
        {
            name: "Tempo Traveller Rental Service in Pune",
            description: "If you need a versatile vehicle for your group travel needs, Shraddha Travels offers tempo traveller rental services in Pune. Our tempo travellers are ideal for medium-sized groups, providing comfort and flexibility for both local and outstation trips, ensuring you have a stress-free travel experience."
        },
        {
            name: "Mini Bus Rental Service in Pune",
            description: "Shraddha Travels provides mini bus rental services in Pune, offering a practical and comfortable solution for group travel. Our mini buses are perfect for various occasions, including corporate events, family outings, and small tours, ensuring everyone enjoys a pleasant journey."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers, helping you create unforgettable travel experiences."
        }
    ],    
    tableData: [
        ['- Bus Hire in Pune at Affordable Rate', '- Bus Rental in Pune'],
        ['- Local Bus Companies for Hire', '- Pune Pandharpur Tuljapur Bus on Rent'],
        ['- Pune to Kolhapur Bus on Rent', '- Pune to Konkan Darshan Bus on Rent'],
        ['- Pune to Shirdi Bus Hire Hadapsar', '- Rent a Bus in Pune'],
        ['- Luxury Bus on Rent in Pune', '- 40 Seater Bus on Rent in Pune'],
        ['- 27 Seater Bus on Rent in Pune', '- 17 Seater Bus on Rent in Pune'],
        ['- Traveller Bus on Rent in Pune', '- Bus on Rent in Pune'],
        ['- Mini Bus on Rent Pune', '- Bus Nashik to Aurangabad on Rent'],
        ['- Mini Bus on Rent Pune', '- Pune Bus Hire Rental Service in Pimpri Chinchwad'],
        ['- Bus Hire Rental Service in Kharadi Pune', '- Mini Coach Rental in Pune'],
        ['- Bus Hire Rental Service in Kothrud', '- Best Bus Rental Services in Shivaji Nagar'],
        ['- Tempo Traveller Rental Service in Pune', '- Mini Bus Rental Service in Pune']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Pune Bus Hire Rental Service?",
            WhyChoosedescription: "When it comes to bus hire in Pune, Shraddha Travels stands out as a reliable provider dedicated to ensuring a comfortable and enjoyable journey. Whether you're planning a wedding, corporate event, or family outing, we focus on delivering an exceptional travel experience tailored to your needs. Our commitment to customer satisfaction sets us apart in the industry."
        },
        {
            WhyChooseheading: "Extensive Fleet:",
            WhyChoosedescription: "Shraddha Travels boasts a diverse fleet of well-maintained buses that cater to various group sizes and preferences. From compact mini buses perfect for small gatherings to spacious luxury coaches equipped with the latest amenities, we have the perfect vehicle for your needs. Every bus is meticulously inspected to ensure reliability and comfort on the road."
        },
        {
            WhyChooseheading: "Comfort and Safety:",
            WhyChoosedescription: "We prioritize your comfort and safety throughout your journey. Our buses are equipped with modern amenities such as plush seating, air conditioning, and entertainment systems, ensuring a pleasant experience for all passengers. Additionally, we adhere to stringent safety protocols, equipping our vehicles with safety features like seat belts and fire extinguishers for your peace of mind."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional and experienced drivers are not only skilled in navigating Pune's routes but also trained in customer service. They ensure a smooth and efficient travel experience while being attentive to the needs of passengers. Their knowledge of the area allows them to avoid traffic and take the best routes, making your journey faster and more enjoyable."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "At Shraddha Travels, we believe in offering competitive pricing without compromising on quality. Our transparent pricing structure means no hidden fees, allowing you to plan your budget effectively. We provide various packages that cater to different financial needs, ensuring that you receive the best value for your money while enjoying top-notch services."
        },
        {
            WhyChooseheading: "Customized Packages:",
            WhyChoosedescription: "We understand that every trip is unique and requires a personalized approach. That’s why we offer customized packages tailored to your specific needs, whether it’s for a corporate event, family outing, or pilgrimage tour. Our team works closely with you to create an itinerary that meets your preferences and ensures an unforgettable experience."
        },
        {
            WhyChooseheading: "Reliability:",
            WhyChoosedescription: "With Shraddha Travels, you can count on us to be punctual and dependable. We pride ourselves on our commitment to timely services, allowing you to focus on enjoying your trip without any worries. Our systematic planning and adherence to schedules mean you’ll arrive at your destination on time, every time."
        },
        {
            WhyChooseheading: "24/7 Customer Support:",
            WhyChoosedescription: "Our dedicated customer support team is available around the clock to assist you with your queries and ensure a hassle-free booking experience. Whether you have questions before your trip or need assistance during your journey, our friendly and knowledgeable staff is always ready to help, ensuring you feel supported every step of the way."
        }
    ]
    
  }



  const faqData = [
    {
        question: "How do I book a bus with Shraddha Travels?",
        answer: "You can easily book a bus by contacting our customer service team via phone or email, or by filling out the booking form on our website. We will guide you through the process and confirm your reservation."
    },
    {
        question: "What types of buses are available for hire?",
        answer: "We offer a range of buses, including mini buses, 17-seater AC buses, 25-seater coaches, and larger coaches for bigger groups. You can choose the one that best fits your group size and travel requirements."
    },
    {
        question: "Are the buses equipped with amenities?",
        answer: "Yes, our buses come with various amenities, such as comfortable seating, air conditioning, music systems, and more, depending on the vehicle type. Please inquire about specific amenities when booking."
    },
    {
        question: "Can I customize my travel itinerary?",
        answer: "Absolutely! We provide customized travel packages to suit your specific itinerary and preferences. Just let us know your requirements, and we will create a tailored plan for you."
    },
    {
        question: "What are the payment options?",
        answer: "We offer flexible payment options, including online payments, bank transfers, and cash payments. Details will be provided during the booking process."
    },
    {
        question: "Is there a cancellation policy?",
        answer: "Yes, we have a cancellation policy in place. Please refer to our terms and conditions or speak to our customer service team for detailed information regarding cancellations and refunds."
    },
    {
        question: "Are your drivers experienced?",
        answer: "Yes, all our drivers are highly trained and experienced. They are familiar with the routes and committed to ensuring a safe and pleasant journey for all passengers."
    },
    {
        question: "Do you provide services for long-distance travel?",
        answer: "Yes, we offer bus hire services for both short and long-distance travel. Whether you’re planning a day trip or an extended tour, we’ve got you covered!"
    }
];


const testimonials = [
    {
        name: "Mr. Rahul Deshmukh",
        text: "I recently hired a bus from Shraddha Travels for our company's annual retreat, and I couldn't be more impressed. The booking process was seamless, and the bus was in excellent condition with all the amenities we needed for a comfortable journey. The driver was professional and punctual, ensuring we arrived at our destination on time. I highly recommend Shraddha Travels for anyone looking for reliable and affordable bus rental services in Pune."
    },
    {
        name: "Ms. Priya Shah",
        text: "I used Shraddha Travel for a family wedding event, and their service was outstanding. We rented a luxury bus for our guests, and it was perfect. The bus was clean, well-maintained, and equipped with all the necessary comforts. The team at Shraddha Travels was very helpful and accommodating, making the entire process stress-free. I would definitely use their services again for future events."
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Pune Bus Hire Rental Service Contact Number: ",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Pune Bus Hire Rental Service | Affordable and Reliable</title>
    <meta name="description" content="Affordable bus hire rental services in Pune for local and outstation trips." />
    <meta name="keywords" content="Pune Bus Hire, Bus Rental Service in Pune, Affordable Bus Hire, Mini Bus Rental, Tempo Traveller Hire" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Pune Bus Hire Rental Service | Affordable and Reliable" />
    <meta property="og:description" content="Reliable and affordable bus hire rental services in Pune for local and outstation trips." />
    <meta property="og:url" content="https://www.amuratatravels.com/pune-bus-hire-rental-service" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.amuratatravels.com/assets/images/bus-hire-rental.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire Rental Service in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.amuratatravels.com",
                "logo": "https://www.amuratatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/amuratatravels",
                    "https://twitter.com/amuratatravels",
                    "https://www.instagram.com/amuratatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.amuratatravels.com/pune-bus-hire-rental-service",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Pune Bus Hire Rental Service",
                    "description": "Affordable bus hire rental services in Pune for local and outstation trips."
                }
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 4-01.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs for Pune Bus Hire Rental Service at Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Punebushirerental;