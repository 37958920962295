
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Luxuryforceurbaniaonrentinpune() {



  const cardData =
  {
    keyword: 'Luxury Force Urbania on Rent in Pune ',
    heading: 'Shraddha Travels: Luxury Force Urbania on Rent in Pune ',
    headingDescription: 'When it comes to premium travel experiences in Pune, Shraddha Travels is proud to offer the Luxury Force Urbania for rent. Ideal for those who seek a blend of style, comfort, and sophistication, the Luxury Force Urbania is a top-tier choice for any occasion. Here’s why the Luxury Force Urbania stands out and why you should consider renting it from Shraddha Travels.',
    top: 'Top Place visit in pune with Shraddha Travels',

    topPlaces: [
        {
            title: "1. Shaniwar Wada",
            description: "An iconic historical monument, Shaniwar Wada was once the majestic seat of the Peshwas of the Maratha Empire. Its grand architecture features stunning stone walls, intricate wooden carvings, and beautiful gardens that reflect the opulence of its time. Visitors can explore the rich history through guided tours, and the captivating light and sound show in the evenings vividly depicts the significant events of the Maratha Empire, making it a must-visit for history enthusiasts."
        },
        {
            title: "2. Aga Khan Palace",
            description: "Aga Khan Palace is a place of immense historical importance, known for its beautiful architecture and significant role in India’s freedom struggle. Built in 1892, it served as a prison for Mahatma Gandhi and other leaders during the Quit India Movement. The palace is surrounded by lush gardens and houses a museum that displays photographs and artifacts from the freedom struggle, offering a poignant reminder of India's fight for independence. It's a peaceful place to reflect on history while enjoying the serene surroundings."
        },
        {
            title: "3. Sinhagad Fort",
            description: "Perched atop a hill, Sinhagad Fort is not only a historical treasure but also a favorite trekking destination. The fort boasts panoramic views of the Sahyadri mountain range and the surrounding landscapes, making the trek worthwhile. It has a rich history, including tales of valor from various battles fought here. Visitors can explore the ancient ruins, enjoy local snacks from food stalls, and immerse themselves in the stories that echo through its walls, making it an ideal spot for adventure seekers and history buffs alike."
        },
        {
            title: "4. Osho Ashram",
            description: "Nestled in the tranquil Koregaon Park area, the Osho Ashram is a serene retreat dedicated to meditation and spiritual practices. It attracts visitors from around the globe seeking peace and rejuvenation. The ashram offers various meditation programs and workshops led by experienced instructors. Its beautifully landscaped gardens, serene ambiance, and thoughtfully designed spaces create a peaceful environment for introspection and relaxation, making it an oasis of calm amidst the city’s hustle and bustle."
        },
        {
            title: "5. Pataleshwar Cave Temple",
            description: "Dating back to the 8th century, Pataleshwar Cave Temple is an ancient rock-cut temple dedicated to Lord Shiva. Carved from a single rock, it showcases intricate sculptures and architectural finesse that reflect the artistry of the period. The peaceful atmosphere and the cool stone interiors provide a perfect sanctuary for meditation and prayer. The temple’s historical significance and unique structure make it a fascinating spot for both devotees and architecture enthusiasts."
        },
        {
            title: "6. Parvati Hill",
            description: "Parvati Hill offers breathtaking views of Pune city and is home to several ancient temples, including the revered Parvati Temple. The hill is an ideal spot for an early morning trek or a leisurely outing, surrounded by lush greenery and scenic vistas. Visitors can explore the temple complex, which features stunning architecture and tranquil gardens. The panoramic views from the top are particularly rewarding, making it a favorite among nature lovers and photographers seeking the perfect shot."
        },
        {
            title: "7. Dagdusheth Halwai Ganapati Temple",
            description: "Dagdusheth Halwai Ganapati Temple is one of Pune's most famous temples, dedicated to Lord Ganesha. Renowned for its stunning architecture and vibrant festivals, the temple attracts thousands of devotees, especially during Ganesh Chaturthi. The intricate decorations and the grand idol of Ganesha, adorned with jewels and flowers, create a mesmerizing sight. The temple’s lively atmosphere, especially during festivals, makes it a remarkable place to visit, reflecting Pune's rich cultural heritage."
        },
        {
            title: "8. Raja Dinkar Kelkar Museum",
            description: "Raja Dinkar Kelkar Museum showcases a vast collection of artifacts that illustrate the rich cultural heritage of India. With exhibits ranging from traditional musical instruments and artworks to weapons and jewelry, the museum provides a comprehensive insight into India’s diverse history. Each artifact tells a story, making it an engaging experience for visitors. The museum’s thoughtfully curated displays allow guests to explore the artistic brilliance and craftsmanship of various eras, making it a must-visit for history enthusiasts."
        },
        {
            title: "9. Khadakwasla Dam",
            description: "Khadakwasla Dam is a picturesque picnic spot located on the outskirts of Pune. The dam is especially beautiful during the monsoon when the water levels rise, creating a serene environment perfect for relaxation. Visitors can enjoy scenic views, go boating, or simply unwind by the water. The surrounding hills and lush greenery add to the picturesque setting, making it a favorite weekend getaway for Pune residents. It's an ideal location for families and friends to spend quality time amidst nature."
        },
        {
            title: "10. Pashan Lake",
            description: "Pashan Lake is a tranquil spot ideal for birdwatching and nature walks. The calm waters and surrounding greenery create a peaceful atmosphere, attracting various bird species, making it a hotspot for ornithologists and nature enthusiasts. The lake’s serene environment is perfect for picnics, leisurely strolls, and photography. It offers a welcome escape from the bustling city life, providing a perfect backdrop for those looking to connect with nature and unwind."
        }
    ],    
    services: [
      {
        name: "Tempo Traveller on Rent in Pune",
        description: "Looking for an easy and convenient solution for group travel? Shraddha Travels offers a wide range of Tempo Travellers on rent in Pune for all kinds of trips, whether it's a short local journey or a long-distance outstation trip. Our vehicles are designed to provide ample space, comfort, and reliability, making them ideal for family vacations, corporate outings, or weekend getaways with friends. Enjoy a hassle-free travel experience with all the amenities you need."
      },
      {
        name: "Traveller Bus on Rent in Pune",
        description: "Shraddha Travels provides Traveller buses on rent in Pune with flexible options suited for both small and large groups. Our buses come with comfortable seating, air conditioning, and professional drivers who prioritize your safety and comfort. Whether you’re planning a school trip, a corporate event, or a family reunion, we have the perfect solution to meet your needs."
      },
      {
        name: "Tempo Traveller on Rent Pune to Outstation",
        description: "For long-distance journeys, Shraddha Travels offers Tempo Travellers on rent from Pune to popular outstation destinations like Shirdi, Mahabaleshwar, and Goa. Our well-maintained vehicles ensure a hassle-free and comfortable journey, complete with all necessary amenities, allowing you to relax and enjoy the scenery on your way."
      },
      {
        name: "Pune to Shirdi Tempo Traveller Rate",
        description: "Planning a pilgrimage to Shirdi? Shraddha Travels offers affordable Pune to Shirdi Tempo Traveller rates starting from ₹18 per kilometer. With our professional drivers and well-maintained vehicles, you can expect a comfortable, safe, and memorable journey as you focus on your spiritual experience."
      },
      {
        name: "Tempo Traveller Hire in Pune",
        description: "Shraddha Travels offers Tempo Traveller hire in Pune for all occasions, including family outings, corporate trips, and group tours. With a variety of seating capacities, we can accommodate groups of any size, ensuring everyone travels comfortably. Our flexible booking options and reliable service make planning your trip easy."
      },
      {
        name: "Tempo Traveller on Rent in Hadapsar",
        description: "If you're in Hadapsar and need a reliable travel option, Shraddha Travels provides Tempo Travellers on rent for group travel, family events, and weekend getaways. Our experienced drivers and well-maintained vehicles guarantee a smooth and enjoyable journey for all your local travel needs."
      },
      {
        name: "Tempo Traveller on Rent in Pune Rate Per Km",
        description: "At Shraddha Travels, our Tempo Traveller rates start from ₹18 to ₹25 per kilometer, depending on the vehicle type and trip duration. We pride ourselves on transparent pricing with no hidden costs, ensuring that you receive the best value for your money while enjoying a comfortable travel experience."
      },
      {
        name: "Tempo Traveller Rental in Pune",
        description: "Shraddha Travels is your go-to choice for Tempo Traveller rental in Pune. Whether you're planning a local tour or a long-distance journey, our fleet of well-equipped vehicles is ready to provide a comfortable travel experience. Our knowledgeable staff is available to assist you in selecting the perfect vehicle for your needs."
      },
      {
        name: "Pune to Mahabaleshwar Cab Tempo Traveller",
        description: "Planning a scenic trip to Mahabaleshwar? Shraddha Travels offers cab Tempo Traveller services for a comfortable journey with ample space for passengers and luggage. Our experienced drivers are familiar with the best routes, ensuring you reach your destination safely and on time while enjoying the beautiful landscapes."
      },
      {
        name: "Pune to Goa Tempo Traveller on Rent",
        description: "Planning a trip to Goa? Shraddha Travels offers Tempo Travellers on rent, equipped with modern amenities to ensure a smooth and comfortable journey. Enjoy your beach holiday with friends and family without worrying about travel logistics. Our reliable service allows you to focus on having fun and making memories."
      },
      {
        name: "Luxury Tempo Traveller on Rent in Pune",
        description: "For those seeking a premium travel experience, Shraddha Travels provides Luxury Tempo Travellers featuring plush interiors, spacious seating, and advanced amenities. Perfect for special occasions or corporate events, our luxury vehicles ensure you travel in style and comfort."
      },
      {
        name: "Tempo Traveller On Rent in Pune Contact Number",
        description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Tempo Traveller rentals in Pune. Our dedicated team is ready to assist you with your booking and ensure a smooth and enjoyable ride for all our customers. Experience the convenience and reliability of our service today!"
      }
    ],
    tableData: [
        ['- Luxury Force Urbania on Rent in Hadapsar', '- 13-Seater Force Urbania on Rent in Pune'],
        ['- 17-Seater Force Urbania on Rent in Pune', '- Urbania Hire in Pune for Outstation'],
        ['- Pune to Lonavala Urbania Bus on Rent', '- Pune to 5 Jyotirlinga Darshan Urbania on Rent'],
        ['- Pune Darshan Urbania on Rent', '- Pune to Nashik Urbania on Rent'],
        ['- Pune to Bhimashankar Urbania Bus Hire', '- Pune to Ajanta Ellora Urbania Bus Hire'],
        ['- Luxury Force Urbania on Rent in Pune Near Me', '- Luxury Force Urbania on Rent in Kothrud'],
        ['- Luxury Force Urbania on Rent in Pune Near Me Contact Number', '- Luxury Force Urbania on Rent in Pimpri Chinchwad']
    ],    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Force Urbania Rentals?",
            WhyChoosedescription: "When it comes to renting a Force Urbania, Shraddha Travels is your trusted provider for a comfortable and reliable journey. Our commitment to excellence ensures that every trip is memorable, whether you're planning a family outing, a corporate retreat, or a special event. With a focus on customer satisfaction and a seamless booking process, we guarantee that you travel in style and comfort. Here's why Shraddha Travels is the best choice for your trip:"
        },
        {
            WhyChooseheading: "Spacious and Comfortable Vehicles:",
            WhyChoosedescription: "Our Force Urbania buses are designed to provide maximum comfort with ample seating and modern amenities. With features like plush seating, generous legroom, and climate control, you’ll enjoy a relaxed and enjoyable ride, no matter how long the journey. Each vehicle is crafted to enhance your travel experience, making it perfect for group travel."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "We take pride in our fleet's maintenance, ensuring that every Force Urbania bus is in peak condition. Each vehicle undergoes regular servicing and thorough inspections to guarantee optimal performance and reliability. Our dedication to vehicle upkeep means you can travel with peace of mind, knowing you're in a safe and dependable vehicle."
        },
        {
            WhyChooseheading: "Professional and Experienced Drivers:",
            WhyChoosedescription: "Our drivers are not only experienced and skilled but also undergo rigorous training to ensure safety and customer satisfaction. Familiar with local routes and outstation destinations, they prioritize your comfort and punctuality, ensuring a safe and pleasant journey. Their professionalism reflects our commitment to providing an exceptional travel experience."
        },
        {
            WhyChooseheading: "Competitive and Transparent Pricing:",
            WhyChoosedescription: "We offer competitive rates for our Force Urbania rentals without compromising on quality. Our transparent pricing model ensures that there are no hidden costs, allowing you to know exactly what you’re paying for. This clarity helps you budget effectively for your travel needs, making us an affordable choice for group travel."
        },
        {
            WhyChooseheading: "Customizable Travel Solutions:",
            WhyChoosedescription: "We understand that every trip is unique, which is why we tailor our services to meet your specific travel needs. Whether you require a bus for a family outing, corporate event, or religious pilgrimage, we provide customized solutions to ensure a seamless experience. Our flexibility allows you to create the perfect itinerary for your journey."
        },
        {
            WhyChooseheading: "Excellent Customer Service:",
            WhyChoosedescription: "From the moment you reach out to us until the end of your journey, our dedicated customer service team is here to assist you. We pride ourselves on our responsiveness and commitment to resolving any concerns. Our goal is to ensure a hassle-free and enjoyable experience for every customer, making your travel planning effortless."
        },
        {
            WhyChooseheading: "Punctual and Reliable:",
            WhyChoosedescription: "We understand the importance of punctuality in travel. Our buses adhere to strict schedules, ensuring timely departures and arrivals. Our commitment to reliability means you can count on us to be there when you need us, allowing you to plan your day without worry."
        }
    ]
    
  }



  const faqData = [
    {
      question: "What is included in the rental of a Force Urbania?",
      answer: "The rental includes comfortable seating, air-conditioning, and professional driver services. Additional amenities like audio systems and Wi-Fi may be available upon request to enhance your travel experience."
    },
    {
      question: "How do I book a Force Urbania with Shraddha Travels?",
      answer: "You can book by contacting us via phone, email, or by filling out our online booking form. Please provide details of your travel dates, pick-up and drop-off locations, and any special requirements to ensure a smooth booking process."
    },
    {
      question: "Are there any additional charges I should be aware of?",
      answer: "While our pricing is transparent, additional charges may apply for extra services or special requests. We will provide a detailed quote before finalizing your booking so you can plan accordingly."
    },
    {
      question: "What is the cancellation policy for a Force Urbania rental?",
      answer: "Cancellation policies vary. Generally, cancellations made well in advance incur a nominal fee, while last-minute cancellations may involve higher charges. For specific details, please contact our customer service."
    },
    {
      question: "Can I request a specific type of Force Urbania?",
      answer: "Yes, you can request specific features or types of Urbania buses based on availability. We offer a range of options to suit different travel needs."
    },
    {
      question: "Are the drivers experienced and licensed?",
      answer: "Yes, all our drivers are professionally trained, experienced, and hold valid licenses. They are committed to ensuring a safe and comfortable travel experience."
    },
    {
      question: "Can I make changes to my booking after it is confirmed?",
      answer: "Yes, changes can be made subject to availability and applicable fees. Please contact our customer service team as soon as possible to discuss any modifications."
    },
    {
      question: "What should I do if I need assistance during my journey?",
      answer: "If you need assistance during your journey, contact the driver or our customer service team immediately. We are dedicated to addressing any concerns or issues promptly."
    },
    {
      question: "How far in advance should I book a Force Urbania?",
      answer: "To ensure availability, we recommend booking as early as possible. For peak seasons or special events, early booking is advisable to secure your desired vehicle."
    },
    {
      question: "Are there any restrictions on luggage or other items?",
      answer: "Standard luggage allowances apply, but we can make special arrangements upon request. Please inform us of any specific needs when booking to ensure a smooth experience."
    }
];



const testimonials = [
    {
      name: "Mr. Rajesh Kumar",
      text: "Our experience with Shraddha Travels for a group trip using the Force Urbania was outstanding. The bus was luxurious and well-maintained, providing a comfortable ride throughout. The driver was professional and ensured we reached our destinations safely and on time. I highly recommend their services for anyone looking for premium travel options in Pune."
    },
    {
      name: "Ms. Priya Mehta",
      text: "We rented a Force Urbania for a corporate event, and the service was top-notch. The bus was spacious and equipped with all the necessary amenities, making our journey enjoyable. The booking process was smooth, and the driver was knowledgeable about the best routes. Shraddha Travels exceeded our expectations, and we’ll definitely use their services again."
    }
];



  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Contact Information for Luxury Force Urbania Rentals",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Luxury Force Urbania On Rent in Pune | Affordable Rentals</title>
    <meta name="description" content="Rent Luxury Force Urbania in Pune for all occasions. Available for local trips, outstation journeys, and more!" />
    <meta name="keywords" content="Luxury Force Urbania on rent Pune, 13 Seater Urbania, 17 Seater Urbania, Urbania hire for outstation" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Luxury Force Urbania On Rent in Pune | Affordable Rentals" />
    <meta property="og:description" content="Explore our Luxury Force Urbania rentals in Pune for family trips, corporate outings, and special events. Book now!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/luxury-force-urbania-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/luxury-force-urbania.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Luxury Force Urbania Rental in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/luxury-force-urbania-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Luxury Force Urbania",
                    "description": "Luxury Force Urbania rentals for local trips, outstation journeys, and events in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-17.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) About Luxury Force Urbania Rentals in Pune</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Luxuryforceurbaniaonrentinpune;