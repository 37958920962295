
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Nonacbusrentalservice() {



  const cardData =
  {
    keyword: ' Non-AC Bus Rental Service in Pune',
    heading: 'Shraddha Travels: Premier Non-AC Bus Rental Service in Pune',
    headingDescription: 'When it comes to economical and reliable group travel in Pune, Shraddha Travels is your top choice for non-AC bus rentals. Whether you’re planning a family picnic, a corporate outing, a school trip, or an outstation journey, our non-AC buses are the perfect blend of comfort and affordability. Here’s why our non-AC buses stand out and what makes them ideal for your travel needs.',
    top: 'Top Reasons to Choose Non-AC Bus Rental Service from Shraddha Travels in Pune',

    topPlaces: [
        {
          title: "1. Cost-Effective Option:",
          description: "Our non-AC buses provide an affordable transportation solution for groups, making them perfect for budget-conscious travelers. Enjoy the convenience of group travel without stretching your budget, allowing more funds for activities and experiences during your trip."
        },
        {
          title: "2. Ideal for Short Trips:",
          description: "Non-AC buses are perfect for local outings, school excursions, and day trips where air conditioning may not be necessary. Breathe in the fresh air as you travel through Pune's scenic routes, making it a great choice for casual getaways."
        },
        {
          title: "3. Spacious Seating:",
          description: "Designed to accommodate larger groups, our non-AC buses offer ample seating space, ensuring that everyone can travel together comfortably. This spacious design allows for easy movement and the ability to socialize during the journey."
        },
        {
          title: "4. Experienced Drivers:",
          description: "Our professional drivers are trained in safe driving practices and have extensive knowledge of local routes, ensuring a smooth and efficient journey for your group. Their expertise allows for timely arrivals and a stress-free travel experience."
        },
        {
          title: "5. Customizable Itineraries:",
          description: "We understand that every group has unique needs. Our non-AC bus rental service allows you to create a flexible itinerary, accommodating specific stops and destinations based on your plans, whether it's a picnic spot or a sightseeing location."
        },
        {
          title: "6. Regular Maintenance and Safety Checks:",
          description: "The safety of our passengers is our top priority. Our non-AC buses undergo routine maintenance and safety inspections, ensuring that they are in excellent condition for your journey. This commitment to safety provides peace of mind for all travelers."
        },
        {
          title: "7. Simple Booking Process:",
          description: "Our user-friendly online booking system makes it easy to reserve your non-AC bus quickly, allowing you to focus on planning your itinerary without unnecessary stress. Quick confirmations mean you can finalize your plans with ease."
        },
        {
          title: "8. 24/7 Customer Support:",
          description: "Our dedicated customer service team is always available to assist with any inquiries or changes regarding your booking, ensuring a seamless rental experience. We're here to help, day or night, to answer your questions or accommodate your needs."
        },
        {
          title: "9. Positive Customer Feedback:",
          description: "Shraddha Travels has built a reputation for reliability and customer satisfaction. Many of our clients return for their travel needs, reflecting our commitment to providing exceptional service and memorable travel experiences for groups of all sizes."
        },
        {
          title: "10. Eco-Friendly Choice:",
          description: "Non-AC buses are often more fuel-efficient for short distances, making them a more environmentally friendly option for group travel compared to multiple cars. Choosing a non-AC bus helps reduce your carbon footprint while enjoying a collective travel experience."
        }
    ],      
    services: [
        {
          name: "Non-AC Bus for Outstation Travel",
          description: "Ideal for long-distance journeys, our non-AC buses provide a cost-effective and comfortable solution for outstation trips. Enjoy the spacious interiors and ample seating, making your travel enjoyable without breaking the bank. Perfect for family vacations, group trips, or corporate outings, our non-AC buses ensure a hassle-free experience as you explore new destinations."
        },
        {
          name: "Non-AC Tempo Traveller on Rent",
          description: "For smaller groups needing additional comfort, our non-AC Tempo Travellers offer extra space and a pleasant travel experience. With comfortable seating and ample legroom, these vehicles are perfect for road trips, local sightseeing, or family outings. Enjoy the journey as much as the destination with our well-maintained Tempo Travellers."
        },
        {
          name: "Non-AC Mini Bus Hire",
          description: "Perfect for medium-sized groups, our non-AC mini buses are ideal for local trips and short-distance travel. They provide a comfortable setting for school excursions, corporate events, or family get-togethers, allowing everyone to travel together conveniently and affordably."
        },
        {
          name: "Non-AC Bus for Picnics",
          description: "Planning a family picnic? Our non-AC buses offer ample space and comfort for a relaxing and enjoyable outing. With room for all your picnic essentials and a friendly driver to get you there, you can focus on making memories rather than logistics."
        },
        {
          name: "Corporate Trip Rentals",
          description: "We provide reliable and affordable non-AC buses for corporate events, ensuring your team travels comfortably and on time. Our services cater to various corporate needs, whether it’s a team-building trip, client meetings, or employee outings, helping you maintain professionalism and comfort."
        },
        {
          name: "Non-AC Bus Per KM Rate in Pune",
          description: "Our per-kilometer rates for non-AC buses are competitive and transparent. Rates are based on distance traveled, with options available for both local and outstation trips. This allows you to budget effectively for your journey, making it easier to plan your travel expenses."
        },
        {
          name: "Bus on Rent in Pimpri Chinchwad",
          description: "For those in Pimpri Chinchwad, our non-AC buses are available for both local and outstation travel, ensuring reliable transportation right from your location. Whether it’s a family gathering, a corporate event, or a school trip, we provide convenient transport solutions tailored to your needs."
        },
        {
          name: "Bus on Rent in Hadapsar",
          description: "Our Hadapsar-based rental services offer convenient and comfortable non-AC buses for various needs, including corporate events and family gatherings. With our user-friendly booking process and dedicated customer support, planning your travel has never been easier."
        },
        {
          name: "Non-AC Bus Hire Prices",
          description: "We offer detailed quotes based on your specific requirements, ensuring you receive the best value for your rental. Our pricing structure is transparent, allowing you to understand exactly what you’re paying for, so there are no surprises when it comes time to settle the bill."
        },
        {
            name: "Non Ac Bus Rental Service in Pune Contact Number:",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Non Ac Bus Rental Service in Pune. We ensure a smooth and enjoyable ride for all our customers."
          }
      ],      
      tableData: [
        ['- Non AC Bus for Hire for Outstation', '- Non AC Tempo Traveller on Rent Pune'],
        ['- Non AC Bus Hire on Rent in Pune', '- Non AC Mini Bus Hire on Rent in Pune'],
        ['- Non AC Bus Hire for Picnic in Pune', '- Best Bus Rental Service in Pune'],
        ['- 32, 35, 40, 45 Seater Local and Outstation Bus', '- 14, 17, 20, 25 Seater Bus on Rent Pune'],
        ['- Best Bus Rental Services in Pune Tempo Traveller on Rent', '- Luxury Tempo Traveller on Rent in Kothrud'],
        ['- Non AC Bus Rental Service for Corporate Trip in Pune', '- Affordable Mini Bus on Rent in Pune'],
        ['- 13, 17, 20, 25, 32 Seater Bus on Rent in Pune', '']
      ],      
      whychoose: [
        {
          WhyChooseheading: "Why Choose Shraddha Travels for Non-AC Bus Rentals in Pune?",
          WhyChoosedescription: "When it comes to non-AC bus rentals in Pune, Shraddha Travels stands out as a reliable choice. Our focus on comfort, safety, and customer satisfaction makes us the preferred provider for groups of all sizes. Whether you're planning a school trip, a family outing, or a corporate event, our services are tailored to meet your specific needs, ensuring a smooth and enjoyable experience from start to finish."
        },
        {
          WhyChooseheading: "Spacious and Comfortable Buses:",
          WhyChoosedescription: "Our non-AC buses are designed to maximize passenger comfort. With generous seating arrangements and ample legroom, you can relax and enjoy your journey. The spacious interiors allow for easy movement, making it perfect for group outings. Additionally, our buses are equipped with large windows, providing you with great views and a pleasant travel atmosphere, whether you're exploring local sights or heading out for a picnic."
        },
        {
          WhyChooseheading: "Experienced and Professional Drivers:",
          WhyChoosedescription: "All of our drivers are highly trained and licensed professionals. They possess extensive knowledge of local and outstation routes, ensuring that you reach your destination safely and efficiently. Our drivers are not only skilled behind the wheel but also committed to providing excellent customer service. They understand the importance of punctuality and are always ready to assist with any special requests during your journey."
        },
        {
          WhyChooseheading: "Well-Maintained Fleet:",
          WhyChoosedescription: "We take great care to ensure that our buses are regularly serviced and maintained to the highest standards. Our fleet undergoes routine inspections to guarantee safety and reliability. Each bus is cleaned thoroughly before and after every trip, ensuring a pleasant environment for passengers. Our commitment to maintaining a top-quality fleet means you can travel with peace of mind, knowing that safety and comfort are our priorities."
        },
        {
          WhyChooseheading: "Transparent Pricing:",
          WhyChoosedescription: "At Shraddha Travels, we believe in transparency. Our pricing is clear and competitive, with no hidden fees. You can easily understand the cost breakdown, which includes mileage, driver charges, and any additional services you may require. This transparency allows for better budgeting and helps you plan your travel expenses without any surprises."
        },
        {
          WhyChooseheading: "Customizable Options:",
          WhyChoosedescription: "We understand that every group has unique needs. That's why we offer customizable options for our non-AC bus rentals. Whether you need a simple local outing, a corporate retreat, or a multi-day excursion, we can tailor our services to meet your specific requirements. You can specify your desired itinerary, including pick-up and drop-off points, and any special stops along the way to ensure a personalized travel experience."
        },
        {
          WhyChooseheading: "Excellent Customer Support:",
          WhyChoosedescription: "Our customer support team is dedicated to providing prompt and helpful assistance from the moment you book until the completion of your journey. We are available to answer your questions, address your concerns, and make any necessary adjustments to your plans. Our commitment to customer satisfaction means you can rely on us for a smooth rental experience, ensuring you have the support you need at every step."
        },
        {
          WhyChooseheading: "Safety First:",
          WhyChoosedescription: "We prioritize the safety of our passengers above all else. Our vehicles undergo routine safety checks, and our drivers are trained to follow all road safety regulations, ensuring a secure travel environment for everyone. We also provide first-aid kits in our buses, and our drivers are trained in basic emergency response, giving you peace of mind while on the road."
        },
        {
          WhyChooseheading: "Affordable Group Travel:",
          WhyChoosedescription: "Traveling with a group doesn’t have to break the bank. Our non-AC bus rentals offer a cost-effective solution for transporting larger groups without compromising on comfort or safety. Enjoy group outings without the financial stress. We provide competitive rates tailored to different group sizes, allowing you to maximize your travel experience while staying within budget."
        }
      ]
      
  }



  const faqData = [
    {
      question: "What is included in the rental of a non-AC bus?",
      answer: "Our non-AC bus rental includes comfortable seating and professional driver services. Additional amenities may be requested based on availability."
    },
    {
      question: "How do I book a non-AC bus with Shraddha Travels?",
      answer: "Contact us via phone, email, or our online booking form with your travel details, and we will assist with the reservation."
    },
    {
      question: "Are there additional charges for extra services?",
      answer: "Our pricing is transparent, but additional charges may apply for extended hours or special requests. We provide a detailed quote before booking."
    },
    {
      question: "What is the cancellation policy?",
      answer: "Cancellation policies vary based on timing. Contact us for specific details regarding your booking."
    },
    {
      question: "Can I request a specific type of non-AC bus?",
      answer: "Yes, requests can be made based on availability. We offer various bus sizes and configurations."
    },
    {
      question: "Are the drivers experienced and licensed?",
      answer: "Yes, all our drivers are experienced, licensed, and trained to ensure a safe journey."
    },
    {
      question: "Can I make changes to my booking?",
      answer: "Yes, changes can be made subject to availability and applicable fees. Contact our customer service team for assistance."
    },
    {
      question: "What should I do if I need assistance during my journey?",
      answer: "Contact the driver or our customer service team for immediate assistance."
    },
    {
      question: "How far in advance should I book?",
      answer: "Early booking is recommended to ensure availability, especially during peak seasons."
    },
    {
      question: "Are there restrictions on luggage?",
      answer: "Standard luggage allowances apply, with accommodations available for additional baggage upon request."
    }
  ];
  


  const testimonials = [
    {
      name: "Miss. Snehal Deshmukh",
      text: "I recently booked a non-AC bus from Shraddha Travels for a school excursion in Pune, and I was thoroughly impressed with the service. The bus was well-maintained and clean, which ensured a comfortable journey for all the students. The driver was punctual and handled the routes efficiently, making sure we reached our destination safely and on time. Despite being a non-AC bus, the ample ventilation and comfortable seating made the trip pleasant. Shraddha Travels provided excellent value for money and I highly recommend their non-AC bus rental services for any budget-friendly group travel needs."
    },
    {
      name: "Mr. Ravi Kumar",
      text: "For our family reunion, we needed a non-AC bus to accommodate a large group traveling around Pune. Shraddha Travels offered a fantastic solution. The bus was spacious and in great condition, and the staff ensured everything was up to our expectations. Although it was a non-AC model, the bus had good airflow and was comfortable for the entire trip. The driver was courteous and managed the traffic and routes skillfully, ensuring a smooth journey. I’m very pleased with the service and would certainly use Shraddha Travels again for similar needs."
    }
  ];
  

  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Non-Ac Bus Rental Service in Pune Contact Number:",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Non AC Bus Rental Service in Pune | Affordable Bus Rentals</title>
    <meta name="description" content="Rent non-AC buses in Pune for local and outstation trips. Affordable rates for family outings, corporate events, and picnics!" />
    <meta name="keywords" content="Non AC bus rental Pune, non AC tempo traveller, non AC mini bus hire, affordable bus rentals, bus hire for corporate trips" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Non AC Bus Rental Service in Pune | Affordable Bus Rentals" />
    <meta property="og:description" content="Explore affordable non-AC bus rental services in Pune for outings, corporate trips, and more. Book your bus today!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/non-ac-bus-rental-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/non-ac-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Non AC Bus Rental Service in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/non-ac-bus-rental-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Non AC Bus",
                    "description": "Rent non-AC buses for local and outstation trips in Pune at affordable rates."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword image-15.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>Frequently Asked Questions (FAQs) for Shraddha travels:</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Nonacbusrentalservice;