import React from 'react';
import '../css/Ourfleet.css'; // Ensure to create this CSS file
import { Helmet } from 'react-helmet';

const Ourfleet = () => {
  const bookingData = [
    {
      id: 1,
      imgSrc: '/img/our-fleet/Our Fleet-01.jpg',
      title: '13 Seater Tempo Traveller',
      description: 'Perfect for city rides and short trips with comfort.',
    },
    {
      id: 2,
      imgSrc: '/img/our-fleet/Our Fleet-02.jpg',
      title: '17 Seater Tempo Traveller',
      description: 'Spacious and ideal for family trips and off-road adventures.',
    },
    {
      id: 3,
      imgSrc: '/img/our-fleet/Our Fleet-03.jpg',
      title: '13 Searter Urbania Bus',
      description: 'Enjoy a premium experience with our luxury urbania.',
    },
    {
      id: 4,
      imgSrc: '/img/our-fleet/Our Fleet-04.jpg',
      title: '17 Searter Urbania Bus',
      description: 'Ideal for group travel, offering ample space and comfort.',
    },
    {
      id: 5,
      imgSrc: '/img/our-fleet/Our fleet 1-11.jpg',
      title: '14 Seater Tata Winger',
      description: 'Perfect for larger groups, providing great comfort.',
    },
    {
      id: 6,
      imgSrc: '/img/our-fleet/Our fleet 1-10.jpg',
      title: '20 Seater Ac / Non AC bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 7,
      imgSrc: '/img/our-fleet/Our fleet 1-09.jpg',
      title: '25 Seater Ac / Non AC bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 8,
      imgSrc: '/img/our-fleet/Our fleet 1-08.jpg',
      title: '32 Seater Ac / Non AC bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 9,
      imgSrc: '/img/our-fleet/Our fleet 1-07.jpg',
      title: '35 Seater Ac / Non AC bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 10,
      imgSrc: '/img/our-fleet/Our fleet 1-06.jpg',
      title: '40 Seater Ac / Non AC bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 11,
      imgSrc: '/img/our-fleet/Our fleet 1-05.jpg',
      title: '45 Seater Ac / Non AC bus ',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 12,
      imgSrc: '/img/our-fleet/Our fleet 1-04.jpg',
      title: '50 Seater Ac / Non AC bus ',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 13,
      imgSrc: '/img/our-fleet/Our fleet 1-03.jpg',
      title: 'Luxury Bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 14,
      imgSrc: '/img/our-fleet/Our fleet 1-02.jpg',
      title: 'Semi Luxury Bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
    {
      id: 15,
      imgSrc: '/img/our-fleet/Our fleet 1-01.jpg',
      title: 'Volvo Bus',
      description: 'For those seeking extra space and amenities on long trips.',
    },
  ];

  return (
    <div>
      <Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel</title>
    <meta name="description" content="Explore top-notch tempo traveller rental services in Pune. Choose from 17-seater options for local and outstation trips at competitive rates." />
    <meta name="keywords" content="Tempo Traveller on Rent, 17 Seater Tempo Traveller Pune, Tempo Traveller Hire Pune, Outstation Tempo Traveller Rental" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Tempo Traveller on Rent in Pune | Affordable & Comfortable Travel" />
    <meta property="og:description" content="Rent a tempo traveller in Pune for all occasions, including family trips, corporate outings, and vacations. Luxury and affordable options available." />
    <meta property="og:url" content="https://www.shraddhatravels.com/tempo-traveller-rent-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/tempo-traveller-pune.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Tempo Traveller on Rent in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/tempo-traveller-rent-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Tempo Traveller Rental Services in Pune",
                    "description": "Affordable tempo traveller rental services in Pune for local and outstation trips. Comfortable and luxurious options available."
                }
            }
        }
        `}
    </script>
</Helmet>

     <section id="about" className="jarallax text-light">
    <div className="center-y relative text-center">
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center">
                    <h1 className='text-white'>Our fleets</h1>
                </div>
                <div className="clearfix"></div>
            </div>
        </div>
    </div>
</section>

      <section className="our-booking">
        <div className="container">
          {/* <h2 className='section-title'>Explore Our Fleet</h2> */}
          <div className="row g-4">
            {bookingData.map(booking => (
              <div key={booking.id} className="col-12 col-md-6 col-lg-4">
                <div className="card booking-card">
                  <div className="card-image">
                    <img src={booking.imgSrc} className="card-img-top" alt={booking.title} />
                  </div>
                  <div className="card-content">
                    <div className="content-border">
                      <h3 className="card-title">{booking.title}</h3>
                      <p className="card-description">{booking.description}</p>
                      <a href="https://wa.me/918087847191" target="_blank" rel="noopener noreferrer">
    {/* <button className="btn btn-primary">Book Now</button> */}
</a>

                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
};

export default Ourfleet;
