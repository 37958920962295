import React from 'react';

const toursData = [
    {
        id: 1,
        country: 'Mahabaleshwer',
        title: '"Mahabaleshwar: A serene hill station famed for its breathtaking views, fresh air, and luscious strawberries."',
        price: 199,
        duration: '6 Nights / 7 Days',
        rating: 4.8,
        image: '/img/mahabaleshwer.jfif',
    },
    {
        id: 2,
        country: 'Bhimashankar',
        title: '"Bhimashankar: A serene pilgrimage site renowned for its ancient temple and lush greenery."',
        price: 250,
        duration: '4 Nights / 5 Days',
        rating: 5,
        image: '/img/bhimashankar.jpg',
    },
    {
        id: 3,
        country: 'Ajanta Caves',
        title: '"Ajanta Caves: Marvel at the ancient rock-cut sculptures and vibrant frescoes, a true testament to India is rich cultural heritage."',
        price: 119,
        duration: '2 Nights / 3 Days',
        rating: 4.1,
        image: '/img/ajanta.jpg',
    },
    {
        id: 4,
        country: 'Mumbai',
        title: '"Mumbai: A lively city where dreams come alive, blending rich history with a bustling modern vibe."',
        price: 299,
        duration: '6 Nights / 7 Days',
        rating: 4.5,
        image: '/img/mumbai.jfif',
    },
    {
        id: 5,
        country: 'Goa',
        title: '"Goa: A vibrant coastal haven of sun, sand, and sea, famous for its beautiful beaches and lively atmosphere."',
        price: 199,
        duration: '6 Nights / 7 Days',
        rating: 5,
        image: '/img/goa.jpg',
    },
    {
        id: 6,
        country: 'Jyotilinga',
        title: '"Maharashtras five Jyotirlingas are revered shrines of Lord Shiva, attracting pilgrims from all over."',
        price: 189,
        duration: '3 Nights / 4 Days',
        rating: 4.2,
        image: '/img/5-Jyotirlinga.jpg',
    },
    
    {
        id: 7,
        country: 'Lonavala',
        title: '"Lonavala: A picturesque hill station famous for its scenic landscapes, lush valleys, and waterfalls."',
        price: 150,
        duration: '5 Nights / 6 Days',
        rating: 4.0,
        image: '/img/lonavala.jpg',
    },
    {
        id: 8,
        country: 'Matheran',
        title: '"Matheran: A serene hill station offering stunning views, lush greenery, and the charm of toy trains, perfect for a getaway."',
        price: 239,
        duration: '5 Nights / 6 Days',
        rating: 4.8,
        image: '/img/matheran.jpg',
    },
];

const ToursSection = () => {
    return (
        <section className="tours-section pt_100 pb_70 py-5">
            {/* <div className="pattern-layer" style={{ backgroundImage: "url(assets/images/shape/shape-2.png)" }}></div> */}
            <div className="auto-container">
                <div className="tabs-box">
                    <div className="upper-box mb_60">
                        <div className="sec-title ">
                            <span className="sub-title text-center">Tour Packages</span>
                            <h2 className='text-center'>Featured Tours</h2>
                        </div>
                        {/* <div className="tab-btn-box p_relative">
                            
                        </div> */}
                    </div>
                    <div className="tabs-content">
                        <div className="tab active-tab" id="tab-1">
                            <div className="row clearfix">
                                {toursData.map(tour => (
                                    <div className="col-lg-3 col-md-6 col-sm-12 tours-block" key={tour.id}>
                                        <div className="tours-block-one">
                                            <div className="inner-box">
                                                <div className="image-box abhi">
                                                    <figure className="image pb-3">
                                                        <img src={tour.image} alt={tour.title} />
                                                    </figure>
                                                        <img src="assets/images/icons/icon-1.png" alt="" />
                                                </div>
                                                <div className="lower-content">
                                                    <h6 className='text-infoo'>{tour.country}</h6>
                                                    <p className='texter'>{tour.title}</p>
                                                  
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default ToursSection;
