import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import './css/style.css';
import './css/about.css';
import './css/chooseus.css';
import './css/tours.css';
import './css/category.css';
import './css/place.css';
import './css/news.css';
import './css/contact-info.css';
import './css/testimonial.css';
import './css/place.css';
import './css/animate.css';
import './css/color.css';
// import './css/elpath.css';
import './css/flaticon.css';
// import './css/font-awesome-all.css';
import './css/nice-select.css';
// import './css/owl.css';
import './css/responsive.css';
import './css/rtl.css';
import './css/switcher-style.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import 'font-awesome/css/font-awesome.min.css'; // For Font Awesome 4

import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
