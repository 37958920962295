
import Accordion from './Accordion';
import BusRatesTable from './BusRatesTable';
import CustomCarousel from './CustomCarousel';
import './smallkey.css';
import { Helmet } from 'react-helmet';


function Bushireforschool() {



  const cardData =
  {
    keyword: 'Bus Hire for School Trips in Pune',
    heading: 'Shraddha Travels: Bus Hire for School Trips in Pune',
    headingDescription: 'When it comes to organizing school trips, safety, comfort, and reliability are paramount. At Shraddha Travels, we understand these needs and offer exceptional bus hire services tailored specifically for school trips in Pune. Here are the top features of our bus hire service that make us the preferred choice for educational institutions:',

    topPlaces: [
        {
            title: "1. Spacious and Comfortable Seating",
            description: "Our buses are equipped with spacious seating arrangements designed for student comfort. With ample legroom and well-cushioned seats, your students will enjoy a relaxed journey, making the trip more enjoyable. The seating layout promotes social interaction, allowing students to engage with each other during travel."
        },
        {
            title: "2. Safety First",
            description: "Safety is our top priority. Our buses are fitted with advanced safety features, including seat belts, emergency exits, and GPS tracking. Our drivers are highly trained and experienced in ensuring a secure journey for all passengers. Regular safety drills and checks are conducted to ensure compliance with all safety regulations."
        },
        {
            title: "3. Air-Conditioned Buses",
            description: "To keep the students comfortable in all weather conditions, we offer air-conditioned buses. Our climate control systems ensure a pleasant environment throughout the trip, no matter how hot or cold it may be outside. This feature helps maintain focus and comfort, particularly on longer journeys."
        },
        {
            title: "4. Well-Maintained Fleet",
            description: "We take pride in maintaining our fleet to the highest standards. Each bus undergoes regular inspections and servicing to ensure it is in top-notch condition. This rigorous maintenance schedule minimizes the risk of breakdowns and ensures a smooth ride, giving parents and teachers peace of mind."
        },
        {
            title: "5. Professional and Friendly Drivers",
            description: "Our drivers are not only skilled and professional but also friendly and approachable. They are trained to handle the unique needs of school trips, ensuring a smooth and enjoyable experience for both students and teachers. Our drivers also act as guides, providing valuable information about the destinations."
        },
        {
            title: "6. Customizable Travel Packages",
            description: "We offer flexible and customizable travel packages to suit your school’s specific needs. Whether you require a full-day trip, a half-day outing, or transportation for special events, we can tailor our services to fit your schedule and budget. Our team will work closely with you to create an itinerary that meets your expectations."
        },
        {
            title: "7. Entertainment Options",
            description: "To keep the students entertained during the journey, many of our buses are equipped with entertainment options like audio systems and DVD players. This feature helps make the trip enjoyable and engaging for the students, allowing them to watch movies or listen to music while traveling."
        },
        {
            title: "8. Affordable Rates",
            description: "At Shraddha Travels, we offer competitive pricing for our school trip bus hire services. Our transparent pricing structure means there are no hidden fees, and you can be assured of getting great value for your money. Special discounts for educational institutions can also be discussed."
        },
        {
            title: "9. Punctuality and Reliability",
            description: "We understand the importance of sticking to schedules, especially for school trips. Our buses adhere to strict schedules to ensure timely departures and arrivals, helping you stay on track with your planned itinerary. Our commitment to punctuality ensures that students maximize their time at destinations."
        },
        {
            title: "10. Excellent Customer Support",
            description: "Our dedicated customer support team is available to assist you throughout the booking process and during the trip. We are committed to addressing any concerns or issues promptly, ensuring a hassle-free experience for schools and students alike. Our team is just a phone call away, ready to provide assistance at any time."
        }
    ],    
    services: [
        {
            name: "Bus Hire for School Trips in Hadapsar Pune",
            description: "When planning a school trip in Hadapsar, Pune, look no further than our reliable bus hire services. We offer a range of options to accommodate your students comfortably and safely. Our well-maintained buses are ideal for school excursions, ensuring a smooth and enjoyable journey. With experienced drivers and modern amenities, we provide the perfect solution for all your school trip needs in Hadapsar."
        },
        {
            name: "Bus Hire for School Trips in Pimpri Chinchwad",
            description: "For school trips in Pimpri Chinchwad, our bus hire services offer unmatched comfort and convenience. Whether it's an educational tour or a recreational outing, we cater to your specific requirements with our fleet of spacious and well-equipped buses. Trust us for a hassle-free experience, complete with safe and professional drivers to ensure your students have a memorable trip."
        },
        {
            name: "Bus Hire for School Picnic to Morachi Chincholi",
            description: "Planning a school picnic to Morachi Chincholi? Our bus hire services provide the perfect transportation solution. Morachi Chincholi, known for its beautiful environment and educational value, is an excellent choice for a school outing. Our buses offer ample space and comfort for students, making the journey enjoyable and stress-free. Book with us for a smooth trip to this fantastic picnic spot."
        },
        {
            name: "Bus Hire for School Picnic Pune to Mahabaleshwar",
            description: "For school picnics from Pune to Mahabaleshwar, our bus hire services are the ideal choice. Mahabaleshwar's scenic beauty and pleasant climate make it a popular destination for school outings. Our buses are equipped to handle long journeys, ensuring your students travel comfortably. With our reliable service, your trip to Mahabaleshwar will be both enjoyable and memorable."
        },
        {
            name: "Bus Hire for School Picnic Pune to Alibaug",
            description: "Looking for bus hire for a school picnic from Pune to Alibaug? Our services offer comfortable and safe transportation for your students to this beautiful coastal destination. Alibaug's beaches and attractions make it an exciting choice for school picnics. Our buses provide a pleasant travel experience, with ample space and modern amenities to ensure a great trip."
        },
        {
            name: "Bus Hire for Pune to Mumbai for School Picnic",
            description: "For school picnics from Pune to Mumbai, our bus hire services ensure a smooth and comfortable journey. Whether you’re heading to Mumbai’s attractions or planning a visit to a specific location, our well-maintained buses are equipped to handle the trip. Enjoy a hassle-free ride with our experienced drivers and arrive at your destination refreshed and ready for fun."
        },
        {
            name: "Bus Hire for Pune to Water Park for School Picnic",
            description: "When planning a school picnic to a water park from Pune, our bus hire services offer the perfect solution. Water parks are a popular choice for school outings, providing fun and relaxation for students. Our buses are designed to accommodate large groups comfortably, with features that ensure a pleasant journey to and from the water park."
        },
        {
            name: "Bus Hire for Pune to Outstation School Picnic",
            description: "For outstation school picnics from Pune, our bus hire services provide reliable and comfortable transportation. Whether you’re heading to a nearby town or a distant destination, our buses are equipped to handle long journeys with ease. Enjoy a stress-free trip with our professional drivers and well-maintained vehicles, ensuring a memorable outing for your students."
        },
        {
            name: "Bus Hire for School Picnic in Pimpri Chinchwad",
            description: "Our bus hire services for school picnics in Pimpri Chinchwad offer the perfect solution for your transportation needs. Whether it's a local picnic spot or a nearby destination, we provide comfortable and safe buses to accommodate your group. With our focus on quality and reliability, you can count on us for a great school picnic experience."
        },
        {
            name: "Mini Bus On Rent in Pune Contact Number",
            description: "Contact Shraddha Travels at +91 8087847191 for prompt and efficient Mini Bus On Rent in Pune. We ensure a smooth and enjoyable ride for all our customers."
        }
    ],
    
    tableData: [
        ['- Bus hire for school trips in Hadapsar Pune', '- Bus hire for School Trips in Pimpri Chinchwad'],
        ['- Bus Hire for School Picnic Morachi Chincholi', '- Bus hire for school picnic Pune to Mahabaleshwar'],
        ['- Bus Hire for School Picnic Pune to Alibaug', '- Bus hire for Pune to Mumbai for school picnic'],
        ['- Bus hire for Pune to water park for school picnic', '- Bus hire for Pune to outstation school picnic in Pune'],
        ['- Bus hire for school picnic in Pimpri Chinchwad', '']
    ],
    
    whychoose: [
        {
            WhyChooseheading: "Why Choose Shraddha Travels for Bus Hire for School Trips in Pune?",
            WhyChoosedescription: "At Shraddha Travels, we prioritize the safety and comfort of your children. Our services ensure a secure and enjoyable journey for school trips, making us a trusted provider for schools in Pune. With a commitment to excellence and a passion for service, we strive to create memorable experiences that enrich the educational journeys of students. Our professional approach and attention to detail set us apart, ensuring that every aspect of your trip is handled with care and efficiency."
        },
        {
            WhyChooseheading: "Safe and Secure Transportation:",
            WhyChoosedescription: "Our buses are equipped with GPS tracking, seat belts, and experienced drivers to ensure a safe journey during school trips. We conduct thorough safety checks before each trip, ensuring that our vehicles are in perfect condition. Your peace of mind is our priority."
        },
        {
            WhyChooseheading: "Well-Maintained Fleet:",
            WhyChoosedescription: "Our fleet is regularly maintained to ensure optimal performance and comfort, accommodating groups of all sizes with mini-buses and large buses. Each vehicle undergoes routine inspections and servicing to guarantee a smooth ride."
        },
        {
            WhyChooseheading: "Affordable Rates:",
            WhyChoosedescription: "We offer competitive pricing for school bus hire with no hidden charges, helping schools plan their trips within budget. Our transparent pricing policy allows for easy budgeting, so you can focus on creating a great experience for your students."
        },
        {
            WhyChooseheading: "Experienced Drivers:",
            WhyChoosedescription: "Our professional drivers are well-trained and familiar with Pune’s routes, ensuring timely and safe arrivals. They prioritize the safety of all passengers, and their friendly demeanor helps create a positive atmosphere during the journey."
        },
        {
            WhyChooseheading: "Comfortable and Spacious Buses:",
            WhyChoosedescription: "Our buses feature comfortable seating and ample space, providing a refreshing journey for students and teachers. With air conditioning and entertainment options available, students can relax and enjoy the ride, making their trip enjoyable and memorable."
        },
        {
            WhyChooseheading: "Tailored Services:",
            WhyChoosedescription: "We offer customized bus hire services for unique school trip needs, from one-day excursions to educational tours. Our team works closely with schools to understand their specific requirements, ensuring a tailored solution that meets their expectations."
        },
        {
            WhyChooseheading: "Punctual and Reliable:",
            WhyChoosedescription: "We ensure our buses arrive on time, allowing trips to proceed smoothly, earning the trust of schools across Pune. Our punctuality reflects our commitment to providing reliable service, helping to keep your schedule on track."
        },
        {
            WhyChooseheading: "Support for School Events:",
            WhyChoosedescription: "In addition to school trips, we provide bus hire for events like sports meets and annual days, with flexible services to meet your needs. Our dedicated team is here to assist with logistics, ensuring that every event runs smoothly."
        }
    ]    
  }



  const faqData = [
    {
        question: "What safety measures do you provide for school trips?",
        answer: "At Shraddha Travels, safety is our top priority. Our buses are equipped with seat belts, GPS tracking, and first-aid kits. Our drivers are experienced and trained to handle school groups with care, ensuring a safe journey for all passengers."
    },
    {
        question: "What are the seating capacities of your buses?",
        answer: "We offer buses with varying seating capacities, ranging from 20-seater mini-buses to 50-seater large buses. You can choose the bus that fits the size of your group, ensuring comfort and space for all."
    },
    {
        question: "How do you ensure that the buses are clean and well-maintained?",
        answer: "Our buses undergo regular maintenance checks and are cleaned thoroughly before every trip. We ensure that the buses are in excellent condition for a comfortable and hassle-free experience, giving you peace of mind."
    },
    {
        question: "Can we hire buses for overnight school trips or outstation tours?",
        answer: "Yes, we provide bus hire services for both day trips and overnight outstation school tours. Our services are flexible and can be tailored to meet the duration and location of your school trip, accommodating all your needs."
    },
    {
        question: "Are the drivers familiar with the routes to common school trip destinations in and around Pune?",
        answer: "Yes, our drivers are experienced and well-acquainted with popular school trip destinations in Pune and nearby areas. They ensure that the journey is smooth and that students reach their destination on time, making the trip enjoyable."
    },
    {
        question: "What are your charges for school bus hire?",
        answer: "Our charges depend on the type of bus, distance, and duration of the trip. We offer competitive pricing and provide a detailed quote after discussing your requirements, ensuring transparency and no hidden costs."
    },
    {
        question: "How do I book a bus for a school trip?",
        answer: "You can book a bus by contacting us through our website, phone, or email. Our customer service team will assist you in choosing the right bus and provide a customized quote based on your needs, making the booking process easy."
    },
    {
        question: "Is there an option to reschedule or cancel a bus booking?",
        answer: "Yes, you can reschedule or cancel your booking based on our cancellation policy. We advise early communication to avoid any cancellation charges, ensuring flexibility for your planning."
    },
    {
        question: "Do you offer additional support for school events like sports or annual functions?",
        answer: "Yes, we offer bus hire services for school events such as sports meets, annual functions, and educational tours. We can accommodate any special requirements to make the event transportation smooth and hassle-free."
    },
    {
        question: "Can we request additional staff to accompany the trip for added safety?",
        answer: "Yes, we can provide additional staff or supervisors to accompany the trip for enhanced safety and assistance, especially for younger students. This can be discussed and arranged during the booking process."
    }
];



const testimonials = [
    {
        name: "Ms. Kavita Deshmukh",
        text: "We have been using Shraddha Travels for our school trips for the past two years, and their service has always been exceptional. The buses are well-maintained, and the drivers are professional and punctual. Safety is our top concern, and Shraddha Travels has consistently provided a secure and comfortable experience for our students. Their affordable rates and flexibility make them our go-to bus service for all our school events. Highly recommended!"
    },
    {
        name: "Mr. Anil Patil",
        text: "Shraddha Travels made our recent school trip to Sinhagad Fort a breeze. The bus was clean, spacious, and arrived on time. The driver was courteous and ensured that the students were comfortable throughout the journey. We also appreciated the GPS tracking feature, which gave parents peace of mind. Shraddha Travels is reliable, and we will definitely be using their services for future school outings!"
    }
];


  const carouselItems = testimonials.map(testimonial => (
    <div key={testimonial.name}>
      <blockquote>

        <p className='testi'>   <span className="quote">“</span>{testimonial.text}</p>
        <footer className=' user'>— {testimonial.name}</footer>
      </blockquote>
    </div>
  ));

  const carouselOptions = {
    loop: true,
    margin: 10,
    nav: true,
    items: 1
  };

  const contactData = {
    heading: " Bus Hire for School Trips in Pune Contact Number",
    contactNumbers: [
      "+91 8087847191",
      "+91 9552010777",

    ],
    email: "booking@shraddhatravel.com"
  };


  const Images = [
    {
      place: "/img/tempoo.jpg",
      text: "Tempo Traveller Hire in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "Mini Bus On Rent in Pune",
    },
    {
      place: "/img/32-seat.jfif",
      text: "32 Seater Bus Rent in Pune",
    },
    {
      place: "/img/45-seatt.png",
      text: "45 Seater Bus on Rent in Pune",
    },
    {
      place: "/img/luxury.jfif",
      text: "Luxury Bus Rental in Pune",
    },
    {
      place: "/img/forcee.webp",
      text: "Force Urbania On Rent in Pune",
    },
    {
      place: "/img/buss.jpg",
      text: "Bus Service for Picnic in Pune",
    },
    {
      place: "/img/minu-buss.jpg",
      text: "17 Seater Tempo Traveller on Rent in Pune",
    },
    {
      place: "/img/Keyword/Keyword 4-11.jpg",
      text: "Pune Local Bus on Rent",
    },
    {
      place: "/img/Keyword/Keyword 1-03.jpg",
      text: " Bus On Rent in Kharadi Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-04.jpg",
      text: "Bus on Rent in Hinjewadi",
    },
    {
      place: "/img/Keyword/Keyword 1-05.jpg",
      text: "Bus Hire For Wedding in Pune",
    },
    {
      place: "/img/Keyword/Keyword 1-06.jpg",
      text: "Buses For Corporate Events in Pune ",
    },
    {
      place: "/img/Keyword/Keyword 1-11.jpg",
      text: "Bus on Rent For Event in Pune",
    },

     {
      place: "/img/Keyword/Keyword 2 -14.jpg",
      text: "Pune to Goa Bus Hire",
    },
    {
      place: "/img/Keyword/Keyword 3-03.jpg",
      text: "Bus on Rent in Hadapsar",
    },
    {
      place: "/img/Keyword/Keyword 2 -10.jpg",
      text: "Corporate Travels in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-06.jpg",
      text: "Tourist Bus Rental Services",
    },
    {
      place: "/img/Keyword/Keyword 4-05.jpg",
      text: "Monthly Bus Hire Service in Pune",
    },
    {
      place: "/img/Keyword/Keyword 3-08.jpg",
      text: "Bus hire for Pune to Maharashtra Darshan",
    },
    {
      place: "/img/Keyword/Keyword 1-16.jpg",
      text: "Bus hire for Pune to Ashtavinayak",
    },
    {
      place: "/img/Keyword/Keyword image-07.jpg",
      text: "Pune to Shirdi Tempo Traveller On Rent ",
    },
  ];





  return (
    <div>
<Helmet>
    <title>Contact: +91 8087847191 | Shraddha Travels | Bus Hire for School Trips in Pune | Affordable School Picnic Rentals</title>
    <meta name="description" content="Reliable bus hire services for school trips in Pune. Enjoy safe and affordable transport for school picnics and outings." />
    <meta name="keywords" content="Bus Hire for School Trips in Pune, School Picnic Bus Rentals, Bus hire for school trips in Hadapsar, Bus hire for Pune to Mahabaleshwar" />

    {/* Open Graph tags */}
    <meta property="og:title" content="Contact: +91 8087847191 | Shraddha Travels | Bus Hire for School Trips in Pune | Affordable School Picnic Rentals" />
    <meta property="og:description" content="Plan your school outings with our reliable bus hire services in Pune. Comfortable and safe transportation for all your school trips!" />
    <meta property="og:url" content="https://www.shraddhatravels.com/bus-hire-school-trips-pune" />
    <meta property="og:type" content="website" />
    <meta property="og:image" content="https://www.shraddhatravels.com/assets/images/school-trips-bus.jpg" />

    {/* Schema markup */}
    <script type="application/ld+json">
        {`
        {
            "@context": "https://schema.org",
            "@type": "Service",
            "serviceType": "Bus Hire for School Trips in Pune",
            "provider": {
                "@type": "Organization",
                "name": "Shraddha Travels",
                "url": "https://www.shraddhatravels.com",
                "logo": "https://www.shraddhatravels.com/assets/images/logo.png",
                "sameAs": [
                    "https://www.facebook.com/shraddhatravels",
                    "https://twitter.com/shraddhatravels",
                    "https://www.instagram.com/shraddhatravels"
                ]
            },
            "areaServed": "Pune, Maharashtra",
            "offers": {
                "@type": "Offer",
                "url": "https://www.shraddhatravels.com/bus-hire-school-trips-pune",
                "priceCurrency": "INR",
                "price": "Variable",
                "itemOffered": {
                    "@type": "Product",
                    "name": "Bus Hire for School Trips",
                    "description": "Affordable and reliable bus hire services for school trips and picnics in Pune."
                }
            },
            "aggregateRating": {
                "@type": "AggregateRating",
                "ratingValue": "4.8",
                "ratingCount": "100"  // Replace with actual rating count
            }
        }
        `}
    </script>
</Helmet>

      <section id="about" className="jarallax text-light">
        <div className="center-y relative text-center">
          <div className="container">
            <div className="row ">
              <div className="col-md-12 text-center">
                <h1 className='text-white'>{cardData.keyword}</h1>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      </section>




      <section>
        <div className="container-fluid" >
          <div className="row container-fluid">
            <div className="col-12 col-md-7 ">
              <img src='/img/Keyword/Keyword 1-01.jpg' alt='img' />
              <h3 className="py-1"
                style={{
                  color: '#671547', // Red color for the title 
                  textShadow: '5px 5px 10px rgba(255, 255, 255, 0.7)', // Light shadow effect
                  fontWeight: 'bold'
                }}

              >{cardData.heading} </h3><p className='fw-bold lead'>{cardData.headingDescription}</p>
              <div className="">
                <p className='fw-bold py-3'>{cardData.top}</p>
                {cardData.topPlaces.map((place, index) => (
                  <div key={index} className="">
                    <div
                      style={{
                        cursor: 'pointer',
                        padding: '10px',
                        marginBottom: '7px',
                        borderRadius: '8px', // Optional: rounded corners
                        transition: 'transform 0.2s' // Optional: smooth scaling effect
                      }}
                    // Centers text within the card
                    >
                      <h4 className="mb-1 darkcolor">{place.title}</h4>
                      <p className="mb-0">{place.description}</p>
                    </div>
                  </div>
                ))}
              </div>
              <div
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  marginBottom: '7px',
                  borderRadius: '8px', // Optional: rounded corners
                  transition: 'transform 0.2s' // Optional: smooth scaling effect
                }}
              >
                {cardData.services.map((service, index) => (
                  <div key={index} className=" my-4">
                    <h4 className="py-1 darkcolor">{service.name}</h4>
                    <p>{service.description}</p>
                  </div>
                ))}
              </div>

              <table className="table table-responsive Border-key my-2">
                <tbody className=' Border-key'>
                  {cardData.tableData.map((row, rowIndex) => (
                    <tr className='Border-key ' key={rowIndex}>
                      {row.map((cell, cellIndex) => (
                        <td key={cellIndex} className=' Border-key bluecolor fw-bold' >{cell}</td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>



              <div id="why-choose-section"
                className='px-2'>
                {cardData.whychoose.map((item, index) => (
                  <div key={index}>
                    <h4 className="py-1 whycolor">{item.WhyChooseheading}</h4>
                    <p>{item.WhyChoosedescription}</p>
                  </div>
                ))}
              </div>

              <div>
                <h3 className='Colo'>FAQs: Bus Hire for School Trips in Pune for Shraddha Travels</h3>
                <Accordion data={faqData} />
              </div>
              <div className=''>


                <CustomCarousel items={carouselItems} options={carouselOptions} />
              </div>

              <div className='py-5'>

                <div className="contact-box borderrr py-md-3">
                  <h2>{contactData.heading}</h2>
                  <p>For booking inquiries or any assistance, please feel free to contact us:</p>
                  <div className="contact-details">
                    <p><strong className=''>Mobile No:</strong></p>
                    <ul>
                      {contactData.contactNumbers.map((number, index) => (
                        <li key={index}>
                          <a href={`tel:${number}`} className="contact-link fw-bold fs-5 darkcolor">
                            {number}
                          </a>
                        </li>
                      ))}
                    </ul>
                    <p><strong className=''>Email Id: </strong>
                      <a href={`mailto:${contactData.email}`} className="contact-link darkcolor">
                        {contactData.email}
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className='col-12 col-md-4' >
              {Images.map((e) => {
                return (
                  <div className="box1">
                    <a
                      href="./"
                      className="d-flex justify-content-around align-items-center"
                    >
                      <div className="b1">
                        <img src={e.place} alt="img" />
                      </div>
                      <div className="b2">
                        {" "}
                        <a href="./">{e.text}</a>
                      </div>
                    </a>
                  </div>
                );
              })}

              <div className=" rounded p-4 shadow-sm Uni_border"
                style={{
                  cursor: 'pointer',
                  padding: '10px',
                  border: '1px solid #681448',
                  // backgroundColor: '#681448',
                  marginBottom: '7px',
                  fontWeight: 'bold',
                  color: 'white'
                }}

              >
                <h4 className="pb-3 pt-3 lead fw-bold text-dark">Contact Information</h4>

                <div className="row">

                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center py-2 " style={{backgroundColor:'#681448'}}>
                      <h4 className=" lead fw-semibold whitt">Phone Numbers</h4>
                      <i className="bi bi-telephone-fill fs-1 mb-2"></i>
                      <div className=''>
                        <a href="tel:+91 8087847191" className="d-block  text-white">+91 8087847191</a>
                        <a href="tel:+91 9552010777" className="d-block text-white">+91 9552010777</a>

                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3 ">
                    <div className="Small_border rounded text-center">
                      <i className="bi bi-envelope fs-1 mb-2"></i>
                      <div  className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Email</h4>
                        <a href="mailto:booking@shraddhatravel.com" className=" text-white d-block">
                          booking@shraddhatravel.com
                        </a>
                      </div>
                    </div>
                  </div>


                  <div className="col-12 mb-3">
                    <div className="Small_border  rounded text-center">
                      <i className="bi bi-house-fill fs-1 mb-2"></i>
                      <div className='rounded py-2' style={{backgroundColor:'#681448'}}>
                        <h4 className=" fw-semibold lead whitt">Address</h4>
                        <p className="whit text-white ">
                          <i>

                            Shraddha Travels:<br />
                            Bhairab nivas lane number 8 <br />
                            uttareshwar Nagar  S/R nb.275<br />
                            sathe vasti Lohegaon Pune <br />
                            Maharashtra 411014
                          </i>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>



            </div>
            <BusRatesTable />
          </div>
        </div>
      </section>






    </div>
  );
}

export default Bushireforschool;