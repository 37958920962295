import React, { useState, useEffect } from 'react';

const Visionn = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const sectionStyle = {
        padding: isMobile ? '0px' : '40px',
        margin: '0 auto',
    };

    const containerStyle = {
        maxWidth: isMobile ? '100%' : '1200px',
        margin: '0 auto',
        padding: '0 15px',
    };

    const rowStyle = {
        display: 'flex',
        flexDirection: isMobile ? 'column' : 'row',
        justifyContent: 'center',
        gap: '20px',
    };

    const colStyle = {
        flex: '1',
        minWidth: isMobile ? '100%' : '300px',
        boxSizing: 'border-box',
        marginBottom: isMobile ? '20px' : '0',
    };

    const headingStyle = {
        textAlign: 'center',
        paddingBottom: '20px',
        fontSize: isMobile ? '24px' : '32px',
        color: '#343a40',
    };

    const visionContentStyle = {
        backgroundColor: '#6B144A', 
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
        textAlign: 'center',
        color: 'white',
    };

    const sectionTitleStyle = {
        color: '#6B144A', // Light color for titles
    };
    const sectionTitleStylee = {
        color: 'white'
    };

    return (
        <section style={sectionStyle}>
            <div style={containerStyle}>
                <h3 style={{ ...headingStyle, ...sectionTitleStyle }}>About Our Vision & Mission</h3>
                <div style={rowStyle}>
                    <div style={colStyle}>
                        <div style={visionContentStyle}>
                            <h4 style={sectionTitleStylee}>Vision</h4>
                            <p className='text-white'>
                                At Shraddha Travels , our vision is to revolutionize the transportation experience by offering safe, reliable, and comfortable rides that cater to every traveler’s needs. We aim to connect people and places with a seamless travel experience that prioritizes customer satisfaction and safety. Our commitment to leveraging innovative technology and sustainable practices ensures that every journey with us is not only enjoyable but also eco-friendly. Join us as we pave the way for a future where travel is efficient, dependable, and accessible to all.
                            </p>
                        </div>
                    </div>
                    <div style={colStyle}>
                        <div style={visionContentStyle}>
                            <h4 style={sectionTitleStylee}>Mission</h4>
                            <p className='text-white'>
                                Our mission at Shraddha Travels  is to provide unmatched customer service by delivering prompt and dependable transportation solutions. We strive to create a safe and enjoyable travel environment through rigorous driver training and high-quality vehicle maintenance. By embracing customer feedback and continuously improving our services, we seek to exceed expectations at every turn. Our goal is to build lasting relationships with our passengers, ensuring that every ride is a memorable and positive experience.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Visionn;
